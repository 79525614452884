import { ActivitiesStorageQuery } from './../../storage/activities-storage/activities-storage.query';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { MatTableDataSource } from '@angular/material/table';
import { MachineService } from '../../storage/machine-storage/machine-storage.service';
import { MachineStorageQuery } from '../../storage/machine-storage/machine-storage.query';
import { MatDialog } from '@angular/material/dialog';
import { Machine } from '../../storage/machine-storage/machine-storage.store';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { Subscription } from 'rxjs';
import { MoneyQuery } from '../../storage/money-storage/money-storage.query';
import { DialogConfirmationResource } from '../confirmation-dialog/confirmation-dialog';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import { MoneyService } from '../../storage/money-storage/money-storage.service';
import { DialogProviderComponent } from '../dialog-provider/dialog-provider.component';
import { ProviderService } from '../../storage/provider-storage/provider-storage.service';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { UserQuery } from '@src/app/store/user/user.query';
import { DialogShowMachineryComponent } from '../dialogs/dialog-show-machinery/dialog-show-machinery.component';

@Component({
	selector: 'app-machinery-table',
	templateUrl: './machinery-table.component.html',
	styleUrls: ['./machinery-table.component.scss'],
})
export class MachineryTableComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = [
		'position',
		'name',
		'model',
		'abbreviation',
		'date',
		'type_possession',
		'quantity',
		'unit',
		'coin',
		'cost',
		'totalhour',
		'totalcost',
	];
	public displayedColumnsOriginal: string[] = [
		'position',
		'name',
		'model',
		'abbreviation',
		'date',
		'type_possession',
		'quantity',
		'unit',
		'coin',
		'cost',
		'totalhour',
		'totalcost',
	];
	public dataSource;
	public elementData = [];
	public moneyValues = [];
	public copiedElement = undefined;
	private numberOfElementsModified = 0;
	private idsOfElemetsThatWereModified = [];
	private subscriptions = new Subscription();
	private isSaving = false;
	private idsOfElementsToBeDeleted = [];
	public machineTypes = [
		{ id: 'Abonadora' },
		{ id: 'Arado' },
		{ id: 'Arado cincel' },
		{ id: 'Arado de secado' },
		{ id: 'Excavadora' },
		{ id: 'Cortadora de césped' },
		{ id: 'Cosechadora' },
		{ id: 'Cosechadora de algodón' },
		{ id: 'Cosechadora de cereales' },
		{ id: 'Cosechadora de forraje' },
		{ id: 'Cosechadora de remolacha' },
		{ id: 'Cultivador' },
		{ id: 'Descompactador' },
		{ id: 'Desmotadora de algodón' },
		{ id: 'Desbrozadora o motoguadaña' },
		{ id: 'Desgranadora' },
		{ id: 'Desvaradora' },
		{ id: 'Enfardadora o empacadora' },
		{ id: 'Fumigadora' },
		{ id: 'Grada o rastra de dientes' },
		{ id: 'Motocultor' },
		{ id: 'Motor para riego' },
		{ id: 'Plataforma de recogida de frutas' },
		{ id: 'Pulverizadora' },
		{ id: 'Rastra de discos' },
		{ id: 'Remolque' },
		{ id: 'Rodillo o rolo' },
		{ id: 'Rotocultor' },
		{ id: 'Secadora de granos' },
		{ id: 'Segadora' },
		{ id: 'Sembradora' },
		{ id: 'Subsolador' },
		{ id: 'Tractor' },
		{ id: 'Trituradora o moledora' },
		{ id: 'Vendimiadora' },
		{ id: 'Vibrocultivador' },
		{ id: 'Otro' },
	];
	public possessionType = ['Alquiler', 'Propio'];
	public unityTypes = ['Hora – maquina', 'Dia – maquina'];

	constructor(
		private readonly globalToastService: GlobalToastService,
		private machineService: MachineService,
		private moneyQuery: MoneyQuery,
		private providerService: ProviderService,
		private machineQuery: MachineStorageQuery,
		public dialog: MatDialog,
		public sharedData: SharedDataServiceService,
		public moneyService: MoneyService,
		public activityQuery: ActivitiesStorageQuery,
		public userQuery: UserQuery
	) {}

	ngOnInit(): void {
		this.initializeData();
	}

	public async initializeData() {
		await this.moneyService.findAll();
		this.moneyQuery.selectAll().subscribe((allMoneys) => {
			this.moneyValues = allMoneys;
		});
		await this.machineService.findAll();
		this.fillTableData();
		this.subscriptions.add(
			this.sharedData.getClickSaveMachine().subscribe((x) => {
				this.save();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilter().subscribe((x) => {
				this.filterData();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToExport().subscribe((x) => {
				this.exportDataToExcel();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilterColumns().subscribe((x) => {
				if (this.sharedData.selectedColumns.length == 0)
					this.displayedColumns = this.displayedColumnsOriginal;
				else {
					this.sharedData.selectedColumns.unshift('position');
					this.displayedColumns = this.sharedData.selectedColumns;
				}
			}),
		);
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue;
	}

	public filterData() {
		var rowSelected = this.sharedData.columnSelectedToFilter;
		var textInput = this.sharedData.searchTextToFilter;
		if (rowSelected === undefined) {
			return;
		}
		this.setDataSourceFilter(rowSelected);

		this.applyFilter(textInput);
	}

	public setDataSourceFilter(rowSelected) {
		switch (rowSelected) {
			case '1':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.name
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '2':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.model
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '3':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.date
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '4':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.type_possession
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '5':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.quantity
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '6':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.unit
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '7':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.coin
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '8':
				this.dataSource.filterPredicate = (
					data: Machine,
					filter: string,
				) => {
					return data.cost
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			default:
				return;
		}
	}

	public fillTableData() {
		this.subscriptions.add(
			this.machineQuery.selectAll().subscribe((all) => {
				this.elementData = [];
				all.forEach((element) => {
					var elemnToBeInserted = {
						id: element.id,
						name: element.name,
						model: element.model,
						abbreviation: '',
						date: element.date,
						type_possession: element.type_possession,
						quantity: element.quantity,
						unit: element.unit,
						cost: element.cost,
						coin: element.coin,
						totalHour: 0,
						totalCost: 0,
					};
					if (
						elemnToBeInserted.quantity == undefined ||
						isNaN(parseInt(elemnToBeInserted.quantity))
					) {
						elemnToBeInserted.quantity = '';
					}
					elemnToBeInserted.abbreviation = this.getAbrreviation(
						element.name,
					);
					this.elementData.push(elemnToBeInserted);
				});
				if (this.elementData.length < 20) {
					var elementsNeededToCompleteTable =
						20 - this.elementData.length;
					for (
						let index = 0;
						index < elementsNeededToCompleteTable;
						index++
					) {
						this.elementData.push(this.getNewMachineObject());
					}
				}
				const allActivities = this.activityQuery.getAll();
				this.calculateTotalCostAndTotalHourPerMachineObject(
					allActivities,
				);
				this.dataSource = new MatTableDataSource(this.elementData);
			}),
		);
	}

	public calculateTotalCostAndTotalHourPerMachineObject(allActivities) {
		if (allActivities.length > 0) {
			this.elementData.forEach((element) => {
				if (element.id != '') {
					element.totalHour = 0;
					element.totalCost = 0;
					for (let index = 0; index < allActivities.length; index++) {
						const activityObject = allActivities[index];
						if (activityObject.activity.detail.length > 0) {
							for (
								let j = 0;
								j < activityObject.activity.detail.length;
								j++
							) {
								const resource =
									activityObject.activity.detail[j];
								if (
									resource.resource + '' == element.id &&
									resource.type == 1
								) {
									const horasMaquina =
										activityObject.activity.workday *
										activityObject.activity.duration *
										(resource.percentage / 100);
									element.totalHour =
										horasMaquina + element.totalHour;

									const horasMaquinaPrice = parseInt(
										element.cost,
									);
									const totalValue =
										horasMaquina * horasMaquinaPrice;
									element.totalCost =
										totalValue + element.totalCost;
									break;
								}
							}
						}
					}
					if (element.totalHour > 0)
						element.totalHour = (
							Math.round(element.totalHour * 100) / 100
						).toLocaleString('en-US');

					if (element.totalCost > 0)
						element.totalCost = (
							Math.round(element.totalCost * 100) / 100
						).toLocaleString('en-US');
				}
			});
		}
	}

	public getNewMachineObject() {
		return {
			id: '',
			name: 'Abonadora',
			model: '',
			date: '',
			abbreviation: '',
			type_possession: 'Alquiler',
			quantity: '',
			unit: 'Hora – maquina',
			cost: '',
			coin: '1',
			totalHour: 0,
			totalCost: 0,
		};
	}

	public isNotListedToBeUpdated(idOfLabor) {
		return (
			idOfLabor !== '' &&
			idOfLabor !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(idOfLabor)
		);
	}

	public getAbrreviation(word: string) {
		if (word.length >= 3) {
			return word.substr(0, 3);
		} else {
			return word;
		}
	}

	public addRowUp(position) {
		this.elementData.splice(position, 0, this.getNewMachineObject());
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public addRowDown(position) {
		this.elementData.splice(position, 0, this.getNewMachineObject());
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public copy(element) {
		var elemnToBeInserted = {
			id: 'new',
			name: element.name,
			model: element.model,
			date: element.date,
			abbreviation: element.abbreviation,
			type_possession: element.type_possession,
			quantity: element.quantity,
			unit: element.unit,
			cost: element.cost,
			coin: element.coin,
		};
		this.copiedElement = elemnToBeInserted;
	}

	public paste(position) {
		this.elementData[position] = this.copiedElement;
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public delete(position) {
		this.idsOfElementsToBeDeleted.push(this.elementData[position].id);
		if (
			this.idsOfElemetsThatWereModified.includes(
				this.elementData[position].id,
			)
		) {
			var index = this.idsOfElemetsThatWereModified.indexOf(
				this.elementData[position].id,
			);
			this.idsOfElemetsThatWereModified.splice(index, 1);
		}
		this.elementData.splice(position, 1);
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public changeType(event, element) {
		element.name = event.target.value;
		element.abbreviation = this.getAbrreviation(element.name);
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public changeModel(event, element) {
		element.model = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public changeDate(event, element) {
		element.date = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeTypeOfPossession(event, element) {
		element.type_possession = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeAmount(event, element) {
		element.quantity = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeUnity(event, element) {
		element.unit = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeCoin(event, element) {
		element.coin = event.target.value + '';
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeMachineHourCost(event, element) {
		element.cost = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public save() {
		if (this.isSaving) {
			return;
		} else {
			this.isSaving = true;
		}
		this.numberOfElementsModified = 0;
		var newElemets: Machine[] = [];
		var elementsToBeUpdated: Machine[] = [];
		for (let index = 0; index < this.elementData.length; index++) {
			const element = this.elementData[index];
			if (element.id !== '') {
				this.numberOfElementsModified++;
			}
			if (element.id === 'new') {
				if (this.validation(element)) {
					newElemets.push(element);
				} else {
					if (
						!(
							element.model == '' &&
							element.date == '' &&
							element.quantity == '' &&
							element.cost == ''
						)
					) {
						this.isSaving = false;
						this.globalToastService.createErrorToast(
							'Error',
							'Por favor llenar todos los campos obligatorios',
						);
						return;
					}
				}
			}
			if (this.idsOfElemetsThatWereModified.includes(element.id)) {
				if (this.validation(element)) {
					elementsToBeUpdated.push(element);
				} else {
					this.isSaving = false;
					this.globalToastService.createErrorToast(
						'Error',
						'Por favor llenar todos los campos obligatorios',
					);
					return;
				}
			}
		}
		if (
			newElemets.length === 0 &&
			elementsToBeUpdated.length === 0 &&
			this.idsOfElementsToBeDeleted.length === 0
		) {
			this.sharedData.sentViewConsultance();
			this.isSaving = false;
			return;
		}
		const dialogRef = this.dialog.open(DialogConfirmationResource, {
			data: {
				mensaje:
					'¿Desea guardar ' +
					this.numberOfElementsModified +
					' recursos de "Maquinaria"?',
			},
		});

		var g = dialogRef.afterClosed().subscribe((result) => {
			if (result === undefined) {
				this.idsOfElemetsThatWereModified = [];
				this.idsOfElementsToBeDeleted = [];
				this.fillTableData();
				this.sharedData.sentViewConsultance();
			}

			if (result) {
				if (newElemets.length > 0)
					this.machineService.createManyMachines(newElemets);
				if (elementsToBeUpdated.length > 0)
					this.machineService.updateMany(elementsToBeUpdated);
				if (this.idsOfElementsToBeDeleted.length > 0)
					this.machineService.deleteMany(
						this.idsOfElementsToBeDeleted,
					);
				this.idsOfElementsToBeDeleted = [];
				this.idsOfElemetsThatWereModified = [];
				this.sharedData.sentViewConsultance();
			}

			if (result === false) {
			}
			this.numberOfElementsModified = 0;
			this.isSaving = false;
			g.unsubscribe();
		});
	}

	public validation(element: Machine): boolean {
		if (
			element.name === '' ||
			element.unit === '' ||
			element.coin === '' ||
			element.cost === ''
		) {
			return false;
		}
		return true;
	}

	public exportDataToExcel() {
		if (this.elementData !== undefined) {
			const worksheet = XLSX.utils.json_to_sheet(this.elementData);
			const workbook: XLSX.WorkBook = {
				Sheets: { data: worksheet },
				SheetNames: ['data'],
			};
			const excelBuffer: any = XLSX.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			this.saveAsExcelFile(excelBuffer, 'excelFileName');
		}
	}
	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: ExcelConst.EXCEL_TYPE });
		FileSaver.saveAs(
			data,
			fileName +
				'_export_' +
				new Date().getTime() +
				ExcelConst.EXCEL_EXTENSION,
		);
	}

	public ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	public async getProvider(e, element) {
		const type = '1';
		if (element.id !== '') {
			const provider = await this.providerService.get(element.id, type);
			provider['type'] = type;
			provider['resource'] = element.id;
			this.openDialog(e, provider);
		}
	}
	public openDialog(e, data): void {
		e.stopPropagation();
		e.preventDefault();

		const dialogRef = this.dialog.open(DialogProviderComponent, {
			width: '400px',
			height: '450px',
			disableClose: true,
			panelClass: 'custom-dialog-container',
			data: data,
		});
	}

	public async getResourceInformation(element) {
		this.machineService.updateActive(element.id);
		const dialogRef = this.dialog.open(DialogShowMachineryComponent, {
			width: '600px',
			height: '400px',
			panelClass: 'modalClass',
		});
	}

	public isValidPlan() {
        const currentUser = this.userQuery.getInformation();
        return currentUser.permission.resourceManagement;
    }

    public showConfirmPlanUpdate() {
        if (this.isValidPlan())
            return;

        const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
            width: '400px'
        });
        saveRef.afterClosed().subscribe()
    }

	
}
