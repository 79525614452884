import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { Router } from '@angular/router';
import { MyFieldsUiQuery, MyFieldsUiService } from '../../../store';

@Component({
	selector: 'app-resources-navbar',
	templateUrl: './resources-navbar.component.html',
	styleUrls: ['./resources-navbar.component.scss'],
})
export class ResourcesNavbarComponent implements OnInit {
	public options: SelectItem[];

	constructor(private readonly myFieldsUiService: MyFieldsUiService) {
		this.options = [
			{ label: 'Personal', value: 1 },
			{ label: 'Maquinaria', value: 2 },
			{ label: 'Recursos', value: 3 },
		];
	}

	ngOnInit(): void {}

	public changeResource({ value }: any) {
		this.myFieldsUiService.setSelectedResource(value);
	}
}
