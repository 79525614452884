import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainPageComponent, ResourcesPageComponent } from './pages';

import { FieldsComponent } from './components';

const routes: Routes = [
	{
		path: '',
		component: MainPageComponent,
		children: [
			{
				path: '',
				redirectTo: 'fields',
				pathMatch: 'full',
			},
			{
				path: 'fields',
				loadChildren: () =>
					import('./modules/fields/fields.module').then(
						(m) => m.FieldsModule
					),
			},
			{
				path: 'resources',
				component: ResourcesPageComponent,
			},
		],
	},
	// {
	// 	path: 'fields',
	// },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MyFieldsRoutingModule {}
