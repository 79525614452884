import { Injectable } from '@angular/core';


@Injectable()
export class SharedMapData {

    public polygonCoords;
	public polygonCenter;
    public polygonArea;
    public lotID;
    public ndvi;

    constructor() { }

    public setNDVI( e){
        this.ndvi = e;
    }

    public getNDVI(){
        return this.ndvi;
    }

    public setPolygonCoords(coords){
        this.polygonCoords = coords;
    }

    public setPolygonCenter(center){
        this.polygonCenter = center;
    }

    public setPolygonArea(area){
        this.polygonArea = area;
    }
}