import {
	CanActivate,
	Router,
	RouterStateSnapshot,
	ActivatedRouteSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { AuthHttpService } from '@store/auth/auth-http.service';
import { UserQuery } from '@src/app/store/user/user.query';

@Injectable()
export class AuthGuard implements CanActivate {
	public constructor(
		private readonly router: Router,
		private readonly authService: AuthHttpService,
		private readonly userQuery: UserQuery
	) { }

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		const path = route.url[0].path;

		const isLoggedIn = this.authService.isLoggedIn();


		if (isLoggedIn) {
			if (path == "auth") {
				this.router.navigate(['/my-fields']);
				return false;
			}

			if (route.data.permission) {
				const currentUser = this.userQuery.getInformation();
				const type: string = route.data.permission[0]
				return currentUser.permission[type];
			}

			return true;
		}
		if (path == "auth")
			return true

		this.router.navigate(['/auth/login'], {
			queryParams: {
				returnUrl: state.url
			}
		});

		return false;
	}
}
