import { ActivitiesStore } from './../../../storage/activities-storage/activities-storage.store';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelExportService } from '@src/app/shared/services/excel-export/excel-export.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { Subscription } from 'rxjs';
import { ActivitiesStorageQuery } from '../../../storage/activities-storage/activities-storage.query';
import { WaterStorageQuery } from '../../../storage/water-storage/water-storage.query';
import { Water } from '../../../storage/water-storage/water-storage.store';
import { CalendarActivityComponent } from '../calendar-activity/calendar-activity.component';
import { ActivityDetailInfoComponent } from '../activity-detail-info/activity-detail-info.component';

@Component({
	selector: 'app-dialog-show-water',
	templateUrl: './dialog-show-water.component.html',
	styleUrls: ['../dialog-resource-detail.component.scss'],
})
export class DialogShowWaterComponent implements OnInit {
	displayedColumns: string[] = [
		'fieldName',
		'lotName',
		'activity',
		'days',
		'unity',
		'amount',
		'cost',
	];
	dataSource = new MatTableDataSource();
	public activeWaterObject: Water = undefined;
	public activitiesForTable = [];
	public suscriptions: Subscription[] = [];
	public dataToBeDisplayed = [];
	private dialogCalendar = undefined;
	private dialogActivity = undefined;

	constructor(
		public dialogRef: MatDialogRef<DialogShowWaterComponent>,
		public activityQuery: ActivitiesStorageQuery,
		public waterQuery: WaterStorageQuery,
		public readonly lotsQuery: LotsQuery,
		private readonly fieldsQuery: FieldsQuery,
		private readonly exportService: ExcelExportService,
		public activitiesStore: ActivitiesStore,
		public dialog: MatDialog,
	) {
		this.activeWaterObject = this.waterQuery.getEntity(
			this.waterQuery.getActiveId(),
		);
		this.suscriptions.push(
			this.activityQuery.selectAllActivities.subscribe((allActivies) => {
				this.dataSource = new MatTableDataSource();
				this.dataToBeDisplayed = [];
				for (let index = 0; index < allActivies.length; index++) {
					const activityObject = allActivies[index];
					if (activityObject.activity.detail.length > 0) {
						for (
							let j = 0;
							j < activityObject.activity.detail.length;
							j++
						) {
							const resource = activityObject.activity.detail[j];
							if (
								resource.resource + '' ==
									this.activeWaterObject.id &&
								resource.type == 2
							) {
								const lotInfo = this.lotsQuery.getEntity(
									activityObject.camp_id,
								);
								const fieldInfo = this.fieldsQuery.getEntity(
									lotInfo.fieldId,
								);
								const costPerProduct = parseFloat(
									this.activeWaterObject.cost,
								);
								var totalValue =
									resource.percentage * costPerProduct;

								if (totalValue > 0)
									totalValue =
										Math.round(totalValue * 100) / 100;

								this.dataToBeDisplayed.push({
									fieldName: fieldInfo.name,
									lotName: lotInfo.name,
									activity: activityObject.activity.name,
									unity: this.activeWaterObject.unit,
									amount: resource.percentage,
									cost: totalValue.toLocaleString('en-US'),
									activityId: activityObject.id,
									days: activityObject.activity.duration,
								});
								break;
							}
						}
					}
				}
				this.dataSource = new MatTableDataSource(
					this.dataToBeDisplayed,
				);
			}),
		);
	}
	ngOnDestroy(): void {
		this.unsuscribeFromAll();
	}

	ngOnInit(): void {}

	close() {
		this.unsuscribeFromAll();
		if (this.dialogCalendar !== undefined) this.dialogCalendar.close();
		if (this.dialogActivity !== undefined) this.dialogActivity.close();
		this.dialogRef.close();
	}

	unsuscribeFromAll() {
		this.suscriptions.forEach((element) => {
			element.unsubscribe();
		});
	}

	exportToExcel() {
		if (this.dataToBeDisplayed.length > 0) {
			this.exportService.exportDataToExcel(
				this.dataToBeDisplayed,
				'resource-water-' + this.activeWaterObject.id,
			);
		}
	}

	public openActivityDetail(activityId){
		if (this.dialogActivity !== undefined) this.dialogActivity.close();
		if (this.dialogCalendar !== undefined) this.dialogCalendar.close();
		this.activitiesStore.setActive(activityId);
		this.dialogActivity = this.dialog.open(ActivityDetailInfoComponent, {
			width: '400px',
			height: '350px',
			panelClass: 'modalClass',
			hasBackdrop: false,
			disableClose: true
		});
	}

	public openCalendar(activityId) {
		if (this.dialogCalendar !== undefined) this.dialogCalendar.close();
		if (this.dialogActivity !== undefined) this.dialogActivity.close();
		this.activitiesStore.setActive(activityId);
		this.dialogCalendar = this.dialog.open(CalendarActivityComponent, {
			width: '300px',
			height: '300px',
			disableClose: true,
			panelClass: 'modalClass',
			hasBackdrop: false,
		});
	}
}
