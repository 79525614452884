import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Calendar {
    id?: number;
    start_week?: number;
    start_schedule?: string;
    end_schedule?: string;
    start_break?:string,
    end_break?:string,
    days?:string
}

export interface CalendarState extends EntityState<Calendar, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'calendar-storage' })
export class CalendarStore extends EntityStore<CalendarState> {

    constructor() {
        super();
        //this.initializeData();
    }
}

