import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@src/environments/environment';

import { SupplyStore, Supply, EmailProvider } from './supplies-storage.store';
import { SupplyStorageQuery } from './supplies-storage.query';

export interface Message {
    StatusCode: number,
    Error: string,
    message: Supply[]
}

@Injectable({ providedIn: 'root' })
export class SupplyService {

    constructor(
        private supplyStore: SupplyStore,
        private supplyStorageQuery: SupplyStorageQuery,
        private http: HttpClient
    ) {
    }

    public updateActive(id){
        this.supplyStore.setActive(id)
    }

    public async findAll(): Promise<Supply[]> {
        const hasCache = this.supplyStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }

        try {
            const data = await this.http
                .get<Message>(`${environment.apiUrl}/resource/`)
                .toPromise<Message>();

            const dataArray = data.message

            const supplies = dataArray.map((currentLabor: any) => {
                const supply = currentLabor;
                supply.id = String(supply.id);
                supply.quantity = String(supply.quantity);
                supply.coin = String(supply.coin);
                return supply;
            });
            this.supplyStore.set(supplies);
            return supplies;
        }
        catch (error) {
        }
    }

    public async createManyMachines(arrayOfMachineObjects) {
        try {
            arrayOfMachineObjects.forEach(element => {
                element.quantity = parseInt(element.quantity);
                element.coin = parseInt(element.coin);
                delete element.id;
                delete element.abbreviation;
                delete element.costPerUnit;
                if (element.date === "")
                    element.date = null
            });
            const machine = await this.http
                .post<Message>(`${environment.apiUrl}/resource/`,
                    arrayOfMachineObjects
                )
                .toPromise<Message>();
            const dataArray = machine.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.supplyStore.add(element)
            });
        } catch (error) {

        }
    }

    public async updateMany(arrayOfLaborObjects) {
        try {
            arrayOfLaborObjects.forEach((element) => {
                element.id = parseInt(element.id)
                element.quantity = parseInt(element.quantity);
                element.coin = parseInt(element.coin);
                delete element.abbreviation;
                delete element.costPerUnit;
                if (element.date === "")
                    element.date = null
            });
            const labor = await this.http
                .put<Message>(`${environment.apiUrl}/resource/`,
                    arrayOfLaborObjects
                )
                .toPromise<Message>();
            const dataArray = labor.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.supplyStore.update(element.id, element)
            });
        } catch (error) {

        }
    }

    public async deleteMany(arrayOfLaborObjects) {
        try {
            var idsOfSupplyObjects = []
            arrayOfLaborObjects.forEach(element => {
                idsOfSupplyObjects.push(parseInt(element))
            });
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    idsOfSupplyObjects
            };
            await this.http
                .delete(`${environment.apiUrl}/resource/`,
                    options
                )
                .toPromise();
            arrayOfLaborObjects.forEach(element => {
                this.supplyStore.remove(element)
            });
        } catch (error) {
        }
    }
    public async sendEmailProvider(data: EmailProvider) {
        try {
            let response_provider = await this.http
                .post<any>(`${environment.apiAgromonitor}/report/`,
                    data
                )
                .toPromise<any>();
            
            response_provider.message.forEach(element => {
                element.resources.forEach(resource => {
                    this.supplyStore.update(resource.id, resource)
                });
                
            });
            return response_provider.statusCode
        } catch (error) {
            console.log(error)
        }
    }
}   
