import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { environment } from '@src/environments/environment';

export interface Generic {
    main: {
        feels_like: string,
        humidity: string,
        pressure: string,
        temp: string,
        temp_max: string,
        temp_min: string,
    }
    weather: [
        {
            description: string,
            icon: string,
            id: string,
            main: string,

        }
    ],
    body: {
        lat: string,
        lon: string,
        timezone: string,
        timezone_offset: string,

        current: {
            dt: string,
            sunrise: string,
            sunset: string,
            temp: string,
            feels_like: string,
            pressure: string,
            humidity: string,
            dew_point: string,
            uvi: string,
            clouds: string,
            wind_speed: string,
            wind_deg: string,
            weather: [
                {
                    id: string,
                    main: string,
                    description: string,
                    icon: string
                }
            ]
        }

        hourly: [
            {
                dt: string,
                temp: string,
                feels_like: string,
                pressure: string,
                humidity: string,
                dew_point: string,
                clouds: string,
                wind_speed: string,
                wind_deg: string,
                weather: [
                    {
                        id: string,
                        main: string,
                        description: string,
                        icon: string
                    }
                ]
            }
        ]

        daily: [
            {
                dt: string,
                sunrise: string,
                sunset: string,
                temp: {
                    day: string,
                    min: string,
                    max: string,
                    night: string,
                    eve: string,
                    morn: string
                },
                feels_like: {
                    day: string,
                    night: string,
                    eve: string,
                    morn: string
                },
                pressure: string,
                humidity: string,
                dew_point: string,
                wind_speed: string,
                wind_deg: string,
                weather: [
                    {
                        id: string,
                        main: string,
                        description: string,
                        icon: string
                    }
                ],
                clouds: string,
                rain: string,
                uvi: string
            }
        ]
    }
}

@Injectable({
    providedIn: 'root'
})
export class MetServiceService {

    constructor(
        private readonly http: HttpClient,
    ) { }


    public async getTemperatureForLot(lat: string, lon: string, lot_id: string) {
        try {
            const data = await this.http.get<any>(`${environment.apiUrl}/agromet/currentWeather/` + "?lat=" + lat + "&lon=" + lon + '&camp_id=' + lot_id).toPromise<any>();
            return data.message as Generic;
        } catch (error) {
            console.log(error)
        }
    }
}