import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageProcessingService } from '@src/app/modules/services/image-processing/image-processing.service';

export interface DiseaseInputData {
  id: string;
  userId: string;
}

@Component({
  selector: 'dialog-no-more-space',
  styles: [
    `
      .content {
        padding: 0;
        margin: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
      }
      .header-options {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
      .title-header {
        display: flex;
        width: 100%;
        background: #5c953b;
        padding: 5px;
        align-items: center;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      .title-header h2 {
        margin: 0px;
        color: white;
        width: 100%;
        text-align: center;
        line-height: 100%;
      }
      .tab {
        width: 50%;
        color: white;
        background-color: #78787a;
        font-size: 1rem;
        padding: 0.5rem;
        font-weight: 600;
        border: none;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      .tab-active {
        background-color: #a7a9aa;
      }
      .body-cnt {
        background: #f8f8f8;
        width: 100%;
        height: 100%;
      }
      .body-cnt-plague {
        background: #f8f8f8;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 6px;
      }
      .body-cnt-desc {
        text-align: center;
        font-size: 1rem;
        margin: 1rem 0;
      }

      .activity-btn {
        color: white;
        background-color: #1b991b;
        width: 90%;
      }
      .is-center {
        text-align: center;
      }
      .image-cnt {
        position: relative;
        width: 100%;
        min-height: 256px;
        padding: 25px;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .image-title {
        position: absolute;
        bottom: 6px;
        left: 10px;
        color: white;
        font-size: 1rem;
      }

      .disease-info {
        padding: 1rem;
        list-style-type: none;
      }

      .img-disease {
        max-height: 250px;
      }

      .back-button {
        background-color: #f8f8f8;
        color: #1b991b !important;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    `,
  ],
  template: `
    <div mat-dialog-content class="content">
      <header class="header-options">
        <div class="title-header">
          <h2>Reconocimiento de Enfermedades</h2>

          <button (click)="closeDialog()" mat-mini-fab class="back-button"><mat-icon>close</mat-icon></button>
        </div>
        <div style="background: #5c953b;">
          <button class="tab" [ngClass]="{ 'tab-active': selectedTab === 0 }" type="button" (click)="selectedTab = 0">
            Fotografía
          </button>
          <button class="tab" [ngClass]="{ 'tab-active': selectedTab === 1 }" type="button" (click)="selectedTab = 1">
            Información de la plaga
          </button>
        </div>
      </header>
      <ng-container *ngIf="diseaseInformation">
        <div class="body-cnt is-center" *ngIf="selectedTab === 0">
          <div class="image-cnt" style="background-image: url({{ urlbase + diseaseInformation.imageName }});">
            <!-- <img
              class="img-disease"
              [src]="urlbase + diseaseInformation.imageName"
              [alt]="diseaseInformation.prediction.title"
            /> -->
            <h2 class="image-title">{{ diseaseInformation.prediction.title }}</h2>
          </div>

          <p class="body-cnt-desc"><b>Descripción: </b>{{ diseaseInformation.description }}</p>
          <button class="activity-btn" mat-button type="button" (click)="createActivity()">+ Adicionar atividade</button>
        </div>
        <div class="body-cnt-plague" *ngIf="selectedTab === 1">
          <div class="image-cnt" [style]="'background-image: url(' + imageURL + ')'">
            <!-- <img
              class="img-disease"
              [src]="diseaseInformation.prediction.image_url"
              [alt]="diseaseInformation.prediction.title"
            /> -->
            <h2 class="image-title">{{ diseaseInformation.prediction.title }}</h2>
          </div>
          <ul class="disease-info">
            <li>
              <p>
                <b>Nombre del cultivo:</b>
                {{ diseaseInformation.prediction.crop_name }}
              </p>
            </li>
            <li>
              <p>
                <b>Tipo:</b>
                {{ diseaseInformation.prediction.type }}
              </p>
            </li>
            <li>
              <p>
                <b>Agente Causal:</b>
                {{ diseaseInformation.prediction.causal_agent }}
              </p>
            </li>
            <li>
              <p>
                <b>Familia de Agente Causal:</b>
                {{ diseaseInformation.prediction.family }}
              </p>
            </li>
            <li>
              <p>
                <b>Orden:</b>
                {{ diseaseInformation.prediction.order }}
              </p>
            </li>
            <li>
              <p>
                <b>Descripción:</b>
                {{ diseaseInformation.prediction.description }}
              </p>
            </li>
            <li>
              <p>
                <b>Recomendaciones:</b>
                {{ diseaseInformation.prediction.prevent }}
              </p>
            </li>
            <li>
              <p>
                <b>Diseminación:</b>
                {{ diseaseInformation.prediction.diseminacion }}
              </p>
            </li>
            <li>
              <p>
                <b>Órganos afectados:</b>
                {{ diseaseInformation.prediction.organo_afectado }}
              </p>
            </li>
            <li>
              <p>
                <b>Condiciones de Desarrollo:</b>
                <br />
                <span [innerText]="diseaseInformation.prediction.development_conditions"></span>
              </p>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  `,
})
export class DiseaseInformationDialog implements OnDestroy {
  diseaseInformation: any = null;
  selectedTab = 0;
  urlbase = 'https://app.infoagro.pe/disease-recognition/static/uploads/';
  imageURL = '';
  event = null;
  counter = 1;

  constructor(
    public dialogRef: MatDialogRef<DiseaseInformationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DiseaseInputData,
    private imageProcessingService: ImageProcessingService,
  ) {
    const { userId, id } = this.data;
    this.imageProcessingService.getRegistredDiseases(userId, id).subscribe({
      next: (response) => {
        console.log(response);
        this.diseaseInformation = response;
        this.imageURL = this.diseaseInformation.prediction.image_url;
        this.event = setInterval(() => {
          this.counter++;

          if (this.counter === 1) {
            this.imageURL = this.diseaseInformation.prediction.image_url;
          }
          if (this.counter === 2) {
            this.imageURL = this.diseaseInformation.prediction.image_url_2;
          }
          if (this.counter === 3) {
            this.imageURL = this.diseaseInformation.prediction.image_url_3;
            this.counter = 1;
          }
        }, 3000);
      },
    });
  }
  ngOnDestroy(): void {
    clearInterval(this.event);
  }

  closeDialog() {
    this.dialogRef.close('CLOSE');
  }

  createActivity() {
    this.dialogRef.close('CREATE_ACTIVITY');
  }
}
