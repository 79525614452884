import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Header } from '../../constants/header';
import { PreviewActivity } from '../../constants/previewActivity';
import { ManageModalActivityService } from '../../services/manage-modal-activity.service';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { FullActivity } from '../../storage/activities-storage/activities-storage.store';
import { LaborService } from '../../storage/labor-storage/labor-storage.service';
import { MachineService } from '../../storage/machine-storage/machine-storage.service';
import { WaterService } from '../../storage/water-storage/water-storage.service';


@Component({
	selector: 'app-week-calendar',
	templateUrl: './week-calendar.component.html',
	styleUrls: ['./week-calendar.component.scss'],
})
export class WeekCalendarComponent implements OnInit, AfterViewChecked {
	@ViewChild('scrollOne') scrollOne: ElementRef;
	@Input() lotZoom: boolean;
	@Input() lotsFromServer: [];
	public headers: Header[];
	public percentage: number;
	public activities: PreviewActivity[];
	public allActivities: FullActivity[];
	public months: string[];
	public month: number;
	public year: number;
	public dateToPadding = 0;
	public name_days = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"]

	constructor(
		private activitiesService: ActivitiesService,
		private activitiesQuery: ActivitiesStorageQuery,
		public dialog: MatDialog,
		private readonly laborService: LaborService,
		private readonly machineService: MachineService,
		private readonly waterService: WaterService,
		private sharedDataService: SharedDataServiceService,
		private manageModalActivityService: ManageModalActivityService) {
		this.activitiesService.findAll();
		this.activities = [];
		this.initializeData();
		this.activitiesQuery.selectAll().subscribe((data) => {
			this.allActivities = JSON.parse(JSON.stringify(data));
			this.updatePreviewActivity(this.allActivities);
		});

		this.sharedDataService.getClickToUpdateCalendar().subscribe((x) => {
			this.initializeData();
			this.updatePreviewActivity(this.allActivities);
		});
	}

	ngOnInit(): void { }

	public initializeData() {
		this.year = this.sharedDataService.yearSelected;
		this.month = this.sharedDataService.monthSelected;
		this.getDaysOfMonth(this.year, this.month);
	}

	private getDaysOfMonth(year, month) {
		this.headers = [];
		var date = new Date(year, month, 1);
		let week: number = 1;
		let detail = [];
		let totalDays = 0;
		while (date.getMonth() == month) {
			detail.push(this.name_days[date.getDay()] + " " + date.getDate());
			date.setDate(date.getDate() + 1);
			if (date.getDay() == 0) {
				this.headers.push({
					title: 'Semana ' + week.toString(),
					detail: detail,
				});
				week += 1;
				detail = [];
			}
			totalDays += 1;
		}
		if (detail.length != 0) {
			this.headers.push({
				title: 'Semana ' + week.toString(),
				detail: detail,
			});
		}
		this.percentage = 100 / totalDays;
	}

	private getDuration(fullActivity: FullActivity) {
		let start = new Date(this.year, this.month, 1);
		let temp = new Date(fullActivity.activity.start_date);
		temp.setDate(temp.getDate() + 1);
		temp.setHours(0);
		temp.setMinutes(0);
		temp.setSeconds(0);
		if (temp > start) {
			start = temp;
		}
		let end = new Date(this.year, this.month + 1, 1);
		temp = new Date(fullActivity.activity.end_date);
		temp.setDate(temp.getDate() + 1);
		temp.setHours(0);
		temp.setMinutes(0);
		temp.setSeconds(0);
		if (temp < end) {
			end = temp;
		}
		let diff = end.getTime() - start.getTime();
		diff = diff / (1000 * 60 * 60 * 24);
		if (diff < 0) {
			diff = 0;
		}
		return {
			duration: Math.floor(diff),
			start: start.getDate(),
		};
	}
	public nextMonth(aum: number) {
		this.month += aum;
		if (this.month < 0) {
			this.year -= 1;
			this.month = 11;
		}
		if (this.month > 11) {
			this.year += 1;
			this.month = 0;
		}
	}

	public createActivity(lot, event) {
		let activity = {
			camp_id: lot.id,
			season: lot.campaign,
			activity: {
				name: "",
				phase: "",
				start_date: "",
				end_date: "",
				hour: 0,
				workday: 0,
				duration: 0,
				detail: [],
				description: ""
			}
		}
		this.openDialog(activity, event)
	}

	openDialog(data, e): void {
		e.stopPropagation();
		e.preventDefault();
		this.manageModalActivityService.openDialogActivity(this.dialog, data);
	}

	public updatePreviewActivity(activities) {
		this.activities = [];
		this.dateToPadding = 0;
		var lots: { [id: string]: number } = {};
		activities.forEach((activity) => {
			if (lots[activity.camp_id] != undefined) {
				lots[activity.camp_id] += 1;
			} else {
				lots[activity.camp_id] = 0;
			}
			let durationActivity = this.getDuration(activity);
			if (durationActivity.duration > 0) {
				this.activities.push({
					duration: durationActivity.duration,
					fullActivity: activity,
					start: durationActivity.start,
					index: lots[activity.camp_id],
				});
			}

			
		});
		this.dateToPadding = this.sharedDataService.daySelected
	}

	public scrollUp = 0;
	updateScroll() {
		const scrollOne = this.scrollOne.nativeElement as HTMLElement;
		// do logic and set
		if (scrollOne.scrollLeft >= 450) {
			this.sharedDataService.weekSelected = Math.floor((scrollOne.scrollLeft) / 450);
			this.sharedDataService.sentUpdateToCalendarScroll();
		}
		else {
			this.sharedDataService.weekSelected = 0;
			this.sharedDataService.sentUpdateToCalendarScroll();
		}

		if (this.scrollUp !== scrollOne.scrollTop) {
			this.sharedDataService.scrollVertical = scrollOne.scrollTop;
		}

	}

	ngAfterViewChecked(): void {
		this.updateScrollToActualDate();
		this.dateToPadding = 0;
		const scrollOne = this.scrollOne.nativeElement as HTMLElement;
		scrollOne.scrollTop = this.sharedDataService.scrollVertical;
	}


	public updateScrollToActualDate() {
		if (this.dateToPadding < 1) {
			return;
		}
		const scrollOne = this.scrollOne.nativeElement as HTMLElement;

		scrollOne.scrollLeft = (this.dateToPadding * 100) - 100
	}
}
