import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface PhenologicalState {
	id: number,
	product_id?: number,
	measure?: string;
	type?: string;
	detail?: [
		{
			id: string,
			name: string,
			minimun_days: string,
			maximun_days: string,
			product: number
		}
	]
}
export interface PhenologicalStateState extends EntityState<PhenologicalState, number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'phenological-state' })
export class PhenologicalStateStore extends EntityStore<PhenologicalStateState> {
	public constructor() {
		super();
	}
}