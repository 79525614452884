import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { ProviderService} from '../../storage/provider-storage/provider-storage.service';
import { Provider } from '../../storage/provider-storage/provider-storage.store';

@Component({
  selector: 'app-dialog-provider',
  templateUrl: './dialog-provider.component.html',
  styleUrls: ['./dialog-provider.component.scss']
})
export class DialogProviderComponent implements OnInit {

  public messages: Array<string>;

  public provider: Provider = {
    resource:"",
    type:"",
    ruc:"",
    business_name: "",
    email:"",
    phone:"",
    web_page:"",
    person_contact:""


  }
  constructor(
    public dialogRef: MatDialogRef<DialogProviderComponent>, 
    @Inject(MAT_DIALOG_DATA) data,
    private toastService: GlobalToastService,
    private providerService: ProviderService){
    this.setData(data);
    
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close({
    })
  }
  public setData(data){
    this.provider.resource = data["resource"];
    this.provider.business_name = data["business_name"]
    this.provider.email = data["email"]
    this.provider.phone = data["phone"]
    this.provider.web_page = data["web_page"]
    this.provider.person_contact = data["person_contact"]
    this.provider.ruc = data["ruc"]
    this.provider.type = data["type"]

  }
  async createProvider(){
    const response = await this.providerService.create(this.provider)
    if (response === 201){
      this.toastService.createSuccessToast(
				'Exito',
				'El proveedor se añadio correctamente',
      );
      this.close()
    }
    else{
      this.toastService.createErrorToast(
				'Hubo un error',
				'Los datos ingresados son incorrectos',
			);
    }
  }
}
