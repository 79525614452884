import { Component, Inject, OnInit } from '@angular/core';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { AgrodatNotifyQuery } from '@src/app/store/agrodat-notify/agrodat-notify.query';
import { AgrodatNotifyService } from '@src/app/store/agrodat-notify/agrodat-notify.service';
import { AgrodatNotify } from '@src/app/store/agrodat-notify/agrodat-notify.store';
import { SubVarietyQuery } from '@src/app/store/subVariety/state/sub-variety.query';
import { UserService } from '@src/app/store/user/user.service';
import { VarietyQuery } from '@src/app/store/variety/state/variety.query';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { UserQuery } from '@src/app/store/user/user.query';
import { DialogInformationNotification } from '../information-dialog/noti-information-dialog';
import { DialogConfirmationNotification } from '../confirmation-dialog/noti-confirm-dialog';
import { AgrodatHistoricalService } from '@src/app/store/historic-agrodat-notifications/historical-agrodat-notify.service';
import { MapSidebarComService } from '@src/app/modules/my-fields/modules/fields/services/map-sidebar-com.service';


@Component({
    selector: 'app-agrodat-notifications',
    templateUrl: './agrodat-notifications.component.html',
    styleUrls: ['./agrodat-notifications.component.scss']
})
export class AgrodatNotificationsComponent implements OnInit {

    public allVarietiesFromServer = [];
    public allSubVarietiesFromServer = [];
    public subVarietiesFromCrop = []

    public alertList = []
    public data = []
    public userInfo = undefined;
    public allAgrodatNotify = []
    public notifySelected = undefined;
    public idNotifySelected = undefined;
    public alertSelected = undefined;

    public historicalData = [];

    constructor(
        public dialogRef: MatDialogRef<AgrodatNotificationsComponent>,
        public varietyQuery: VarietyQuery,
        public subVarietyQuery: SubVarietyQuery,
        public userService: UserService,
        public agrodatNotifyService: AgrodatNotifyService,
        public agrodatNotifyQuery: AgrodatNotifyQuery,
        public toastService: GlobalToastService,
        public dialog: MatDialog,
        private readonly userQuery: UserQuery,
        private agrodatHistoricalService: AgrodatHistoricalService,
        private mapSidebarComService: MapSidebarComService
    ) {
        this.subVarietyQuery.selectAll().subscribe(elem => {
            let productList = []
            elem.forEach(variety => {
                if (variety.type == "S") {
                    this.allSubVarietiesFromServer.push(variety)
                    let product_instance = this.varietyQuery.getEntity(variety.product.id)
                    productList.push(product_instance)
                }
            })
            let hash = {};
            let new_array = productList.filter(o => hash[o.id] ? false : hash[o.id] = true);
            this.allVarietiesFromServer = new_array
        });
    }

    async ngOnInit() {
        await this.agrodatHistoricalService.findAll().then((all) => {
            if(!all) return;
            this.historicalData = JSON.parse(JSON.stringify(all));
            this.historicalData.forEach((element) => {
                const dateFromElem = new Date(element.date);
                let monthNumber = dateFromElem.getMonth() + 1;
                if (monthNumber < 10) element.date = dateFromElem.getFullYear() + "-" + "0" + monthNumber + "-" + dateFromElem.getDate();
                else element.date = dateFromElem.getFullYear() + "-" + monthNumber + "-" + dateFromElem.getDate();
                let minutes = dateFromElem.getMinutes();
                if(minutes < 10) element.hour = dateFromElem.getHours() + ":0" + dateFromElem.getMinutes();
                else element.hour = dateFromElem.getHours() + ":" + dateFromElem.getMinutes();
                const subVariety = this.subVarietyQuery.getEntity(element.variety);
                element.varietyName = subVariety.product.name;
                element.message.body = element.message.body.replaceAll("\n ","\n")
                element.message.body = element.message.body.trim()
            });
            
        });
        await this.userService.getUserInfo().then(user => {
            this.userInfo = user
        })
        await this.agrodatNotifyService.findAll().then(alert_data => {
            if (alert_data != undefined) {
                this.setAlertList(alert_data)
            }
            else {
                let alert_list = this.agrodatNotifyQuery.getAll()
                this.setAlertList(alert_list)
            }
        })

    }

    public async checkNotification(element: any){
        if(element.readed) return;
        await this.agrodatHistoricalService.update(element.id)
        element.readed = true;
        this.mapSidebarComService.sendClickToCountNotifications('agrodat');
    }

    public setAlertList(alertList: AgrodatNotify[]) {
        alertList.forEach(element => {
            let variety_id = element.variety
            let variety_instance = this.subVarietyQuery.getEntity(variety_id.toString())
            let crop_id = variety_instance.product.id
            let crop_instance = this.varietyQuery.getEntity(crop_id)
            this.data.push(
                {
                    id: element.id,
                    crop: crop_instance.id,
                    crop_name: crop_instance.name,
                    variety: variety_instance.id,
                    variety_name: variety_instance.name,
                    price: element.price,
                    frecuency: element.frecuency,
                    by_sms: element.by_sms,
                    by_push_notification: element.by_push_notification,
                    edit_mode: false
                }
            )
        });
        this.data.push(
            {
                id: null,
                crop: null,
                crop_name: "Cultivo",
                variety: null,
                variety_name: "Variedad",
                price: this.priceOptions[0],
                frecuency: this.frecuencyOptions[1],
                by_sms: false,
                by_push_notification: false,
                edit_mode: false
            }
        )
        let new_data = this.data.slice(0, -1)
        this.alertList = new_data
        this.alertSelected = this.alertList[0]
    }

    public changeAlert(event) {
        this.alertSelected = event.value
    }

    public changeList(event, alert) {
        let crop_id = event.value.id
        let list_variety = []
        this.allSubVarietiesFromServer.forEach(variety => {

            if (variety.product.id == crop_id) {
                list_variety.push(variety)
            }
        })
        this.subVarietiesFromCrop = list_variety
        alert.variety = this.subVarietiesFromCrop[0]
    }

    public validateData(alert) {
        if (alert.variety == null || alert.variety == undefined) {
            this.toastService.createErrorToast("Error", "Debe seleccionar una variedad")
            return false
        }

        return true

    }
    public async save(alert) {
        let is_valid = this.validateData(alert)

        if (alert.id == null && is_valid == true) {
            //case when theres no notification
            var detail: AgrodatNotify = {
                variety: alert.variety.id,
                price: alert.price.id,
                frecuency: alert.frecuency.id,
            }
            try {
                await this.agrodatNotifyService.postAgrodatNotification(detail).then((rpt) => {
                    const agrodatnotify = this.agrodatNotifyQuery.getEntity(rpt.id);

                    let variety_id = agrodatnotify.variety
                    let variety_instance = this.subVarietyQuery.getEntity(variety_id.toString())
                    let crop_id = variety_instance.product.id
                    let crop_instance = this.varietyQuery.getEntity(crop_id)
                    this.data.pop()
                    this.data.push(
                        {
                            id: agrodatnotify.id,
                            crop: crop_instance.id,
                            crop_name: crop_instance.name,
                            variety: variety_instance.id,
                            variety_name: variety_instance.name,
                            price: agrodatnotify.price,
                            frecuency: agrodatnotify.frecuency,
                            by_sms: agrodatnotify.by_sms,
                            by_push_notification: agrodatnotify.by_push_notification,
                            edit_mode: false
                        }
                    )
                    let new_alert_list = this.data.slice()
                    this.alertList = new_alert_list
                    this.data.push(
                        {
                            id: null,
                            crop: null,
                            crop_name: "Cultivo",
                            variety: null,
                            variety_name: "Variedad",
                            price: this.priceOptions[0],
                            frecuency: this.frecuencyOptions[1],
                            by_sms: false,
                            by_push_notification: false,
                            edit_mode: false
                        }
                    )
                    alert.edit_mode = false;
                })
                this.toastService.createSuccessToast("Completado", "Se han guardado los parámetros")
                this.goToConfig();

            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        } else if (alert.id != null && is_valid == true) {
            var detailUpdate: AgrodatNotify = {
                id: alert.id,
                variety: alert.variety.id,
                price: alert.price.id,
                frecuency: alert.frecuency.id,
                by_email: alert.by_email,
                by_push_notification: alert.by_push_notification,
                by_sms: alert.by_sms
            }
            try {
                await this.agrodatNotifyService.putAgrodatNotification(detailUpdate).then((rpt) => {
                    let variety_id = rpt.variety
                    let variety_instance = this.subVarietyQuery.getEntity(variety_id.toString())
                    let crop_id = variety_instance.product.id
                    let crop_instance = this.varietyQuery.getEntity(crop_id)
                    alert.id = rpt.id,
                        alert.crop = crop_instance.id,
                        alert.crop_name = crop_instance.name,
                        alert.variety = variety_instance.id,
                        alert.variety_name = variety_instance.name,
                        alert.price = rpt.price,
                        alert.frecuency = rpt.frecuency,
                        alert.by_sms = rpt.by_sms,
                        alert.by_push_notification = rpt.by_push_notification,
                        alert.edit_mode = false;
                })
                this.toastService.createSuccessToast("Completado", "Se han actualizado los parámetros")
            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        }

    }

    public goToConfig(){
        const dialogRef = this.dialog.open(DialogInformationNotification, {
            panelClass: "modalClass",
            width: "470px",
            height: "120px",
            data: {
                text: "Los Parametros se han guardado ahora puede ir hacia Configuraciones"
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
        });
    }

    public deleteNotification(alert) {
        this.notifySelected = alert
        const dialogRef = this.dialog.open(DialogConfirmationNotification, {
            panelClass: "modalClass",
            width: "470px",
            height: "120px",
            data: {
                text: "¿Está seguro de eliminar los parámetros?"
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                try {
                    await this.agrodatNotifyService.deleteNotify(this.notifySelected.id).then(() => {
                        let new_data = []
                        this.data.forEach(element => {
                            if (element.id != this.notifySelected.id) {
                                new_data.push(element)
                            }
                        })
                        this.data = new_data;
                        let new_alert_list = this.data.slice(0, -1)
                        this.alertList = new_alert_list
                        this.notifySelected = undefined;

                        this.toastService.createSuccessToast("Completado", "Se han borrado los parámetros")
                    }).catch(() => {
                        this.toastService.createErrorToast("Error", "No se han podido borrar los parámetros")
                    })

                } catch (error) {

                }

            }
        });
    }


    public getName(variety) {
        let variety_instance = this.subVarietyQuery.getEntity(variety)
        return variety_instance.name
    }

    public close() {
        this.dialogRef.close()
    }

    public priceOptions = [
        { value: "Mínimo", id: "MIN" }, { value: "Promedio", id: "AVE" }, { value: "Máximo", id: "MAX" }, { value: "Todos", id: "ALL" }
    ]

    public frecuencyOptions = [
        { value: "Reciente", id: "R" }, { value: "Diario", id: "D" }, { value: "Quincenal", id: "Q" }, { value: "Mensual", id: "M" }
    ]

    public validatePlan(e) {
        const currentUser = this.userQuery.getInformation();

        if (e.checked && !currentUser.permission.pushNotification) {
            const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
                width: '400px'
            });
            saveRef.afterClosed().subscribe(
                data => {
                    this.alertSelected.by_push_notification = false;
                })
        }
    }
}
