import { Component, OnInit } from '@angular/core';
import { FieldsService } from '@store/fields/fields.service';
import { SubVarietyService } from '@src/app/store/subVariety/state/sub-variety.service';
import { VarietyService } from '@src/app/store/variety/state/variety.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivitiesService } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.service';
import { CalendarService } from '@src/app/modules/agromonitor/storage/calendar-storage/calendar-storage.service';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { LotDroneTicketService } from '@src/app/store/drone-ticket/drone-ticket.service';

@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss']
})

export class MainPageComponent implements OnInit {
	constructor(
		private readonly fieldsService: FieldsService,
		private readonly subVarietyService: SubVarietyService,
		private readonly varietyService: VarietyService,
		private readonly spinner: NgxSpinnerService,
		private activitiesService: ActivitiesService,
		private calendarService: CalendarService,
		private readonly lotDroneService: LotDroneTicketService,
		private readonly sidebarService: SidebarService
	) {}

	public async ngOnInit() {
		this.sidebarService.updateSelectedService(2);
		this.spinner.show('Syncrow');
		await this.lotDroneService.findAll();
		await this.varietyService.findAll();
		await this.subVarietyService.findAll();
		await this.fieldsService.findAll();
		await this.activitiesService.findAll();
		await this.calendarService.findAll();
		this.spinner.hide('Syncrow');
	}
}
