import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FieldsQuery } from '@src/app/store/fields/fields.query';

@Component({
  selector: 'app-collaborator-permissions',
  templateUrl: './collaborator-permissions.component.html',
  styleUrls: ['./collaborator-permissions.component.scss']
})
export class CollaboratorPermissionsComponent implements OnInit {

  public allFields = [];
  public isGenericPermissions: string = "Específico";
  public typePermissions: string[] = ["General", "Específico"];
  public lots: any[] = [];
  public campaign: any = {};
  public campaigns: any = [];
  public allCampaigns: any = [];
  public propagation: boolean = false;
  constructor(private readonly fieldsQuery: FieldsQuery, public dialogRef: MatDialogRef<CollaboratorPermissionsComponent>) { }

  ngOnInit(): void {
    this.fieldsQuery.selectFieldsWithLots().subscribe((x) => {
      this.allFields = x;
    });
  }

  select(e) {
    this.propagation = true;
    let lot = e.value;
    if (lot.length == 1)
      this.campaigns = lot[0].campaigns;
    else
      this.campaigns = []
  }

  selectField(field: any) {
    if (this.propagation) {
      this.propagation = false;
      return;
    }
    this.lots.push(...field.lots);
    let allLotsSelect = new Set<any>(this.lots);
    this.lots = Array.from(allLotsSelect);
  }

  save(response: boolean) {
    this.dialogRef.close({
      response: response,
      campaignsId: this.lots.map(lot => lot.campaigns[0].id) || []
    });
  }

}
