import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { GenericResources } from "../constants/genericResource";
import { LaborStorageQuery } from "../storage/labor-storage/labor-storage.query";
import { MachineStorageQuery } from "../storage/machine-storage/machine-storage.query";
import { ServiceStorageQuery } from "../storage/serv-other-storage/serv-other-storage.query";
import { SupplyStorageQuery } from "../storage/supplies-storage/supplies-storage.query";
import { WaterStorageQuery } from "../storage/water-storage/water-storage.query";

@Injectable()
export class ResourceService {
    public resources: GenericResources[];
    private subjectUpdateResources = new Subject<any>();

    constructor(private readonly laborQuery: LaborStorageQuery, private readonly machineQuery: MachineStorageQuery,
        private readonly supplyQuery: SupplyStorageQuery, private readonly waterQuery: WaterStorageQuery,
        private readonly servOtherQuery: ServiceStorageQuery) {
        this.resources = [];
    }

    public getAllResources() {
        let array: Array<boolean> = new Array<boolean>(false, false, false, false, false);
        let checker = arr => arr.every(Boolean);
        this.resources = [];
        this.laborQuery.selectAll().subscribe(labors => {
            labors.forEach(labor => {
                this.resources.push({
                    resource: +labor.id,
                    name: labor.name,
                    cost: +labor.cost / +labor.hours,
                    percentage: 100,
                    type: 0,
                    unit: "hrs"
                });
            });
            array[0] = true;

            if (checker(array))
                this.subjectUpdateResources.next();
        });

        this.machineQuery.selectAll().subscribe(machines => {
            machines.forEach(machine => {
                this.resources.push({
                    resource: +machine.id,
                    name: machine.name,
                    cost: +machine.cost,
                    percentage: 100,
                    type: 1,
                    unit: "hrs"
                });
            })
            array[1] = true;

            if (checker(array))
                this.subjectUpdateResources.next();
        });

        this.supplyQuery.selectAll().subscribe(supplies => {
            supplies.forEach(supply => {
                this.resources.push({
                    resource: +supply.id,
                    name: supply.name,
                    cost: +supply.cost / +supply.quantity,
                    percentage: 1,
                    type: 3,
                    unit: supply.unit
                });
            })
            array[2] = true;

            if (checker(array))
                this.subjectUpdateResources.next();
        });

        this.waterQuery.selectAll().subscribe(waters => {
            waters.forEach(water => {
                this.resources.push({
                    resource: +water.id,
                    name: "agua",
                    cost: +water.cost,
                    percentage: 1,
                    type: 2,
                    unit: water.unit
                });
            });
            array[3] = true;

            if (checker(array))
                this.subjectUpdateResources.next();
        });

        this.servOtherQuery.selectAll().subscribe(services => {
            services.forEach(service => {
                this.resources.push({
                    resource: +service.id,
                    name: service.name,
                    cost: +service.cost,
                    percentage: 1,
                    type: 4,
                    unit: service.unit
                });
            });
            array[4] = true;

            if (checker(array))
                this.subjectUpdateResources.next();
        });
    }

    public getUpdateResources(): Observable<any> {
        return this.subjectUpdateResources.asObservable();
    }
}