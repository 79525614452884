import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Field {
	id?: string;
	name?: string;
	userId?: string;
	lots?: string[];
	color?: string;
}

export interface FieldsState extends EntityState<Field, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fields' })
export class FieldsStore extends EntityStore<FieldsState> {
	public constructor() {
		super();
		//this.initializeData();
	}

	public initializeData() {
	}
}
