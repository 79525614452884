import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-image',
  templateUrl: './dialog-image.component.html',
  styleUrls: ['./dialog-image.component.scss']
})
export class DialogImageComponent implements OnInit {

  public image_path = ""
  constructor(
    public dialogRef: MatDialogRef<DialogImageComponent>, 
    @Inject(MAT_DIALOG_DATA) data,
    ){
    this.setData(data)
    
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close({
    })
  }
  public setData(data){
    this.image_path = data
  }
  async createProvider(){
  }
}
