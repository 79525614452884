import { Injectable } from '@angular/core';
import * as L from 'leaflet';

@Injectable({
	providedIn: 'root'
})
export class CoordinateConverterService {
	public constructor() {}

	public LngLatToLatLng(coordinates: number[][]): Array<L.LatLng> {
		return coordinates.map(x => L.latLng(x[1], x[0]));
	}

	// public coordinatesToLatLngBounds(coordinates: number[][]): L.LatLngBounds {
	// 	let lonMin: number = coordinates[0][0];
	// 	let lonMax: number = coordinates[0][0];
	// 	let latMin: number = coordinates[0][1];
	// 	let latMax: number = coordinates[0][1];

	// 	coordinates.forEach(coordinate => {
	// 		lonMax = Math.max(lonMax, coordinate[0]);
	// 		lonMin = Math.min(lonMin, coordinate[0]);
	// 		latMax = Math.max(latMax, coordinate[1]);
	// 		latMin = Math.min(latMin, coordinate[1]);
	// 	});

	// 	const southWest = L.latLng(latMax, lonMax);
	// 	const northEast = L.latLng(latMin, lonMin);

	// 	return L.latLngBounds(southWest, northEast);
	// }
}
