import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

import { ServiceStore, Service } from './serv-other-storage.store';
import { ServiceStorageQuery } from './serv-other-storage.query';

export interface Message {
    StatusCode: number,
    Error: string,
    message: Service[]
}

@Injectable({ providedIn: 'root' })
export class ServiceAndOthersService {

    constructor(
        private serviceStore: ServiceStore,
        private serviceStorageQuery: ServiceStorageQuery,
        private http: HttpClient
    ) {
    }

    public updateActive(id){
        this.serviceStore.setActive(id);
    }

    public async findAll(): Promise<Service[]> {
        const hasCache = this.serviceStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }

        try {
            const data = await this.http
                .get<Message>(`${environment.apiUrl}/service/`)
                .toPromise<Message>();
            const dataArray = data.message
            const services = dataArray.map((currentLabor: any) => {
                const service = currentLabor;
                service.id = String(service.id);
                service.quantity = String(service.quantity);
                service.coin = String(service.coin);
                return service;
            });
            this.serviceStore.set(services);
            return services;
        }
        catch (error) {
        }
    }


    public async createManyMachines(arrayOfMachineObjects) {
        try {
            arrayOfMachineObjects.forEach(element => {
                element.quantity = parseInt(element.quantity);
                element.coin = parseInt(element.coin);
                delete element.id;
                delete element.abbreviation;
                delete element.totalCost;
            });
            const machine = await this.http
                .post<Message>(`${environment.apiUrl}/service/`,
                    arrayOfMachineObjects
                )
                .toPromise<Message>();
            const dataArray = machine.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.serviceStore.add(element)
            });
        } catch (error) {

        }
    }

    public async updateMany(arrayOfLaborObjects) {
        try {
            arrayOfLaborObjects.forEach((element) => {
                element.quantity = parseInt(element.quantity);
                element.coin = parseInt(element.coin);
                delete element.abbreviation;
            });
            const labor = await this.http
                .put<Message>(`${environment.apiUrl}/service/`,
                    arrayOfLaborObjects
                )
                .toPromise<Message>();
            const dataArray = labor.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.serviceStore.update(element.id, element)
            });
        } catch (error) {

        }
    }


    public async deleteMany(arrayOfLaborObjects) {
        try {
            var idsOfSerAndOtherObjects = []
            arrayOfLaborObjects.forEach(element => {
                idsOfSerAndOtherObjects.push(parseInt(element))
            });
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    idsOfSerAndOtherObjects
            };
            const labor = await this.http
                .delete(`${environment.apiUrl}/service/`,
                    options
                )
                .toPromise();
            arrayOfLaborObjects.forEach(element => {
                this.serviceStore.remove(element)
            });
        } catch (error) {
        }
    }
}   
