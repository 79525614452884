import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class MapSidebarComService {
    private subject = new Subject<any>();
    private subject2 = new Subject<any>();
    private subject3 = new Subject<any>();
    private subject4 = new Subject<any>();
    private subject5 = new Subject<any>();
    private subject6 = new Subject<any>();
    private subject7 = new Subject<any>();
    private subject8 = new Subject<any>();
    private subject9 = new Subject<any>();
    private subject10 = new Subject<any>();
    private subject11 = new Subject<any>();
    private subject12 = new Subject<any>();
    private subject13 = new Subject<any>();
    private subjectCountNotifications = new Subject<any>();
    private subjectUpdateSatelitalImage = new Subject<any>();
    private subjectUpdatePhenologicalChart = new Subject<any>();
    private subjectUpdateIncidence = new Subject<any>();
    private subjectIncidence = new Subject<any>();
    private subjectEnableReportButton = new Subject<any>();
    public enableReport = false;
    public l;
    public n;
    public lotid;
    public name;
    public pheno;
    public porcentagePhenological = 0;
    public statisticsFromNDVI;
    public dateSelected = undefined;
    public viewSelected = undefined;
    public cropVariety;
    public lineChartDataNDVI_NDWI_SAVI = undefined;
    public lotId;
    public layerNameSelected;
    public viewId;
    public imageId;
    public imageLayerNameSelected;
    public fieldCenter;
    private selectedOptionSubject = new BehaviorSubject<number>(1);
    miVariable$: Observable<number> = this.selectedOptionSubject.asObservable();
    constructor() { }
    
    public actualizarVariable(nuevoValor: number) {
        this.selectedOptionSubject.next(nuevoValor);
      }

    public sendClickToCountNotifications(type:string) {
        this.subjectCountNotifications.next(type);
    }

    public getClickToCountNotifications(): Observable<any> {
        return this.subjectCountNotifications.asObservable();
    }

    public sendClickToEnableReportButton() {
        this.subjectEnableReportButton.next();
    }

    public getClickToEnableReportButton(): Observable<any> {
        return this.subjectEnableReportButton.asObservable();
    }

    public sendClickToDisplayorHideIncidence() {
        this.subjectIncidence.next();
    }

    public getClickToDisplayorHideIncidence(): Observable<any> {
        return this.subjectIncidence.asObservable();
    }

    public sendClickToUpdateIncidence() {
        this.subjectUpdateIncidence.next();
    }

    public getClickToUpdateIncidence(): Observable<any> {
        return this.subjectUpdateIncidence.asObservable();
    }


    public sendClickToUpdatePhenologicalChart() {
        this.subjectUpdatePhenologicalChart.next();
    }
    public getClickToUpdatePhenologicalChart(): Observable<any> {
        return this.subjectUpdatePhenologicalChart.asObservable();
    }

    public sendClickToUpdateSatelitalImage() {
        this.subjectUpdateSatelitalImage.next();
    }
    public getClickToUpdateSatelitalImage(): Observable<any> {
        return this.subjectUpdateSatelitalImage.asObservable();
    }

    public sendClickToDeselect() {
        this.subject10.next();
    }
    public getClickForDeselect(): Observable<any> {
        return this.subject10.asObservable();
    }

    public sendClickToUpdateLineChart() {
        this.subject9.next();
    }
    public getClickForUpdatingLineChart(): Observable<any> {
        return this.subject9.asObservable();
    }
    public addStatistics(elem){
        this.statisticsFromNDVI = elem;
    }

    public sendClickEvent(lotID, layer) {
        this.l = layer;
        this.lotid = lotID;
        this.subject.next();
    }
    public getClickEvent(): Observable<any> {
        return this.subject.asObservable();
    }

    public sentClickToCenter() {
        this.subject2.next();
    }
    public getClickToCenter(): Observable<any> {
        return this.subject2.asObservable();
    }

    public sentClickToCenterField(fieldCenter) {
        this.fieldCenter = fieldCenter;
        this.subject13.next();
    }
    public getClickToCenterField(): Observable<any> {
        return this.subject13.asObservable();
    }

    public sentClickEnableEdit(lotID) {
        this.lotid = lotID;
        this.subject3.next();
    }
    public getClickEnableEdit(): Observable<any> {
        return this.subject3.asObservable();
    }

    public sentClickStopEdit(lotID, name, pheno) {
        this.lotid = lotID;
        this.name = name;
        this.pheno = pheno;
        this.subject4.next();
    }
    public getClickStopEdit(): Observable<any> {
        return this.subject4.asObservable();
    }

    public sentClickCancelEdit() {
        this.subject8.next();
    }
    
    public getClickCancelEdit(): Observable<any> {
        return this.subject8.asObservable();
    }

    public sentClickDeleteLot(lotId) {
        this.lotid = lotId
        this.subject5.next();
    }

    public getClickDeleteLot(): Observable<any> {
        return this.subject5.asObservable();
    }

    public sentClickCreateLot() {
        this.subject6.next();
    }

    public getClickCreateLot(): Observable<any> {
        return this.subject6.asObservable();
    }

    public sentClickDeleteAllDrawedTemporalPolygons() {
        this.subject7.next();
    }
    public getClickDeleteAllDrawedTemporalPolygons(): Observable<any> {
        return this.subject7.asObservable();
    }
    public sentClickDrawIndex(lotId, layerNameSelected, viewId ) {
        this.lotId = lotId;
        this.layerNameSelected = layerNameSelected;
        this.viewId = viewId;
        this.subject11.next();
    }
    public getClickDrawIndex(): Observable<any> {
        return this.subject11.asObservable();
    }
    public sentClickDrawIndexComplete(imageId, imageLayerNameSelected) {
        this.imageId = imageId;
        this.imageLayerNameSelected = imageLayerNameSelected;
        this.subject12.next();
    }
    public getClickDrawIndexComplete(): Observable<any> {
        return this.subject12.asObservable();
    }
    updateSelectedOption(option: number) {
        this.selectedOptionSubject.next(option);
      }
}