import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export interface FieldsNotificationRecord {
    id?: number;
    date?: string;
    lot_id?: string;
    message?: {
        id?: string;
        body?: string;
        title?: string;
    };
    readed: boolean;
}

export interface NotifyBody {
    statusCode: number;
    error: string;
    message: FieldsNotificationRecord[];
}

@Injectable({ providedIn: "root" })
export class FieldsHistoricalService {
    constructor(private http: HttpClient) {}

    public async findAll(): Promise<FieldsNotificationRecord[]> {
        const data = await this.http
            .get<NotifyBody>(`${environment.apiUrl}/satellitelImages/notifications`)
            .toPromise<NotifyBody>();
        return data.message;
    }

    public async update( id: any) {
        const data = await this.http.get<any>(`${environment.apiUrl}/satellitelImages/notifications/${id}/read`).toPromise<NotifyBody>();
        return data.message;
    }

    public async getNotReaded() {
        const data = await this.http.get<any>(`${environment.apiUrl}/satellitelImages/notifications/unread`).toPromise<any>();
        return data.message;
    }

}
