import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { StadisticsQuery } from '@src/app/store/stadistics/stadistics.query';
import { environment } from '@src/environments/environment';

export class DialogMessage {
    center: string;
    datesInfo: any[];
    coords: string;
    lot_id: string;
}

@Component({
    selector: 'app-comparison-dialog',
    templateUrl: './comparison-dialog.component.html',
    styleUrls: ['./comparison-dialog.component.scss'],
})
export class ComparisonDialogComponent implements OnInit {
    public sanitazedUrl = undefined;
    public selectedIndex = 'NDVI';
    public leftSide = undefined;
    public rigthSide = undefined;
    public stadisticsData = [];
    public leftStat = -1;
    public rightStat = -1;

    constructor(
        public dialogRef: MatDialogRef<ComparisonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogMessage,
        public sanitizer: DomSanitizer,
        private stadisticsQuery: StadisticsQuery,
    ) {
        //this.sanitazedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
        this.leftSide = this.data.datesInfo[0];
        this.rigthSide = this.data.datesInfo[0];
        this.displayLayer();
        this.stadisticsData = this.stadisticsQuery.getEntity(data.lot_id).stats;
    }

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }

    public selectIndex(index: string) {
        this.selectedIndex = index;
        if (this.leftSide || this.rigthSide) {
            this.displayLayer();
            if(this.leftSide){
                this.getLeftStat()
            }
            if(this.rigthSide){
                this.getRightStat()
            }
        };
    }

    changeDateLeft(event) {
        this.leftSide = event.value;
        this.getLeftStat();
        if (this.selectedIndex !== '' && this.rigthSide) {
            this.displayLayer();
        }
    }

    getLeftStat(){
        const statArray = this.stadisticsData.filter((elem) => elem.date === this.leftSide.date);
        const stat = statArray.length > 0 ? statArray[0] : undefined;
        if (stat) {
            this.leftStat = this.getInfo(stat);
        }else{
            this.leftStat = -1;
        }
    }

    getRightStat(){
        const statArray = this.stadisticsData.filter((elem) => elem.date === this.rigthSide.date);
        const stat = statArray.length > 0 ? statArray[0] : undefined;
        if (stat) {
            this.rightStat = this.getInfo(stat);
        }else{
            this.rightStat = -1;
        }
    }

    changeDateRight(event) {
        this.rigthSide = event.value;
        this.getRightStat();
        if (this.selectedIndex !== '' && this.leftSide) {
            this.displayLayer();
        }
    }

    displayLayer() {
        let left = '';
        let right = '';
        if (this.leftSide) left = `&l=cite:${this.selectedIndex}_${this.leftSide.name}`;
        if (this.rigthSide) right = `&r=cite:${this.selectedIndex}_${this.rigthSide.name}`;
        const url = environment.images_comparison + this.data.center + left + right + '&coords=' + this.data.coords;
        this.sanitazedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getInfo(data) {
        for (let index = 0; index < data.statistics.length; index++) {
            const element = data.statistics[index];
            if (element.name === this.selectedIndex) {
                return element.average;
            }
        }
        return undefined;
    }
}
