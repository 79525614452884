import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelExportService } from '@src/app/shared/services/excel-export/excel-export.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { Subscription } from 'rxjs';
import { ActivitiesStorageQuery } from '../../../storage/activities-storage/activities-storage.query';
import { ActivitiesStore } from '../../../storage/activities-storage/activities-storage.store';
import { LaborStorageQuery } from '../../../storage/labor-storage/labor-storage.query';
import { Labor } from '../../../storage/labor-storage/labor-storage.store';
import { ActivityDetailInfoComponent } from '../activity-detail-info/activity-detail-info.component';
import { CalendarActivityComponent } from '../calendar-activity/calendar-activity.component';
import { DialogShowResourceComponent } from '../dialog-show-resource/dialog-show-resource.component';

@Component({
	selector: 'app-dialog-show-labor',
	templateUrl: './dialog-show-labor.component.html',
	styleUrls: ['../dialog-resource-detail.component.scss'],
})
export class DialogShowLaborComponent implements OnInit, OnDestroy {
	displayedColumns: string[] = [
		'fieldName',
		'lotName',
		'activity',
		'days',
		'hh',
		'cost',
	];
	dataSource = new MatTableDataSource();
	public activeLaborObject: Labor = undefined;
	public activitiesForTable = [];
	public suscriptions: Subscription[] = [];
	public dataToBeDisplayed = [];
	private dialogCalendar = undefined;
	private dialogActivity = undefined;

	constructor(
		public dialogRef: MatDialogRef<DialogShowResourceComponent>,
		public activityQuery: ActivitiesStorageQuery,
		public activitiesStore: ActivitiesStore,
		public laborQuery: LaborStorageQuery,
		public readonly lotsQuery: LotsQuery,
		private readonly fieldsQuery: FieldsQuery,
		private readonly exportService: ExcelExportService,
		public dialog: MatDialog,
	) {
		this.activeLaborObject = this.laborQuery.getEntity(
			this.laborQuery.getActiveId(),
		);
		this.suscriptions.push(
			this.activityQuery.selectAllActivities.subscribe((allActivies) => {
				this.dataSource = new MatTableDataSource();
				this.dataToBeDisplayed = [];
				for (let index = 0; index < allActivies.length; index++) {
					const activityObject = allActivies[index];
					if (activityObject.activity.detail.length > 0) {
						for (
							let j = 0;
							j < activityObject.activity.detail.length;
							j++
						) {
							const resource = activityObject.activity.detail[j];
							if (
								resource.resource + '' ==
									this.activeLaborObject.id &&
								resource.type == 0
							) {
								const lotInfo = this.lotsQuery.getEntity(
									activityObject.camp_id,
								);
								const fieldInfo = this.fieldsQuery.getEntity(
									lotInfo.fieldId,
								);
								const horasHombrePrice =
									parseFloat(this.activeLaborObject.cost) /
									parseFloat(this.activeLaborObject.hours);
								var hhTotal =
									activityObject.activity.workday *
									activityObject.activity.duration *
									(resource.percentage / 100);
								var totalValue =
									activityObject.activity.workday *
									activityObject.activity.duration *
									(resource.percentage / 100) *
									horasHombrePrice;

								if (hhTotal > 0)
									hhTotal = Math.round(hhTotal * 100) / 100;
								if (totalValue > 0)
									totalValue =
										Math.round(totalValue * 100) / 100;

								this.dataToBeDisplayed.push({
									fieldName: fieldInfo.name,
									lotName: lotInfo.name,
									activity: activityObject.activity.name,
									days: activityObject.activity.duration,
									hh: hhTotal,
									cost: totalValue.toLocaleString('en-US'),
									activityId: activityObject.id,
								});
								break;
							}
						}
					}
				}
				this.dataSource = new MatTableDataSource(
					this.dataToBeDisplayed,
				);
			}),
		);
	}
	ngOnDestroy(): void {
		this.unsuscribeFromAll();
	}

	ngOnInit(): void {}

	public close() {
		this.unsuscribeFromAll();
		if (this.dialogCalendar !== undefined) this.dialogCalendar.close();
		if (this.dialogActivity !== undefined) this.dialogActivity.close();
		this.dialogRef.close();
	}

	private unsuscribeFromAll() {
		this.suscriptions.forEach((element) => {
			element.unsubscribe();
		});
	}

	public exportToExcel() {
		if (this.dataToBeDisplayed.length > 0) {
			this.exportService.exportDataToExcel(
				this.dataToBeDisplayed,
				'resource-labor-' + this.activeLaborObject.id,
			);
		}
	}

	public openActivityDetail(activityId){
		if (this.dialogActivity !== undefined) this.dialogActivity.close();
		if (this.dialogCalendar !== undefined) this.dialogCalendar.close();
		this.activitiesStore.setActive(activityId);
		this.dialogActivity = this.dialog.open(ActivityDetailInfoComponent, {
			width: '450px',
			height: '350px',
			panelClass: 'modalClass',
			hasBackdrop: false,
			disableClose: true
		});
	}

	public openCalendar(activityId) {
		if (this.dialogCalendar !== undefined) this.dialogCalendar.close();
		if (this.dialogActivity !== undefined) this.dialogActivity.close();
		this.activitiesStore.setActive(activityId);
		this.dialogCalendar = this.dialog.open(CalendarActivityComponent, {
			width: '300px',
			height: '300px',
			disableClose: true,
			panelClass: 'modalClass',
			hasBackdrop: false,
		});
	}
}
