import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PhenologicalDataState, PhenologicalDataStore } from './phenological-data.store';
import { PhenologicalDataQuery } from './phenological-data.query';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PhenologicalDataService {
    public constructor(
        private readonly phenologicalDataStore: PhenologicalDataStore,
        private http: HttpClient,
    ) { }

    public async findOnePhenologicalState(idPhenologicalState): Promise<PhenologicalDataState> {
        try {
            const data = await this.http.get<PhenologicalDataState>(`${environment.apiUrlAgroDat}/variety_detail/` + idPhenologicalState + "/")
                .toPromise<PhenologicalDataState>();
            this.phenologicalDataStore.add(data)
            return data;
        } catch (error) {
            console.log(error)
        }
    }

    public async updateOnePhenologicalState(idPhenologicalState, lotID, season, classification, sowing_date, pruning_date, rainfall_date) {
        try {
            if (sowing_date === "") { sowing_date = null }
            if (pruning_date === "") { pruning_date = null }
            if (rainfall_date === "") { rainfall_date = null }
            const data = await this.http.put<PhenologicalDataState>(`${environment.apiUrlAgroDat}/variety_detail/` + idPhenologicalState + "/", {
                "lot_id": lotID,
                "season": season,
                "classification": classification,
                "sowing_date": sowing_date,
                "pruning_date": pruning_date,
                "rainfall_date": rainfall_date
            }).toPromise<PhenologicalDataState>();
            this.phenologicalDataStore.update(lotID, data)
            return data;
        } catch (error) {
            console.log(error)
        }
    }

    public async createOnePhenologicalState(lotID, season, classification, sowing_date, pruning_date, rainfall_date) {
        try {
            if (sowing_date === "") { sowing_date = null }
            if (pruning_date === "") { pruning_date = null }
            if (rainfall_date === "") { rainfall_date = null }
            const data = await this.http.post<PhenologicalDataState>(`${environment.apiUrlAgroDat}/variety_detail/`, {
                "lot_id": lotID,
                "season": season,
                "classification": classification,
                "sowing_date": sowing_date,
                "pruning_date": pruning_date,
                "rainfall_date": rainfall_date
            }).toPromise<PhenologicalDataState>();
            
            this.phenologicalDataStore.add(data)
            return data;
        } catch (error) {
        }
    }

    public async deleteOne(id) {
        try {
            const data = await this.http.delete<PhenologicalDataState>(`${environment.apiUrlAgroDat}/variety_detail/` + id + "/", {}).toPromise<PhenologicalDataState>();
            this.phenologicalDataStore.remove(id)
            return data;
        } catch (error) {
        }

    }
}
