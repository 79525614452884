export interface ResourceObject {
    name: string;
    unit: string;
    quantity: string;
    cost: string;
}
export class CampaingFormat {
    campaing_id = '';
    campaing_name = '';
    start_date = '';
    end_date = '';
    variety_name = '';
    subvariety_name = '';
    volumn = '';
    unit = '';
    price_unit = '';
    total_amount = '';
    lost_volumn = '';
    lost_amount = '';
    labor = {
        total_hh: '',
        total_cost: '',
        hh_unit: '',
        cost_unit: '',
    };
    machine = {
        total_hm: '',
        total_cost: '',
        hm_unit: '',
        cost_unit: '',
    };
    resources: ResourceObject[] = [];
    service_and_others: ResourceObject[] = [];
    labor_utilization_unit = '';
    machine_utilization_unit = '';
    water_utilization_unit = '';
    labor_utilization: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    machine_utilization: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    water_utilization: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    constructor() {}
}

export interface StatsData {
    name: string;
    data: [number, number][];
}
export class GeneratePDFReport {
    field_name: string;
    lot_id: string;
    lot_name: string;
    user_name: string;
    campaigns: CampaingFormat[];
    statistics: StatsData[];
    constructor() {
        this.field_name = '';
        this.lot_id = '';
        this.lot_name = '';
        this.campaigns = [];
        this.statistics = null;
        this.user_name = ''
    }
}
