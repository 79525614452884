const phases = [
    "Preparación del terreno",
    "Transplante",
    "Preparación de pozas",
    "Siembra",
    "Labores culturas",
    "Cosecha", 
    "Post Cosecha",
    "Otra"
];

export { phases }
