import { Injectable } from '@angular/core';
import { QueryEntity, combineQueries } from '@datorama/akita';
import { SubVarietyStore, SubVarietyState, SubVariety } from './sub-variety.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubVarietyQuery extends QueryEntity<SubVarietyState> {

    public selectAllFields: Observable<SubVariety[]> = this.selectAll();
    
    constructor(
        protected store: SubVarietyStore
    ) {
        super(store);
    }
}
