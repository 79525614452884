import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { SupplyService } from '../../storage/supplies-storage/supplies-storage.service';
import { SupplyStorageQuery } from '../../storage/supplies-storage/supplies-storage.query';
import { EmailProvider, EmailResource, Supply, SupplyStore } from '../../storage/supplies-storage/supplies-storage.store';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';
import { Subscription } from 'rxjs';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { DialogShowResourceComponent } from '../dialogs/dialog-show-resource/dialog-show-resource.component';
import { ProviderService } from '../../storage/provider-storage/provider-storage.service';
import { DialogProviderComponent } from '../dialog-provider/dialog-provider.component';
import { DialogConfirmationResource } from '../confirmation-dialog/confirmation-dialog';
import { UserService } from '@src/app/store/user/user.service';
import { ProviderStorageQuery } from '../../storage/provider-storage/provider-storage.query';
import { ProviderStore } from '../../storage/provider-storage/provider-storage.store';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';

@Component({
	selector: 'app-stock-table',
	templateUrl: './stock-table.component.html',
	styleUrls: ['./stock-table.component.scss'],
})
export class StockTableComponent implements OnInit, OnDestroy{
	public displayedColumns: string[] = [
		'position',
		'name',
		'kind',
		'abbreviation',
		'unit',
		'quantity',
		'cost',
		'date',
		'used',
		'rest',
		'action',
	];
	public copiedElement = undefined;
	public dataSource = new MatTableDataSource([]);
	public elementData = [];
	private detail_activities =[];
	private subscriptions = new Subscription()
	private listEmailProvider : EmailProvider[] = [];
	private userInfo;

	constructor(
		public supplyService: SupplyService,
		public supplyStorageQuery: SupplyStorageQuery,
		public supplyStore: SupplyStore,
		public dialog: MatDialog,
		public sharedData: SharedDataServiceService,
		public activitiesService: ActivitiesService,
		public activitiesStorageQuery: ActivitiesStorageQuery,
		public providerService: ProviderService,
		public providerQuery: ProviderStorageQuery,
		public userService : UserService,
		public toastService: GlobalToastService,
	) {}

	ngOnInit(): void {
		this.initializeData();
	}

	public async initializeData() {
		await this.supplyService.findAll();
		await this.activitiesService.findAll();
		await this.userService.getUserInfo().then(user =>{
			this.userInfo = user
		})
		this.fillTableData();
		this.subscriptions.add(
            this.sharedData.getClickToFilter().subscribe(x => {
                this.filterData();
            })
		);
		this.subscriptions.add(
            this.sharedData.getClickToExport().subscribe(x => {
                this.exportDataToExcel();
            })
        );
		this.subscriptions.add(
            this.sharedData.getClickSaveEmailProvider().subscribe(x => {
                this.sendEmailToProvider();
            })
        );
	}

	public exportDataToExcel(){
        if (this.elementData !== undefined) {
			const worksheet = XLSX.utils.json_to_sheet(this.elementData);
			const workbook: XLSX.WorkBook = {
				Sheets: { data: worksheet },
				SheetNames: ['data'],
			};
			const excelBuffer: any = XLSX.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			this.saveAsExcelFile(excelBuffer, 'excelFileName');
		}
	}

	public async getResourceInformation(element){
        this.supplyService.updateActive(element.id)
        const dialogRef = this.dialog.open(DialogShowResourceComponent, {
			data:'STOCK',
            width: '600px',
            height: '400px', 
            panelClass: 'modalClass'
        });
    }
	
	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: ExcelConst.EXCEL_TYPE });
		FileSaver.saveAs(
			data,
			fileName +
				'_export_' +
				new Date().getTime() +
				ExcelConst.EXCEL_EXTENSION,
		);
    }

	applyFilter(filterValue: string) {
        //filterValue = filterValue.trim(); // Remove whitespace
        //filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
	}
	
	public filterData() {
        var rowSelected = this.sharedData.columnSelectedToFilter;
        var textInput = this.sharedData.searchTextToFilter;
        if (rowSelected === undefined) {
            return;
        }
        this.setDataSourceFilter(rowSelected)

        this.applyFilter(textInput)
    }

    public setDataSourceFilter(rowSelected) {
        switch (rowSelected) {
            case 1:
                this.dataSource.filterPredicate = (data: Supply, filter: string) => {
                    return (data.name).toLowerCase().includes(filter.toLowerCase());
                };
                break;
            case 2:
                this.dataSource.filterPredicate = (data: Supply, filter: string) => {
                    return (data.kind).toLowerCase().includes(filter.toLowerCase());
                };
                break;
            case 3:
                this.dataSource.filterPredicate = (data: Supply, filter: string) => {
                    return (data.date).toLowerCase().includes(filter.toLowerCase());
                };
                break;
            case 4:
                this.dataSource.filterPredicate = (data: Supply, filter: string) => {
                    return (data.unit).toLowerCase().includes(filter.toLowerCase());
                };
                break;
            case 5:
                this.dataSource.filterPredicate = (data: Supply, filter: string) => {
                    return (data.quantity).toLowerCase().includes(filter.toLowerCase());
                };
                break;
            case 6:
                this.dataSource.filterPredicate = (data: Supply, filter: string) => {
                    return (data.cost).toLowerCase().includes(filter.toLowerCase());
                };
                break;
            case 7:
                this.dataSource.filterPredicate = (data: Supply, filter: string) => {
                    return (data.coin).toLowerCase().includes(filter.toLowerCase());
                };
                break;
            default:
                return;
        }
    }

	public fillTableData() {
		
		this.activitiesStorageQuery.selectAll().subscribe( (full_activity) => {
			
			full_activity.forEach( (activity) => {
				if (activity.activity.detail !== undefined && activity.activity.detail !== [])
					this.detail_activities = this.detail_activities.concat(activity.activity.detail);
			});	
		})

		this.supplyStorageQuery.selectAll().subscribe((all) => {
			this.elementData = [];
			all.forEach((element) => {
				var elemnToBeInserted: Supply = {
					id: element.id,
					name: element.name,
					kind: element.kind,
					abbreviation: '',
					date: element.date,
					cost: element.cost,
					quantity: element.quantity,
					unit: element.unit,
					coin: element.coin,
					used: '',
					rest: '',
					email_is_sended: element.email_is_sended,
					email_to_send: element.email_is_sended,
				};

				elemnToBeInserted.abbreviation = this.getAbrreviation(
					element.name,
				);

				let used = this.findActivity(elemnToBeInserted.id);
				elemnToBeInserted.used= used.toString();
				elemnToBeInserted.rest = (
					Number(elemnToBeInserted.quantity) - used
				).toString();
				this.elementData.push(elemnToBeInserted);
			});
			if (this.elementData.length < 20) {
				var elementsNeededToCompleteTable =
					20 - this.elementData.length;
				for (
					let index = 0;
					index < elementsNeededToCompleteTable;
					index++
				) {
					this.elementData.push(this.getNewSupplyObject());
				}
			}
			this.dataSource = new MatTableDataSource(this.elementData);
		});
	}

	public getAbrreviation(word: string) {
		if (word.length >= 2) {
			return word.substr(0, 2).toUpperCase();
		} else {
			return word;
		}
	}

	public getNewSupplyObject() {
		return {
			id: '',
			name: '',
			kind: '',
			abbreviation: '',
			date: '',
			cost: '',
			quantity: '',
			unit: '',
			coin: '',
			costPerUnit: '',
			observation: '',
		};
	}

	private findActivity(id_detail_activity) {
		let total_resource  = 0;
		this.detail_activities.forEach( (detail) => {

			if (id_detail_activity == detail.resource &&  detail.type == 3	){
				total_resource += detail.percentage;
			}
		})
		return total_resource;
	}

	public async addToList(e, element){
		let provider = undefined
		if (await this.existProvider(element) == false){
			this.getProvider(e, element)
		}
		provider = await this.providerService.get(element.id, "3")
		
		if (provider.email !== ""){
			let emailProvider : EmailProvider= {
				"name": this.userInfo.name,
				"email": this.userInfo.email,
				"phone": this.userInfo.phone,
				"provider": provider.email,
				"list_resources":[
					{
						"id": parseInt(element.id),
						"unit" : element.unit,
						"amount" : element.used,
						"name": element.name,
					}
				]
			}
	
			let addNew = this.addToSameEmail(emailProvider)
			if ( addNew === false){
				this.listEmailProvider.push(emailProvider)
			}
			element.email_to_send = true
		}
		else{
			element.email_to_send = false
		}
		
	
	}
	public addToSameEmail(new_resource: EmailProvider){

		let result = false
		this.listEmailProvider.forEach(resource => {
			if(resource.provider === new_resource.provider){
				resource.list_resources.push(new_resource.list_resources[0])
				result = true
			}
			
		})
		return result
	}

	public async sendEmailToProvider(){
		
		
		const dialogRef = this.dialog.open(DialogConfirmationResource, {
            data: {
                mensaje: '¿Desea realizar los pedidos a su proveedor?'
            }
        });
        var g = dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
				this.listEmailProvider.forEach(async resource =>{
					let response = await this.supplyService.sendEmailProvider(resource)
					if(response == 201){
						this.toastService.createSuccessToast("Completado", "Se ha enviado el email a su Proveedor")
						
					}
					else{
						this.toastService.createErrorToast("Error", "No se ha podido enviar el email")
					}
				})
				
                
            }
			this.listEmailProvider = []
            g.unsubscribe();
        });

	}
	public async confirmResource(element){
		element.email_is_sended = false;
		await this.supplyService.updateMany([element])
	}

	public async existProvider(element): Promise<boolean>{
		const provider = await this.providerService.get(element.id, "3")
		if (provider.email == "" || provider.email== undefined){
			return false
		}
		return true
	}

	public async getProvider(e, element) {
        const type = "3";
        if (element.id !== "") {
            const provider = await this.providerService.get(element.id, type)
            provider["type"] = type;
            provider["resource"] = element.id;
            this.openDialog(e, provider)
        }
    }
	public openDialog(e, data): void {
        e.stopPropagation();
        e.preventDefault();

        const dialogRef = this.dialog.open(DialogProviderComponent, {
            width: '400px',
            height: '450px', disableClose: true,
            panelClass: 'custom-dialog-container',
            data: data
        });

    }
	public ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}




