import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DialogActivityComponent } from '../components/dialog-activity/dialog-activity.component';
import { ModalActivityParameters } from '../constants/modalActivityParameters';
import { ActivitiesService } from '../storage/activities-storage/activities-storage.service';
@Injectable({
  providedIn: 'root',
})
export class ManageModalActivityService {
  constructor(private datePipe: DatePipe, private activitiesService: ActivitiesService) {}

  openDialogActivity(dialog, data) {
    const dialogRef = dialog.open(DialogActivityComponent, {
      width: ModalActivityParameters.WIDTH,
      height: ModalActivityParameters.HEIGHT,
      disableClose: ModalActivityParameters.DISABLE_CLOSE,
      panelClass: 'custom-dialog-container',
      data: {
        name: data.activity.name,
        phase: data.activity.phase,
        detail: data.activity.detail,
        start: data.activity.start_date,
        end: data.activity.end_date,
        workingDay: data.activity.workday,
        duration: data.activity.duration,
        description: data.activity.description,
        calendar: data.activity.schedule_setting,
      },
    });

    dialogRef.afterClosed().subscribe((dataRecieve) => {
      if (dataRecieve.response == 'save') {
        data.activity.phase = dataRecieve.phase;
        data.activity.name = dataRecieve.name;
        data.activity.start_date = dataRecieve.start;
        data.activity.end_date = dataRecieve.end;
        data.activity.workday = dataRecieve.workingDay;
        data.activity.duration = dataRecieve.duration;
        data.activity.description = dataRecieve.description;
        data.activity.delete = dataRecieve.delete;
        data.activity.detail = [];
        dataRecieve.detail.forEach((detail) => {
          data.activity.detail.push({
            resource: detail.resource,
            percentage: detail.percentage,
            cost: '1',
            hour: 0,
            type: detail.type,
            id: detail.id != null ? detail.id : undefined,
          });
        });
        data.activity.start_date = this.datePipe.transform(data.activity.start_date, 'yyyy-MM-dd');
        data.activity.end_date = this.datePipe.transform(data.activity.end_date, 'yyyy-MM-dd');
        data.activity.schedule_setting = dataRecieve.calendar;
        if (data.id == undefined) {
          this.activitiesService.createOne([data]);
        } else {
          this.activitiesService.updateActivities([data]);
        }
      }
    });
  }
}
