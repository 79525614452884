import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Stadistic {
    average: number,
    id: number,
    lot: number,
    name: string,
    range1: number,
    range2: number,
    range3: number,
    range4: number,
    range5: number,
    range6: number,
    range7: number,
    range8: number,
    range9: number,
    range10: number
}

export interface StadisticsSingleObject {
    date: string,
    lot_id: string,
    name: string,
    statistics: Stadistic[]
}

export interface StadisticsObject {
    id: string,
    stats: StadisticsSingleObject []
}

export interface StadisticsState extends EntityState<StadisticsObject, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'stadistics' })
export class StadisticsStore extends EntityStore<StadisticsState> {
    public constructor() {
        super();
    }
}
