import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { MoneyState, Money, MoneyStore } from './money-storage.store'

@Injectable({ providedIn: 'root' })

export class MoneyQuery extends QueryEntity<MoneyState> {
    public selectAllFields: Observable<Money[]> = this.selectAll();
    constructor(protected store: MoneyStore) {
        super(store);
    }

}
