import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LotsQuery } from '@src/app/store/lots/lots.query';

@Component({
  templateUrl: 'dialog-select-lot.html',
  styleUrls: [`dialog-select-lot.scss`],
})
export class DialogSelectLot implements OnInit {
  lotForm = new FormGroup({
    lotId: new FormControl('', Validators.required),
    campaign: new FormControl('', Validators.required),
  });

  availableLots: any[] = [];
  lotCampaigns: any[] = [];

  constructor(public dialogRef: MatDialogRef<DialogSelectLot>, private lotsQuery: LotsQuery) {
    this.lotsQuery.selectAll().subscribe((result) => {
      this.availableLots = result;
      if (this.availableLots.length) {
        const campaigns = this.availableLots[0].campaigns || [];
        this.lotCampaigns = campaigns.filter((elem) => !elem.isFinished);

        if (this.lotCampaigns.length) {
          const activeCampaign = this.lotCampaigns[0];
          if (activeCampaign) this.lotForm.patchValue({ campaign: activeCampaign.id });
        }

        this.lotForm.patchValue({ lotId: this.availableLots[0].id });
      }
    });
  }

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close({ result: 'CLOSE' });
  }

  selectNewCampaing(event) {
    const id = event.target.value;
    const lot = this.availableLots.find((elem) => elem.id === id);
    const campaigns = lot.campaigns || [];
    this.lotCampaigns = campaigns.filter((elem) => !elem.isFinished);
    if (this.lotCampaigns.length) {
      const activeCampaign = this.lotCampaigns[0];
      if (activeCampaign) this.lotForm.patchValue({ campaign: activeCampaign.id });
    }
  }

  saveSelectedLot() {
    if (this.lotForm.valid) this.dialogRef.close({ result: 'LOT_SELECTED', data: this.lotForm.value });
  }
}
