import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class ThemeDarkLightService {
	private subject = new Subject<any>();
	private themeClass: string = 'light-theme';
	constructor() {}

	public sendClickToUpdateTheme(className: string) {
		this.themeClass = className;
		const body = document.getElementsByTagName('body')[0];
		if (className == 'light-theme') {
			body.classList.remove('dark-theme');
			body.classList.add('light-theme');
		} else {
			body.classList.remove('light-theme');
			body.classList.add('dark-theme');
		}
		this.subject.next(className);
	}

	public getClickToUpdateTheme(): Observable<any> {
		return this.subject.asObservable();
	}

	public getThemeName(): string {
		return this.themeClass;
	}
}
