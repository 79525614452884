import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { WaterService } from '../../storage/water-storage/water-storage.service';
import { Water } from '../../storage/water-storage/water-storage.store';
import { WaterStorageQuery } from '../../storage/water-storage/water-storage.query';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { Subscription } from 'rxjs';
import { MoneyQuery } from '../../storage/money-storage/money-storage.query';
import { DialogConfirmationResource } from '../confirmation-dialog/confirmation-dialog';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { UserQuery } from '@src/app/store/user/user.query';
import { DialogShowWaterComponent } from '../dialogs/dialog-show-water/dialog-show-water.component';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';

@Component({
	selector: 'app-water-table',
	templateUrl: './water-table.component.html',
	styleUrls: ['./water-table.component.scss'],
})
export class WaterTableComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = [
		'position',
		'date',
		'unit',
		'abbreviation',
		'quantity',
		'coin',
		'cost',
		'totalCost',
		'usedAmount',
		'totalCostUsed',
		'observation',
	];
	public displayedColumnsOriginal: string[] = [
		'position',
		'date',
		'unit',
		'abbreviation',
		'quantity',
		'coin',
		'cost',
		'totalCost',
		'usedAmount',
		'totalCostUsed',
		'observation',
	];

	public waterUnitOptions = [
		'Lt.',
		'm3',
		'Galones'

	]
	public dataSource = new MatTableDataSource([]);
	public copiedElement = undefined;
	public elementData = [];
	public moneyValues = [];
	private numberOfElementsModified = 0;
	private idsOfElemetsThatWereModified = [];
	private subscriptions = new Subscription();
	private isSaving = false;
	private idsOfElementsToBeDeleted = [];
	constructor(
		private readonly globalToastService: GlobalToastService,
		public waterService: WaterService,
		public waterQuery: WaterStorageQuery,
		private moneyQuery: MoneyQuery,
		public dialog: MatDialog,
		public sharedData: SharedDataServiceService,
		public activityQuery: ActivitiesStorageQuery,
		private readonly userQuery: UserQuery
	) {}

	ngOnInit(): void {
		this.initializeData();
	}

	public async initializeData() {
		this.moneyQuery.selectAll().subscribe((allMoneys) => {
			this.moneyValues = allMoneys;
		});
		await this.waterService.findAll();
		this.fillTableData();
		this.subscriptions.add(
			this.sharedData.getClickSaveWater().subscribe((x) => {
				this.save();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilter().subscribe((x) => {
				this.filterData();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToExport().subscribe((x) => {
				this.exportDataToExcel();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilterColumns().subscribe((x) => {
				if (this.sharedData.selectedColumns.length == 0)
					this.displayedColumns = this.displayedColumnsOriginal;
				else {
					this.sharedData.selectedColumns.unshift('position');
					this.displayedColumns = this.sharedData.selectedColumns;
				}
			}),
		);
	}

	public applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue;
	}

	public filterData() {
		var rowSelected = this.sharedData.columnSelectedToFilter;
		var textInput = this.sharedData.searchTextToFilter;
		if (rowSelected === undefined) {
			return;
		}
		this.setDataSourceFilter(rowSelected);

		this.applyFilter(textInput);
	}

	public setDataSourceFilter(rowSelected) {
		switch (rowSelected) {
			case '1':
				this.dataSource.filterPredicate = (
					data: Water,
					filter: string,
				) => {
					return data.date
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '2':
				this.dataSource.filterPredicate = (
					data: Water,
					filter: string,
				) => {
					return data.unit
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '3':
				this.dataSource.filterPredicate = (
					data: Water,
					filter: string,
				) => {
					return data.quantity
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '4':
				this.dataSource.filterPredicate = (
					data: Water,
					filter: string,
				) => {
					return data.coin
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '5':
				this.dataSource.filterPredicate = (
					data: Water,
					filter: string,
				) => {
					return data.cost
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			default:
				return;
		}
	}

	public fillTableData() {
		this.waterQuery.selectAll().subscribe((all) => {
			this.elementData = [];
			all.forEach((element) => {
				var elemnToBeInserted = {
					id: element.id,
					date: element.date,
					unit: element.unit,
					abbreviation: '',
					quantity: element.quantity,
					cost: element.cost,
					coin: element.coin,
					totalCost: '',
					observation: element.observation,
					usedAmount: 0,
					totalCostUsed: 0,
				};
				elemnToBeInserted.totalCost = (
					Math.round(
						parseFloat(element.cost) *
							parseFloat(element.quantity) *
							100,
					) / 100
				).toString();
				this.elementData.push(elemnToBeInserted);
			});
			if (this.elementData.length < 20) {
				var elementsNeededToCompleteTable =
					20 - this.elementData.length;
				for (
					let index = 0;
					index < elementsNeededToCompleteTable;
					index++
				) {
					this.elementData.push(this.getNewWaterObject());
				}
			}
			const allActivities = this.activityQuery.getAll();
			this.calculateActivityCostWater(allActivities);
			this.dataSource = new MatTableDataSource(this.elementData);
		});
	}

	public calculateActivityCostWater(allActivities) {
		if (allActivities.length > 0) {
			this.elementData.forEach((element) => {
				if (element.id !== '') {
					element.usedAmount = 0;
					element.totalCostUsed = 0;
					for (let index = 0; index < allActivities.length; index++) {
						const activityObject = allActivities[index];
						if (activityObject.activity.detail.length > 0) {
							for (
								let j = 0;
								j < activityObject.activity.detail.length;
								j++
							) {
								const resource =
									activityObject.activity.detail[j];
								if (
									resource.resource + '' == element.id &&
									resource.type == 2
								) {
									element.usedAmount =
										resource.percentage +
										element.usedAmount;

									const costPerProduct = parseInt(
										element.cost,
									);
									const totalValue =
										resource.percentage * costPerProduct;

									element.totalCostUsed =
										totalValue + element.totalCostUsed;
									break;
								}
							}
						}
					}
					try {
						if (element.usedAmount > 0)
							element.usedAmount = (
								Math.round(element.usedAmount * 100) / 100
							).toLocaleString('en-US');

						if (element.totalCostUsed > 0)
							element.totalCostUsed = (
								Math.round(element.totalCostUsed * 100) / 100
							).toLocaleString('en-US');
					} catch (error) {}
				}
			});
		}
	}
	public getNewWaterObject() {
		return {
			id: '',
			date: '',
			unit: 'Lt.',
			abbreviation: '',
			quantity: '',
			cost: '',
			coin: '1',
			totalCost: '',
			observation: '',
			usedAmount: 0,
			totalCostUsed: 0,
		};
	}
	public addRowUp(position) {
		this.elementData.splice(position, 0, this.getNewWaterObject());
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public addRowDown(position) {
		this.elementData.splice(position, 0, this.getNewWaterObject());
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public isNotListedToBeUpdated(idOfLabor) {
		return (
			idOfLabor !== '' &&
			idOfLabor !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(idOfLabor)
		);
	}

	public copy(element) {
		var elemnToBeInserted = {
			id: 'new',
			date: element.date,
			unit: element.unit,
			abbreviation: element.abbreviation,
			quantity: element.quantity,
			cost: element.cost,
			coin: element.coin,
			totalCost: element.totalCost,
			observation: element.observation,
		};
		this.copiedElement = elemnToBeInserted;
	}

	public paste(position) {
		this.elementData[position] = this.copiedElement;
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public delete(position) {
		this.idsOfElementsToBeDeleted.push(this.elementData[position].id);
		if (
			this.idsOfElemetsThatWereModified.includes(
				this.elementData[position].id,
			)
		) {
			var index = this.idsOfElemetsThatWereModified.indexOf(
				this.elementData[position].id,
			);
			this.idsOfElemetsThatWereModified.splice(index, 1);
		}
		this.elementData.splice(position, 1);
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public changeAssigned(event, element) {
		element.date = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeUnity(event, element) {
		element.unit = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeAmount(event, element) {
		element.quantity = event.target.value;
		this.calculateCostPerHour(element);
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeCoin(event, element) {
		element.coin = event.target.value + '';

		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeCostPerUnity(event, element) {
		element.cost = event.target.value;
		this.calculateCostPerHour(element);
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public changeObservations(event, element) {
		element.observation = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public calculateCostPerHour(element) {
		if (element.cost !== '' && element.hours !== '') {
			try {
				element.totalCost = (
					Math.round(
						parseFloat(element.cost) *
							parseFloat(element.quantity) *
							100,
					) / 100
				).toString();
			} catch (error) {
				element.totalCost = '';
			}
		}
	}

	public save() {
		if (this.isSaving) {
			return;
		} else {
			this.isSaving = true;
		}
		this.numberOfElementsModified = 0;
		var newElemets: Water[] = [];
		var elementsToBeUpdated: Water[] = [];
		for (let index = 0; index < this.elementData.length; index++) {
			const element = this.elementData[index];
			if (element.id !== '') {
				this.numberOfElementsModified++;
			}
			if (element.id === 'new') {
				if (this.validation(element)) {
					newElemets.push(element);
				} else {
					if (
						!(
							element.date == '' &&
							element.unit == '' &&
							element.quantity == '' &&
							element.cost == '' &&
							element.observation == ''
						)
					) {
						this.isSaving = false;
						this.globalToastService.createErrorToast(
							'Error',
							'Por favor llenar todos los campos obligatorios',
						);
						return;
					}
				}
			}
			if (this.idsOfElemetsThatWereModified.includes(element.id)) {
				if (this.validation(element)) {
					elementsToBeUpdated.push(element);
				} else {
					this.globalToastService.createErrorToast(
						'Error',
						'Por favor llenar todos los campos obligatorios',
					);
					this.isSaving = false;
					return;
				}
			}
		}
		if (
			newElemets.length === 0 &&
			elementsToBeUpdated.length === 0 &&
			this.idsOfElementsToBeDeleted.length === 0
		) {
			this.sharedData.sentViewConsultance();
			return;
		}
		const dialogRef = this.dialog.open(DialogConfirmationResource, {
			data: {
				mensaje:
					'¿Desea guardar ' +
					this.numberOfElementsModified +
					' recursos de "Agua"?',
			},
		});

		var g = dialogRef.afterClosed().subscribe((result) => {
			if (result === undefined) {
				this.idsOfElemetsThatWereModified = [];
				this.idsOfElementsToBeDeleted = [];
				this.fillTableData();
				this.sharedData.sentViewConsultance();
			}

			if (result) {
				if (newElemets.length > 0)
					this.waterService.createManyMachines(newElemets);
				if (elementsToBeUpdated.length > 0)
					this.waterService.updateMany(elementsToBeUpdated);
				if (this.idsOfElementsToBeDeleted.length > 0)
					this.waterService.deleteMany(this.idsOfElementsToBeDeleted);
				this.idsOfElemetsThatWereModified = [];
				this.sharedData.sentViewConsultance();
			}

			if (result === false) {
			}
			this.numberOfElementsModified = 0;
			this.isSaving = false;
			g.unsubscribe();
		});
	}

	public validation(element: Water): boolean {
		if (
			element.date === '' ||
			element.unit === '' ||
			element.quantity === '' ||
			element.coin === '' ||
			element.cost === ''
		) {
			return false;
		}
		return true;
	}

	public exportDataToExcel() {
		if (this.elementData !== undefined) {
			const worksheet = XLSX.utils.json_to_sheet(this.elementData);
			const workbook: XLSX.WorkBook = {
				Sheets: { data: worksheet },
				SheetNames: ['data'],
			};
			const excelBuffer: any = XLSX.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			this.saveAsExcelFile(excelBuffer, 'excelFileName');
		}
	}
	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: ExcelConst.EXCEL_TYPE });
		FileSaver.saveAs(
			data,
			fileName +
				'_export_' +
				new Date().getTime() +
				ExcelConst.EXCEL_EXTENSION,
		);
	}

    public ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    public isValidPlan() {
        const currentUser = this.userQuery.getInformation();
        return currentUser.permission.resourceManagement;
    }

    public showConfirmPlanUpdate() {
        if (this.isValidPlan())
            return;

        const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
            width: '400px'
        });
        saveRef.afterClosed().subscribe()
    }

	public async getResourceInformation(element) {
		this.waterService.updateActive(element.id);
		const dialogRef = this.dialog.open(DialogShowWaterComponent, {
			width: '600px',
			height: '400px',
			panelClass: 'modalClass',
		});
	}
}
