import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message-update-plan',
  templateUrl: './message-update-plan.component.html',
  styleUrls: ['./message-update-plan.component.scss']
})
export class MessageUpdatePlanComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MessageUpdatePlanComponent>, private readonly router: Router, private dialogRefAll: MatDialog) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(true);
  }

  viewPlans(): void {
    this.router.navigate(["/plans"])
    this.dialogRefAll.closeAll();
  }

}
