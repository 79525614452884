import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { SupplyState, Supply, SupplyStore } from './supplies-storage.store'

@Injectable({ providedIn: 'root' })

export class SupplyStorageQuery extends QueryEntity<SupplyState> {
    public selectAllFields: Observable<Supply[]> = this.selectAll();
    constructor(protected store: SupplyStore) {
        super(store);
    }

}
