import { Injectable } from '@angular/core';
import { QueryEntity, combineQueries } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LaborState, Labor, LaborStore } from './labor-storage.store'

@Injectable({ providedIn: 'root' })

export class LaborStorageQuery extends QueryEntity<LaborState> {
    public selectAllFields: Observable<Labor[]> = this.selectAll();
    constructor(protected store: LaborStore) {
        super(store);
    }

}
