import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LotDroneTicketQuery } from '@src/app/store/drone-ticket/drone-ticket.query';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { LotsService } from '@src/app/store/lots/lots.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import {
    CannotDeleteDialog,
    FieldDeleteDialog,
    LotDeleteDialog,
} from '../../modules/fields/components/field-list/field-list.component';
import { FieldsUiService } from '../../modules/fields/services/fields-ui.service';
import { MapSidebarComService } from '../../modules/fields/services/map-sidebar-com.service';
import { SharedLotsService } from '../../services/shared-lots/shared-lots.service';
import { LotEditService } from '../../states/lot-edit/lot-edit.service';
import { SatellitalImageService } from '../../store/satellital-images-state/satellital-images-state.service';

@Component({
    selector: 'app-shared-field-list',
    templateUrl: './shared-field-list.component.html',
    styleUrls: ['./shared-field-list.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('0.5s ease-out', style({ height: 300, opacity: 1 })),
            ]),
            transition(':leave', [
                style({ height: 300, opacity: 1 }),
                animate('0.5s ease-in', style({ height: 0, opacity: 0 })),
            ]),
        ]),
    ],
})
export class SharedFieldListComponent implements OnInit, OnDestroy {
    public selectedModal: number = 2;
    public suscriptions: Subscription[] = [];
    public allFields = [];
    public showList = true;
    public filterInputText = '';
    public allFieldsOriginal = [];

    constructor(
        private readonly fieldsQuery: FieldsQuery,
        private readonly fieldsUiService: FieldsUiService,
        private lotsService: LotsService,
        private mapSidebarComService: MapSidebarComService,
        private fieldsService: FieldsService,
        public dialog: MatDialog,
        public lotEditService: LotEditService,
        private readonly spinner: NgxSpinnerService,
        private satellitalImageService: SatellitalImageService,
        private readonly lotDroneTicketQuery: LotDroneTicketQuery,
        private readonly sharedLots: SharedLotsService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.allFields = await this.sharedLots.getLotsShared();
        if (this.allFields.length > 0) this.allFieldsOriginal = JSON.parse(JSON.stringify(this.allFields));
        /*
        this.allFields.forEach( (field) =>{
            field.lots.forEach( async (lot) =>{
                console.log("lot", lot)
                
                console.log("r", response)
            })
        })
        */
    }

    ngOnDestroy(): void {
        
    }

    public lotDetail(lot) {
        this.lotEditService.setSelectedResource(0);
        this.sharedLots.sendUpdateSelectedLotShared(lot);
        this.satellitalImageService.setLotIdForSatellitalImage(lot.id);
    }

    public filterSearch(event) {
        this.filterInputText = event.target.value;
        this.allFields = JSON.parse(JSON.stringify(this.allFieldsOriginal));
        /*
        if (this.selectedModal == 1) {
            this.allFields = this.allFields.filter((elem) =>
                elem.name.toLowerCase().includes(this.filterInputText.toLowerCase()),
            );
        }*/
        if (this.selectedModal == 2) {
            for (let index = 0; index < this.allFields.length; index++) {
                const element = this.allFields[index];
                element.lots = element.lots.filter((lot) =>
                    lot.name.toLowerCase().includes(this.filterInputText.toLowerCase()),
                );
            }
        } 
        /*
        if (this.selectedModal == 3) {
            for (let index = 0; index < this.allFields.length; index++) {
                const element = this.allFields[index];
                element.lots = element.lots.filter((lot) =>
                    lot.varietyName.toLowerCase().includes(this.filterInputText.toLowerCase()),
                );
            }
        }*/
    }

    trackByFn(index, item) {
        return index; // or item.id
    }

    public isThereAnyLotTicketInField(fieldId) {
        const field = this.fieldsQuery.getEntity(fieldId);
        const ticketsOfLot = this.lotDroneTicketQuery.getAll().filter((ticket) => field.lots.includes(ticket.lot));
        return ticketsOfLot.length > 0;
    }

    public async deleteField(idField: string) {
        if (this.isThereAnyLotTicketInField(idField)) {
            const dialogRef = this.dialog.open(CannotDeleteDialog);
        } else {
            const dialogRef = this.dialog.open(FieldDeleteDialog);
            const a = dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.spinner.show('Syncrof');
                    try {
                        this.fieldsService.removeField(idField);
                    } catch (error) {}
                    this.spinner.hide('Syncrof');
                }
                a.unsubscribe();
            });
            this.fieldsUiService.closeSidebar();
        }
    }

    public editField(fieldId: string) {
        this.fieldsService.setActiveField(fieldId);
        this.fieldsUiService.setSelectedResource(4);
        this.fieldsUiService.openSidebar();
    }

    public isThereAnyLotTicket(idLot) {
        const ticketsOfLot = this.lotDroneTicketQuery.getAll().filter((ticket) => ticket.lot == idLot);
        return ticketsOfLot.length > 0;
    }

    public createField(fieldId) {
        this.fieldsService.setActiveField(fieldId);
        this.mapSidebarComService.sentClickCreateLot();
    }

    public hideList() {
        this.showList = !this.showList;
    }

    public deleteLot(lotId: string) {
        if (this.isThereAnyLotTicket(lotId)) {
            const dialogRef = this.dialog.open(CannotDeleteDialog);
        } else {
            const dialogRef = this.dialog.open(LotDeleteDialog);
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) await this.comfirmDeleteLot(lotId);
            });
        }
    }

    public async comfirmDeleteLot(lotId: string) {
        this.spinner.show('Syncrof');
        this.lotsService.setActiveLot(lotId);
        let rpt = await this.lotsService.deleteLot(lotId);
        if (!rpt) return;

        this.fieldsUiService.closeSidebar();
        this.mapSidebarComService.sentClickDeleteLot(lotId);
        this.spinner.hide('Syncrof');
    }

    public editLot(lotId) {
        if (this.isThereAnyLotTicket(lotId)) {
            const dialogRef = this.dialog.open(CannotDeleteDialog);
        } else {
            //Change value of state that will display edit form
            this.lotEditService.setSelectedResource(1);
            //Set the lot that will be shown in the sidebar
            this.lotsService.setActiveLot(lotId);
            //Go to the center of the lot
            this.mapSidebarComService.sentClickToCenter();
            //Set the sidebar that will be displayed
            this.fieldsUiService.setSelectedResource(3);
            //Open the sidebar
            this.fieldsUiService.openSidebar();
            //Enable polygon edit
            this.mapSidebarComService.sentClickEnableEdit(lotId);
            this.satellitalImageService.setSatellitalImageDisplay(false);
        }
    }

    public hideListReactive() {
        if (this.showList) {
            this.heightOfList = '24px';
        } else {
            this.heightOfList = '40%';
        }
        this.showList = !this.showList;
    }
    public heightOfList = '100%';
    @ViewChild('filterField') redel: ElementRef;
}
