export interface TypeData {
    id: number;
    lineSrc: string;
    fillSrc: string;
    name: string;
    color: string;
}

const typesResources: TypeData[] = [
    { id: 0, lineSrc: "assets/images/agromonitor/mano-de-obra-linea.svg", fillSrc: "assets/images/agromonitor/mano-de-obra-relleno.svg", name: "Mano de obra", color: "#1eb865" },
    { id: 1, lineSrc: "assets/images/agromonitor/maquinaria-linea.svg", fillSrc: "assets/images/agromonitor/maquinaria-relleno.svg", name: "Maquinaria", color: "#5ccbf0" },
    { id: 2, lineSrc: "assets/images/agromonitor/agua-linea.svg", fillSrc: "assets/images/agromonitor/agua-relleno.svg", name: "Agua", color: "#a7e7fd" },
    { id: 3, lineSrc: "assets/images/agromonitor/insumos-linea.svg", fillSrc: "assets/images/agromonitor/insumos-relleno.svg", name: "Insumos", color: "#e7eb8c" },
    { id: 4, lineSrc: "assets/images/agromonitor/servicios-linea.svg", fillSrc: "assets/images/agromonitor/servicios-relleno.svg", name: "Servicios / Otros", color: "#b2b3b4" }
];
export { typesResources }