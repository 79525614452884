import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import { FullActivity } from '../storage/activities-storage/activities-storage.store';
@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }

  public transformData(dataToTransform) {
    let allData = [];
    dataToTransform.forEach(d => {
      let generalInformation = [];
      let hours = d.activity.duration * d.activity.workday;
      generalInformation.push(d.field);
      generalInformation.push(d.camp_id);
      generalInformation.push(d.season);
      generalInformation.push(d.activity.phase);
      generalInformation.push(d.activity.name);
      generalInformation.push(d.activity.duration);
      generalInformation.push(d.activity.start_date);
      generalInformation.push(d.activity.end_date);
      generalInformation.push(hours);

      let resources: Array<Array<any>> = Array([], [], [], [], [], []);
      d.activity.detail.forEach(resource => {
        if (resource.type == 0) {
          let mo = []
          mo.push("nombre")
          mo.push(resource.percentage.toString() + "%")
          mo.push((resource.percentage / 100) * hours)
          mo.push("cost")
          resources[0].push(mo);
        }
        if (resource.type == 1) {
          let maq = []
          maq.push("nombre")
          maq.push(resource.percentage.toString() + "%")
          maq.push((resource.percentage / 100) * hours)
          maq.push("cost")
          resources[1].push(maq);
        }
        if (resource.type == 2) {
          let sup = []
          sup.push("nombre")
          sup.push(resource.percentage.toString())
          sup.push("cost")
          resources[2].push(sup);
        }
        if (resource.type == 3) {
          let wat = []
          wat.push("nombre")
          wat.push(resource.percentage.toString())
          wat.push("cost")
          resources[3].push(wat);
        }
        if (resource.type == 4) {
          let ser = []
          ser.push("nombre")
          ser.push(resource.percentage.toString())
          ser.push("cost")
          resources[4].push(ser);
        }
      })
      allData.push({
        generalInformation: generalInformation,
        resource: resources
      })

    })

    return {
      title: "Gestión de actividades",
      headers: ["CAMPO", "PARCELA", "CAMPAÑA / TEMPORADA", "FASE", "ACTIVIDAD", "DURACION (Días)", "INICIO", "FIN", "DURACION (horas)"],
      headersResource: [
        { value: "MO", headers: ["Asignado", "%", "Unidad HH", "Total S/"] },
        { value: "MAQ", headers: ["Asignado", "%", "Unidad HM", "Total S/"] },
        { value: "INSUMOS", headers: ["Asignado", "Cant", "Total S/"] },
        { value: "AGUA", headers: ["Asignado", "Cant", "Total S/"] },
        { value: "SERVICIO / OTROS", headers: ["Asignado", "Cant", "Total S/"] }
      ],
      data: allData
    }
    return {
      title: "Gestión de actividades",
      headers: ["CAMPO", "PARCELA", "CAMPAÑA / TEMPORADA", "FASE", "ACTIVIDAD", "DURACION (Días)", "INICIO", "FIN", "DURACION (horas)"],
      headersResource: [
        { value: "MO", headers: ["Asignado", "%", "Unidad HH", "Total S/"] },
        { value: "MAQ", headers: ["Asignado", "%", "Unidad HM", "Total S/"] },
        { value: "INSUMOS", headers: ["Asignado", "Cant", "Total S/"] },
        { value: "AGUA", headers: ["Asignado", "Cant", "Total S/"] },
        { value: "SERVICIO / OTROS", headers: ["Asignado", "Cant", "Total S/"] }
      ],
      data: [
        {
          generalInformation: ["La panchita", "LP 32", "2019-1", "Preparación de terreno", "Limpieza de terreno", 2, "25/07/2020", "27/07/2020", 16],
          resource: [
            [
              ["Luis Campo", "50%", 8, 40],
              ["Juan Campo", "50%", 8, 50],
              ["Tractor 300X", "25%", 4, 200],
            ],
            [
              ["Tractor 300X", "25%", 4, 200],
            ],
            [

            ],
            [

            ],
            [
              ["Herramienta Palas", 5, 99],
            ],
          ]
        },
      ]
    }
  }

  public exportActivityExcel(excelData) {
    excelData = this.transformData(excelData)
    const title = excelData.title;
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Agromonitor');

    for (let i = 0; i < excelData.headers.length; ++i) {
      let column = String.fromCharCode(65 + i)
      worksheet.mergeCells(column + '2', column + '3');
      let headerRow = worksheet.getCell(column + '2');
      headerRow.value = excelData.headers[i];
    }
    let columnStart = String.fromCharCode(65 + excelData.headers.length)
    let columnEnd = String.fromCharCode(65 + excelData.headers.length + 16)

    worksheet.mergeCells(columnStart + '2', columnEnd + '2');
    let headerRow = worksheet.getCell(columnStart + '2');
    headerRow.value = "Recursos";
    let pos = 0;
    for (let i = 0; i < excelData.headersResource.length; ++i) {
      let columnStart = String.fromCharCode(65 + pos + excelData.headers.length)
      let columnEnd = String.fromCharCode(65 + pos + excelData.headers.length + excelData.headersResource[i].headers.length - 1)
      worksheet.mergeCells(columnStart + '3', columnEnd + '3');
      let headerRow = worksheet.getCell(columnStart + '3');
      headerRow.value = excelData.headersResource[i].value;
      for (let j = 0; j < excelData.headersResource[i].headers.length; ++j) {
        let column = String.fromCharCode(65 + pos + excelData.headers.length)
        let headerResourceRow = worksheet.getCell(column + '4');
        headerResourceRow.value = excelData.headersResource[i].headers[j]
        pos++
      }
    }


    for (let i = 0; i < excelData.data.length; i++) {
      let row = i + 6;
      for (let j = 0; j < excelData.headers.length; ++j) {
        let column = String.fromCharCode(65 + j)
        let cell = worksheet.getCell(column + row.toString());
        cell.value = excelData.data[i].generalInformation[j];
      }
      row++
      let maxRow = row
      let pos = excelData.headers.length;
      for (let d = 0; d < excelData.headersResource.length; ++d) {
        let tempRow = row
        for (let j = 0; j < excelData.data[i].resource[d].length; ++j) {
          for (let k = 0; k < excelData.headersResource[d].headers.length; ++k) {
            let column = String.fromCharCode(65 + pos + k)
            let headerResourceRow = worksheet.getCell(column + tempRow.toString());
            headerResourceRow.value = excelData.data[i].resource[d][j][k]
          }
          tempRow++
        }
        pos += excelData.headersResource[d].headers.length
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: ExcelConst.EXCEL_TYPE });
      fs.saveAs(blob, title + '.xlsx')
    })
  }
}
