import { Injectable } from '@angular/core';
import {StoreConfig, EntityState, EntityStore } from '@datorama/akita';

export interface Variety {
    id?: string,
    name?: string
}

export interface VarietyState extends EntityState<Variety, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Variety' })
export class VarietyStore extends EntityStore<VarietyState> {

    constructor() {
        super();
    }


}

