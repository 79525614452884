import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Campaing {
    id?: string
    isFinished?: boolean
    name?: string
    varietyId?: number
    phonologicalState?: number
}

export interface Lot {
    id?: string;
    fieldId?:string;
    name?: string;
    area?: number;
    center?: number[][];
    coordinates?: number[][];
    varietyId?: string;
    cropName?: string;
    varietyName?: string;
    subVarietyName?: string;
    phonologicalState?: number;
    campaigns?: Campaing[];
    ubigeo?: String;
}
export interface LotsState extends EntityState<Lot, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lots' })
export class LotsStore extends EntityStore<LotsState> {
    public constructor() {
        super();
    }
}
