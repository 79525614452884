import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Header } from '../../constants/header';
import { PreviewActivity } from '../../constants/previewActivity';
import { ManageModalActivityService } from '../../services/manage-modal-activity.service';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { FullActivity } from '../../storage/activities-storage/activities-storage.store';
import { LaborService } from '../../storage/labor-storage/labor-storage.service';
import { MachineService } from '../../storage/machine-storage/machine-storage.service';
import { WaterService } from '../../storage/water-storage/water-storage.service';

@Component({
    selector: 'app-year-component',
    templateUrl: './year-component.component.html',
    styleUrls: ['./year-component.component.scss']
})
export class YearComponentComponent implements OnInit, AfterViewChecked {
    @ViewChild('scrollOne') scrollOne: ElementRef;
    @Input() lotsFromServer: [];
    @Input() lotZoom: boolean;
    public percentage = 40;
    public headers: Header[];
    public activities: PreviewActivity[];
    public allActivities: FullActivity[];
    public yearSelected;
    public month = 9;
    public months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC", "ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"]
    public sundays;
    private today = new Date();
    public dateToPadding = 0;
    constructor(
        private sharedDataService: SharedDataServiceService,
        private readonly laborService: LaborService,
        private readonly machineService: MachineService,
        private readonly waterService: WaterService,
        private activitiesService: ActivitiesService,
        private activitiesQuery: ActivitiesStorageQuery,
        public dialog: MatDialog,
        private manageModalActivityService: ManageModalActivityService
    ) {
        this.initializeDate();
        this.sharedDataService.getClickToUpdateCalendar().subscribe(x => {
            this.initializeDate();
        });

        this.activitiesQuery.selectAll().subscribe(data => {
            this.allActivities = JSON.parse(JSON.stringify(data));
            this.updatePreviewActivity(this.allActivities);
        });
    }
    ngAfterViewChecked(): void {
        this.updateScrollToActualDate();
        this.dateToPadding = 0;
        const scrollOne = this.scrollOne.nativeElement as HTMLElement;
        scrollOne.scrollTop = this.sharedDataService.scrollVertical;
    }
    ngOnInit(): void {
    }

    public async initializeDate() {
        await this.activitiesService.findAll();
        this.yearSelected = this.sharedDataService.yearSelected;
        this.month = 9;
        this.sundays = []
        var thisYear = this.getDefaultOffDays2(this.yearSelected);
        this.sundays = thisYear
        if (this.sundays !== undefined) {
            this.sundays = this.sundays.concat(this.getDefaultOffDays2(this.yearSelected + 1))
        }
        this.activities = [];
        this.updatePreviewActivity(this.allActivities);
        if (this.sundays !== undefined) {
            for (let index = 0; index < this.sundays.length; index++) {
                const element = this.sundays[index];
                let end = new Date(element[0] + "-" + element[1] + "-" + element[2])
                var start = new Date((new Date(element[0] + "-" + element[1] + "-" + element[2]).setDate(end.getDate() - 6)))
                var unixToday = parseInt((this.today.getTime() / 1000).toFixed(0))
                var unixStart = parseInt((start.getTime() / 1000).toFixed(0))
                var unixEnd = parseInt((end.getTime() / 1000).toFixed(0))
                if (this.overlapWeekToday(unixToday, unixStart, unixEnd)) {
                    this.dateToPadding = index - 1;
                }
            }
        }
    }

    public getDefaultOffDays2(year) {
        if (year === undefined) {
            return;
        }
        var date = new Date(year, 0, 1);
        while (date.getDay() != 0) {
            date.setDate(date.getDate() + 1);
        }
        var days = [];
        while (date.getFullYear() === year) {
            var m = date.getMonth() + 1;
            var d = date.getDate();
            days.push([year, (m < 10 ? '0' + m : m), (d < 10 ? '0' + d : d)]);
            date.setDate(date.getDate() + 7);
        }
        return days;
    }

    public isBetweenRange(text: string, sunday) {
        return false;
    }

    public updatePreviewActivity(activities) {
        this.activities = [];
        var lots: { [id: string]: number; } = {};
        this.dateToPadding = 0;
        activities.forEach(activity => {

            if (lots[activity.camp_id] != undefined) {
                lots[activity.camp_id] += 1
            }
            else {
                lots[activity.camp_id] = 0
            }
            //if (lots[activity.camp_id] < 4) {
            let durationActivity = this.getDuration(activity);
            if (durationActivity.duration > 0) {
                this.activities.push({
                    duration: durationActivity.duration,
                    fullActivity: activity,
                    start: durationActivity.start,
                    index: lots[activity.camp_id]
                })
            }
            //}
        }
        );
    }
    private getDuration(fullActivity: FullActivity) {
        if (this.sundays === undefined) {
            return {
                duration: 0,
                start: 0,
            };
        }
        let activityStarDate = new Date(fullActivity.activity.start_date);
        let activityEndDate = new Date(fullActivity.activity.end_date);
        var cont = 0;
        var started = undefined;

        for (let index = 0; index < this.sundays.length; index++) {
            const element = this.sundays[index];
            let end = new Date(element[0] + "-" + element[1] + "-" + element[2])
            var start = new Date((new Date(element[0] + "-" + element[1] + "-" + element[2]).setDate(end.getDate() - 6)))

            if (this.overlapWeek(
                start,
                end,
                activityStarDate,
                activityEndDate
            )) {
                if (started === undefined) {
                    started = index;
                }
                cont++;
            }
        }
        return {
            duration: cont,
            start: started,
        }
    }

    public overlapWeek(startD, endD, startdate, enddate) {
        return (endD >= startdate && startD <= enddate)
    }
    public createActivity(lot, event) {
        let activity = {
            camp_id: lot.id,
            season: lot.campaign,
            activity: {
                name: "",
                phase: "",
                start_date: "",
                end_date: "",
                hour: 0,
                workday: 0,
                duration: 0,
                detail: [],
                description: ""
            }
        }
        this.openDialog(activity, event)
    }

    openDialog(data, e): void {
        e.stopPropagation();
        e.preventDefault();
        this.manageModalActivityService.openDialogActivity(this.dialog, data);
    }

    public overlapWeekToday(today, startdate, enddate) {
        return (today >= startdate && today <= enddate)
    }

    public scrollUp = 0;
    public updateScroll() {
        const scrollOne = this.scrollOne.nativeElement as HTMLElement;
        if (scrollOne.scrollLeft > scrollOne.scrollWidth / 2) {
            this.sharedDataService.yearDisplayed = this.sharedDataService.yearSelected + 1;
            this.sharedDataService.sentUpdateToCalendarScroll();
        } else {
            this.sharedDataService.yearDisplayed = this.sharedDataService.yearSelected;
            this.sharedDataService.sentUpdateToCalendarScroll();
        }
        if (this.scrollUp !== scrollOne.scrollTop) {
            this.sharedDataService.scrollVertical = scrollOne.scrollTop;
        }
    }

    public updateScrollToActualDate() {
        if (this.dateToPadding < 1) {
            return;
        }
        const scrollOne = this.scrollOne.nativeElement as HTMLElement;

        scrollOne.scrollLeft = this.dateToPadding * 40
    }
}
