import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	Validators,
	AbstractControl,
} from '@angular/forms';

import { FieldsService } from '@src/app/store/fields/fields.service';
import { FieldsUiService } from '../../services/fields-ui.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-create-field',
	templateUrl: './create-field.component.html',
	styleUrls: ['./create-field.component.scss'],
})
export class CreateFieldComponent {
	public form: FormGroup;
	public color = "#2DA951";
	@ViewChild('widgetsContent') widgetsContent: ElementRef;
	@ViewChild('inputTextFieldName') inputTextFieldName: ElementRef;

	constructor(
		private readonly formBuiilder: FormBuilder,
		private readonly fieldsService: FieldsService,
		private readonly fieldsUiService: FieldsUiService,
		private readonly spinner: NgxSpinnerService,
		private readonly toastService: GlobalToastService,
		public dialog: MatDialog,
	) {
		this.form = this.formBuiilder.group({
			name: ['', [Validators.required, Validators.maxLength(50)]],
		});

	}

	public async registerField() {
		if (this.form.invalid) {
			return;
		}
		this.spinner.show('Syncro');
		try {
			this.fieldsUiService.closeSidebar();
			if (!this.availableColors.includes(this.color)) {
				this.color = "#2DA951"
			}
			// TODO: Habilitar cuando se tengan los servicios
			await this.fieldsService.createOne(this.fname.value, this.color);
			this.spinner.hide('Syncro');
			this.toastService.createSuccessToast(
				'Exito',
				'El campo se creó correctamente',
			);
			this.inputTextFieldName.nativeElement.value = "";
			const dialogRef = this.dialog.open(CreateNewItemDialog);
			dialogRef.afterClosed().subscribe(async (result) => {});

		} catch (error) {

			this.spinner.hide('Syncro');
			this.toastService.createErrorToast(
				'Hubo un error',
				'No se pudo conectar con el servidor',
			);
		};

	}

	public get fname(): AbstractControl {
		return this.form.get('name');
	}

	public selectedColor(color: string) {
		if (this.availableColors.includes(color)) {
			this.color = color;
		} else {
			this.color = "#2DA951"
		}
	}

	public cancelRegistration() {
		this.inputTextFieldName.nativeElement.value = "";
		this.fieldsUiService.closeSidebar();
	}

	scrollLeft() {
		this.widgetsContent.nativeElement.scrollLeft -= 50;
	}

	scrollRight() {
		this.widgetsContent.nativeElement.scrollLeft += 50;
	}


	public availableColors = [
		"#C11717", "#E61822", "#FCBF17", "#F1E610", "#93C353", "#2DA951", "#1BAEE4", "#076FB8", "#202C56", "#66398E",
		"#000000", "#EEECE0", "#1A4A7B", "#4E7FC2", "#C1504A", "#9BBC54", "#8165A0", "#43ADC5", "#EE974A",
		"#F2F2F2", "#7F7F7F", "#DED9C3", "#C7D9F1", "#DCE4F1", "#F1DCDB", "#EAF1DF", "#E6E0EC", "#DBEEF4", "#FDEADB",
		"#707070", "#4D7ABD", "#F7EA20", "#EF8086", "#88AEDD"
	]
}




@Component({
    selector: 'create-new-item-dialog',
    templateUrl: 'create-new-item.html',
})
export class CreateNewItemDialog {

    constructor(
        public dialogRef: MatDialogRef<CreateNewItemDialog>,
    ) {}

    public onNoClick(): void {
        this.dialogRef.close();
    }


}
