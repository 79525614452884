import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface SatImage {
    cloud_cover: string
    date: string
    id: string
    lotId: string
    name: string
    ndviWms: string
    ndwiWms: string
    rgbWms: string
    saviWms: string
}

export interface SatImageSingleObject {
    status: String
    lotData: SatImage[]
    space: 0
    url: 'https://app.infoagro.pe/geoserver/cite/wms'
}

export interface SatImageObject {
    id: string,
    satImgDate: SatImageSingleObject
}

export interface SatImageState extends EntityState<SatImageObject, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sat-img-dates' })
export class SatImageStore extends EntityStore<SatImageState> {
    public constructor() {
        super();
    }
}
