import { Injectable } from '@angular/core';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ExcelExportService {

    constructor() { }
    
    public exportDataToExcel(data, fileName) {
        if (data !== undefined) {
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook: XLSX.WorkBook = {
                Sheets: { data: worksheet },
                SheetNames: ['data'],
            };
            const excelBuffer: any = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });
            this.saveAsExcelFile(excelBuffer, fileName);
        }
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: ExcelConst.EXCEL_TYPE });
        FileSaver.saveAs(
            data, fileName + ExcelConst.EXCEL_EXTENSION,
        );
    }
}
