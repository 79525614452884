import { Component, ViewChild, ElementRef } from '@angular/core';
import { PdfService } from '../my-fields/services/pdf-service/pdfService.service';
import { HtmlContentService } from '../my-fields/services/pdf-service/htmlService.service';
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-pdfgenerator',
  templateUrl: './pdfgenerator.component.html',
  styleUrls: ['./pdfgenerator.component.scss']
})
export class PdfGeneratorComponent {
    constructor(private pdfContentService: PdfService, private pdfService: PdfService) {}
    downloadPDF() {
        const content = this.pdfContentService.buildPdfContent();
        if (content) {
          html2pdf().from(content).save();
        } else {
          console.error('No se pudo construir el contenido HTML del PDF.');
        }
      }
}