import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators,
	AbstractControl
} from '@angular/forms';
import { FieldsService } from '@store/fields/fields.service';
import { MyFieldsUiService } from '@modules/my-fields/store/my-fields-ui/my-fields-ui.service';

@Component({
	selector: 'app-register-field',
	templateUrl: './register-field.component.html',
	styleUrls: ['./register-field.component.scss']
})
export class RegisterFieldComponent {
	public form: FormGroup;

	constructor(
		private readonly formBuiilder: FormBuilder,
		private readonly fieldsService: FieldsService,
		private readonly myFieldsUiService: MyFieldsUiService
	) {
		this.form = this.formBuiilder.group({
			name: ['', [Validators.required, Validators.maxLength(50)]]
		});
	}

	public async registerField() {
		if (this.form.invalid) {
			return;
		}

		// TODO: Modificar el paremetro de color, està hardcodeado
		await this.fieldsService.createOne(this.fname.value, "RED");
	}

	public get fname(): AbstractControl {
		return this.form.get('name');
	}
}
