import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

import { baseUrl, environment } from '@src/environments/environment';

// Store
import { AuthStore } from './auth.store';

// Queries
import { AuthQuery } from './auth.query';

// Interfaces
import { IAuthService } from '@shared/interfaces/services/auth-service.interface';

import { resetStores } from '@datorama/akita';

import { akitaConfig } from '@datorama/akita';
import { CookieService } from 'ngx-cookie-service';
akitaConfig({
  resettable: true,
});

@Injectable({ providedIn: 'root' })
export class AuthHttpService implements IAuthService {
  public constructor(
    private readonly authStore: AuthStore,
    private readonly authQuery: AuthQuery,
    private readonly http: HttpClient,
    private cookieService: CookieService,
  ) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.authStore.update(currentUser);
  }

  public async login(username: string, password: string, ip = ''): Promise<any> {
    const data = await this.http
      .post<any>(`${baseUrl.base}login-user/api/auth/login`, {
        username,
        password,
        ip,
      })
      .pipe(
        map((user) => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.authStore.update(user);
          return user;
        }),
      )
      .toPromise();
    return data;
  }

  public getIp() {
    return this.http.get('https://api.ipify.org?format=json');
  }

  public isLoggedIn(): boolean {
    const user = this.authQuery.getValue();
    return user.token !== null;
  }

  public resendEmailConfirmation(email: string) {
    return this.http.get<any>(`${environment.apiUrl}/auth/resendEmail/confirmation/${email}`).toPromise();
  }

  public validateUser(token: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/auth/validate/token/` + token).toPromise();
  }

  public async updateEmail(data): Promise<any> {
    return await this.http.put<any>(`${environment.apiUrl}/users/updateEmail/`, data).toPromise();
  }

  public async activate(): Promise<any> {
    return await this.http.get<any>(`${environment.apiUrl}/users/activate`).toPromise();
  }

  public async isValidate(): Promise<any> {
    return await this.http.get<any>(`${environment.apiUrl}/users/isValidate`).toPromise();
  }

  // Integrar solución al front
  // private setSession(authResult: User | any) {
  private setSession(authResult: any) {
    const expiresAt = moment().add(authResult.expiresIn, 'days');
    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  // Se utilizará en el futuro
  public getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);

    return moment(expiresAt);
  }

  public logout(): void {
    this.http.post<any>(`${environment.apiUrl}/users/logout`, null).toPromise();
    const value = this.cookieService.delete('MoodleSession', '/moodle/');
    resetStores();
    localStorage.removeItem('currentUser');
  }

  public restorePassword(email: string): any {
    return this.http.put<any>(`${environment.apiUrl}/restorePassword/` + email, null).toPromise();
  }

  public get currentUser() {
    return this.authQuery.getValue();
  }
}
