export const laborFilters = [
    { value: 'ng', name: 'Buscar por fila' },
    { value: '1', name: 'Nombre y Apellido'},
    { value: '2', name: 'DNI'},
    { value: '3', name: 'Labor'},
    { value: '4', name: 'Inicio'},
    { value: '5', name: 'Fin'},
    { value: '6', name: 'Unidad'},
    { value: '7', name: 'Horas'},
    { value: '8', name: 'Costo'},
    { value: '9', name: 'Moneda'},
];

export const laborColumns = [
    { name: 'Nombre y Apellido', colum: 'name' },
    { name: 'DNI', colum: 'dni' },
    { name: 'Abreviatura', colum: 'abbreviation' },
    { name: 'Función / Rol', colum: 'labour' },
    { name: 'Inicio', colum: 'start_date' },
    { name: 'Fin', colum: 'end_date' },
    { name: 'Jornal por Unidad', colum: 'unit' },
    { name: 'Horas', colum: 'hours' },
    { name: 'Costo por Unidad', colum: 'cost' },
    { name: 'Moneda', colum: 'coin' },
    { name: 'Costo x Hr', colum: 'costPerHour' },
    { name: 'H.H. Total', colum: 'totalhour' },
    { name: 'Costo Total (U)', colum: 'totalcost' },
    { name: 'Observaciones', colum: 'observation' }
];

export const machineFilters = [
    { value: 'ng', name: 'Buscar por fila'},
    { value: '1', name: 'Tipo'},
    { value: '2', name: 'Modelo'},
    { value: '3', name: 'Fecha'},
    { value: '4', name: 'Tipo de poseción'},
    { value: '5', name: 'Cantidad'},
    { value: '6', name: 'Unidad'},
    { value: '7', name: 'Moneda'},
    { value: '8', name: 'Costo x HM'}
];

export const machineColumns = [
    { name: 'Tipo de Maquinaria', colum:'name'},
    { name: 'Modelo', colum:'model'},
    { name: 'Abreviatura', colum:'abbreviation'},
    { name: 'Fecha', colum:'date'},
    { name: 'Tipo de Posesión', colum:'type_possession'},
    { name: 'Cantidad', colum:'quantity'},
    { name: 'Unidad', colum:'unit'},
    { name: 'Moneda', colum:'coin'},
    { name: 'Costo x HM', colum:'cost'},
    { name: 'H.M. Total', colum:'totalhour'},
    { name: 'Costo Total (U)', colum:'totalcost'}
];

export const supplyFilters = [
    { value: 'ng', name: 'Buscar por fila'},
    { value: '1', name: 'Clase'},
    { value: '2', name: 'Tipo'},
    { value: '3', name: 'Fecha'},
    { value: '4', name: 'Unidad'},
    { value: '5', name: 'Cantidad'},
    { value: '6', name: 'Costo'},
    { value: '7', name: 'Moneda'}
];

export const supplyColumns = [
    { name: 'Clase de Ins.', colum:'name'},
    { name: 'Tipo de Ins.', colum:'kind'},
    { name: 'Abreviatura', colum:'abbreviation'},
    { name: 'Fecha de compra', colum:'date'},
    { name: 'Unidad', colum:'unit'},
    { name: 'Cantidad', colum:'quantity'},
    { name: 'Costo total', colum:'cost'},
    { name: 'Moneda', colum:'coin'},
    { name: 'Costo x UND', colum:'costPerUnit'},
    { name: 'Cantidad (U)', colum:'usedAmount'},
    { name: 'Costo Total (U)', colum:'totalCost'},
    { name: 'Observaciones', colum:'observation'}
];

export const waterFilters = [
    { value: 'ng', name: 'Buscar por fila'},
    { value: '1', name: 'Asignado'},
    { value: '2', name: 'Unidad'},
    { value: '3', name: 'Cantidad'},
    { value: '4', name: 'Moneda'},
    { value: '8', name: 'Costo x UND'},
];

export const waterColumns = [
    { name: 'Asignado', colum:'date'},
    { name: 'Unidad', colum:'unit'},
    { name: 'Abreviatura', colum:'abbreviation'},
    { name: 'Cantidad', colum:'quantity'},
    { name: 'Moneda', colum:'coin'},
    { name: 'Costo x UND', colum:'cost'},
    { name: 'Costo Total', colum:'totalCost'},
    { name: 'Cantidad (U)', colum:'usedAmount'},
    { name: 'Costo Total (U)', colum:'totalCostUsed'},
    { name: 'Observaciones', colum:'observation'}
];

export const servAndOthersFilters = [
    { value: 'ng', name: 'Buscar por fila', colum: '' },
    { value: '1', name: 'Asignado', colum: 'name' },
    { value: '2', name: 'Tipo', colum: 'kind' },
    { value: '3', name: 'Unidad', colum: 'unit' },
    { value: '4', name: 'Cantidad', colum: 'quantity' },
    { value: '5', name: 'Costo', colum: 'cost' },
    { value: '6', name: 'Moneda', colum: 'coin' },
];

export const servAndOthersColumns = [
    { name: 'Asignado', colum:'name'},
    { name: 'Abreviatura', colum:'abbreviation'},
    { name: 'Tipo', colum:'kind'},
    { name: 'Unidad', colum:'unit'},
    { name: 'Cantidad', colum:'quantity'},
    { name: 'Costo', colum:'cost'},
    { name: 'Moneda', colum:'coin'},
    { name: 'Total', colum:'totalCost'},
    { name: 'Cantidad (U)', colum:'usedAmount'},
    { name: 'Costo Total (U)', colum:'totalCostUsed'},
    { name: 'Observaciones', colum:'observation'}
];