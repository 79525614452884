import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FieldsQuery } from '@store/fields/fields.query';
import { LotsService } from '@store/lots/lots.service';
import { MyFieldsUiService } from '@modules/my-fields/store/my-fields-ui/my-fields-ui.service';
import { MyFieldsModals } from '@modules/my-fields/enums/my-fields.enum';

@Component({
	selector: 'app-fields',
	templateUrl: './fields.component.html',
	styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {
	public isEmpty: boolean;
	public fields: any[];

	constructor(
		private readonly fieldsQuery: FieldsQuery,
		private readonly lotsService: LotsService,
		private readonly myFieldsUiService: MyFieldsUiService
	) {
		this.fields = [];

		this.fieldsQuery.selectFieldsWithLots().subscribe(data => {
			if (!data || data.length === 0) {
				this.isEmpty = true;
				this.fields = [];
				return;
			}
			
			this.isEmpty = false;
			this.fields = data;
		});
	}

	ngOnInit(): void {}

	public showLotInfo(lotId: string) {
		this.lotsService.setActiveLot(lotId);
	}

	public createLot() {
	}
}
