import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
    selector: 'edit-camping',
    templateUrl: './edit-camping.component.html',
    styleUrls: ['./edit-camping.component.scss']
})
export class EditCampingComponent implements OnInit {
    constructor(
    ) {
        
        
    }

    ngOnInit(): void {
    }
}
