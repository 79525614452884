import { Component, OnInit } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { MultiDataSet, Label, Colors } from 'ng2-charts';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';

@Component({
    selector: 'app-donut-graph',
    templateUrl: './donut-graph.component.html',
    styleUrls: ['./donut-graph.component.scss']
})
export class DonutGraphComponent implements OnInit {


    constructor(
        private readonly mapSidebarComService: MapSidebarComService
    ) {
        this.mapSidebarComService.getClickToUpdatePhenologicalChart().subscribe(x => {
            var porcentage = this.mapSidebarComService.porcentagePhenological
            if (this.mapSidebarComService.porcentagePhenological > 100 || this.mapSidebarComService.porcentagePhenological < 0) {
                this.doughnutChartData = [
                    [0, 100]
                ];
            } else {
                this.doughnutChartData = [
                    [porcentage, 100 - porcentage]
                ];
            }
        })
    }

    ngOnInit(): void {
    }
    doughnutChartOptions: ChartOptions = {
        maintainAspectRatio: false,
        cutoutPercentage: 75,
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var index = tooltipItem.index;
                    var currentValue = parseInt(data.datasets[tooltipItem.datasetIndex].data[index].toString())
                    var total = 0;
                    data.datasets.forEach(function (el) {
                        total = total + parseInt(el.data[index].toString());
                    });
                    return currentValue + '%';
                }
            }
        }
    }
    doughnutChartLabels: Label[] = ['', ''];
    doughnutChartData: MultiDataSet = [
        [0, 100]
    ];
    donutColors = [

        {
            backgroundColor: [
                'rgba(0, 160, 56, 1)',
                'rgba(216, 216, 216, 1)',
            ]
        }
    ];
    doughnutChartType: ChartType = 'doughnut';
    lineChartLegend = false;
}
