import { Component, OnInit } from '@angular/core';
import { UserQuery } from '@src/app/store/user/user.query';
import { GeneralUserInfo, SharedLotsService } from '../../services/shared-lots/shared-lots.service';

@Component({
  selector: 'app-submission-requests',
  templateUrl: './submission-requests.component.html',
  styleUrls: ['./submission-requests.component.scss']
})
export class SubmissionRequestsComponent implements OnInit {

  public typingTimer;                //timer identifier
  public doneTypingInterval = 500;
  public email: string = "";
  public myName: string = "";
  public results: GeneralUserInfo[] = [];
  public selectedUsers: GeneralUserInfo[] = [];
  constructor(private readonly sharedLots: SharedLotsService,
    private readonly userQuery: UserQuery) { }

  ngOnInit(): void {
    let user = this.userQuery.getValue();
    this.myName = user.email;
  }

  public onKeydown(event) {
    clearTimeout(this.typingTimer);
  }

  public keyUp(event) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.doneTyping(), this.doneTypingInterval);
  }

  public async doneTyping() {
    if (this.email == "")
      this.results = [];
    else
      this.results = await this.sharedLots.searchUsers(this.email);
  }

  public selectUser(user: GeneralUserInfo) {
    if (this.selectedUsers.findIndex((data) => data.id == user.id) != -1)
      return;
    this.selectedUsers.push(user);
    this.results = [];
    this.email = "";
  }

  public removeSelectedUser(index: number) {
    this.selectedUsers.splice(index, 1);
  }

  public sendRequests() {
    this.selectedUsers.forEach(async user => {
      await this.sharedLots.sendRequests(user.id, this.myName, user.email);
    });
    this.results = [];
    this.email = "";
    this.selectedUsers = [];
  }
}
