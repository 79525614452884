import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as ApexCharts from 'apexcharts';
import * as html2pdf from 'html2pdf.js';
@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private pdfContent: HTMLElement;
  constructor() { }
    
  setPdfContent(content: HTMLElement) {
    this.pdfContent = content;
  }

  getPdfContent(): HTMLElement {
    return this.pdfContent;
  }

  buildPdfContent(): HTMLElement {
    const content = document.createElement('div');
    content.innerHTML = `
      <div style="background-color: black; color: white">
        <div>
            REPORTE METEOROLÓGICO
        </div>
      </div>
      
    `;
    return content;
  }
  generatePDFFromHTML(htmlContent: string, fileName: string) {
    // Crear una instancia de jsPDF
    let pdf = new jsPDF();
    
    // Agregar el contenido HTML al PDF
    pdf.text(htmlContent,10,10);
    
    // Guardar el PDF con el nombre especificado
    pdf.save(fileName + '.pdf');
  }

  async generatePDFFromContent(htmlContent: HTMLElement[], charts: HTMLElement[], fileName: string) {
    // Crear una instancia de jsPDF
    const pdf = new jsPDF();
    let yPos = 10;

    // Agregar contenido HTML al PDF
    for (const element of htmlContent) {
      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL('image/jpeg');
      pdf.addImage(imageData, 'JPEG', 10, yPos, canvas.width * 0.1, canvas.height * 0.1);
      yPos += canvas.height * 0.1 + 10; // Ajusta la posición para el siguiente elemento
    }

    // Agregar gráficos al PDF
    for (const chart of charts) {
      const canvas = await html2canvas(chart);
      const imageData = canvas.toDataURL('image/jpeg');
      pdf.addImage(imageData, 'JPEG', 10, yPos, canvas.width * 0.1, canvas.height * 0.1);
      yPos += canvas.height * 0.1 + 10; // Ajusta la posición para el siguiente gráfico
    }

    // Guardar el PDF con el nombre especificado
    pdf.save(fileName + '.pdf');
  }

  generateChart(chartData: any[], chartOptions: ApexCharts.ApexOptions): HTMLElement {
    const chartDiv = document.createElement('div');
    const chart = new ApexCharts(chartDiv, chartOptions);
    chart.render();
    return chartDiv;
  }

  async generatePDF(fileName: string) {
    const content = this.buildPdfContent();
    if (content) {
      setTimeout(() => {
        html2canvas(content).then(canvas => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          pdf.save(fileName + '.pdf');
        });
      }, 500); // Ajusta el tiempo de espera según sea necesario
    } else {
      console.error('No se pudo construir el contenido HTML del PDF.');
    }
  }
}
