import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { environment } from '@src/environments/environment';

import { ProductStore, Product } from './product-storage.store';
import { ProductStorageQuery } from './product-storage.query';
import { response } from '../activities-storage/activities-storage.service';
import { element } from 'protractor';

@Injectable({ providedIn: 'root' })
export class ProductService {
	constructor(
		private productStore: ProductStore,
		private productStorageQuery: ProductStorageQuery,
		private http: HttpClient,
	) {}

	public async findAll(): Promise<Product[]> {
        const hasCache = this.productStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }
        try {
            const data = await this.http
                .get<response>(`${environment.apiUrl}/product/`)
                .toPromise<response>();
            const products = data.message.map((currentActivity: Product) => {
                return currentActivity;
            });
            this.productStore.set(products);
            return products;
        }
        catch (error) {
        }
	}
	
	public async createMany(arrayOfProductObjects) {
        try {
			arrayOfProductObjects.forEach((element) => {
				element.cost = parseInt(element.cost);
				element.quantity = parseFloat(element.quantity);
				delete element.id;
				delete element.coin;
				delete element.dni;
				delete element.image;
				delete element.cost;
				delete element.hours;
                delete element.abbreviation;
                if (element.start_date === "")
                    element.start_date = null
                if (element.end_date === "")
                    element.end_date = null
                if (element.lost === "")
                    delete element.lost
			});
			let products : Product[] = arrayOfProductObjects
            const data = await this.http
                .post<response>(`${environment.apiUrl}/product/`, products).toPromise<response>();

            for (let i = 0; i < products.length; ++i) {
                products[i].id = data.message[data.message.length - 1 - i].id
                this.productStore.add({
                    ...products[i]
                });
            }
        } catch (error) {
        }
	}
	
	public async updateMany(arrayOfProductObjects) {
        try {
			arrayOfProductObjects.forEach((element) => {
				element.cost = parseInt(element.cost);
				element.quantity = parseFloat(element.quantity);
				delete element.coin;
				delete element.image;
				delete element.cost;
				delete element.dni;
				delete element.hours;
                delete element.abbreviation;
                if (element.start_date === "")
                    element.start_date = null
                if (element.end_date === "")
                    element.end_date = null
                if (element.lost === "")
                    delete element.lost
			});

			let products : Product[] = arrayOfProductObjects;
            const data = await this.http.put<response>(`${environment.apiUrl}/product/`, products
            ).toPromise<response>();
            for (let i = 0; i < products.length; ++i) {
                this.productStore.update(({ id }) => id == products[i].id, {
                    ...products[i]
                });
            }
        } catch {
        }
    }


	public async updateImage(form_data, object_id) {
		try {
			const product = await this.http
				.post(
					`${environment.apiUrl}/uploadImage/${object_id}`,
					form_data,
				)
				.toPromise<any>();
			product.message.image = product.message.image.substr(17);
			this.productStore.update(object_id, product.message);
		} catch (error) {}
	}

    public async deleteImage(data) {
		try {
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body: data
            };
            
			const product = await this.http
				.delete(
					`${environment.apiUrl}/product/remove-image`, options
				)
				.toPromise<any>();
			product.message.image = product.message.image.substr(17);
			this.productStore.update(product.message.image.id, product.message);
            /*
            const product = await this.http
                .delete(`http://176.31.224.4:9003/api/product/remove_image/?user_id=0e9563ac-d257-47e7-a7ad-88b26c8640ae`, options
                )
                .toPromise<any>();
            product.image = product.image.substr(17);
            this.productStore.update(product.image.id, product);
            */
    } catch (error) {}
	}

	public async deleteMany(arrayOfProductObjects) {
        try {

			let products = arrayOfProductObjects
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body: products
            };
            const data = await this.http.delete<response>(`${environment.apiUrl}/product/`, options
            ).toPromise<response>();
            this.productStore.remove(products)
        } catch {
        }
    }
}
