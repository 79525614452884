import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatIconModule } from '@angular/material/icon'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// Prime ng
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

// Sidebar
import { SidebarModule } from 'ng-sidebar';

// Routing
import { MyFieldsRoutingModule } from './my-fields-routing.module';

// Pages
import { MainPageComponent, ResourcesPageComponent } from './pages';

// Components
import {
	NavBarComponent,
	FieldsComponent,
	FieldSearchBarComponent,
	RegisterFieldComponent,
	RegisterLotComponent,
	LotDetailComponent,
} from './components';
import { ResourcesNavbarComponent } from './components/resources';

// Modals

// Services
import { MyFieldsModalsService } from './services';
import { ResourceTablesComponent } from './components/resources/resource-tables/resource-tables.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { SharedLotsService } from './services/shared-lots/shared-lots.service';
import { NgApexchartsModule } from 'ng-apexcharts';
@NgModule({
	imports: [
		CommonModule,
		MyFieldsRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		LeafletModule,
		LeafletDrawModule,
		// CDK
		OverlayModule,
		// Sidebar
		SidebarModule,
		// PrimeNg
		InputTextModule,
		DropdownModule,
		TableModule,
		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatProgressSpinnerModule,
		NgxSpinnerModule,
		MatRippleModule,
		MatInputModule,
		NgApexchartsModule,
		
	],
	declarations: [
		// Pages
		MainPageComponent,
		// Components
		NavBarComponent,
		FieldsComponent,
		FieldSearchBarComponent,
		RegisterFieldComponent,
		RegisterLotComponent,
		LotDetailComponent,
		ResourcesPageComponent,
		ResourcesNavbarComponent,
		ResourceTablesComponent,
		
		
	],
	providers: [MyFieldsModalsService, SharedLotsService],
	entryComponents: [RegisterFieldComponent, RegisterLotComponent],
})
export class MyFieldsModule { }
