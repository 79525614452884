import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { MapSidebarComService } from '@src/app/modules/my-fields/modules/fields/services/map-sidebar-com.service';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { AgrometNotifyQuery } from '@src/app/store/agromet-notify/agromet-notify.query';
import { AgrometNotifyService } from '@src/app/store/agromet-notify/agromet-notify.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { AgrometHistoricalService } from '@src/app/store/historic-agromet-notifications/historical-agromet-notify.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { UserQuery } from '@src/app/store/user/user.query';
import { DialogConfirmationNotification } from '../confirmation-dialog/noti-confirm-dialog';
import { DialogInformationNotification } from '../information-dialog/noti-information-dialog';


@Component({
    selector: 'app-agromet-notifications',
    templateUrl: './agromet-notifications.component.html',
    styleUrls: ['./agromet-notifications.component.scss']
})
export class AgrometNotificationsComponent implements OnInit {

    public checkedPushNotification = false;
    public enableEdition = false;
    public allLotsFromServer = [];
    public lot_id_selected = undefined;
    public notificationDetail = undefined;
    public historicalData = [];


    //values
    min_temp: any= { value: -21 };
    max_temp: any = { value: 51 };
    min_wind: any = { value: 1 };
    max_wind: any = { value: 21 };
    min_prec: any = { value: 1 };
    max_prec: any = { value: 121 };

    constructor(
        public dialogRef: MatDialogRef<AgrometNotificationsComponent>,
        public lotsQuery: LotsQuery,
        public agrometNotifyService: AgrometNotifyService,
        public agrometNotifyQuery: AgrometNotifyQuery,
        public toastService: GlobalToastService,
        public dialog: MatDialog,
        private readonly userQuery: UserQuery,
        private agrometHistoricalService: AgrometHistoricalService,
        private fieldsQuery: FieldsQuery,
        private mapSidebarComService: MapSidebarComService
    ) {
        this.lotsQuery.selectAll().subscribe(allLots => {
            this.allLotsFromServer = allLots;
        });

    }

    public tempRange = [
        { value: "ninguno" }, { value: 51 }, { value: 50 }, { value: 45 }, { value: 40 },
        { value: 35 }, { value: 30 }, { value: 25 }, { value: 20 },
        { value: 15 }, { value: 10 }, { value: 5 }, { value: "0" },
        { value: -5 }, { value: -10 }, { value: -15 }, { value: -20 },
        { value: -21 }, 
    ];

    public windRange = [
        { value: "ninguno" }, { value: 21 }, { value: 20 }, { value: 18 }, { value: 16 }, { value: 14 },
        { value: 12 }, { value: 10 }, { value: 8 }, { value: 6 }, { value: 4 },
        { value: 2 }, { value: 1 }];

    public precipitationRange = [

        { value: "ninguno" }, { value: 121 }, { value: 120 }, { value: 110 }, { value: 100 }, { value: 90 },
        { value: 80 }, { value: 70 }, { value: 60 }, { value: 50 }, { value: 40 },
        { value: 30 }, { value: 25 }, { value: 20 }, { value: 15 }, { value: 10 },
        { value: 5 }, { value: 4 }, { value: 3 }, { value: 2 }, { value: 1 }];

    async ngOnInit() {
        this.agrometHistoricalService.findAll().then((all) => {
            this.historicalData = JSON.parse(JSON.stringify(all));
            this.historicalData.forEach((element) => {
                const dateFromElem = new Date(element.date);
                let monthNumber = dateFromElem.getMonth() + 1;
                if (monthNumber < 10) element.date = dateFromElem.getFullYear() + "-" + "0" + monthNumber + "-" + dateFromElem.getDate();
                else element.date = dateFromElem.getFullYear() + "-" + monthNumber + "-" + dateFromElem.getDate();
                let minutes = dateFromElem.getMinutes();
                if(minutes < 10) element.hour = dateFromElem.getHours() + ":0" + dateFromElem.getMinutes();
                else element.hour = dateFromElem.getHours() + ":" + dateFromElem.getMinutes();
                const lotData = this.lotsQuery.getEntity(element.lot);
                element.lot_name = lotData.name;
                const fieldData = this.fieldsQuery.getEntity(lotData.fieldId);
                element.field_name = fieldData.name;
            });
        });

        this.lot_id_selected = this.allLotsFromServer[0].id
        await this.agrometNotifyService.findAll();
        await this.setInitialLot(this.lot_id_selected) 
        
    }

    public async checkNotification(element: any){
        if(element.readed) return;
        await this.agrometHistoricalService.update(element.id)
        element.readed = true;
        this.mapSidebarComService.sendClickToCountNotifications('agromet');
    }

    public changeLotSelected(data) {
        this.resetValues();
        this.lot_id_selected = data.value.id;
        try {
            this.enableEdition = false;
            const agrometnotify = this.agrometNotifyQuery.getEntity(this.lot_id_selected);

            if (agrometnotify != undefined) {
                this.notificationDetail = agrometnotify;
                this.min_temp = { value: parseInt(agrometnotify.min_temperature) };
                this.max_temp = { value: parseInt(agrometnotify.max_temperature) };
                this.min_wind = { value: parseInt(agrometnotify.min_wind) };
                this.max_wind = { value: parseInt(agrometnotify.max_wind) };
                this.min_prec = { value: parseInt(agrometnotify.min_precipitation) };
                this.max_prec = { value: parseInt(agrometnotify.max_precipitation) };
                this.checkedPushNotification = agrometnotify.by_push_notification
            } else {
                this.notificationDetail = undefined;
                this.checkedPushNotification = false;
            }

        } catch (error) {

        }
    }

    public async setInitialLot(lot_id) {
        this.resetValues();
        this.lot_id_selected = lot_id;
        try {
            this.enableEdition = false;
            const agrometnotify = this.agrometNotifyQuery.getEntity(this.lot_id_selected);

            if (agrometnotify != undefined) {
                this.notificationDetail = agrometnotify;
                this.min_temp = { value: parseInt(agrometnotify.min_temperature) };
                this.max_temp = { value: parseInt(agrometnotify.max_temperature) };
                this.min_wind = { value: parseInt(agrometnotify.min_wind) };
                this.max_wind = { value: parseInt(agrometnotify.max_wind) };
                this.min_prec = { value: parseInt(agrometnotify.min_precipitation) };
                this.max_prec = { value: parseInt(agrometnotify.max_precipitation) };
                this.checkedPushNotification = agrometnotify.by_push_notification

            } else {
                this.notificationDetail = undefined;
                this.checkedPushNotification = false;
            }

        } catch (error) {

        }
    }

    public changeTemp($event){
        if ($event.value.value == "ninguno"){
            this.max_temp = this.tempRange[0];
            this.min_temp = this.tempRange[0];
        }
    }
    public changePrec($event){
        if ($event.value.value == "ninguno"){
            this.max_prec= this.precipitationRange[0]
            this.min_prec= this.precipitationRange[0]
        }
    }
    public changeWind($event){
        if ($event.value.value == "ninguno"){
            this.max_wind= this.windRange[0]
            this.min_wind= this.windRange[0]
        }
    }

    public goToConfig(){
        const dialogRef = this.dialog.open(DialogInformationNotification, {
            panelClass: "modalClass",
            width: "470px",
            height: "120px",
            data: {
                text: "Los Parametros se han guardado ahora puede ir hacia Configuraciones"
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
        });
    }

    public async save() {
        const selectedNotify = this.agrometNotifyQuery.getEntity(this.lot_id_selected);
        const selectedLot = this.lotsQuery.getEntity(this.lot_id_selected)
        if (selectedLot == undefined)
            return
        if (selectedNotify == undefined) {
            //case when theres no notification
            this.validate_values();
            var detail = {
                "lot": this.lot_id_selected,
                "lot_name": selectedLot.name,
                "lat": selectedLot.center[0][1] + "",
                "lon": selectedLot.center[0][0] + "",
                "min_temperature": this.min_temp.value,
                "max_temperature": this.max_temp.value,
                "min_wind": this.min_wind.value,
                "max_wind": this.max_wind.value,
                "min_precipitation": this.min_prec.value,
                "max_precipitation": this.max_prec.value,
                "by_email": false,
                "by_push_notification": false,
                "by_sms": false
            }
            try {
                await this.agrometNotifyService.postAgrometNotification(detail).then((rpt) => {
                    const agrometnotify = this.agrometNotifyQuery.getEntity(this.lot_id_selected);
                    this.notificationDetail = agrometnotify;
                    this.min_temp = { value: parseInt(rpt.min_temperature) };
                    this.max_temp = { value: parseInt(rpt.max_temperature) };
                    this.min_wind = { value: parseInt(rpt.min_wind) };
                    this.max_wind = { value: parseInt(rpt.max_wind) };
                    this.min_prec = { value: parseInt(rpt.min_precipitation) };
                    this.max_prec = { value: parseInt(rpt.max_precipitation) };
                })
                this.toastService.createSuccessToast("Completado", "Se han guardado los parámetros")
            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        } else {
            //case to update one already created
            this.validate_values();
            var detailUpdate = {
                "id": selectedNotify.notification_id,
                "lot": this.lot_id_selected,
                "lot_name": selectedLot.name,
                "lat": selectedLot.center[0][1] + "",
                "lon": selectedLot.center[0][0] + "",
                "min_temperature": this.min_temp.value,
                "max_temperature": this.max_temp.value,
                "min_wind": this.min_wind.value,
                "max_wind": this.max_wind.value,
                "min_precipitation": this.min_prec.value,
                "max_precipitation": this.max_prec.value,
                "by_email": false,
                "by_push_notification": selectedNotify.by_push_notification,
                "by_sms": false
            }
            try {
                await this.agrometNotifyService.putAgrometNotification(detailUpdate).then((rpt) => {
                    const agrometnotify = this.agrometNotifyQuery.getEntity(this.lot_id_selected);
                    this.notificationDetail = agrometnotify;
                    this.min_temp = { value: parseInt(rpt.min_temperature) };
                    this.max_temp = { value: parseInt(rpt.max_temperature) };
                    this.min_wind = { value: parseInt(rpt.min_wind) };
                    this.max_wind = { value: parseInt(rpt.max_wind) };
                    this.min_prec = { value: parseInt(rpt.min_precipitation) };
                    this.max_prec = { value: parseInt(rpt.max_precipitation) };
                })
                this.toastService.createSuccessToast("Completado", "Se han actualizado los parámetros")
            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        }
        this.enableEdition = false;
        this.goToConfig();
    }

    public resetValues() {
        this.lot_id_selected = undefined;
        this.min_temp = { value: -21 };
        this.max_temp = { value: 51 };
        this.min_wind = { value: 1 };
        this.max_wind = { value: 21 };
        this.min_prec = { value: 1 };
        this.max_prec = { value: 121 };
    }

    public deleteNotification() {
        const dialogRef = this.dialog.open(DialogConfirmationNotification, {
            panelClass: "modalClass",
            width: "470px",
            height: "120px",
            data: {
                text: "¿Está seguro de eliminar los parámetros?"
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                try {
                    await this.agrometNotifyService.deleteTicket(this.notificationDetail.notification_id, this.lot_id_selected).then(() => {
                        this.notificationDetail = undefined;
                        this.min_temp = { value: -21 };
                        this.max_temp = { value: 51 };
                        this.min_wind = { value: 1 };
                        this.max_wind = { value: 21 };
                        this.min_prec = { value: 1 };
                        this.max_prec = { value: 121 };
                        this.enableEdition = false;
                        this.checkedPushNotification = false;
                        this.toastService.createSuccessToast("Completado", "Se han borrado los parámetros")
                    }).catch(() => {
                        this.toastService.createErrorToast("Error", "No se han podido borrar los parámetros")
                    })

                } catch (error) {

                }

            }
        });
    }

    public validate_values(notify = null) {
        if (notify == null) {
            if (this.min_temp.value == null || this.min_temp.value.toString() == "ninguno")
                this.min_temp.value = null
            if (this.max_temp.value == null || this.max_temp.value.toString() == "ninguno")
                this.max_temp.value = null
            if (this.min_prec.value == null || this.min_prec.value.toString() == "ninguno")
                this.min_prec.value = null
            if (this.max_prec.value == null || this.max_prec.value.toString() == "ninguno")
                this.max_prec.value = null
            if (this.min_wind.value == null || this.min_wind.value.toString() == "ninguno")
                this.min_wind.value = null
            if (this.max_wind.value == null || this.max_wind.value.toString() == "ninguno")
                this.max_wind.value = null

        }
        else {
            if (notify.min_temp.value == null || notify.min_temp.value.toString() == "ninguno")
                notify.min_temp.value = null
            if (notify.max_temp.value == null || notify.max_temp.value.toString() == "ninguno")
                notify.max_temp.value = null
            if (notify.min_prec.value == null || notify.min_prec.value.toString() == "ninguno")
                notify.min_prec.value = null
            if (notify.max_prec.value == null || notify.max_prec.value.toString() == "ninguno")
                notify.max_prec.value = null
            if (notify.min_wind.value == null || notify.min_wind.value.toString() == "ninguno")
                notify.min_wind.value = null
            if (notify.max_wind.value == null || notify.max_wind.value.toString() == "ninguno")
                notify.max_wind.value = null

        }
    }

    public close() {
        this.dialogRef.close()
    }

    public async saveConfig() {
        const selectedNotify = this.agrometNotifyQuery.getEntity(this.lot_id_selected);
        const selectedLot = this.lotsQuery.getEntity(this.lot_id_selected);
        if (selectedLot == undefined)
            return
        if (selectedNotify == undefined) {
            //case when theres no notification
            var detail = {
                "lot": this.lot_id_selected,
                "lot_name": selectedLot.name,
                "lat": selectedLot.center[0][1] + "",
                "lon": selectedLot.center[0][0] + "",
                "min_temperature": -21,
                "max_temperature": 51,
                "min_wind": 1,
                "max_wind": 21,
                "min_precipitation": 1,
                "max_precipitation": 121,
                "by_email": false,
                "by_push_notification": this.checkedPushNotification,
                "by_sms": false
            }
            try {
                await this.agrometNotifyService.postAgrometNotification(detail).then((rpt) => {

                    this.toastService.createSuccessToast("Completado", "Se han guardado los parámetros")
                })

            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        } else {
            //case to update one already created
            var detailUpdate = {
                "id": selectedNotify.notification_id,
                "lot": this.lot_id_selected,
                "lot_name": selectedLot.name,
                "lat": selectedLot.center[0][1] + "",
                "lon": selectedLot.center[0][0] + "",
                "min_temperature": selectedNotify.min_temperature,
                "max_temperature": selectedNotify.max_temperature,
                "min_wind": selectedNotify.min_wind,
                "max_wind": selectedNotify.max_wind,
                "min_precipitation": selectedNotify.min_precipitation,
                "max_precipitation": selectedNotify.max_precipitation,
                "by_email": false,
                "by_push_notification": this.checkedPushNotification,
                "by_sms": false
            }
            try {
                await this.agrometNotifyService.putAgrometNotification(detailUpdate).then((rpt) => {
                    this.toastService.createSuccessToast("Completado", "Se han actualizado los parámetros")
                })

            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        }
        this.enableEdition = false;

    }

    public lotSelectedLabel = undefined;

    

    public validatePlan(e) {
        const currentUser = this.userQuery.getInformation();

        if (e.checked && !currentUser.permission.pushNotification) {
            const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
                width: '400px'
            });
            saveRef.afterClosed().subscribe(
                data => {
                    this.checkedPushNotification = false;
                })
        }
    }

}