import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MoneyStore, Money } from './money-storage.store';
import { MoneyQuery } from './money-storage.query';
import { environment } from '@src/environments/environment';
export interface Message {
    StatusCode: number,
    Error: string,
    message: Money[]
}
@Injectable({ providedIn: 'root' })
export class MoneyService {

    constructor(
        private moneyStore: MoneyStore,
        private moneyQuery: MoneyQuery,
        private http: HttpClient
    ) {
    }
    
    public async findAll(): Promise<Money[]> {
        const hasCache = this.moneyQuery.getHasCache();
        if (hasCache) {
            return;
        }

        try {
            const data = await this.http
                .get<Message>(`${environment.apiUrl}/coin/`)
                .toPromise<Message>();
            const dataArray = data.message
            const moneys  = dataArray.map((currentLabor: any) => {
                const money = currentLabor;
                money.id = String(money.id);
                return money;
            });
            this.moneyStore.set(moneys);
            return moneys ;
        }
        catch (error) {
        }
    }
}   
