import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export interface DialogData {
	name: string;
	dni: string;
	role: string;
	startDate: Date;
	endDate: Date;
	hours: string;
	cost: string;
	startRow: number;
	endRow: number;
	sheet: Object;
  }



@Component({
	selector: 'app-excel-format',
	templateUrl: './excel-format.component.html',
	styleUrls: ['./excel-format.component.scss'],
  })
  export class DialogExcelFormat {

	public title = "XlsRead";
	public file:File;
	public arrayBuffer:any;
	public filelist:any;
  
	constructor(
	  public dialogRef: MatDialogRef<DialogExcelFormat>,
	  @Inject(MAT_DIALOG_DATA) public data: DialogData) {
	}
  
    close() {
	    this.dialogRef.close({
	    })
  	}
	onNoClick(): void {
	  this.dialogRef.close();
	}
	sendData() {
	  this.dialogRef.close(this.data);
	}

	public addfile(event)     
	{   
		this.file= event.target.files[0];     
		let fileReader = new FileReader();        
		fileReader.onload = (e) => {    
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {type:"binary"});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
			this.data.sheet = worksheet;
		}    
		 fileReader.readAsArrayBuffer(this.file);
	}  
  
  }