import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Machine {
    id?: string;
    name?: string;
    model?: string;
    abbreviation?: string;
    date?: string;
    type_possession: string;
    quantity?: string;
    unit?: string;
    coin?: string;
    cost?: string;
}

export interface MachineState extends EntityState<Machine, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'machine-storage' })
export class MachineStore extends EntityStore<MachineState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "1",
                name: "Tractpr",
                model: "100xFDDD",
                abbreviation: "Tra",
                date: "2020-01-01",
                type_possession: "propio",
                quantity: "1",
                unit: "unidad",
                cost: "200.00",
                coin: "1"
            }
        )
    }
}

