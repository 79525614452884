import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { typesResources } from '../../../constants/typesResources';
import { ActivitiesStorageQuery } from '../../../storage/activities-storage/activities-storage.query';
import { LaborStorageQuery } from '../../../storage/labor-storage/labor-storage.query';
import { MachineStorageQuery } from '../../../storage/machine-storage/machine-storage.query';
import { ServiceStorageQuery } from '../../../storage/serv-other-storage/serv-other-storage.query';
import { SupplyStorageQuery } from '../../../storage/supplies-storage/supplies-storage.query';
import { WaterStorageQuery } from '../../../storage/water-storage/water-storage.query';

@Component({
	selector: 'app-activity-detail-info',
	templateUrl: './activity-detail-info.component.html',
	styleUrls: ['./activity-detail-info.component.scss'],
})
export class ActivityDetailInfoComponent implements OnInit, OnDestroy {
	public activeFullActivity = undefined;
	public options = typesResources;
	public resourcesActitvity = [];
	public totalCost = '';
	displayedColumns: string[] = ['resource', 'utilization', 'unit', 'cost'];
	dataSource = new MatTableDataSource([]);

	constructor(
		public dialogRef: MatDialogRef<ActivityDetailInfoComponent>,
		public activityQuery: ActivitiesStorageQuery,
		private readonly laborQuery: LaborStorageQuery,
		private machineQuery: MachineStorageQuery,
		private supplyQuery: SupplyStorageQuery,
		private waterQuery: WaterStorageQuery,
		private servAndOthersQuery: ServiceStorageQuery,
	) {}

	ngOnDestroy(): void {
		this.activeFullActivity = undefined;
	}

	ngOnInit(): void {
		this.activeFullActivity = this.activityQuery.getEntity(
			this.activityQuery.getActiveId(),
		);
		this.calculateActivityDetail();
	}
	calculateActivityDetail() {
		var alreadyListedLabor = [];
		var alreadyListedMachine = [];
		var alreadyListedResource = [];
		var alreadyListedWater = [];
		var alreadyListedServices = [];
		this.resourcesActitvity = [];
		var totalCost = 0;
		this.activeFullActivity.activity.detail.forEach((elementResource) => {
			var resourceObj = undefined;
			if (elementResource.type == 0) {
				if (!alreadyListedLabor.includes(elementResource.resource)) {
					alreadyListedLabor.push(elementResource.resource);
					resourceObj = this.laborQuery.getEntity(
						elementResource.resource + '',
					);
					if (resourceObj !== undefined) {
						const horasHombrePrice =
							parseFloat(resourceObj.cost) /
							parseFloat(resourceObj.hours);

						const hoursLabor =
							this.activeFullActivity.activity.workday *
							this.activeFullActivity.activity.duration *
							(elementResource.percentage / 100);

						const totalValue = this.to2Decimal(
							hoursLabor * horasHombrePrice,
						);
						resourceObj = JSON.parse(JSON.stringify(resourceObj));
						resourceObj.type = 0;
						resourceObj.percentage =
							elementResource.percentage + ' %';
						resourceObj.totalValue = 'S/. ' + totalValue.toLocaleString('en-US');
						resourceObj.hoursContract = hoursLabor + ' hrs';
						this.resourcesActitvity.push(resourceObj);
						totalCost = totalCost + totalValue;
					}
				}
			} else if (elementResource.type == 1) {
				if (!alreadyListedMachine.includes(elementResource.resource)) {
					alreadyListedMachine.push(elementResource.resource);
					resourceObj = this.machineQuery.getEntity(
						elementResource.resource + '',
					);

					if (resourceObj !== undefined) {
						const horasHombrePrice = parseFloat(resourceObj.cost);

						const hoursMachine =
							this.activeFullActivity.activity.workday *
							this.activeFullActivity.activity.duration *
							(elementResource.percentage / 100);

						const totalValue = this.to2Decimal(
							hoursMachine * horasHombrePrice,
						);
						resourceObj = JSON.parse(JSON.stringify(resourceObj));
						resourceObj.type = 1;
						resourceObj.percentage =
							elementResource.percentage + ' %';
						resourceObj.totalValue = 'S/. ' + totalValue.toLocaleString('en-US');
						resourceObj.hoursContract = hoursMachine + ' hrs';
						this.resourcesActitvity.push(resourceObj);
						totalCost = totalCost + totalValue;
					}
				}
			} else if (elementResource.type == 2) {
				if (!alreadyListedWater.includes(elementResource.resource)) {
					alreadyListedWater.push(elementResource.resource);
					resourceObj = this.waterQuery.getEntity(
						elementResource.resource + '',
					);
					if (resourceObj !== undefined) {
						const costPerProduct = parseFloat(resourceObj.cost);
						const totalValue = this.to2Decimal(
							elementResource.percentage * costPerProduct,
						);

						resourceObj = JSON.parse(JSON.stringify(resourceObj));
						resourceObj.type = 2;
						resourceObj.percentage = elementResource.percentage;
						resourceObj.totalValue = 'S/. ' + totalValue.toLocaleString('en-US');
						resourceObj.hoursContract =
							elementResource.percentage + ' ' + resourceObj.unit;
						this.resourcesActitvity.push(resourceObj);
						totalCost = totalCost + totalValue;
					}
				}
			} else if (elementResource.type == 3) {
				if (!alreadyListedResource.includes(elementResource.resource)) {
					alreadyListedResource.push(elementResource.resource);
					resourceObj = this.supplyQuery.getEntity(
						elementResource.resource + '',
					);

					if (resourceObj !== undefined) {
						const unityCost =
							parseFloat(resourceObj.cost) /
							parseFloat(resourceObj.quantity);

						const totalValue = this.to2Decimal(
							elementResource.percentage * unityCost,
						);

						resourceObj = JSON.parse(JSON.stringify(resourceObj));
						resourceObj.type = 3;
						resourceObj.percentage = elementResource.percentage;
						resourceObj.totalValue = 'S/. ' + totalValue.toLocaleString('en-US')
						resourceObj.hoursContract =
							elementResource.percentage + ' ' + resourceObj.unit;
						this.resourcesActitvity.push(resourceObj);
						totalCost = totalCost + totalValue;
					}
				}
			} else if (elementResource.type == 4) {
				if (!alreadyListedServices.includes(elementResource.resource)) {
					alreadyListedServices.push(elementResource.resource);
					resourceObj = this.servAndOthersQuery.getEntity(
						elementResource.resource + '',
					);
					if (resourceObj !== undefined) {
						const totalValue = this.to2Decimal(
							elementResource.percentage *
								parseFloat(resourceObj.cost),
						);

						resourceObj = JSON.parse(JSON.stringify(resourceObj));
						resourceObj.type = 4;
						resourceObj.percentage = elementResource.percentage;
						resourceObj.totalValue = 'S/. ' + totalValue.toLocaleString('en-US');;
						resourceObj.hoursContract =
							elementResource.percentage + ' ' + resourceObj.unit;
						this.resourcesActitvity.push(resourceObj);
						totalCost = totalCost + totalValue;
					}
				}
			}
			this.dataSource = new MatTableDataSource(this.resourcesActitvity)
		});

		totalCost = this.to2Decimal(totalCost);
		this.totalCost = totalCost.toLocaleString('en-US');
	}

	public close() {
		//this.unsuscribeFromAll();
		this.dialogRef.close();
	}

	public to2Decimal(num: number) {
		return Math.round(num * 100) / 100;
	}
}
