import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Provider {
    id?: string;
    resource?: string;
    type?: string;
    ruc: string;
    business_name?: string;
    email?: string;
    phone?: string;
    web_page?: string;
    person_contact?: string;
}
export interface ProviderState extends EntityState<Provider, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'provider-storage' })
export class ProviderStore extends EntityStore<ProviderState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "",
                resource: "",
                type: "",
                ruc: "",
                business_name: '',
                email: "",
                phone: "",
                web_page: "",
                person_contact: "",

            }
        )
    }
}

