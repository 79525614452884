import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { SatellitalImageNotify, SatellitalImageNotifyState, SatellitalImageNotifyStore } from './satellital-image-notify.store';

@Injectable({ providedIn: 'root' })
export class SatellitalImageNotifyQuery extends QueryEntity<SatellitalImageNotifyState> {
    
    public selectAllNotifications: Observable<SatellitalImageNotify[]> = this.selectAll();

    constructor(protected store: SatellitalImageNotifyStore) {
        super(store);
    }

}
