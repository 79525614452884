/* export const baseUrl = {
    base: 'http://176.31.226.236:8000/',
};

export const environment = {
    production: false,
    apiAgromonitor: baseUrl.base + 'api',
    apiUrl: baseUrl.base + 'api',
    images_comparison: "http://176.31.226.236/sbs/?",
    apiUrlAgroDat: 'http://176.31.226.236:9000/agrodat/api',
    apiUrlAgroMet: 'http://176.31.226.236:9000/agrodat/api',	
    apiUrlAgroAnalytics: 'https://infoagro.tk/agroanalytics/api',
    images_sat_url: 'http://176.31.226.236:8081/',
}; */

export const baseUrl = {
  base: 'https://paulet.space/',
  // 'https://app.infoagro.pe/',
  weather: 'https://paulet.space/'
};

export const environment = {
  production: true,
  apiAgromonitor: baseUrl.base + 'api',
  apiUrl: baseUrl.base + 'api',
  apiUrlAgroDat: 'http://176.31.224.4:9010/agrodat/api',
  apiUrlAgroAnalytics: baseUrl.base + 'agroanalytics/api',
  apiUrlAgroMet: baseUrl.base + 'agrodat/api',
  images_comparison: baseUrl.base + 'sbs/?',
  images_sat_url: 'http://176.31.224.4:9015/',
  mercado_pago_public_key: 'APP_USR-aa76759a-a8bb-4a99-ba0e-cfd32bfa6a8e',
  weather: baseUrl.weather +'routes/api'
};
