import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Ticket {
    id: number,
    date: string,
    hour: string,
    is_active: boolean
}

export interface LotDroneTicket {
    id: number,
    date: string,
    hour: string,
    distance: string,
    type_road: string,
    reference: string,
    aerodrome: boolean,
    aerodrome_name: string,
    interference: boolean,
    special_permissions: boolean,
    lot: string,
    process: {
        id: number,
        quote_solicitude: boolean,
        price: number,
        quote_approval: boolean,
        drone_solicitude: boolean,
        drone_sent: boolean,
        drone_arrival: boolean,
        state: null,
        ticket: number
    }

}

export interface LotDroneTicketState extends EntityState<LotDroneTicket, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lotdroneticket' })
export class LotDroneTicketStore extends EntityStore<LotDroneTicketState> {
    public constructor() {
        super();
    }
}
