import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { FieldsStore, Field } from '@src/app/store/fields/fields.store';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsUiService } from '../../services/fields-ui.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})
export class EditFieldComponent implements OnInit {
  public form: FormGroup;
  public color = "#2DA951";
  constructor(
    private readonly formBuiilder: FormBuilder,
    private fieldsQuery: FieldsQuery,
    private fieldsService: FieldsService,
    private readonly fieldsUiService: FieldsUiService,
    private readonly spinner: NgxSpinnerService,
  ) {
    this.form = this.formBuiilder.group({
      name: [(this.fieldsQuery.getActive() as Field).name, [Validators.required, Validators.maxLength(50)]],

    });
  }

  ngOnInit(): void {
  }
  async editField() {
    this.spinner.show('Syncrof');
    var idField = this.fieldsQuery.getActiveId()
    if (!this.availableColors.includes(this.color)) {
      this.color = "#2DA951"
    }
    await this.fieldsService.updateField(idField, this.form.value.name, this.color)
    this.fieldsUiService.closeSidebar();
    this.spinner.hide('Syncrof');
  }

  public closeSidebar() {
    this.fieldsUiService.closeSidebar()
  }

  public selectedColor(color: string) {
    if (this.availableColors.includes(color)) {
      this.color = color;
    } else {
      this.color = "#2DA951"
    }
  }

  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  scrollLeft() {
    this.widgetsContent.nativeElement.scrollLeft -= 50;
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollLeft += 50;
  }

  public availableColors = [
    "#C11717", "#E61822", "#FCBF17", "#F1E610", "#93C353", "#2DA951", "#1BAEE4", "#076FB8", "#202C56", "#66398E",
    "#000000", "#EEECE0", "#1A4A7B", "#4E7FC2", "#C1504A", "#9BBC54", "#8165A0", "#43ADC5", "#EE974A",
    "#F2F2F2", "#7F7F7F", "#DED9C3", "#C7D9F1", "#DCE4F1", "#F1DCDB", "#EAF1DF", "#E6E0EC", "#DBEEF4", "#FDEADB",
    "#707070", "#4D7ABD", "#F7EA20", "#EF8086", "#88AEDD"
  ]
}
