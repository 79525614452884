import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Water {
    id?: string;
    date?: string;
    unit?: string;
    abbreviation?: string;
    quantity?: string;
    coin?: string;
    cost?: string;
    totalCost?: string;
    observation?: string;
}
export interface WaterState extends EntityState<Water, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'water-storage' })
export class WaterStore extends EntityStore<WaterState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "1",         
                date: "2020-12-12",
                unit: "m3",
                quantity: "3",
                cost: "20",
                coin: "1",
                observation: "ninguna"
            }
        )
    }
}

