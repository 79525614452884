import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

import { WaterStore, Water } from './water-storage.store';
import { WaterStorageQuery } from './water-storage.query';

export interface Message {
    StatusCode: number,
    Error: string,
    message: Water[]
}

@Injectable({ providedIn: 'root' })
export class WaterService {

    constructor(
        private waterStore: WaterStore,
        private waterStorageQuery: WaterStorageQuery,
        private http: HttpClient
    ) {
    }
    
    public updateActive(id){
        this.waterStore.setActive(id)
    }

    public async findAll(): Promise<Water[]> {
        const hasCache = this.waterStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }

        try {
            const data = await this.http
                .get<Message>(`${environment.apiUrl}/water/`)
                .toPromise<Message>();
            const dataArray = data.message
            const waterAll = dataArray.map((currentLabor: any) => {
                const water = currentLabor;
                water.id = String(water.id);
                water.coin = String(water.coin);
                water.quantity = String(water.quantity);
                return water;
            });

            this.waterStore.set(waterAll);
            return waterAll;
        }
        catch (error) {
        }
    }


    public async createManyMachines(arrayOfMachineObjects) {
        try {
            arrayOfMachineObjects.forEach(element => {
                element.quantity = parseInt(element.quantity);
                element.coin = parseInt(element.coin);
                delete element.id;
                delete element.abbreviation;
                delete element.totalCost;
            });
            const machine = await this.http
                .post<Message>(`${environment.apiUrl}/water/`,
                    arrayOfMachineObjects
                )
                .toPromise<Message>();
            const dataArray = machine.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.waterStore.add(element)
            });
        } catch (error) {

        }
    }

    public async updateMany(arrayOfLaborObjects) {
        try {
            arrayOfLaborObjects.forEach((element) => {
                element.id = parseInt(element.id)
                element.quantity = parseInt(element.quantity);
                element.coin = parseInt(element.coin);
                delete element.abbreviation;
                delete element.totalCost;
            });
            const labor = await this.http
                .put<Message>(`${environment.apiUrl}/water/`,
                    arrayOfLaborObjects
                )
                .toPromise<Message>();
            const dataArray = labor.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id);
                this.waterStore.update(element.id, element)
            });
        } catch (error) {
        }
    }

    public async deleteMany(arrayOfLaborObjects) {
        try {
            var idsOfWaterObjects = []
            arrayOfLaborObjects.forEach(element => {
                idsOfWaterObjects.push(parseInt(element))
            });
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    idsOfWaterObjects
            };
            const labor = await this.http
                .delete(`${environment.apiUrl}/water/`,
                    options
                )
                .toPromise();
            arrayOfLaborObjects.forEach(element => {
                this.waterStore.remove(element)
            });
        } catch (error) {
        }
    }
}   
