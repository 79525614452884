import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare let gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'infoagro';

	constructor(public router: Router) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				gtag('config', 'G-0CDQ2B0STL',
					{
						'page_path': event.urlAfterRedirects
					}
				);
			}
		}
		)
	}
}

