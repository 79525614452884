// Angular components
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

// Modals
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { SaveConfirmationDialogComponent } from '../save-confirmation-dialog/save-confirmation-dialog.component';
import { CalendarAvailabilityComponent } from '../calendar-availability/calendar-availability.component';

// Storage
import { CalendarStorageQuery } from '../../storage/calendar-storage/calendar-storage.query';
import { ResourceService } from '../../shared-data/resources.service';

import * as moment from 'moment';

// Constants
import { phases } from '../../constants/phasesActivity';
import { TypeData, typesResources } from '../../constants/typesResources';
import { GenericResources } from '../../constants/genericResource';
import { ActivityErrors } from '../../constants/activity.errors';
import { UserQuery } from '@src/app/store/user/user.query';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { DiseaseInformationDialog } from './disease.component';

export interface ActivityGeneral {
  name: string;
  phase: string;
}

@Component({
  selector: 'app-dialog-activity',
  templateUrl: './dialog-activity.component.html',
  styleUrls: ['./dialog-activity.component.scss'],
})
export class DialogActivityComponent implements OnInit {
  public data: ActivityGeneral;
  public resources: GenericResources[];
  public resourcesToUse: GenericResources[];
  public backupResourcesToUse: GenericResources[];
  public options: TypeData[];
  public phases: string[];
  public optionSelect: number;
  public form: FormGroup = null;
  public description: string = '';
  public calendar: any = null;
  public isCalendarGeneric: boolean = false;
  public advancedActivityManagement: boolean = false;
  public isCalendarOpened: boolean = false;
  public days: boolean[] = [true, true, true, true, true, false, false];

  public diseaseId = '';

  constructor(
    public dialogRef: MatDialogRef<DialogActivityComponent>,
    public dialogMes: MatDialog,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    private calendarStorageQuery: CalendarStorageQuery,
    private resourcesGenericService: ResourceService,
    private readonly userQuery: UserQuery,
  ) {
    this.initData();

    this.calendar = data.calendar;
    let duration = moment(data.end).diff(moment(data.start), 'days');
    if (data.start == '') {
      let today = moment();
      data.start = { year: today.year(), month: today.month(), date: today.date() };
      today.add(1, 'days');
      data.end = { year: today.year(), month: today.month(), date: today.date() };
      duration = 1;
      data.workingDay = 8;
    }
    if (data.phase === '') data.phase = 'Otra';
    else {
      const phase = data.phase.split('|');
      if (phase.length > 1) {
        data.phase = phase[0];
        this.diseaseId = phase[1];
      }
    }
    this.data = { name: data.name, phase: data.phase };
    this.description = data.description;
    this.form = this.formBuilder.group({
      startDate: [moment(data.start), []],
      endDate: [moment(data.end), []],
      workingDay: [{ value: data.workingDay, disabled: true }, [Validators.min(1), Validators.max(24)]],
      duration: [duration, [Validators.min(1)]],
      hours: [{ value: duration * data.workingDay, disabled: true }, []],
    });
    this.form.patchValue({
      startDate: moment(this.fstartDate.value),
      endDate: moment(this.fendDate.value),
    });
    if (this.calendar == undefined) {
      this.isCalendarGeneric = true;
      this.calendarStorageQuery.selectAll().subscribe((x) => {
        this.updateCalendar(x[0], true);
      });
    } else this.updateWorkDays();

    this.resourcesGenericService.getUpdateResources().subscribe(() => {
      this.validateResources(data);
    });
    this.resourcesGenericService.getAllResources();
    this.calculateDuration();
  }

  ngOnInit(): void {
    const currentUser = this.userQuery.getInformation();
    if (currentUser.permission.advancedActivityManagement) this.advancedActivityManagement = true;
  }

  showImg() {
    const currentUser = this.userQuery.getInformation();
    const data = {
      userId: currentUser.id,
      id: this.diseaseId,
    };
    const dialog = this.dialogMes.open(DiseaseInformationDialog, {
      panelClass: ['no-padding', 'is-transparent'],
      width: '600px',
      height: '450px',
      data,
    });
    //this.diseaseId
  }

  detectClick(event): void {
    let el = event.srcElement;
    const attr = el.attributes.getNamedItem('class');
    if (attr.value.indexOf('mat-tab-label-content') >= 0) el = el.parentElement;
    const tabIndex = el.id.substring(el.id.length - 1);
    const currentUser = this.userQuery.getInformation();
    if (tabIndex == 1 && !currentUser.permission.advancedActivityManagement) {
      const saveRef = this.dialogMes.open(MessageUpdatePlanComponent, {
        width: '400px',
      });
      saveRef.afterClosed().subscribe((data) => {});
    }
  }

  initData(): void {
    this.backupResourcesToUse = [];
    this.phases = phases;
    this.optionSelect = 0;
    this.resources = [];
    this.resourcesToUse = [];
    this.options = typesResources;
  }

  private updateCalendar(newCalendar: any, isCalendarGeneric: boolean) {
    this.calendar = JSON.parse(JSON.stringify(newCalendar));
    this.isCalendarGeneric = isCalendarGeneric;
    this.updateWorkDays();
    let start: number =
      this.getHourByString(this.calendar.end_schedule) - this.getHourByString(this.calendar.start_schedule);
    let breaka: number =
      this.getHourByString(this.calendar.end_break) - this.getHourByString(this.calendar.start_break);
    this.fworkingDay.setValue(start - breaka);
    this.calculateDuration();
  }

  private getHourByString(value: string) {
    let digits: number = value.length > 7 ? 2 : 1;
    return +value.substring(0, digits);
  }

  private updateWorkDays() {
    this.days = [false, false, false, false, false, false, false];
    for (let i = 0; i < this.calendar.days.length; i += 2) this.days[(+this.calendar.days[i] + 6) % 7] = true;
  }

  private calculateDuration() {
    let interval = moment(this.fendDate.value).diff(moment(this.fstartDate.value), 'days');
    const count = this.days.filter((value) => value).length;
    let duration: number = Math.floor(interval / 7) * count;
    for (let i = 0; i < interval % 7; i++) if (this.days[(i + this.fstartDate.value.day() - 1) % 7]) duration++;

    this.form.patchValue({ hours: duration * this.fworkingDay.value, duration: duration });
  }

  validateData() {
    let errors: Array<string> = [];
    if (!this.fduration.valid || Number.isNaN(this.fduration.value)) errors.push(ActivityErrors.SHORT_DURATION);
    if (this.data.name.length < 3 || this.data.name.length > 100) errors.push(ActivityErrors.WRONG_NAME);
    if (this.data.phase.length < 3 || this.data.phase.length > 100) errors.push(ActivityErrors.WRONG_PHASE);

    for (let i = 0; i < this.resourcesToUse.length; ++i)
      if (
        (this.resourcesToUse[i].percentage > 100 || this.resourcesToUse[i].percentage <= 0) &&
        this.resourcesToUse[i].type <= 2
      ) {
        errors.push(ActivityErrors.WRONG_PERCENTAGE);
        break;
      }

    return errors;
  }

  isEmpty() {
    if (!Number.isNaN(this.fduration.value)) return false;
    if (this.fworkingDay.value != null) return false;
    if (this.data.name != '' || this.data.phase != '') return false;
    if (this.resourcesToUse.length > 0) return false;
    return true;
  }

  notSave(): void {
    this.returnData('notSave', []);
  }

  returnData(response: string, deleteResources: GenericResources[]): void {
    this.dialogRef.close({
      response: response,
      phase: this.data.phase,
      name: this.data.name,
      start: new Date(this.fstartDate.value),
      end: new Date(this.fendDate.value),
      workingDay: this.fworkingDay.value,
      duration: this.fduration.value,
      detail: this.resourcesToUse,
      calendar: this.calendar,
      description: this.description,
      delete: deleteResources,
    });
  }

  saveData(): void {
    let errors: Array<string> = this.validateData();
    if (errors.length > 0) {
      this.dialogMes.open(DialogMessageComponent, {
        width: '400px',
        data: errors,
      });
    } else {
      let deleteResources: GenericResources[] = [];
      if (this.backupResourcesToUse != null && this.backupResourcesToUse != this.resourcesToUse) {
        this.backupResourcesToUse.forEach((resource) => {
          let find = this.resourcesToUse.find(
            (resourceT) => resourceT.id == resource.id && resourceT.type == resource.type,
          );
          if (find == undefined) deleteResources.push(resource);
        });
      }
      if (this.isCalendarGeneric) this.calendar.id = undefined;

      this.returnData('save', deleteResources);
    }
  }

  onNoClick(): void {
    if (this.isEmpty()) return this.notSave();

    const saveRef = this.dialogMes.open(SaveConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: '¿Desea guardar sus cambios?',
      },
    });
    saveRef.afterClosed().subscribe((data) => {
      if (data.response == 'save') this.saveData();
      else this.notSave();
    });
  }

  updateDataSelect(select: number) {
    this.optionSelect = select;
  }

  drop(event: CdkDragDrop<GenericResources[]>) {
    if (event.previousContainer === event.container)
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    else transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
  }

  addEvent(type: string) {
    let duration = moment(this.fendDate.value).diff(moment(this.fstartDate.value), 'days');
    if (duration <= 0) {
      if (type == 'start') {
        let endDate = moment(this.fstartDate.value).add(this.fduration.value, 'days');
        this.form.patchValue({
          endDate: endDate,
          startDate: this.fstartDate.value,
        });
      } else {
        let startDate = moment(this.fendDate.value).subtract(this.fduration.value, 'days');
        this.form.patchValue({
          startDate: startDate,
          endDate: this.fendDate.value,
        });
      }
    } else {
      this.form.patchValue({
        startDate: this.fstartDate.value,
        endDate: this.fendDate.value,
      });
      this.calculateDuration();
    }
  }

  updateDuration() {
    let duration = this.fduration.value;
    const count = this.days.filter((value) => value).length;
    let interval = Math.floor(duration / count) * 7;
    let restOfDays = duration % count;
    for (let i = 0; i < 7 && restOfDays > 0; i++) {
      if (this.days[(i + this.fstartDate.value.day() - 1) % 7]) restOfDays--;
      interval++;
    }
    let endDate = moment(this.fstartDate.value).add(interval, 'days');
    this.form.patchValue({ hours: duration * this.fworkingDay.value, duration: duration, endDate: endDate });
  }

  updatePhase(event) {
    this.data.phase = event.target.value;
  }

  totalCost() {
    let totalCost = 0;
    for (let i = 0; i < this.resourcesToUse.length; ++i) {
      if (this.resourcesToUse[i].type <= 1)
        totalCost += (this.resourcesToUse[i].cost * this.resourcesToUse[i].percentage * this.fhours.value) / 100;
      else totalCost += this.resourcesToUse[i].cost * this.resourcesToUse[i].percentage;
    }

    return totalCost;
  }

  public validateResources(data: any) {
    this.resourcesGenericService.resources.forEach((resource) => {
      let copyResource = JSON.parse(JSON.stringify(resource));
      let found = false;
      for (let i = 0; i < data.detail.length; i++) {
        if (data.detail[i].resource == resource.resource && data.detail[i].type == resource.type) {
          found = true;
          copyResource.id = data.detail[i].id;
          copyResource.percentage = data.detail[i].percentage;
          this.resourcesToUse.push(copyResource);
          this.backupResourcesToUse.push(copyResource);
          break;
        }
      }
      if (!found) this.resources.push(copyResource);
    });
  }

  public openCalendar() {
    if (this.isCalendarOpened) return;

    this.isCalendarOpened = true;
    let data = null;

    if (!this.isCalendarGeneric) data = this.calendar;

    const dialogRef = this.dialogMes.open(CalendarAvailabilityComponent, {
      height: '350px',
      width: '400px',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      data: data,
    });

    var g = dialogRef.afterClosed().subscribe((result) => {
      if (result) this.updateCalendar(result, false);

      this.isCalendarOpened = false;
      g.unsubscribe();
    });
  }

  public get fstartDate(): AbstractControl {
    return this.form.get('startDate');
  }

  public get fendDate(): AbstractControl {
    return this.form.get('endDate');
  }

  public get fworkingDay(): AbstractControl {
    return this.form.get('workingDay');
  }

  public get fduration(): AbstractControl {
    return this.form.get('duration');
  }

  public get fhours(): AbstractControl {
    return this.form.get('hours');
  }
}
