import { Component, OnInit } from '@angular/core';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MoneyService } from '../../storage/money-storage/money-storage.service';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { Router } from '@angular/router';
import { UserQuery } from '@src/app/store/user/user.query';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { laborColumns, laborFilters, machineColumns, machineFilters, servAndOthersColumns, servAndOthersFilters, supplyColumns, supplyFilters, waterColumns, waterFilters } from '../../constants/resourceColumns';

@Component({
	selector: 'app-resource-management',
	templateUrl: './resource-management.component.html',
	styleUrls: ['./resource-management.component.scss'],
})
export class ResourceManagementComponent implements OnInit {

	public laborFilters = laborFilters;
	public laborColumns = laborColumns;
	public machineFilters = machineFilters;
    public machineColumns = machineColumns;
	public supplyFilters = supplyFilters;
    public supplyColumns = supplyColumns;
	public waterFilters = waterFilters;
    public waterColumns = waterColumns;
	public servAndOthersFilters = servAndOthersFilters;
    public servAndOthersColumns = servAndOthersColumns;

	public selectedRows = laborColumns;
	public selectedColumns = [];
	public value = 'Buscar';
	public optionSelected = 'mObra';
	private applySelection = 'mObra';
	public rowForFilter = this.laborFilters;
    public columnFilter = this.laborColumns;
	public filterSelected = false;
	constructor(
		public sharedData: SharedDataServiceService,
		public dialog: MatDialog,
		public moneyService: MoneyService,
		private lotsQuery: LotsQuery,
		private fieldsService: FieldsService,
		private readonly sidebarService: SidebarService,
		private readonly router: Router,
		private readonly activityService: ActivitiesService,
		private readonly userQuery: UserQuery
	) {}

	async ngOnInit() {
		this.sidebarService.updateSelectedService(7);

		await this.fieldsService.findAll();
		const allLots = this.lotsQuery.getAll().length;

		if (allLots < 1) {
			this.router.navigate(['/agromonitor']);
		}

		this.sharedData.getClickViewConsultance().subscribe((x) => {
			this.optionSelected = String(this.applySelection);
			this.filterSelected = false;
			switch (this.optionSelected) {
				case 'mObra':
					this.rowForFilter = this.laborFilters;
                    this.columnFilter = this.laborColumns;
					this.selectedRows = laborColumns;
					this.sharedData.selectedColumns = [];
					break;
				case 'maq':
					this.rowForFilter = this.machineFilters;
                    this.columnFilter = this.machineColumns
					this.selectedRows = machineColumns;
					this.sharedData.selectedColumns = [];
					break;
				case 'ins':
					this.rowForFilter = this.supplyFilters;
                    this.columnFilter = this.supplyColumns;
					this.selectedRows = supplyColumns;
					this.sharedData.selectedColumns = [];
					break;
				case 'agua':
					this.rowForFilter = this.waterFilters;
                    this.columnFilter = this.waterColumns;
					this.selectedRows = waterColumns;
					this.sharedData.selectedColumns = [];
					break;
				case 'serv':
					this.rowForFilter = this.servAndOthersFilters;
                    this.columnFilter = this.servAndOthersColumns;
					this.selectedRows = servAndOthersColumns;
					this.sharedData.selectedColumns = [];
					break;
				default:
					this.rowForFilter = [];
			}
		});
		await this.initializateDate();
	}

	private async initializateDate() {
		await this.moneyService.findAll();
	}

	public sendFilterData() {
		this.sharedData.sentUpdateFilterColumns(this.selectedColumns);
	}

	public save() {
		if (this.optionSelected === 'mObra') {
			this.sharedData.saveLabor();
		}
		if (this.optionSelected === 'maq') {
			this.sharedData.saveMachine();
		}
		if (this.optionSelected === 'ins') {
			this.sharedData.saveSupplies();
		}
		if (this.optionSelected === 'agua') {
			this.sharedData.saveWater();
		}
		if (this.optionSelected === 'serv') {
			this.sharedData.saveServicesAndOther();
		}
	}

	public changeFilerRows(event) {
		var allColumns = [];
		for (let index = 0; index < event.value.length; index++) {
			const element = event.value[index];
			allColumns.push(element.colum);
		}
		this.selectedColumns = allColumns;
	}

	public changeOption(newOption: string) {
		if (newOption === this.optionSelected) {
			return;
		}
		this.applySelection = newOption;
		switch (this.optionSelected) {
			case 'mObra':
				this.sharedData.saveLabor();
				break;
			case 'maq':
				this.sharedData.saveMachine();
				break;
			case 'ins':
				this.sharedData.saveSupplies();
				break;
			case 'agua':
				this.sharedData.saveWater();
				break;
			case 'serv':
				this.sharedData.saveServicesAndOther();
				break;
			default:
				return;
		}
	}

	public changeFilterOption(event) {
		if (event === 'ng') {
			this.filterSelected = false;
			this.sharedData.columnSelectedToFilter = undefined;
			return;
		} else {
			this.filterSelected = true;
			this.sharedData.columnSelectedToFilter = event;
		}
	}

    public changeText(event) {
        this.sharedData.searchTextToFilter = event;
        this.sharedData.sentUpdateToFilter();
    }

    public exportToExcel() {
        const currentUser = this.userQuery.getInformation();
        if (currentUser.permission.exportData)
            this.sharedData.sentUpdateToExport();
        else {
            const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
                width: '400px'
            });
            saveRef.afterClosed().subscribe(
                data => {
                })
        }
    }
	  
}
