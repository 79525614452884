import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface PhenologicalDataState {
    id: number,
    lot_id: string,
    season: string,
    type: string,
    classification: string,
    sowing_date: string,
    pruning_date: string,
    rainfall_date: string
}

export interface PhenologicalDataState extends EntityState<PhenologicalDataState, number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'phenological-state' })
export class PhenologicalDataStore extends EntityStore<PhenologicalDataState> {
	public constructor() {
		super();
	}
}