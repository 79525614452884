import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';

import { MarkerService } from '@shared/services';
import { LotsQuery } from '@store/lots/lots.query';
import { Lot } from '@store/lots/lots.store';
import { Field } from '@store/fields/fields.store';
import { FieldsQuery } from '@store/fields/fields.query';

@Component({
	selector: 'app-lot-detail',
	templateUrl: './lot-detail.component.html',
	styleUrls: ['./lot-detail.component.scss']
})
export class LotDetailComponent implements AfterViewInit {
	public map: L.DrawMap;
	public featureGroup: L.FeatureGroup;

	public isLotSelected: boolean;
	public currentLot: Lot;
	public currentField: Field;

	constructor(
		private readonly markerService: MarkerService,
		private readonly lotsQuery: LotsQuery,
		private readonly fieldsQuery: FieldsQuery
	) {
		this.currentLot = {};
		this.currentField = {};
		this.isLotSelected = true;

		this.lotsQuery.selectActiveId().subscribe((data: string) => {
			if (!data) {
				this.isLotSelected = false;
				return;
			}

			this.isLotSelected = true;
			this.currentLot = this.lotsQuery.getActive() as Lot;
			this.currentField = this.fieldsQuery.getActive() as Field;
			this.markerService.drawAndCenterPolygon(
				this.map,
				this.featureGroup,
				this.currentLot.coordinates,
				this.currentLot.area
			);
		});
	}

	public ngAfterViewInit() {
		this.initmap();
	}

	public initmap(): void {
		const openStreetMap: L.TileLayer = L.tileLayer(
			'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			{
				maxZoom: 19,
				attribution:
					'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
			}
		);

		const googleMap = L.tileLayer(
			'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
			{
				maxZoom: 19,
				subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
			}
		);

		let baseMaps = {
			openStreetMap,
			googleMap
		};

		this.map = L.map('lot-detail-map', {
			center: [-5.219646, -79.975689],
			zoom: 10,
			layers: [openStreetMap, googleMap]
		});
		L.control.layers(baseMaps).addTo(this.map);

		// Register polygons manager
		this.featureGroup = new L.FeatureGroup();
		this.map.addLayer(this.featureGroup);

	}

	public drawPolygon() {
		const polygonDrawer = new L.Draw.Polygon(this.map);
		polygonDrawer.enable();
	}
}
