import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { SatImageState, SatImageStore } from './satImgDates.store';

@Injectable({ providedIn: 'root' })
export class SatImageQuery extends QueryEntity<SatImageState> {
	public constructor(protected store: SatImageStore) {
		super(store);
	}
}
