import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FieldsUiService } from '../../modules/fields/services/fields-ui.service';
import { FieldsUiQuery } from '../../modules/fields/store/fields-ui.query';
import { FielListQuery } from '../../store/field-list/fiel-list.query';
import {FielListService} from "../../store/field-list/fiel-list.service"
@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

	public suscriptions: Subscription[] = [];
	public isOpened: boolean = false;
	public selectedModal: number = 1;
	public selectedList = "1"

	constructor(
		private readonly fielListService: FielListService,
		private readonly fielListQuery: FielListQuery,
		private readonly fieldsUiQuery: FieldsUiQuery,
		private readonly fieldsUiService: FieldsUiService
	) {
		this.suscriptions.push(
			this.fieldsUiQuery.selectIsOpen.subscribe((x) => {
				this.isOpened = x;
			}),
			this.fieldsUiQuery.selectSelectedModal.subscribe((x) => {
				this.selectedModal = x;
			}),
			this.fielListQuery.selectSelectedResource.subscribe((x) => {
				this.selectedList = x + ""
			})
		);
	}

	ngOnInit(): void {}

	public onChange(value: string) {
		this.fielListService.setSelectedResource(parseInt(value));
	}

	public changeViewSide(selectedModalToChange: number){
		if(this.selectedModal !== selectedModalToChange){
			this.fieldsUiService.setSelectedResource(selectedModalToChange);
		}
	}
}
