import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Request, SharedLotsService } from '../../services/shared-lots/shared-lots.service';
import { CollaboratorPermissionsComponent } from '../collaborator-permissions/collaborator-permissions.component';

@Component({
  selector: 'app-request-received',
  templateUrl: './request-received.component.html',
  styleUrls: ['./request-received.component.scss']
})
export class RequestReceivedComponent implements OnInit {

  public requests: Request[] = [];
  constructor(private readonly sharedLots: SharedLotsService,
    public dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    this.requests = await this.sharedLots.getRequests();
  }

  public async responseRequest(index: number, data: boolean) {
    if (!data) {
      await this.sharedLots.responseRequest(this.requests[index], data, []);
      this.requests.splice(index, 1);
    }
    else {
      const dialogRef = this.dialog.open(CollaboratorPermissionsComponent);
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.response) {
          await this.sharedLots.responseRequest(this.requests[index], data, result.campaignsId);
          this.requests.splice(index, 1);
        }
      });
    }
  }
}
