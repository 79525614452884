import { QueryEntity } from '@datorama/akita';
import { LotsState, LotsStore } from './lots.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LotsQuery extends QueryEntity<LotsState> {
	public constructor(protected store: LotsStore) {
		super(store);
	}
}
