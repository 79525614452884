import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { PhenologicalStateQuery } from '@src/app/modules/my-fields/store/phenological-state-storage/phenological-state.query';
import { PhenologicalStateService } from '@src/app/modules/my-fields/store/phenological-state-storage/phenological-state.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { LotsService } from '@src/app/store/lots/lots.service';
import { Lot } from '@src/app/store/lots/lots.store';
import { PhenologicalDataQuery } from '@src/app/store/pheno-info/phenological-data.query';
import { SubVarietyQuery } from '@src/app/store/subVariety/state/sub-variety.query';
import { FieldsUiService } from '../../services/fields-ui.service';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';

@Component({
    selector: 'app-phenological-state',
    templateUrl: './phenological-state.component.html',
    styleUrls: ['./phenological-state.component.scss']
})
export class PhenologicalStateComponent implements OnInit {
    public favoriteSeason: string;
    public activeLot: Lot;
    public activeLotFildName = "";
    public typesOfSeasons = [
        {
            "value": "P",
            "name": 'Permanente',
            "checked": false
        },
        {
            "value": "S",
            "name": 'Semipermanente',
            "checked": false
        },
        {
            "value": "T",
            "name": 'Transitorio',
            "checked": false
        }
    ];
    public valueSelected = undefined;
    public thisLotVarietyDoesntHavePhenoState = false;
    public seasonLot = "";
    public sowing_date = "";
    public pruning_date = "";
    public rainfall_date = "";
    public selectedDate = "";
    public lotVarietyId = undefined;
    private activeCampaing = undefined;
    constructor(
        public readonly lotsQuery: LotsQuery,
        public readonly lotsService: LotsService,
        public readonly fieldsQuery: FieldsQuery,
        public readonly phenologicalService: PhenologicalStateService,
        public readonly phenologicalStateQuery: PhenologicalStateQuery,
        public readonly subVarietyQuery: SubVarietyQuery,
        private fieldUiService: FieldsUiService,
        private mapSidebarComService: MapSidebarComService,
        public readonly phenologicalQuery: PhenologicalDataQuery
    ) {
        this.valueSelected = undefined;
        this.thisLotVarietyDoesntHavePhenoState = false;
        this.lotsQuery.selectActiveId().subscribe(x => {

            if (x !== undefined) {
                var activeLot = this.lotsQuery.getEntity(x);
                if (activeLot !== undefined) {
                    try {
                        this.activeLot = activeLot;
                        this.activeLotFildName = this.fieldsQuery.getEntity(this.fieldsQuery.getActiveId()).name;
                        this.lotVarietyId = undefined;

                        if (activeLot !== undefined && activeLot.campaigns !== undefined && activeLot.campaigns.length > 0) {
                            for (let index = 0; index < activeLot.campaigns.length; index++) {
                                const element = activeLot.campaigns[index];
                                if (!element.isFinished) {
                                    this.activeCampaing = element;
                                    const subVariety = this.subVarietyQuery.getEntity(element.varietyId + "");
                                    if (subVariety !== undefined) {
                                        this.lotVarietyId = parseInt(subVariety.product.id);
                                    } else {
                                        this.thisLotVarietyDoesntHavePhenoState = true;
                                        return;
                                    }
                                }
                            }
                        }
                        if (this.activeCampaing == undefined) {
                            return;
                        }
                        const phenoInfo = this.phenologicalStateQuery.getEntity(this.lotVarietyId)
                        if (phenoInfo === undefined) {
                            this.thisLotVarietyDoesntHavePhenoState = true;
                            return;
                        } else {
                            this.selectedDate = phenoInfo.measure
                            this.typesOfSeasons.forEach(element => {
                                if (element.value === phenoInfo.type) {
                                    element.checked = true;
                                    this.valueSelected = phenoInfo.type;
                                }
                            });
                            if (this.activeCampaing.phonologicalState !== 0) {
                                var phenoRegistred = this.phenologicalQuery.getEntity(this.activeCampaing.phonologicalState);
                                if (phenoRegistred !== undefined) {
                                    this.seasonLot = phenoRegistred.season;
                                    this.valueSelected = phenoRegistred.classification;
                                    this.sowing_date = phenoRegistred.sowing_date;
                                    this.pruning_date = phenoRegistred.pruning_date;
                                    this.rainfall_date = phenoRegistred.rainfall_date;
                                } else {
                                    this.phenologicalService.findOnePhenologicalState(this.activeCampaing.phonologicalState).then(x => {
                                        this.seasonLot = x.season;
                                        this.valueSelected = x.classification;
                                        this.sowing_date = x.sowing_date;
                                        this.pruning_date = x.pruning_date;
                                        this.rainfall_date = x.rainfall_date;
                                    })
                                }
                                
                                this.phenologicalService.findOnePhenologicalState(this.activeCampaing.phonologicalState).then(x => {
                                    this.seasonLot = x.season;
                                    this.valueSelected = x.classification;
                                    this.sowing_date = x.sowing_date;
                                    this.pruning_date = x.pruning_date;
                                    this.rainfall_date = x.rainfall_date;
                                })
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }

                }
            } else {
                this.activeLot = undefined;
            }
        })
    }

    @ViewChild(MatSelectionList) shoes: MatSelectionList;
    ngOnInit(): void {
    }


    public change(e) {
        this.shoes.deselectAll();
        e.option.selected = true;
        this.valueSelected = e.option.value;
    }

    public hideSelectedField() {
        this.fieldUiService.closeSidebar();
        this.mapSidebarComService.sendClickToDeselect();
    }

    public async savePhenologicalState() {
        if (this.activeLot !== undefined && this.activeCampaing !== undefined) {
            if (this.activeCampaing.phonologicalState !== 0) {
                await this.phenologicalService.updateOnePhenologicalState(
                    this.activeCampaing.phonologicalState,
                    this.activeCampaing.id,
                    this.seasonLot,
                    this.valueSelected,
                    this.sowing_date,
                    this.pruning_date,
                    this.rainfall_date
                );

            } else {
                await this.phenologicalService.createOnePhenologicalState(
                    this.activeCampaing.id,
                    this.seasonLot,
                    this.valueSelected,
                    this.sowing_date,
                    this.pruning_date,
                    this.rainfall_date
                ).then(
                    (x) => {
                        try {
                            const newPhenoId = x.id;
                            this.lotsService.updateCampaing(this.activeLot.id, this.activeCampaing.id, this.activeCampaing.name, this.activeCampaing.varietyId, this.activeCampaing.isFinished, newPhenoId)
                        } catch (error) {

                        }
                    }
                )
            }
        }
        this.fieldUiService.setSelectedResource(3)
    }

    public changeSowing(e) {
    }
}
