import {  Component,  AfterViewInit,  Inject,  HostListener,  OnDestroy,  Output,  EventEmitter,  ViewChild, OnInit,} from '@angular/core';
import * as L from 'leaflet';
import '@here/maps-api-for-javascript';
import * as ToGeojson from '@mapbox/togeojson';
import * as fileLayer from 'leaflet-filelayer';
import { FieldsUiService } from '../../services/fields-ui.service';
import { MainPageComponent } from '../../pages/main-page/main-page.component';
import { Subscription } from 'rxjs';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { Injectable } from '@angular/core';
import { SharedMapData } from './sharedMapData';
import { FielListQuery } from '@src/app/modules/my-fields/store/field-list/fiel-list.query';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { LotsService } from '@src/app/store/lots/lots.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FielListService } from '@src/app/modules/my-fields/store/field-list/fiel-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LotEditQuery } from '@src/app/modules/my-fields/states/lot-edit/lot-edit.query';
import { KeyCode } from '@src/app/modules/my-fields/enums/keyboard-enum';
import { LotCropServiceService } from '@src/app/modules/my-fields/services/lot-crop-service/lot-crop-service.service';
import { SatellitalImageQuery } from '@src/app/modules/my-fields/store/satellital-images-state/satellital-images-state.query';
import { SatImageQuery } from '@src/app/store/satImgDates/satImgDates.query';
import { SatImageService } from '@src/app/store/satImgDates/satImgDates.service';
import { SatellitalImageService } from '@src/app/modules/my-fields/store/satellital-images-state/satellital-images-state.service';
import { LotEditService } from '@src/app/modules/my-fields/states/lot-edit/lot-edit.service';
import { UserQuery } from '@src/app/store/user/user.query';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedLotsService } from '@src/app/modules/my-fields/services/shared-lots/shared-lots.service';
import { environment } from '@src/environments/environment';
import { ComparisonDialogComponent } from '../../sidebar-components/lot-detail/comparison-dialog/comparison-dialog.component';
import { LatLng } from 'leaflet';
import { ImageProcessingService } from '@src/app/modules/services/image-processing/image-processing.service';
import { cameraIcon } from './map-markers';
import { DiseaseInformationDialog } from './disease-info-dialog';
import { DialogActivityComponent } from '@src/app/modules/agromonitor/components/dialog-activity/dialog-activity.component';
import { DatePipe } from '@angular/common';
import { ActivitiesService } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.service';
import { DialogSelectLot } from './dialog-select-lot';
import { infoagroPlans } from '@src/app/core/utils/infoagro_plans';

import { HereApiService } from '@src/app/modules/my-fields/store/here-maps/here-routes.service';

import { WeatherService } from '@src/app/modules/my-fields/services/weather-service/weather.service';

import { MapService } from '../../services/map.service';
import { SidebarComponent } from '@src/app/layouts/sidebar/sidebar.component';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';
import { forkJoin } from 'rxjs';
import * as ApexCharts from 'apexcharts';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexLegend,
} from 'ng-apexcharts';
import { NominatimService } from '../../services/nominatim.service';
import { UserService } from '@src/app/store/user/user.service';

export interface DialogData {
  areaTotal: '';
  periTotal: '';
  modified: true;
}
export interface DialogMessage {
  message: '';
}
export interface DateResult {
  url: '';
  lotData: [];
}

@Component({
  selector: 'app-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['./main-map.component.scss'],
})
@Injectable()
export class MainMapComponent implements AfterViewInit, OnDestroy, OnInit {
  @Output()
  @ViewChild(SidebarComponent, { static: false }) sidebar!: SidebarComponent;
  public service = 1;
  datesForStatistics = new EventEmitter<any>();
  public distance;
  public infoagroPlans = infoagroPlans;
  public clickEventsubscription: Subscription;
  public clickCenterSubscription: Subscription;
  public map: L.DrawMap;
  public group: L.LayerGroup;
  public drawLayer;
  public openStreetMapLayer: L.TileLayer;
  public googleMapLayer: L.TileLayer;
  public presentedLayer = false;
  public presentedLayerView = false;
  public presentedLayerComplete = false;
  public imageSelected;
  public tmpRestoreLayer;
  public fields$ = this.fieldsQuery.selectFieldsWithLots();
  public drawnItems: L.FeatureGroup = L.featureGroup();
  public polygonItems: L.FeatureGroup = L.featureGroup();
  public pointsItems: L.FeatureGroup = L.featureGroup();
  public layersOnMap: L.FeatureGroup = L.featureGroup();
  private diseaseItems: L.FeatureGroup = L.featureGroup();
  public selectedModal: string = '1';
  public suscriptions: Subscription[] = [];
  public lotid = '';
  public l = '';
  public n = '';
  public fieldObserver;
  public selectedDate:string = 'domain';
  public selectedDateView = 'domain';
  public dates = '';
  public datesResponse;
  public datesResponseView;
  public layerPresentedOnMap;
  public layerNameSelected;
  public thereIsASelectedField = false;
  public searchInputText = '';
  public uploadFileControler = undefined;
  public activeLotSelectedId = undefined;
  public style = { weight: 8 };
  public allFields = [];
  public layerIds: any = {};
  public imageIds: any = {};
  public user: any = null;
  private showDiseaseRecognitionPhotos = true;
  filteredCoordinates: any[];
  routeInstructionsContainer: HTMLElement;
  public fieldsDrawed: any = {}
  public lotsDrawed: any = {}
  public polylineGroup = L.featureGroup();
  public markerGroup = L.featureGroup();
  public arraysOfCentroids  = [];
  public imagesPath = {
    Drizzle: 'assets/images/temp_rain.svg',
    Rain: 'assets/images/temp_rain.svg',
    Thunderstorm: 'assets/images/temp_storm.svg',
    Snow: 'assets/images/temp_snow.svg',
    Mist: 'assets/images/temp_fog.svg',
    Smoke: 'assets/images/temp_fog.svg',
    Haze: 'assets/images/temp_fog.svg',
    Dust: 'assets/images/temp_fog.svg',
    Fog: 'assets/images/temp_fog.svg',
    Sand: 'assets/images/temp_fog.svg',
    Ash: 'assets/images/temp_fog.svg',
    Squall: 'assets/images/temp_fog.svg',
    Tornado: 'assets/images/temp_fog.svg',
    Clear: 'assets/images/temp_clear.svg',
    Clouds: 'assets/images/temp_cloud_field.svg',
  };
  public tempInfo = undefined;
  selectedOption: number;
  private selectionSubscription: Subscription;
  //CHART------------------
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: any;
  series: any = {
    data: [],
    label: [],
  };
  public display = false
  renderChart() {
    this.pointList.length = 0;
    this.chartOptions = {
      series: [
        {
          name: 'Altitud',
          data: this.series.data,
        },
      ],
      chart: {
        type: 'line',
        height: 130,
        zoom: {
          enabled: false,
        },
        padding: {
          // Establecer el padding a 0 en todos los lados
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'straight',
      },

      title: {
        text: 'Altitud',
        align: 'left',
        style: {
          color: '#FFFFFF', // Cambiar color del título del gráfico
        },
      },
      subtitle: {
        text: '',
        align: 'left',
      },
      labels: {
        style: {
          color: '#FFFFFF', // Cambiar color de las etiquetas del eje Y
        },
      },
      xaxis: {
        type: 'string', // Asegúrate de usar el tipo numérico para el eje x
        tickAmount: this.pointList.length,
        labels: {
          formatter: function(val) {
            return (val-1)*20 + "Km"; // Formato de las etiquetas del eje x
          },
          style: {
            colors: '#FFFFFF', // Color de las etiquetas del eje x
          },
        },
      },
      yaxis: {
        opposite: true,
        labels: {
          style: {
            colors: ['#FFFFFF'],
          },
        },
      },
      legend: {
        horizontalAlign: 'left',
      },
    };
  }

  pointList: any = [];
  address = '';
  weatherData:any = []
  distance_data: number[]=[]
  distance_data1: number[]=[]
  distance_data2: number[]=[]
  show = false
  temperatureLayer: L.TileLayer;
  cloudLayer: L.TileLayer;
  windLayer: L.TileLayer;
  precipitationLayer: L.TileLayer;
  pressureLayer: L.TileLayer;
  tab = 0;
  wind = false;
  temp = false;
  prec = false;
  pres = false;
  cloud = false;
  loading = true;
  total_distance = 0;
  itemschartloading = false;
  showCalendar: boolean = false;
  currentMonth: string;
  daysOfWeek: string[] = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
  days: { day: number; currentMonth: boolean }[] = [];

  currentDate: Date = new Date();
  weeks: any[] = [];
  selectedDay = this.currentDate.getDate();
  mockChartData = {
    series: [
      {
        name: 'Series A',
        type: 'column',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
      },
      {
        name: 'Series B',
        type: 'line',
        data: [23, 43, 55, 56, 81, 95, 90, 110, 120]
      }
    ],
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
  };
  service2Tab = 1;
  tempList:any = [];
  tramoA = [-12.04322, -77.028242];
  tramoB = [-13.405259999999972, -76.19300999999926];
  tramoC = [-14.518479999999899, -75.21277999999896];
  mainTemp = 0;
  clouds = 0;
  winds = 0;
  precipitation = 0;
  pressure = 0;
  mainSrc = ""
  dataByHours:any = []
  tam = 5;
  seriesMidChart = [
    {
      name: "Precipitación",
      type: "column",
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
    },
    {
      name: "Temp. Alta",
      type: "area",
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    },
    {
      name: "Temp. Baja",
      type: "area",
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
    }
  ];

  allSeries = [
    {
    name: "Precipitación",
    type: "column",
    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
  },
  {
    name: "Temp. Alta",
    type: "area",
    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
  },
  {
    name: "Temp. Baja",
    type: "area",
    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
  }
]

allData:any = [
  
]
allDataTemp:any = []
  labels = [    "01/01/2003",
  "02/01/2003",
  "03/01/2003",
  "04/01/2003",
  "05/01/2003",
  "06/01/2003",
  "07/01/2003",
  "08/01/2003",
  "09/01/2003",
  "10/01/2003",
  "11/01/2003"]
  allLabels:any = []
  originalData:any = []
  seriesVisibility: boolean[] = [true, true, true]; // Array que controla la visibilidad de las series
  allTramos:any = []
  tramos:any = [];
  option = 0;
  userInfo:any ;
  drawControl = new L.Control;
  polygonCoordinates: any[] = [];
  
  constructor(
    private readonly fielListQuery: FielListQuery,
    private fieldsUiService: FieldsUiService,
    private mapSidebarComService: MapSidebarComService,
    private fieldsQuery: FieldsQuery,
    private fieldsService: FieldsService,
    public mainPageComponent: MainPageComponent,
    private sharedMapData: SharedMapData,
    private lotCropServiceService: LotCropServiceService,
    private lotsQuery: LotsQuery,
    private lotsService: LotsService,
    public dialog: MatDialog,
    public fielListService: FielListService,
    private readonly spinner: NgxSpinnerService,
    public lotEditQuery: LotEditQuery,
    private satellitalImageQuery: SatellitalImageQuery,
    private satellitalImageService: SatellitalImageService,
    private satImgDate: SatImageQuery,
    private satImgService: SatImageService,
    public lotEditService: LotEditService,
    public userQuery: UserQuery,
    private route: ActivatedRoute,
    private sharedLotsService: SharedLotsService,
    private imageProcessingService: ImageProcessingService,
    private datePipe: DatePipe,
    private activitiesService: ActivitiesService,
    private hereApiService: HereApiService,
    private weatherService: WeatherService,
    private apiService: MapService,
    private LatLonService: NominatimService,
    private mapsideservice: MapSidebarComService,
    private userService: UserService
    
  ) {
    //CALENDAR
    this.mapSidebarComService.miVariable$.subscribe((valor) => {
      this.service = valor;
      // console.log(this.service)
      if(this.service==2)
      {
        if(this.map!=undefined)
        {
          this.map.removeLayer(this.polylineGroup)
          this.map.removeLayer(this.markerGroup)
          if(this.group!=undefined)
          {
            this.group.clearLayers()
          }
          
        }
        this.display = false;
        // this.pointWayRouteTemp()
        // console.log(this.polylineGroup)
        // console.log(this.allTramos[0]['centroid']['latitude'],this.allTramos[0]['centroid']['altitude'])
        this.getDatabySegment(this.allTramos[0]['centroid']['latitude'],this.allTramos[0]['centroid']['altitude'])
      }
      if(this.service==1)
      {
        if(this.map!=undefined)
        {
          this.map.removeLayer(this.polylineGroup)
          this.map.removeLayer(this.markerGroup)
          if(this.group!=undefined)
          {
            this.group.clearLayers()
            // this.pointWayRouteTemp()
          }
        }
        
      }
    });

    this.updateCalendar(this.currentDate);
    this.weeks = this.getWeeksForMonth(this.currentDate);
    //END CALENDAR
    this.renderChart()
    this.satellitalImageQuery.selectSelectedResource.subscribe((satellitalState) => {
      this.removePresentedLayer();
      if (satellitalState.isSatellitalImageDisplayed) {
        if (this.activeLotSelectedId == satellitalState.idLotToBeDisplayed) {
          this.getDatesToFillButtons(satellitalState.satellitalImageType, true);
        } else {
          this.activeLotSelectedId = satellitalState.idLotToBeDisplayed;
          this.getDatesToFillButtons(satellitalState.satellitalImageType, false);
        }
      } else {
        this.activeLotSelectedId = undefined;
        this.datesResponse = undefined;
        this.presentedLayer = false;
        this.selectedDate = undefined;
      }
    });
    this.mapSidebarComService.getClickToCenter().subscribe(() => {
      this.fitBoundsToLayer();
    });

    this.mapSidebarComService.getClickToCenterField().subscribe(() => {
      this.fitBoundsToLayerField();
    });

    this.sharedLotsService.getSelectedLotShared().subscribe(() => {
      let lot = this.sharedLotsService.selectSharedLot;
      this.fitBoundsToLayer(lot);
    });

    this.mapSidebarComService.getClickEnableEdit().subscribe(() => {
      this.enableEditOfLotById(this.mapSidebarComService.lotid);
    });

    this.mapSidebarComService.getClickStopEdit().subscribe(() => {
      this.stopEditAndSaveOfLotById(
        this.mapSidebarComService.lotid,
        this.mapSidebarComService.name,
        this.mapSidebarComService.pheno,
      );
    });

    this.mapSidebarComService.getClickDeleteLot().subscribe(() => {
      this.deletePolygon(this.mapSidebarComService.lotid);
    });

    this.mapSidebarComService.getClickCreateLot().subscribe(() => {
      this.drawPolygon(false);
    });

    this.mapSidebarComService.getClickDeleteAllDrawedTemporalPolygons().subscribe(() => {
      this.clearAllDrawedLayers();
      this.tmp = undefined;
    });

    this.mapSidebarComService.getClickCancelEdit().subscribe(() => {
      this.cancelEditionPolygonAndReverse();
    });

    this.mapSidebarComService.getClickDrawIndex().subscribe(() => {
      this.showLotView();
    });

    this.mapSidebarComService.getClickDrawIndexComplete().subscribe(() => {
      this.showImageComplete();
    });

    this.suscriptions.push(
      this.fielListQuery.selectSelectedResource.subscribe(async (x) => {
        if (this.selectedModal == '4') {
          await this.updatePolygons();
        }
        this.selectedModal = x + '';
        if (!this.thereIsASelectedField) {
          if (this.selectedModal === '2') {
            this.showPolygons();
          } else {
            this.showPoints();
          }
        } else {
          if (this.selectedModal === '2') {
            var tmp = this.lotsQuery.getActiveId();
            this.pointsItems.eachLayer((layer) => {
              this.map.removeLayer(layer);
            });
            this.polygonItems.eachLayer((layer) => {
              if (layer['options']['className'] === tmp) {
                this.map.addLayer(layer);
              }
            });
          } else {
            var tmp = this.lotsQuery.getActiveId();
            this.polygonItems.eachLayer((layer) => {
              this.map.removeLayer(layer);
            });
            this.pointsItems.eachLayer((layer) => {
              if (layer['options']['alt'] === tmp) {
                this.map.addLayer(layer);
              }
            });
          }
        }
      }),
    );
    this.mapSidebarComService.getClickForDeselect().subscribe(() => {
      this.clearAllDrawedLayers();
      this.thereIsASelectedField = false;
      this.map.setMaxZoom(17);
      this.map.setZoom(15);
      if (this.selectedModal === '2') {
        this.showPolygons();
      } else {
        this.showPoints();
      }
    });
    this.distance = 0;
    this.initData();
    
  }

  async initData(){
    try {
      this.userInfo = await this.userService.loadUserDataN();
      // console.log('DATA INSIDE', this.userInfo);
      if (this.userInfo) {
        this.weatherService.getRetrieveTemp(this.userInfo.id).subscribe((rpt: any) => {
          // console.log(rpt);
          this.allTramos = rpt.results;
        });
      }

    } catch (error){
      console.error('ERROR', error)
    }
    // this.userInfo = this.userQuery.loadUserData().then((rpta)=>{
    //   this.userInfo= rpta;
    //   this.weatherService.getRetrieveTemp(this.userInfo.id).subscribe((rpt: any) => {
    //     console.log(rpt);
    //     this.allTramos = rpt.results;
    //   });
    // });
    // console.log('DATA INSIDE', this.userInfo)
  }
  
  ngOnInit() {
    // Puedes suscribirte a los cambios aquí si necesitas realizar alguna acción específica

  }

  ngOnDestroy(): void {
    this.satellitalImageService.setSatellitalImageDisplay(false);
    this.fieldsUiService.closeSidebar();
  }
  //SERVICE 2 TOOLS
  service2Expand1()
  {
    this.service2Tab=1;
    if(this.service2Tab==1)
    {
      var div1 = document.getElementById('right-panel') as HTMLElement;
      div1.style.width = "100%"
      var div2 = document.getElementById('bottom-panel2') as HTMLElement;
      div2.style.display = "none"
    }
  }

  service2Expand2()
  {
    this.option = 1
    this.display = true
    if(this.display == true)
    {
      var div1 = document.getElementById('right-panel') as HTMLElement;
      div1.style.width = "75%"
      this.getAllDatabyTramos(5)

      
    }
  
  }
  service2Expand3()
  {
    this.option = 2
    this.display = true
    if(this.display == true)
    {
      var div1 = document.getElementById('right-panel') as HTMLElement;
      div1.style.width = "75%"
      this.getAllDatabyTramos(16)
    }
  
  }
  closeExpand()
  {
    this.display = false
    if(this.display == false)
    {
      var div1 = document.getElementById('right-panel') as HTMLElement;
      div1.style.width = "100%"
      var div2 = document.getElementById('bottom-panel2') as HTMLElement;
      div2.style.display = "none"
    } 
  }

  //END SERVICE 2 TOOLS
  //CALENDAR
  getNextMonth() {

    const nextMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
    this.currentDate = nextMonth;
    this.weeks = this.getWeeksForMonth(this.currentDate);
  }

  getPreviousMonth() {

    const previousMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() - 1,
      1
    );
    this.currentDate = previousMonth;
    this.weeks = this.getWeeksForMonth(this.currentDate);

  }

  getWeeksForMonth(date: Date = new Date()) {
    const weeks = [];
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const offset = (firstDay.getDay() === 0 ? 7 : firstDay.getDay()) - 1;
    const startDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), 1 - offset);

    while (
      startDate.getFullYear() < date.getFullYear() ||
      (startDate.getFullYear() === date.getFullYear() && startDate.getMonth() <= date.getMonth())
    ) {
      const week = [];

      for (let i = 0; i < 7; i++) {
        if (startDate.getMonth() === date.getMonth() && startDate.getDate() <= lastDay) {
          week.push(startDate.getDate());
        } else {
          week.push(null);
        }

        startDate.setDate(startDate.getDate() + 1);
      }

      weeks.push(week);
    }

    return weeks;
  }

  toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
    this.tempbyDep();
    if(this.showCalendar == false)
    {
      this.clearLayersFromMap()
      this.clearAllDrawedLayers()
    }
    
  }

  updateCalendar(date: Date): void {
    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    this.currentMonth = monthNames[date.getMonth()] + ' ' + date.getFullYear();

    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const startingDay = firstDayOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)

    const daysArray = [];

    for (let i = 1; i <= 6 * 7; i++) {
      const day = i - startingDay;
      const currentMonth = (day > 0 && day <= daysInMonth);

      if (currentMonth) {
        daysArray.push({ day, currentMonth });
      } else {
        daysArray.push({ day: null, currentMonth });
      }
    }

    this.days = daysArray;
  }

  previousMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.updateCalendar(this.currentDate);
  }

  nextMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.updateCalendar(this.currentDate);
  }

  selectDate(day: number | null): void {
    this.selectedDay = day === this.selectedDay ? null : day;
    var selectDate = new Date (this.currentDate.getFullYear(), this.currentDate.getMonth(), day)
    console.log(this.formatearFecha(selectDate))
    this.tempbyDate(selectDate)
  }
  //END CALENDAR
  public async ngAfterViewInit() {
    this.initmap();
    // try {
    //   this.userInfo = await this.userService.loadUserDataN();
    //   console.log('DATA INSIDE', this.userInfo);
    //   if (this.userInfo) {
    //     this.weatherService.getRetrieveTemp(this.userInfo.id).subscribe((rpt: any) => {
    //       console.log(rpt);
    //       this.allTramos = rpt.results;
    //     });

    //     // this.weatherService.getForecast16byFront(-12.0464, -77.0428, this.userInfo.id).subscribe((rpt:any)=>{
    //     //   console.log(rpt)
    //     // });

    //     // this.weatherService.getForecastFromRoutePoint(-12.0464, -77.0428, this.userInfo.id).then((rpt:any)=>{
    //     //   console.log(rpt)
    //     // });
    //   }

    // } catch (error){
    //   console.error('ERROR', error)
    // }
    // this.weatherService.getRetrieveTemp().then((rpt:any)=>{
    //   console.log(rpt)
    //   this.allTramos = rpt.results;

      
    // })
    
    /*

    this.weatherService.getForecast16(-12.0464, -77.0428).subscribe((rpt:any)=>{
      console.log(rpt)
    })

    const otroContenedor = document.getElementById('tools');
    const controlLayers = document.querySelector('.leaflet-control-layers');
    if (otroContenedor && controlLayers) {
      console.log("XD")
      otroContenedor.appendChild(controlLayers); // Mueve el controlador de capas al otro contenedor
    }*/
    // console.log('USER ID ', this.userInfo.id)
    

    

    this.weatherService.getPlaceName(-12.0464, -77.0428).subscribe((rpt:any)=>{console.log(rpt)})
  
    const lotId = this.route.snapshot.paramMap.get('id');
    if (lotId == undefined) this.centerInTheBiggestLot();
    else {
      try {
        this.fitBoundsToLayer();
      } catch (error) {
        this.centerInTheBiggestLot();
      }
    }

  }

  public initmap(): void {
    //Calling to div that will contain the map and setting the point view
    this.map = L.map('fields-main-map').setView([-14.2350, -51.9253], 6).setMaxZoom(30);
    //Adding Google Maps Layer
    this.googleMapLayer = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      maxZoom: 30,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      noWrap: true,
      minZoom: 2,
    }).addTo(this.map);
    
    //Moving the zoom control from left to rigth
    this.map.zoomControl.remove();
    L.control.zoom({ position: 'topright' }).addTo(this.map);
    //Delete leflet attributtion
    /*this.map.attributionControl.setPrefix('');
    L.control.scale({ position: 'bottomright' }).addTo(this.map);
    this.listAllPolygonsOfServer();
    this.listRegistredImgDisease();
    fileLayer(null, L, ToGeojson);
    var g: any = L;
    */
    this.map.attributionControl.setPrefix('');
    L.control.scale({ position: 'bottomright' }).addTo(this.map);
    this.listAllPolygonsOfServer();
    this.listRegistredImgDisease();
    fileLayer(null, L, ToGeojson);
    var g: any = L;
    g.Control.FileLayerLoad.LABEL = '<img src="assets/images/my-fields/upload_small.svg">';


    this.map.on('draw:created', (e: any) => {
      const layer = e.layer;
      const latLngs = layer.getLatLngs()[0]; // Assuming it's a simple polygon
      latLngs.forEach((latLng: any) => {
        this.polygonCoordinates.push([latLng.lat, latLng.lng]);
      });
      console.log("POLYGON", this.polygonCoordinates)
    });

    this.map.on(L.Draw.Event.CREATED, this.onCreate.bind(this));
    this.map.on(L.Draw.Event.DRAWSTOP, this.onCanceled.bind(this));
    // this.uploadFileControler.loader.on('data:loaded', this.onUploadFile.bind(this));
    // this.uploadFileControler.loader.on('data:loaded', this.onUploadFile, this);
    // this.uploadFileControler.loader.on('data:loaded', (e) => {
    //   console.log('File loaded:', e);
    //   this.onUploadFile(e);
    // });
    L.drawLocal.draw.handlers.polygon = {
      tooltip: {
        start: 'Click en un punto para empezar a dibujar.',
        cont: 'Click para continuar dibujando la línea.',
        end: 'Click en el primer punto para finalizar la linea.',
      },
    };
    L.drawLocal.edit.handlers.edit = {
      tooltip: {
        text: 'Arrastre las manijas o marcadores para editar la figura.',
        subtext: 'Click cancel to undo changes.',
      },
    };

    // this.weatherService.getAirPollution(-9.2047259, -79.514827,1606223802,1606482999).subscribe((rpt:any)=>{
    //   console.log(rpt)
    // })
      // this.getPoints()
    
    //this.map.on('zoomend', this.resizePolygonBorder.bind(this));

    //SET WEATHER LAYER
    /*var temperatureLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    temperatureLayer.setOpacity(1.0);

    var cloudsLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    cloudsLayer.setOpacity(1.0);

    var windLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    windLayer.setOpacity(1.0);

    var precipitationLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    precipitationLayer.setOpacity(1.0);

    var pressureLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    pressureLayer.setOpacity(1.0);
    var baseLayers = {
      'Mapa Base': this.googleMapLayer,
    };

    var overlays = {
      'Mapa de Temperatura': temperatureLayer,
      'Mapa de Nubes': cloudsLayer,
      'Mapa de Vientos': windLayer,
      'Mapa de Precipitaciones': precipitationLayer,
      'Mapa de Presión': pressureLayer,
    };
    L.control.layers(baseLayers, overlays).addTo(this.map);*/
    //END SET WEATHER LAYER
    

  }


  public graphRoutes(){
    this.pointList = []
    this.series.data = []
    this.group = L.layerGroup().addTo(this.map);
    this.group.clearLayers();
    this.layerInCreation = 3;
    this.hideAll();
    let points: Array<LatLng> = [];
    let line = new L.Draw.Polygon(this.map);
    this.hereApiService.getRoutesViaPoints('truck','-9.980094,-63.028020','-9.920384,-62.174452',[],true).subscribe(
      (data:any) => {
        console.log('DATA FOR SOME POINTS R1', data)
        const new_Data = data.routes[0];
        const polylineList = [];
        const distanceList = [];
        const durantionList = [];
        const actionsList = [];
        const lineStringOffSetList = [];
        new_Data.sections.forEach((section:any)=>{
          const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          let linestringOffset = linestring.getLatLngAltArray();
          lineStringOffSetList.push(linestringOffset)
          polylineList.push(linestring);
          distanceList.push(section.travelSummary.length);
          durantionList.push(section.travelSummary.duration);
          actionsList.push(section.actions);
        });

        this.total_distance = new_Data.sections[0].travelSummary.length/1000;
        this.pointList = [];
        var location = {
          "locations":[]
        }
        let cont = 0;
        polylineList.forEach((line, index)=>{
          let new_list_latlong = this.transformCoordinates(line['$']);
          const leafletCoordinates: L.LatLngExpression[] = new_list_latlong.map(
              (coord) => [coord[0], coord[1]] as L.LatLngExpression,
          );
          const distance_separator = this.ruleSeparation(distanceList[index]);
          const resultPoints = this.calculateResultPoints(leafletCoordinates, actionsList[index], distance_separator,1);
          this.createTempMarkers(resultPoints,actionsList[index],lineStringOffSetList[index]);

          let colores = [
            'rgba(218, 68, 71, 0.7)',
            'rgba(245, 128, 255, 0.7)',
            'rgba(48, 93, 185, 0.8)',
            'rgba(88, 223, 255, 0.7)',
            'rgba(159, 1, 66, 0.7)',
            'rgba(218, 118, 68, 0.7)',
            'rgba(112, 150, 36, 0.7)',
            'rgba(20, 175, 185, 0.7)',
            'rgba(255, 193, 7, 0.7)',
            'rgba(63, 81, 181, 0.7)',
            'rgba(233, 30, 99, 0.7)',
            'rgba(255, 87, 34, 0.7)',
            'rgba(0, 188, 212, 0.7)',
            'rgba(103, 58, 183, 0.7)',
            'rgba(139, 195, 74, 0.7)',
            'rgba(255, 152, 0, 0.7)',
            'rgba(121, 85, 72, 0.7)',
            'rgba(255, 235, 59, 0.7)',
            'rgba(33, 150, 243, 0.7)',
          ];
          console.log('INDEXBF', index)
         
          this.createPolylines(resultPoints, colores, index, this.distance_data);

        });

      },
      (error) => {
        console.error('Error fetching routes:', error);
      },
    );
    console.log("RUTA 2")
    // #ROUTE 2
    this.hereApiService.getRoutesViaPoints('truck','-10.688823,-62.268864','-10.906343,-61.929030',[],true).subscribe(
      (data:any) => {
        console.log('DATA RUTA 2 ', data)
        const new_Data = data.routes[0];
        const polylineList = [];
        const distanceList = [];
        const durantionList = [];
        const actionsList = [];
        const lineStringOffSetList = [];
        new_Data.sections.forEach((section:any)=>{
          const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          let linestringOffset = linestring.getLatLngAltArray();
          lineStringOffSetList.push(linestringOffset)
          polylineList.push(linestring);
          distanceList.push(section.travelSummary.length);
          durantionList.push(section.travelSummary.duration);
          actionsList.push(section.actions);
        });

        this.total_distance = new_Data.sections[0].travelSummary.length/1000;
        this.pointList = [];
        var location = {
          "locations":[]
        }
        let cont = 0;
        polylineList.forEach((line, index)=>{
          let new_list_latlong = this.transformCoordinates(line['$']);
          const leafletCoordinates: L.LatLngExpression[] = new_list_latlong.map(
              (coord) => [coord[0], coord[1]] as L.LatLngExpression,
          );
          const distance_separator = this.ruleSeparation(distanceList[index]);
          const resultPoints = this.calculateResultPoints(leafletCoordinates, actionsList[index], distance_separator,2);
          this.createTempMarkers(resultPoints,actionsList[index],lineStringOffSetList[index]);

          let colores = [
            'rgba(159, 1, 66, 0.7)',
            'rgba(218, 118, 68, 0.7)',
            'rgba(112, 150, 36, 0.7)',
            'rgba(20, 175, 185, 0.7)',
            'rgba(255, 193, 7, 0.7)',
            'rgba(63, 81, 181, 0.7)',
            'rgba(233, 30, 99, 0.7)',
            'rgba(255, 87, 34, 0.7)',
            'rgba(0, 188, 212, 0.7)',
            'rgba(103, 58, 183, 0.7)',
            'rgba(139, 195, 74, 0.7)',
            'rgba(255, 152, 0, 0.7)',
            'rgba(121, 85, 72, 0.7)',
            'rgba(255, 235, 59, 0.7)',
            'rgba(33, 150, 243, 0.7)',
          ];
          console.log('INDEXBF', index)
         
          this.createPolylines(resultPoints, colores, index, this.distance_data1);

        });

      },
      (error) => {
        console.error('Error fetching routes:', error);
      },
    );
    console.log("RUTA 3")
    // ROUTE 3  
    this.hereApiService.getRoutesViaPoints('truck','-9.669405,-63.097384','-9.248923,-63.165589',[],true).subscribe(
      (data:any) => {
        console.log('DATA FOR SOME POINTS R3', data)
        const new_Data = data.routes[0];
        const polylineList = [];
        const distanceList = [];
        const durantionList = [];
        const actionsList = [];
        const lineStringOffSetList = [];
        new_Data.sections.forEach((section:any)=>{
          const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          let linestringOffset = linestring.getLatLngAltArray();
          lineStringOffSetList.push(linestringOffset)
          polylineList.push(linestring);
          distanceList.push(section.travelSummary.length);
          durantionList.push(section.travelSummary.duration);
          actionsList.push(section.actions);
        });

        this.total_distance = new_Data.sections[0].travelSummary.length/1000;
        this.pointList = [];
        var location = {
          "locations":[]
        }
        let cont = 0;
        polylineList.forEach((line, index)=>{
          let new_list_latlong = this.transformCoordinates(line['$']);
          const leafletCoordinates: L.LatLngExpression[] = new_list_latlong.map(
              (coord) => [coord[0], coord[1]] as L.LatLngExpression,
          );
          const distance_separator = this.ruleSeparation(distanceList[index]);
          const resultPoints = this.calculateResultPoints(leafletCoordinates, actionsList[index], distance_separator,3);
          this.createTempMarkers(resultPoints,actionsList[index],lineStringOffSetList[index]);

          let colores = [
            'rgba(103, 58, 183, 0.7)',
            'rgba(139, 195, 74, 0.7)',
            'rgba(255, 152, 0, 0.7)',
            'rgba(121, 85, 72, 0.7)',
            'rgba(255, 235, 59, 0.7)',
            'rgba(33, 150, 243, 0.7)',
          ];
          console.log('INDEXBF', index)
         
          this.createPolylines(resultPoints, colores, index, this.distance_data2);

        });

      },
      (error) => {
        console.error('Error fetching routes:', error);
      },
    );
    document.addEventListener('keydown', (event) => {
      if (event.keyCode == 27) {
        this.tab=0
        this.map.eachLayer(function (layer) {
          this.map.removeLayer(layer);
      });
      }
    });
  }

  

  public centerInTheBiggestLot() {
    try {
      const allLots = this.lotsQuery.getAll();
      var selectedLot = undefined;
      var area = 0;
      for (let index = 0; index < allLots.length; index++) {
        const element = allLots[index];
        if (element.area > area) {
          selectedLot = element;
          area = element.area;
        }
      }
      if (selectedLot?.coordinates) {
        this.map.fitBounds(selectedLot.coordinates);
        this.map.setZoom(15);
      }
    } catch (error) {
      console.log(error);
    }
  }
  onUploadFile(e) {
    console.log('Upload event:', e)
    try {
      console.log("FILE")
      this.pointWayRouteTemp();
      if (this.layerInCreation == 0) {
        this.pointWayRouteTemp();
      //   //Adding new polygon to map
      //   this.drawnItems.addLayer(e.layer);
      //   this.drawnItems.addTo(this.map);
      //   this.layerInCreation = 1;
      //   this.mainPageComponent.showFieldList = false;
      //   this.fieldsUiService.closeSidebar();
      //   var area = 0;
      //   var change = L.featureGroup();
      //   change.addLayer(e.layer);
      //   var polygonToGeoJson = change.toGeoJSON();
      //   var latList = [];
      //   polygonToGeoJson['features'][0]['geometry']['coordinates'][0].forEach((element) => {
      //     latList.push(new L.LatLng(element[1], element[0]));
      //   });
      //   area = L.GeometryUtil.geodesicArea(latList);
      //   area = Math.round(area * 0.0001 * 100) / 100;
      //   const dialogRef = this.dialog.open(DialogConfirmationLot, {
      //     data: {
      //       areaTotal: area,
      //       modified: false,
      //     },
      //   });

      //   dialogRef.afterClosed().subscribe((result) => {
      //     if (result) {
      //       this.addLotToLayer(e, area);
      //     }

      //     if (result === false) {
      //       this.mainPageComponent.showFieldList = true;
      //       this.layerInCreation = 0;
      //       this.clearAllDrawedLayers();
      //     }
      //     this.layerInCreation = 0;
      //   });
      }
    } catch (error) {
      console.log(error);
    }
  }
  public displayPolygon(idClass, colorField, obj, style) {
    /*
			Para dibujar require cambiar las posiciones de latitud longitud
		*/
    var a = JSON.stringify(obj);
    var polygon = L.polygon(JSON.parse(a), {
      fillOpacity: 0,
      color: colorField,
      dashArray: '0',
      className: idClass,
    })
      .on('click', this.changeToSelectedPolygon.bind(this))
      .setStyle(style);
    polygon.addTo(this.polygonItems); //.bindPopup('<b>La parcela "' + lotName + '" tiene:</b><br>' + area + ' ha');
  }

  public addMarkerToPointItems(lotId, color: String, coords) {
    var a = JSON.stringify(coords);
    var polygon = L.polygon(JSON.parse(a), {
      weight: 8,
      fillOpacity: 0,
      dashArray: '0',
    });
    const center = polygon.getBounds().getCenter();
    const realColor = color.replace('#', '');
    var newIcon = new L.Icon({
      iconUrl: 'assets/images/my-fields/marker-icons/location-pointer-' + realColor + '.svg', //'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-' + color + '.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [60, 60],
      popupAnchor: [1, -42],
      shadowSize: [42, 42],
    });

    const mark_1 = L.marker(center, {
      icon: newIcon,
      alt: lotId,
    }).on('click', this.changeToSelectedPointer.bind(this));
    mark_1.addTo(this.pointsItems);
  }

  public changeToSelectedPolygon(e) {
    const lotId = e.target.options.className;
    this.displayWithId(lotId);
  }

  public changeToSelectedPointer(e) {
    const lotId = e.target.options.alt;
    this.displayWithId(lotId);
  }
  public displayWithId(lotId) {
    this.lotEditService.setSelectedResource(0);
    this.lotsService.setActiveLot(lotId);
    this.mapSidebarComService.sentClickToCenter();
    this.fieldsUiService.setSelectedResource(3);
    this.fieldsUiService.openSidebar();
    this.satellitalImageService.setLotIdForSatellitalImage(lotId);
  }

  private listRegistredImgDisease() {
    this.userQuery.selectUserId.subscribe((user) => {
      if (user) {
        this.user = this.userQuery.getInformation();
        if (this.user.namePlanType === infoagroPlans.PLAN_PLATA) {
          this.imageProcessingService.getAllRegistredDiseases(this.user?.id).subscribe((diseases: any[]) => {
            const data = diseases || [];
            data.map((item: any) => {
              const { latitude, longitude, id } = item;
              if (latitude && longitude) {
                try {
                  this.addCameraMarker(latitude, longitude, id);
                } catch (error) {
                  console.log('Check that lat and long have the correct format');
                }
              }
            });
            this.diseaseItems.addTo(this.map);
          });
        }
      }
    });
  }

  private addCameraMarker(latitude: string, longitude: string, id: string) {
    const marker = L.marker([parseFloat(latitude), parseFloat(longitude)], {
      icon: cameraIcon,
      alt: id,
    }).on('click', this.openCameraDisease.bind(this));
    marker.addTo(this.diseaseItems);
  }

  private openCameraDisease(e: any) {
    const id = e.target.options.alt;
    this.showDialogForDisease({ id, userId: this.user.id });
  }

  private showDialogForDisease(data: { id: string; userId: string }) {
    const dialog = this.dialog.open(DiseaseInformationDialog, {
      panelClass: ['no-padding', 'is-transparent'],
      width: '600px',
      height: '450px',
      data,
    });
    dialog.afterClosed().subscribe((response) => {
      if (response === 'CREATE_ACTIVITY') {
        // TODO: crear actividad
        this.openDialogToSelectLot(data.id);
      }
    });
  }

  public openDialogToSelectLot(idProcessedImg: string) {
    const dialogSelectLot = this.dialog.open(DialogSelectLot, {
      width: '600px',
      height: '275px',
      panelClass: ['no-padding', 'is-transparent'],
    });

    dialogSelectLot.afterClosed().subscribe((event) => {
      if (event.result === 'LOT_SELECTED') {
        const { lotId, campaign } = event.data;
        this.createActivity(lotId, campaign, idProcessedImg);
      }
    });
  }

  public createActivity(lotId: string, camp_id: string, idProcessedImg: string) {
    let activity = {
      camp_id: lotId,
      season: camp_id,
      activity: {
        name: '',
        phase: '',
        start_date: '',
        end_date: '',
        hour: 0,
        workday: 0,
        duration: 0,
        description: '',
        detail: [],
      },
    };
    this.openDialog(activity, idProcessedImg);
  }

  openDialog(activity: any, idProcessedImg: string): void {
    let data = JSON.parse(JSON.stringify(activity));
    const dialogRef = this.dialog.open(DialogActivityComponent, {
      width: '600px',
      height: '540px',
      disableClose: true,
      panelClass: 'no-padding',
      data: {
        name: data.activity.name,
        phase: data.activity.phase,
        detail: data.activity.detail,
        start: data.activity.start_date,
        end: data.activity.end_date,
        workingDay: data.activity.workday,
        duration: data.activity.duration,
        calendar: data.activity.schedule_setting,
        description: data.activity.description,
      },
    });

    dialogRef.afterClosed().subscribe((dataRecieve) => {
      if (dataRecieve.response == 'save') {
        data.activity.phase = `${dataRecieve.phase}|${idProcessedImg}`;
        data.activity.name = dataRecieve.name;
        data.activity.start_date = dataRecieve.start;
        data.activity.end_date = dataRecieve.end;
        data.activity.workday = dataRecieve.workingDay;
        data.activity.duration = dataRecieve.duration;
        data.activity.description = dataRecieve.description;
        data.activity.detail = [];
        dataRecieve.detail.forEach((detail) => {
          data.activity.detail.push({
            resource: detail.resource,
            percentage: detail.percentage,
            cost: '1',
            hour: 0,
            type: detail.type,
            id: detail.id != null ? detail.id : undefined,
          });
        });
        data.activity.start_date = this.datePipe.transform(data.activity.start_date, 'yyyy-MM-dd');
        data.activity.end_date = this.datePipe.transform(data.activity.end_date, 'yyyy-MM-dd');
        data.activity.schedule_setting = dataRecieve.calendar;
        if (data.id == undefined) {
          this.activitiesService.createOne([data]);
        } else {
          this.activitiesService.updateActivities([data]);
        }
      }
    });
  }

  private clearLayersFromMap() {
    this.map.eachLayer((layer) => {
      if (layer && layer !== this.googleMapLayer) {
        this.map.removeLayer(layer);
      }
    });
  }
  //DATA POR TRAMOS
  public getDatabySegment(lat:number, long:number)
  {
    this.weatherService.getWeather(lat,long, this.userInfo.id).subscribe((rpt:any)=>{
      this.mainTemp = parseInt(this.allTramos[0]['centroid']['weather'][0]['temperature']);
      this.clouds = this.allTramos[0]['centroid']['weather'][0]['nubosity']
      this.winds = this.allTramos[0]['centroid']['weather'][0]['wind']
      this.precipitation = this.allTramos[0]['centroid']['weather'][0]['temperature']
      this.pressure = this.allTramos[0]['centroid']['weather'][0]['pressure']
      this.mainSrc = "https://openweathermap.org/img/wn/"+this.allTramos[0]['centroid']['weather'][0]['icon']+"@2x.png"
    })

    this.weatherService.getForecast(lat,long, this.userInfo.id).subscribe((rpt:any)=>{
      //EL SERVICIO NO ARROJA DATA
      for(var i = 0; i<10;i++)
      {
        var element = {
          hora: "",
          src: "",
          maxt:0,
          mint:0,
          estado:"",
          prec:0
        }
        console.log(rpt)
        var hora = rpt.results[i]?.dt_txt.split('T')
        hora = hora[1].split('Z')
        hora = hora[0]
        element.hora = hora
        element.src = "https://openweathermap.org/img/wn/"+rpt.results[i].icon+"@2x.png"
        element.maxt = Math.trunc(rpt.results[i].temp_max)
        element.mint = Math.trunc(rpt.results[i].temp_min)
        element.estado = rpt.results[i].description
        element.prec = rpt.results[i].wind_speed
        this.dataByHours.push(element)
      }
      console.log(this.dataByHours)
      this.seriesMidChart[0].data = []
      this.seriesMidChart[1].data = []
      this.seriesMidChart[2].data = []
      this.allSeries[0].data = []
      this.allSeries[1].data = []
      this.allSeries[2].data = []
      this.labels = []
      for(var i = 0; i<10; i++)
      {
        this.seriesMidChart[0].data.push(this.dataByHours[i]['prec'])
        this.seriesMidChart[1].data.push(this.dataByHours[i]['maxt'])
        this.seriesMidChart[2].data.push(this.dataByHours[i]['mint'])
        this.allSeries[0].data.push(this.dataByHours[i]['prec'])
        this.allSeries[1].data.push(this.dataByHours[i]['maxt'])
        this.allSeries[2].data.push(this.dataByHours[i]['mint'])
        this.labels.push(this.dataByHours[i]['hora'])
      }
      
    })
  }

  public isPuntoDentroDeBounds(punto: L.LatLng, bounds: L.LatLngBounds): boolean {
    return bounds.contains(punto);
  }

  public eliminarUltimoPolyline() {
    console.log(this.polylineGroup)
    this.polylineGroup.eachLayer((layer: any) => {
           
      console.log(layer)
     
        
          if(layer['options']['color']=='green')
          {
            this.polylineGroup.removeLayer(layer)
          }
         

      
    
   
  });
  }

  public dataBySegment(){
    this.eliminarUltimoPolyline()
    this.dataByHours = []
    var selector = document.getElementById("segment-selector") as HTMLSelectElement;
    for(var i = 0; i<this.allTramos.length;i++)
    {
      if(selector.value == this.allTramos[i]['name'])
      {

          var coordinates = [this.allTramos[i]['centroid']['latitude'],this.allTramos[i]['centroid']['altitude']]
          var latLng = new L.LatLng(coordinates[0], coordinates[1]);
          this.map.setView(latLng, 25);
          console.log(latLng)
          console.log(this.polylineGroup)
          this.polylineGroup.eachLayer((layer: any) => {
           
              console.log(layer)
              if(layer._layers)
              {
                Object.keys(layer._layers).forEach((clave:any) => {
                  if(this.isPuntoDentroDeBounds(latLng,layer._layers[clave].getBounds()))
                  {
                   //layer['_layers'][clave].setStyle({ color: 'green' });
                   var duplicatedPolyline = L.polyline(layer._layers[clave].getLatLngs(), { color: 'green' });
                   this.polylineGroup.addLayer(duplicatedPolyline);
                   
                  }
               });
              }

     
              
            
           
          });
          this.mainTemp = parseInt(this.allTramos[i]['centroid']['weather'][0]['temperature']);
          this.clouds = this.allTramos[i]['centroid']['weather'][0]['nubosity']
          this.winds = this.allTramos[i]['centroid']['weather'][0]['wind']
          this.precipitation = this.allTramos[i]['centroid']['weather'][0]['temperature']
          this.pressure = this.allTramos[i]['centroid']['weather'][0]['pressure']
          this.mainSrc = "https://openweathermap.org/img/wn/"+this.allTramos[i]['centroid']['weather'][0]['icon']+"@2x.png"

        this.weatherService.postForecast(this.allTramos[i]['centroid']['latitude'],this.allTramos[i]['centroid']['altitude'],this.userInfo.id).subscribe((rpt:any)=>{
          console.log("SUCCESS FORECAST")
        })
        this.weatherService.getForecast(this.allTramos[i]['centroid']['latitude'],this.allTramos[i]['centroid']['altitude'], this.userInfo.id).subscribe((rpt:any)=>{
          for(var i = 0; i<10;i++)
          {
            var element = {
              hora: "",
              src: "",
              maxt:0,
              mint:0,
              estado:"",
              prec:0
            }
            var hora = rpt.results[i]?.dt_txt.split('T')
            hora = hora[1].split('Z')
            hora = hora[0].split(':')
            hora = hora[0]+':00'
            console.log(hora)
            element.hora = hora
            element.src = "https://openweathermap.org/img/wn/"+rpt.results[i].icon+"@2x.png"
            element.maxt = Math.trunc(rpt.results[i].temp_max)
            element.mint = Math.trunc(rpt.results[i].temp_min)
            element.estado = rpt.results[i].description
            element.prec = rpt.results[i].wind_speed
            this.dataByHours.push(element)
          }
          console.log(this.dataByHours)
          this.seriesMidChart[0].data = []
          this.seriesMidChart[1].data = []
          this.seriesMidChart[2].data = []
          this.allSeries[0].data = []
          this.allSeries[1].data = []
          this.allSeries[2].data = []
          this.labels = []
          for(var i = 0; i<10; i++)
          {
            this.seriesMidChart[0].data.push(this.dataByHours[i]['prec'])
            this.seriesMidChart[1].data.push(this.dataByHours[i]['maxt'])
            this.seriesMidChart[2].data.push(this.dataByHours[i]['mint'])
            this.allSeries[0].data.push(this.dataByHours[i]['prec'])
            this.allSeries[1].data.push(this.dataByHours[i]['maxt'])
            this.allSeries[2].data.push(this.dataByHours[i]['mint'])
            this.labels.push(this.dataByHours[i]['hora'])
          }
          
        })
        break;
      }
    }




    


  }
  //END DATA POR TRAMOS
  //GET ALL DATA BY TRAMOS
  public getAllDatabyTramos(tam: number) {
    this.tramos = this.allTramos;
    console.log(this.tramos.length)
    this.itemschartloading = true
    this.allData = []
    this.allLabels = []
    const observables = this.tramos.map(tramo =>
      forkJoin([
        this.weatherService.postForecast16(tramo['centroid']['latitude'], tramo['centroid']['altitude'],  this.userInfo.id),
        this.weatherService.getForecast16(tramo['centroid']['latitude'], tramo['centroid']['altitude'],  this.userInfo.id)
      ])
    );
      
    forkJoin(observables).subscribe((responses: any[]) => {
      console.log(responses)
      this.itemschartloading = false
      responses.forEach((response, index) => {
        // console.log(index)
        // console.log("RESPONSE")
        // console.log(response)
        // console.log("RESPONSE[0]")
        // console.log(response[0])
        // console.log("RESPONSE[0][INDEX]")
        // console.log(response[0][index])
        // console.log("RESPONSE[1]")
        // console.log(response[0])
        // console.log("RESPONSE[1][INDEX]")
        // console.log(response[1][index])
        const rpt = response[0]; // Obtener el resultado de la segunda llamada (getForecast16byFront)
        const dataLabel = [];
        const listElement = [
          { name: "Precipitación", type: "column", data: [] },
          { name: "Temp. Alta", type: "area", data: [] },
          { name: "Temp. Baja", type: "area", data: [] }
        ];
  
        for (let j = 0; j < tam; j++) {

          const day = new Date(rpt[j]['dt'] * 1000);
          const fecha = this.formatearFecha(day);
          dataLabel.push(fecha);
          listElement[0].data.push(rpt[j].rain || 0);
          listElement[1].data.push(Math.trunc(rpt[j]['temp_max'] - 273));
          listElement[2].data.push(Math.trunc(rpt[j]['temp_min'] - 273));
        }
  
        this.allData[index] = listElement;
        this.allLabels.push(dataLabel);
        console.log(this.allData)
      });
      // Renderizar gráficos después de que todas las llamadas asíncronas se completen
      // ...
    });
  }
  public timestampToDate(timestamp) {
    const date = new Date(timestamp);
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

 public kelvinToCelsius(kelvin) {
    return kelvin - 273.15;
  }
  //END GET ALL DATA BY TRAMOS
  public listAllPolygonsOfServer() {
    /*
			Trae todos los poligonos del usuario y los agrega al mapa
		*/
    const myObserver = {
      next: (fields: any) => {
        if (fields?.length) {
          this.polygonItems.clearLayers();
          this.pointsItems.clearLayers();
          this.clearLayersFromMap();
          if (this.selectedModal !== '4') this.allFields = fields;
          fields.forEach((field) => {
            if (field?.lots?.length > 0) {
              for (let index = 0; index < field['lots'].length; index++) {
                const lot = field['lots'][index];
                this.displayPolygon(lot.id, field.color, lot['coordinates'], this.style);
                this.addMarkerToPointItems(lot.id, field.color, lot['coordinates']);
              }
            }
          });
          this.selectedModal === '2' ? this.showPolygons() : this.showPoints();
          this.diseaseItems.addTo(this.map);
        }
      },
      error: (err: any) => console.log(err),
    };

    this.fieldObserver = this.fields$.subscribe(myObserver);
    this.sharedLotsService.getUpdateSharedFields().subscribe(myObserver);
  }

  public toogleShowDiseasePhotos() {
    this.showDiseaseRecognitionPhotos = !this.showDiseaseRecognitionPhotos;
    if (this.showDiseaseRecognitionPhotos) this.diseaseItems.addTo(this.map);
    else this.map.removeLayer(this.diseaseItems);
  }

  public drawPolygon(noFieldSelected) {
    this.layerInCreation = 0;
    this.hideAll();
    this.map.on(L.Draw.Event.CREATED, this.onCreate.bind(this));
    this.fieldsUiService.closeSidebar();
    if (noFieldSelected) this.fieldsService.setActiveField(undefined);
    this.mainPageComponent.showFieldList = false;
    this.satellitalImageService.setSatellitalImageDisplay(false);
    const polygonDrawer = new L.Draw.Polygon(this.map);
    polygonDrawer.setOptions({ allowIntersection: false });
    polygonDrawer.setOptions({ shapeOptions: { color: 'purple', weight: 3, dashArray: '6, 6', dashOffset: '0' } });
    polygonDrawer.enable();
    // this.tab = 2;
    // this.layerInCreation = 0;
    // this.hideAll();
    // this.map.on(L.Draw.Event.CREATED, this.onCreate.bind(this));
    // this.fieldsUiService.closeSidebar();
    // if (noFieldSelected) this.fieldsService.setActiveField(undefined);
    // this.mainPageComponent.showFieldList = false;
    // this.satellitalImageService.setSatellitalImageDisplay(false);
    // this.drawnItems.clearLayers();

    // if (this.drawControl) {
    //   this.map.removeControl(this.drawControl);
    // }
    // const drawControl = new L.Control.Draw({
    //   draw: {
    //     polygon: {
    //       shapeOptions: {
    //         color: 'purple',
    //         weight: 3,
    //         dashArray: '6, 6',
    //         dashOffset: '0'
    //       },
    //       allowIntersection: false
    //     },
    //     polyline: false,
    //     rectangle: false,
    //     circle: false
    //   },
    //   edit: {
    //     featureGroup: this.drawnItems
    //   }
    // });
    
    // this.drawControl = drawControl;
    // // this.map.removeControl(drawControl);
    // this.map.addControl(this.drawControl);
  
    // this.map.on(L.Draw.Event.CREATED, this.onCreate.bind(this));
    // const polygonDrawer = new L.Draw.Polygon(this.map);
    // polygonDrawer.setOptions({ allowIntersection: false });
    // polygonDrawer.setOptions({ shapeOptions: { color: 'purple', weight: 3, dashArray: '6, 6', dashOffset: '0' } });
    // polygonDrawer.enable();
    //const polygonEdit = new L.Edit.SimpleShape()
  }
  //refreshDistanceAndLength
  public comparelatlng(point1: LatLng, point2: LatLng) {
    if (point1.lat * 100000 === point2.lat * 100000 && point1.lng * 100000 === point2.lng * 100000) {
      return true;
    } else {
      return false;
    }
  }
  //refreshDistanceAndLength
  //Regla
  public rule() {
    this.tab = 3;
    this.layerInCreation = 2;
    this.distance = 0;
    let points: Array<LatLng> = [];
    let line = new L.Draw.Polygon(this.map);
    line.setOptions({ showLength: true });
    line.setOptions({ shapeOptions: { color: 'yellow', weight: 3, dashArray: '4, 4', dashOffset: '0' } });
    line.enable();
    this.map.on('click', (e: { latlng: LatLng }) => {
      points.push(e.latlng);
      if (points.length > 1) {
        for (let i = 0; i < points.length - 1; i++) {
          this.distance = this.distance + this.map.distance(points[i], points[i + 1]);
          this.distance = Math.round(this.distance);
        }
      }
    });
    document.addEventListener('keydown', (event) => {
      if (event.keyCode == 27) {
        points = [];
      }
    });
  }
  //End Regla
  public updatePolygons() {
    this.polygonItems.clearLayers();
    this.pointsItems.clearLayers();
    this.map.eachLayer((layer) => {
      if (layer && layer !== this.googleMapLayer) {
        this.map.removeLayer(layer);
      }
    });
    let x = this.allFields;
    x.forEach((field) => {
      if (field['lots'] != undefined && field['lots'].length > 0) {
        for (let index = 0; index < field['lots'].length; index++) {
          const lot = field['lots'][index];
          this.displayPolygon(lot.id, field['color'], lot['coordinates'], this.style);
          this.addMarkerToPointItems(lot.id, field['color'], lot['coordinates']);
        }
      }
    });
  }

  transformCoordinates(inputArray: number[]): number[][] {
    const result: number[][] = [];
    let currentPair: number[] = [];

    for (const value of inputArray) {
      if (value !== 0) {
        currentPair.push(value);
        if (currentPair.length === 2) {
          result.push(currentPair);
          currentPair = [];
        }
      }
    }

    return result;
  }

  ruleSeparation(distance: number) {
    let result = 1000000;
    const valueRanges = [
      { min: 100, max: 500, value: 20 },
      { min: 501, max: 100, value: 50 },
      { min: 1001, max: 5000, value: 200 },
      { min: 5001, max: 10000, value: 500 },
      { min: 10001, max: 50000, value: 2000 },
      { min: 50001, max: 100000, value: 5000 },
      { min: 100001, max: 500000, value: 20000 },
      { min: 500001, max: 1000000, value: 50000 },
      { min: 1000001, max: 5000000, value: 200000 },
      { min: 5000001, max: 10000000, value: 500000 },
      { min: 10000001, max: 50000000, value: 200000 },
      { min: 50000001, max: 100000000, value: 500000 },
    ];
    for (const range of valueRanges) {
      if (distance >= range.min && distance < range.max) {
        result = range.value;
        break;
      }
    }
    return result;
  }

  public getPoints(){
    const locations_1 = ['MOLLENDO', 'AREQUIPA', 'YURA' ,'CONDOROMA', 'ESPINAR']
    locations_1.forEach((elem:string)=>{
      this.LatLonService.getLatLong(elem).subscribe(
        (rpta)=>{
          this.arraysOfCentroids.push([rpta[0].lat,rpta[0].lon])
          console.log(elem,rpta)
        }
      )
    })
    const api_normatin = 'https://nominatim.openstreetmap.org/search?q=fiscal%2C%20Arequipa&polygon_geojson=1&format=jsonv2'
  }

  public pointWayRoute() {
    // this.getPoints()
    this.tab = 1;
    this.pointList = []
    this.series.data = []
    this.group = L.layerGroup().addTo(this.map);
    this.hideAll();
    let points: Array<LatLng> = [];
    let line = new L.Draw.Polygon(this.map);
    this.hereApiService.getRoutesViaPoints('truck','-17.02306,-72.01472', '-14.9253900,-71.3528700',['-16.3988900,-71.5350000','-16.2522300,-71.6796900','-15.3003,-71.1381'],true).subscribe(
      (data:any) => {
        console.log('DATA FOR SOME POINTS', data)
        const new_Data = data.routes[0];
        const polylineList = [];
        const distanceList = [];
        const durantionList = [];
        const actionsList = [];
        const lineStringOffSetList = [];
        new_Data.sections.forEach((section:any)=>{
          const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          let linestringOffset = linestring.getLatLngAltArray();
          lineStringOffSetList.push(linestringOffset)
          polylineList.push(linestring);
          distanceList.push(section.travelSummary.length);
          durantionList.push(section.travelSummary.duration);
          actionsList.push(section.actions);
        });

        this.total_distance = new_Data.sections[0].travelSummary.length/1000;
        this.pointList = [];
        var location = {
          "locations":[]
        }
        let cont = 0;
        polylineList.forEach((line, index)=>{
          let new_list_latlong = this.transformCoordinates(line['$']);
          const leafletCoordinates: L.LatLngExpression[] = new_list_latlong.map(
              (coord) => [coord[0], coord[1]] as L.LatLngExpression,
          );
          const distance_separator = this.ruleSeparation(distanceList[index]);
          const resultPoints = this.calculateResultPoints(leafletCoordinates, actionsList[index], distance_separator, 1);
          this.createMarkers(resultPoints,actionsList[index],lineStringOffSetList[index]);

          let colores = [
            'rgba(218, 68, 71, 0.7)',
            'rgba(245, 128, 255, 0.7)',
            'rgba(48, 93, 185, 0.8)',
            'rgba(88, 223, 255, 0.7)',
            'rgba(159, 1, 66, 0.7)',
            'rgba(218, 118, 68, 0.7)',
            'rgba(112, 150, 36, 0.7)',
            'rgba(20, 175, 185, 0.7)',
            'rgba(255, 193, 7, 0.7)',
            'rgba(63, 81, 181, 0.7)',
            'rgba(233, 30, 99, 0.7)',
            'rgba(255, 87, 34, 0.7)',
            'rgba(0, 188, 212, 0.7)',
            'rgba(103, 58, 183, 0.7)',
            'rgba(139, 195, 74, 0.7)',
            'rgba(255, 152, 0, 0.7)',
            'rgba(121, 85, 72, 0.7)',
            'rgba(255, 235, 59, 0.7)',
            'rgba(33, 150, 243, 0.7)',
          ];
          
          if (index===0){
            cont+=1;
          }
          this.createPolylines(resultPoints, colores, index, this.distance_data);
          const polyline = L.polyline(leafletCoordinates, {
            color: 'rgba(0, 128, 255, 0.7)',
            weight: 12,
          })
          
          
  
          const intermediatePoints = this.dividirPolylineEnSegmentos(polyline,20000);

          for(var i = 0; i<intermediatePoints.length;i++)
          {
            var element = {
              "latitude":intermediatePoints[i]['lat'],
              "longitude":intermediatePoints[i]['lng']
            }
            this.pointList.push(element)
          }


        });
        location.locations=this.pointList
        //FALTA REHACER ESTE SERVICIO PARA EL MANEJO DE ELEVACION
        console.log(location)
         this.apiService.postApi(location).subscribe((rpt:any)=>{
          console.log(rpt.results.length)
          this.handleElevations(rpt.results)
         //this.series.data = []
         console.log(this.series)
         setTimeout(() => {
            this.renderChart()
            this.loading = false
          }, 2000);
         });
         this.handleWeatherElevation();
      },
      (error) => {
        console.error('Error fetching routes:', error);
      },
    );
    document.addEventListener('keydown', (event) => {
      if (event.keyCode == 27) {
        this.tab=0
        this.map.eachLayer(function (layer) {
          this.map.removeLayer(layer);
      });
      }
    });
  }
  public pointWayRouteTemp() {
    console.log("ACCESS")
    // this.getPoints()
    this.pointList = []
    this.series.data = []
    this.group = L.layerGroup().addTo(this.map);
    this.group.clearLayers();
    this.layerInCreation = 3;
    this.hideAll();
    let points: Array<LatLng> = [];
    let line = new L.Draw.Polygon(this.map);
    this.hereApiService.getRoutesViaPoints('truck','-9.976385,-63.023834', '-9.944545,-61.418724',['-9.921276,-62.164301',' -9.944545,-61.418724','-9.933246,-61.368450','-10.054926,-62.013207'],true).subscribe(
      (data:any) => {
        console.log('DATA FOR SOME POINTS', data)
        const new_Data = data.routes[0];
        const polylineList = [];
        const distanceList = [];
        const durantionList = [];
        const actionsList = [];
        const lineStringOffSetList = [];
        new_Data.sections.forEach((section:any)=>{
          const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
          let linestringOffset = linestring.getLatLngAltArray();
          lineStringOffSetList.push(linestringOffset)
          polylineList.push(linestring);
          distanceList.push(section.travelSummary.length);
          durantionList.push(section.travelSummary.duration);
          actionsList.push(section.actions);
        });

        this.total_distance = new_Data.sections[0].travelSummary.length/1000;
        this.pointList = [];
        var location = {
          "locations":[]
        }
        let cont = 0;
        polylineList.forEach((line, index)=>{
          let new_list_latlong = this.transformCoordinates(line['$']);
          const leafletCoordinates: L.LatLngExpression[] = new_list_latlong.map(
              (coord) => [coord[0], coord[1]] as L.LatLngExpression,
          );
          const distance_separator = this.ruleSeparation(distanceList[index]);
          const resultPoints = this.calculateResultPoints(leafletCoordinates, actionsList[index], distance_separator, 1);
          this.createTempMarkers(resultPoints,actionsList[index],lineStringOffSetList[index]);

          let colores = [
            'rgba(218, 68, 71, 0.7)',
            'rgba(245, 128, 255, 0.7)',
            'rgba(48, 93, 185, 0.8)',
            'rgba(88, 223, 255, 0.7)',
            'rgba(159, 1, 66, 0.7)',
            'rgba(218, 118, 68, 0.7)',
            'rgba(112, 150, 36, 0.7)',
            'rgba(20, 175, 185, 0.7)',
            'rgba(255, 193, 7, 0.7)',
            'rgba(63, 81, 181, 0.7)',
            'rgba(233, 30, 99, 0.7)',
            'rgba(255, 87, 34, 0.7)',
            'rgba(0, 188, 212, 0.7)',
            'rgba(103, 58, 183, 0.7)',
            'rgba(139, 195, 74, 0.7)',
            'rgba(255, 152, 0, 0.7)',
            'rgba(121, 85, 72, 0.7)',
            'rgba(255, 235, 59, 0.7)',
            'rgba(33, 150, 243, 0.7)',
          ];
          
          if(index===0){
            cont +=1;
          }

          this.createPolylines(resultPoints, colores, index, this.distance_data);

        });

      },
      (error) => {
        console.error('Error fetching routes:', error);
      },
    );
    document.addEventListener('keydown', (event) => {
      if (event.keyCode == 27) {
        this.tab=0
        this.map.eachLayer(function (layer) {
          this.map.removeLayer(layer);
      });
      }
    });
  }
  private handleWeatherElevation():void {
    this.weatherData = []
    console.log(this.pointList.length)
    for(var i = 0; i< this.pointList.length;i++)
      {
        this.weatherService.currentWeatherfromOpenWeather(this.pointList[i]['latitude'],this.pointList[i]['longitude'],  this.userInfo.id).subscribe((rpt:any)=>{
          console.log(rpt)
          this.weatherData.push(rpt);
  
        })
      }
    
  }

  private handleElevations(results: any[]): void {
    for (let i = 0; i < results.length; i++) {
      const elevation = results[i].elevation;
      this.series.label.push(this.address);
      this.series.data.push(elevation);
    }
  }

  private createPolylines(resultPoint: L.LatLngExpression[][], colores: string[], cont: number, distance_data:  number[]) {
    
    const newPolylineGroup = L.featureGroup();
    console.log('DISTANCE',distance_data[0])
    // console.log('RESULT',resultPoint)
    
    resultPoint.forEach((element: L.LatLngExpression[], index: number) => {
      const multi = L.polyline(element, { color: colores[index], weight: 8 })
      
      
      multi.on('click', (event) => {
        if(distance_data.length==1){
          this.handleTempRoute(event, element, index ,distance_data[0]);
        }
        this.handleTempRoute(event, element, index ,distance_data[index]);
      });
      
      // // console.log('ELEMENT',element)
      this.saveRoute(element, index ,this.distance_data[index], cont)
      // console.log('index', index);
      let tooltipContent;
      if(distance_data.length==1){
         tooltipContent = `${distance_data[0]/1000} Km`;
      }else {
         tooltipContent = `${distance_data[index]/1000} Km`;
      }
      
      multi.bindTooltip(tooltipContent, { permanent: true, direction: 'center' });
  
      newPolylineGroup.addLayer(multi);
    });
    
    
    // this.map.removeLayer(this.polylineGroup);
    // this.polylineGroup.clearLayers();
    this.polylineGroup.addLayer(newPolylineGroup);
    this.polylineGroup.addTo(this.map);
    this.map.fitBounds(this.polylineGroup.getBounds());

  }

  private createPolyline(leafletCoordinates: L.LatLngExpression[]): L.Polyline {
    return L.polyline(leafletCoordinates, {
      color: 'rgba(0, 128, 255, 0.7)',
      weight: 8,
    });
  }

  private async saveRoute(coordinates: L.LatLngExpression[], name:number, distance:number, cont:number){
    // // console.log('NAME', name)
    // // console.log('CONTADOR', cont)
    let sizeOfCoordinates = Math.floor(coordinates.length / 2);
    // console.log('THIS USER INFO', this.userInfo)
    if(this.userInfo){
      await this.weatherService.postRetrieveTemp(coordinates[sizeOfCoordinates][0], coordinates[sizeOfCoordinates][1],`Tramo ${cont}-${name}`,distance,  this.userInfo.id)
    .then((rpta)=>{console.log('SUCCESS')});
    }
    
  }


  private createMarkers(resultPoint: L.LatLngExpression[][], actions: any[], offsetList :number[]): void {
    const markers = L.featureGroup();
    resultPoint.forEach((element: L.LatLngExpression[]) => {
      const firstMarker = L.marker([element[0][0], element[0][1]], {
        icon: L.icon({
          iconUrl: 'assets/images/my-fields/marker-icons/location-pointer-2DA951.svg',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        }),
      });
      firstMarker.on('click', (event) => {
        this.handleTemp(event, [element[0][0], element[0][1]]);
      });
      this.group.addLayer(firstMarker);
      //markers.push(firstMarker);

      const lastMarker = L.marker([element[element.length - 1][0], element[element.length - 1][1]], {
        icon: L.icon({
          iconUrl: 'assets/images/my-fields/marker-icons/location-pointer-2DA951.svg',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        }),
      });
    
      lastMarker.on('click', (event) => {
        this.handleTemp(event, [element[element.length - 1][0], element[element.length - 1][1]]);
      });
    
      this.group.addLayer(lastMarker);
      //markers.push(lastMarker);
      this.markerGroup.addLayer(markers);
      this.markerGroup.addTo(this.map);
    });
  
  }

  private createTempMarkers(resultPoint: L.LatLngExpression[][], actions: any[], offsetList :number[]): void {
    const markers = L.featureGroup();
    resultPoint.forEach((element: L.LatLngExpression[]) => {
      const firstMarker = L.marker([element[0][0], element[0][1]], {
        icon: L.icon({
          iconUrl: 'assets/images/my-fields/marker-icons/location-pointer-2DA951.svg',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        }),
      });
      firstMarker.on('click', (event) => {
        this.handleTemp2(event, [element[0][0], element[0][1]]);
      });
      this.group.addLayer(firstMarker);
      //markers.push(firstMarker);

      const lastMarker = L.marker([element[element.length - 1][0], element[element.length - 1][1]], {
        icon: L.icon({
          iconUrl: 'assets/images/my-fields/marker-icons/location-pointer-2DA951.svg',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        }),
      });
    
      lastMarker.on('click', (event) => {
        this.handleTemp2(event, [element[element.length - 1][0], element[element.length - 1][1]]);
      })
    
      this.group.addLayer(lastMarker);
      //markers.push(lastMarker);
      this.markerGroup.addLayer(markers);
      this.markerGroup.addTo(this.map);
    });
  
  }
  private calculateResultPoints(leafletCoordinates: L.LatLngExpression[], actions: any[], distance_separator: number, label:number): L.LatLngExpression[][] {
    let calculateDistance = 0;
    let startIndex = actions[0].offset;
    let resultPoint: L.LatLngExpression[][] = [];

    for (let i = 1; i < actions.length; i += 1) {
      let action = actions[i];

      if (calculateDistance + action.length >= distance_separator) {
        const endIndex = action.offset;
        resultPoint.push([...leafletCoordinates.slice(startIndex, endIndex)]);
        startIndex = endIndex;
        if(label==1){
          this.distance_data.push(calculateDistance);
        }
        else if(label==2){
          this.distance_data1.push(calculateDistance);
        } else if (label==3){
          this.distance_data2.push(calculateDistance);
        }
        calculateDistance = 0;
      }

      calculateDistance = calculateDistance + action.length;
    }

    if (calculateDistance > 0) {
      const endIndex = leafletCoordinates.length;
      resultPoint.push([...leafletCoordinates.slice(startIndex, endIndex)]);
      if(label==1){
        this.distance_data.push(calculateDistance);
      }
      else if(label==2){
        this.distance_data1.push(calculateDistance);
      } else if (label==3){
        this.distance_data2.push(calculateDistance);
      }
      
    }
    return resultPoint;
  }

  getRoutePoint() {}

  // GETINTERMEDIATE POINTS---------
  getIntermediatePoints(points: any[]): any[] {
    const intermediatePoints = [];
    for (let i = 1; i < points.length; i++) {
      const latlng1 = points[i - 1];
      const latlng2 = points[i];
      const dist = latlng1.distanceTo(latlng2);
      const numPoints = Math.floor(dist / 1000); // Puedes ajustar el intervalo de distancia aquí

      for (let j = 0; j < numPoints; j++) {
        const ratio = j / numPoints;
        const lat = latlng1.lat + (latlng2.lat - latlng1.lat) * ratio;
        const lng = latlng1.lng + (latlng2.lng - latlng1.lng) * ratio;
        intermediatePoints.push({ lat, lng });
      }
    }
    return intermediatePoints;
  }

  dividirPolylineEnSegmentos(polyline: L.Polyline, longitudSegmento: number): L.LatLng[] {
    const puntos = polyline.getLatLngs() as L.LatLng[]; // Obtener los puntos del polyline
    const puntosFinales: L.LatLng[] = [];
  
    let distanciaAcumulada = 0;
  
    for (let i = 1; i < puntos.length; i++) {
      const puntoActual = puntos[i - 1];
      const puntoSiguiente = puntos[i];
      
      // Calcular la distancia entre dos puntos en el polyline
      const distancia = puntoActual.distanceTo(puntoSiguiente);
  
      if (distanciaAcumulada + distancia >= longitudSegmento) {
        const fraccion = (longitudSegmento - distanciaAcumulada) / distancia;
  
        const nuevoPunto = new L.LatLng(
          puntoActual.lat + fraccion * (puntoSiguiente.lat - puntoActual.lat),
          puntoActual.lng + fraccion * (puntoSiguiente.lng - puntoActual.lng)
        );
  
        puntosFinales.push(nuevoPunto);
        distanciaAcumulada = 0; // Reiniciar la distancia acumulada
      } else {
        distanciaAcumulada += distancia;
      }
    }
  
    return puntosFinales;
  }
  // END GETINTERMEDIATE POINTS---------
  //Manejo de datos del clima en base a un punto
  private handleTemp(event: L.LeafletEvent, coordinates: number[]): void {
    this.weatherService.getWeatherFromRoutePoint(coordinates[0], coordinates[1], this.userInfo.id).then((res) => {
      this.tempInfo = res;

      const popupContent = `
          <div style="width:105%;display:flex;padding-bottom:10px;background-color: #ffffff;align-items: center;" *ngIf="tempInfo !== undefined">
            <div style="width: 60%;padding-left: 0px;display: flex;align-items: center;">
              <img src="${this.imagesPath[this.tempInfo.weather[0].main]}" routerLink="/agromet"
              style="cursor: pointer;height: 40px;width: 40px;" />
              <div style="padding-top: 10px;height: 100%;display: flex;align-items: center;">
                
                <div style="font-size: 20px;">
                  ${this.tempInfo.main.temp} °C
                </div>
                
              </div>
            </div>
            <div style="width: 40%;padding-top: 10px;">
                <div>Nebulosidade: ${this.tempInfo.clouds.all}%</div>
                <div>Umidade: ${this.tempInfo.main.humidity}%</div>
                <div>Vento a: ${this.tempInfo.wind.speed}m/s</div>
            </div>
          </div>`;
      const popupOptions = { className: 'leaflet-popup-content' };

      this.map.closePopup();

      const latLng = (event as L.LeafletMouseEvent).latlng;
      L.popup({ className: 'leaflet-popup-content' }).setLatLng(latLng).setContent(popupContent).openOn(this.map);
    });
  }
  private handleTemp2(event: L.LeafletEvent, coordinates: number[]): void {
    this.weatherService.getPlaceName(coordinates[0], coordinates[1]).subscribe((rpt:any)=>{
    this.weatherService.getWeatherFromRoutePoint(coordinates[0], coordinates[1], this.userInfo.id).then((res) => {
      this.tempInfo = res;
      const tempColor = this.getTemperatureColor(res.main.temp);
      const wordColor = this.getWordColor(res.main.temp);
      const popupContent = `<div class="custom-popup" style="display: flex;">
      <div style="background-color:black; color:white; font-size:15px; font-weight:bold; width:25%; padding:3px;">${parseInt(res.main.temp)}°C</div>
      <div style="background-color: ${tempColor}; color: ${wordColor}; font-size:15px; font-weight:bold; width:75%; padding:3px;">${rpt['address']['region']}</div>
      </div>`;
      const popupOptions = { className: 'leaflet-popup-content' };

      this.map.closePopup();

      const latLng = (event as L.LeafletMouseEvent).latlng;
      L.popup({ className: 'leaflet-popup-content' }).setLatLng(latLng).setContent(popupContent).openOn(this.map);
    }) });
  }
  //Manejo de datos del clima en base al punto medio de la ruta  
  private handleTempRoute(event: L.LeafletEvent, coordinates: L.LatLngExpression[], name:number, distance:number): void {
    let sizeOfCoordinates = Math.floor(coordinates.length / 2);
    // console.log("DISTANCE FROM HANDLE", distance)
    this.weatherService
      .getWeatherFromRoutePoint(coordinates[sizeOfCoordinates][0], coordinates[sizeOfCoordinates][1], this.userInfo.id)
      .then((res) => {
        this.tempInfo = res;

        const popupContent = `
          <div style="width:105%;display:flex;padding-bottom:10px;background-color: #ffffff;align-items: center;" *ngIf="tempInfo !== undefined">
            <div style="width: 60%;padding-left: 0px;display: flex;align-items: center;">
              <img src="${this.imagesPath[this.tempInfo.weather[0].main]}" routerLink="/agromet"
              style="cursor: pointer;height: 40px;width: 40px;" />
              <div style="padding-top: 10px;height: 100%;display: flex;align-items: center;">
                
                <div style="font-size: 20px;">
                  ${this.tempInfo.main.temp} °C
                </div>
                
              </div>
            </div>
            <div style="width: 40%;padding-top: 10px;">
                <div>Nebulosidade: ${this.tempInfo.clouds.all}%</div>
                <div>Umidade: ${this.tempInfo.main.humidity}%</div>
                <div>Vento a: ${this.tempInfo.wind.speed}m/s</div>
            </div>
          </div>`;
        const popupOptions = { className: 'leaflet-popup-content' };

        this.map.closePopup();

        const latLng = (event as L.LeafletMouseEvent).latlng;
        L.popup({ className: 'leaflet-popup-content' }).setLatLng(latLng).setContent(popupContent).openOn(this.map);
      });
  }

  public tempbyDate(fecha:Date)
  {
    this.clearAllDrawedLayers();
    this.clearLayersFromMap();
    var formatDate = this.formatearFecha(fecha)
    
    let temp = this.weatherService.getTemperature(-12.0464, -77.0428,  this.userInfo.id)
    // console.log(temp)
    var data = [
      { name: 'Lima', temperature:0, coordinates: [-12.0464, -77.0428] },
      { name: 'Arequipa', temperature:0, coordinates: [-16.409, -71.5374] },
      { name: 'Callao', temperature:0, coordinates: [-12.05659, -77.11814] },
      { name: 'La Libertad', temperature:0, coordinates: [-8.11599, -79.02998] },
      { name: 'Lambayeque', temperature:0, coordinates: [	-6.77137, -79.84088] },
      { name: 'Loreto', temperature:0, coordinates: [	-3.74912, -73.25383] },
      { name: 'Junin', temperature:0, coordinates: [-12.06513, -75.20486] },
      { name: 'Piura', temperature:0, coordinates: [-5.19449, -80.63282] },
      { name: 'Ucayali', temperature:0, coordinates: [-8.37915, -74.55387] },
      { name: 'Tacna', temperature:0, coordinates: [-18.01465, -70.25362] },
      { name: 'Ica', temperature:0, coordinates: [-14.06777, -75.72861] },
      { name: 'Puno', temperature:0, coordinates: [-15.5, -70.13333] },
      { name: 'Huanuco', temperature:0, coordinates: [-9.93062, -76.24223] },
      { name: 'Cuzco', temperature:0, coordinates: [-13.52264, -71.96734] },
      { name: 'Ayacucho', temperature:0, coordinates: [-13.15878, -74.22321] },
      { name: 'Cajamarca', temperature:0, coordinates: [-7.16378, -78.50027] },
      { name: 'Ancash', temperature:0, coordinates: [-9.52779, -77.52778] },
      { name: 'Tumbes', temperature:0, coordinates: [-3.56694, -80.45153] },
      { name: 'San Martín', temperature:0, coordinates: [-5.18591, -80.66927] },
      { name: 'Moquegua', temperature:0, coordinates: [-17.19832, -70.93567] },
      { name: 'Huancavelica', temperature:0, coordinates: [-12.78261, -74.97266] },
      { name: 'Madre de Dios', temperature:0, coordinates: [-12.59331, -69.18913] },
      { name: 'Pasco', temperature:0, coordinates: [-10.66748, -76.25668] },
      { name: 'Apurímac', temperature:0, coordinates: [-13.63389, -72.88139] },
      // Agrega más departamentos con sus datos de temperatura y coordenadas
    ];

    const customPopup = L.popup({
      className: 'custom-popup',
      closeButton: true
    });
    
    data.forEach((department:any) => {
      this.weatherService.getForecast(department['coordinates'][0],department['coordinates'][1],  this.userInfo.id).subscribe((rpt:any)=>{
      for(var i = 0; i<rpt.list.length;i++)
      {
        var fullDate = rpt.list[i]['dt_txt'];
        fullDate = fullDate.split(' ')
        // console.log(fullDate[0])
        if(formatDate==fullDate[0])
        {
          const tempColor = this.getTemperatureColor(rpt.list[i].main.temp);
          const marker = L.marker(department.coordinates, {
            icon: L.icon({
              iconUrl: 'assets/favicon.png',
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
            }),
          })
            .addTo(this.map)
            .bindPopup(`<div class="custom-popup" style="display: flex;">
            <div style="background-color:black; color:white; font-size:15px; font-weight:bold; width:25%; padding:3px;">${parseInt(rpt.list[i].main.temp)}°C</div>
            <div style="background-color: ${tempColor}; color: black; font-size:15px; font-weight:bold; width:75%; padding:3px;">${department.name}</div>
            </div>`)
        }
      }

     })
    });
  }

  public formatearFecha(fecha: Date): string {
    const año = fecha.getFullYear();
    const mes = ('0' + (fecha.getMonth() + 1)).slice(-2);
    const día = ('0' + fecha.getDate()).slice(-2);

    return `${año}-${mes}-${día}`;
  }

  public tempbyDep()
  {
    if(this.showCalendar)
    {
      this.clearAllDrawedLayers();
      let temp = this.weatherService.getTemperature(-12.0464, -77.0428,  this.userInfo.id)
      // console.log(temp)
      var data = [
        { name: 'Lima', temperature:0, coordinates: [-12.0464, -77.0428] },
        { name: 'Arequipa', temperature:0, coordinates: [-16.409, -71.5374] },
        { name: 'Callao', temperature:0, coordinates: [-12.05659, -77.11814] },
        { name: 'La Libertad', temperature:0, coordinates: [-8.11599, -79.02998] },
        { name: 'Lambayeque', temperature:0, coordinates: [	-6.77137, -79.84088] },
        { name: 'Loreto', temperature:0, coordinates: [	-3.74912, -73.25383] },
        { name: 'Junin', temperature:0, coordinates: [-12.06513, -75.20486] },
        { name: 'Piura', temperature:0, coordinates: [-5.19449, -80.63282] },
        { name: 'Ucayali', temperature:0, coordinates: [-8.37915, -74.55387] },
        { name: 'Tacna', temperature:0, coordinates: [-18.01465, -70.25362] },
        { name: 'Ica', temperature:0, coordinates: [-14.06777, -75.72861] },
        { name: 'Puno', temperature:0, coordinates: [-15.5, -70.13333] },
        { name: 'Huanuco', temperature:0, coordinates: [-9.93062, -76.24223] },
        { name: 'Cuzco', temperature:0, coordinates: [-13.52264, -71.96734] },
        { name: 'Ayacucho', temperature:0, coordinates: [-13.15878, -74.22321] },
        { name: 'Cajamarca', temperature:0, coordinates: [-7.16378, -78.50027] },
        { name: 'Ancash', temperature:0, coordinates: [-9.52779, -77.52778] },
        { name: 'Tumbes', temperature:0, coordinates: [-3.56694, -80.45153] },
        { name: 'San Martín', temperature:0, coordinates: [-5.18591, -80.66927] },
        { name: 'Moquegua', temperature:0, coordinates: [-17.19832, -70.93567] },
        { name: 'Huancavelica', temperature:0, coordinates: [-12.78261, -74.97266] },
        { name: 'Madre de Dios', temperature:0, coordinates: [-12.59331, -69.18913] },
        { name: 'Pasco', temperature:0, coordinates: [-10.66748, -76.25668] },
        { name: 'Apurímac', temperature:0, coordinates: [-13.63389, -72.88139] },
        // Agrega más departamentos con sus datos de temperatura y coordenadas
      ];
  
      const customPopup = L.popup({
        className: 'custom-popup',
        closeButton: true
      });
      
      data.forEach((department:any) => {
        const name = department
        this.weatherService.getWeather(department['coordinates'][0],department['coordinates'][1],  this.userInfo.id).subscribe((rpt:any)=>{
        const tempColor = this.getTemperatureColor(rpt.main.temp);
        const wordColor = this.getWordColor(rpt.main.temp);
        const marker = L.marker(department.coordinates, {
          icon: L.icon({
            iconUrl: 'assets/favicon.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
        })
          .addTo(this.map)
          .bindPopup(`<div class="custom-popup" style="display: flex;">
          <div style="background-color:black; color:white; font-size:15px; font-weight:bold; width:25%; padding:3px;">${parseInt(rpt.main.temp)}°C</div>
          <div style="background-color: ${tempColor}; color: ${wordColor}; font-size:15px; font-weight:bold; width:75%; padding:3px;">${department.name}</div>
          </div>`)
       })
      });
  
    }
   
  }

  private getTemperatureColor(temperature: number): string {
    let color = '#0000ff'; // Color azul predeterminado
    if (temperature > 30) {
      color = '#ff0000'; // Rojo para temperatura alta
    } else if (temperature > 20 && temperature <= 30) {
      color = '#ffff00'; // Amarillo para temperatura media
    }
    return color;
  }
  private getWordColor(temperature: number): string {
    let wordColor = '#ffffff'
    if (temperature > 30) {
      wordColor = '#000000'
    } else if (temperature > 20 && temperature <= 30) {
      wordColor = '#000000'
    }
    return wordColor;
  }
  private getCustomPopup(department: any) {
    this.weatherService.getWeather(department['coordinates'][0],department['coordinates']['coordinates'][1],  this.userInfo.id).subscribe((rpt:any)=>{
      const tempColor = this.getTemperatureColor(department.temperature);
      return `<div class="custom-popup" style="padding: 0px; display: flex;">
      <div style="background-color:black; color:white; width:20%;">${department.temperature}°C</div>
      <div style="background-color: ${tempColor}; color: #ffffff; width:80%;">${department.name}</div>
      </div>`;
    })


  }

  public hideAll() {
    this.pointsItems.getLayers().forEach((l) => this.map.removeLayer(l));
    this.polygonItems.getLayers().forEach((l) => this.map.removeLayer(l));
  }
  public onCanceled(e) {
    //this.mapSidebarComService.sendClickToDeselect();
    //this.mainPageComponent.showFieldList = true;
  }

  tmp;
  public layerInCreation = 0;
  public onCreate(e) {
    if (this.layerInCreation == 0) {
      this.mainPageComponent.showFieldList = false;
      this.hideAll();

      //Calculation of polygon area
      const area = this.getPerimeterOfLayer(e.layer);

      const dataUser = this.userQuery.getInformation();
      const usedArea = Math.round(this.userQuery.getInformation().permission.usedArea * 100) / 100;
      if (dataUser.permission.usedArea + area > dataUser.permission.availableArea) {
        this.dialog.open(NoMoreSpaceDialog, {
          data: { message: area + ' ha. Actualmente tiene ' + usedArea },
        });
        this.mainPageComponent.showFieldList = true;
        this.mapSidebarComService.sendClickToDeselect();
        this.clearAllDrawedLayers();
        return;
      }

      //Adding new polygon to map
      this.layerInCreation = 1;
      this.drawnItems.addLayer(e.layer);
      this.drawnItems.addTo(this.map);

      const dialogRef = this.dialog.open(DialogConfirmationLot, {
        data: {
          areaTotal: area,
          modified: true,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === undefined) {
          //this.map.addLayer(e.layer)
          e.layer.editing.enable();
          this.tmp = e;
          this.mainPageComponent.showFieldList = false;

          this.layerInCreation = 0;
        }

        if (result) {
          this.addLotToLayer(e, area);
          this.mainPageComponent.showFieldList = false;
          this.layerInCreation = 0;
        }

        if (result === false) {
          this.mainPageComponent.showFieldList = true;
          this.mapSidebarComService.sendClickToDeselect();
          this.clearAllDrawedLayers();

          this.layerInCreation = 0;
        }
      });
    }
    if (this.layerInCreation == 2) {
      this.drawnItems.addLayer(e.layer);
      this.drawnItems.addTo(this.map);
      const dialogRef = this.dialog.open(PerimeterDialog, {
        data: {
          periTotal: this.distance,
          modified: false,
        },
      });
    }
  }

  getPerimeterOfLayer(layer: any) {
    const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
    return Math.round(area * 0.0001 * 100) / 100;
  }

  public addLotToLayer(e, area) {
    var layer = e.layer;
    //Add to new group in order to get a Geojson
    var change = L.featureGroup();
    change.addLayer(layer);
    var polygonToGeoJson = change.toGeoJSON();
    var centerOfPolygon = e.layer.getBounds().getCenter();
    this.sharedMapData.setPolygonArea(area);
    this.sharedMapData.setPolygonCenter([[centerOfPolygon.lng, centerOfPolygon.lat]]);
    var polygonCoords = [];
    polygonToGeoJson['features'][0]['geometry']['coordinates'].forEach((k) => {
      k.forEach((element) => {
        polygonCoords.push(element);
      });
    });
    this.sharedMapData.setPolygonCoords(polygonCoords);
    this.openSideBarForCreatingLots();
  }
  public openSideBarForCreatingLots() {
    this.fieldsUiService.setSelectedResource(2);
    this.fieldsUiService.openSidebar();
  }

  public cropAlreadySelected = undefined;
  public async getDatesToFillButtons(layerNameSelected, IsTheSameLot) {
    this.spinner.show('Uploading');
    this.layerNameSelected = layerNameSelected;
    var activeLotSelected =
      this.sharedLotsService.selectSharedLot || this.lotsQuery.getEntity(this.lotsQuery.getActiveId());
    if (!IsTheSameLot || this.datesResponse == undefined) {
      this.mapSidebarComService.enableReport = false;
      this.mapSidebarComService.sendClickToEnableReportButton();
      this.sharedMapData.setNDVI(undefined);
      this.mapSidebarComService.sendClickToUpdateLineChart();
      const dates = this.satImgDate.getEntity(activeLotSelected.id);
      if (dates !== undefined) {
        const satellitalImagesDates = JSON.parse(JSON.stringify(dates.satImgDate));
        this.updateDates(satellitalImagesDates);
      } else {
        if (this.sharedLotsService.selectSharedLot != null) {
          await this.sharedLotsService
            .getImagesInformation(activeLotSelected.id)
            .then((res) => {
              this.updateDates(res);
            })
            .catch((error) => {
              this.datesForStatistics.emit([]);
              console.log(error);
            });
        } else {
          await this.satImgService
            .getDatesForField(activeLotSelected.id)
            .then((res) => {
              const satellitalImagesDates = JSON.parse(JSON.stringify(res));
              this.updateDates(satellitalImagesDates);
            })
            .catch((x) => {
              this.dialog.open(NoImageDialog, {
                data: {
                  message: 'No se han encontrado imagenes para esa zona, contactarse con contactos@imasd.com.pe',
                },
              });
              this.spinner.show('Uploading');
              this.datesForStatistics.emit([]);
              this.satellitalImageService.setSatellitalImageDisplay(false);
              let data = {
                geometry: activeLotSelected.coordinates,
              };
              this.satImgService.sendRequestImage(data);
            });
        }
      }
    }

    if (this.datesResponse !== undefined && this.datesResponse.length > 0) {
      if (!IsTheSameLot || this.selectedDate == undefined) {
        this.selectedDate = this.datesResponse[0].date;
        this.showSelectedView(this.datesResponse[0].date);
      } else {
        this.showSelectedView(this.selectedDate);
      }

      const dateToIncidence =
        this.selectedDate.slice(8, 10) + '.' + this.selectedDate.slice(5, 7) + '.' + this.selectedDate.slice(2, 4);
      this.mapSidebarComService.viewSelected = this.selectedDate;
      this.mapSidebarComService.dateSelected = dateToIncidence;
      this.mapSidebarComService.sendClickToDisplayorHideIncidence();
    }

    if (this.datesResponse !== undefined && !IsTheSameLot) {
      this.mapSidebarComService.addStatistics(this.datesResponse);
      this.sharedMapData.setNDVI(JSON.parse(JSON.stringify(activeLotSelected.id)));
      this.mapSidebarComService.sendClickToUpdateLineChart();
    }
    this.spinner.hide('Uploading');
  }

  public updateDates(res) {
    this.datesResponse = res.lotData;
    for (let index = 0; index < this.datesResponse.length; index++) {
      var n = this.datesResponse[index].cloud_cover.indexOf('.');
      let percentage = this.datesResponse[index].cloud_cover;
      if (n > -1) percentage = percentage.substring(0, n + 3);

      const dateToIncidence =
        this.datesResponse[index].date.slice(8, 10) +
        '.' +
        this.datesResponse[index].date.slice(5, 7) +
        '.' +
        this.datesResponse[index].date.slice(2, 4);
      this.datesResponse[index].date = this.datesResponse[index].date.substring(0, 10);
      this.datesResponse[index].dateDisplayed = dateToIncidence + ' (' + percentage + '%)';
    }
    this.datesForStatistics.emit(this.datesResponse);
    //this.datesResponse = this.datesResponse.reverse()
  }

  public updateDatesComplete(res) {
    this.datesResponse = res.reverse();
    for (let index = 0; index < this.datesResponse.length; index++) {
      let n = this.datesResponse[index].cloud_cover.indexOf('.');
      let percentage = this.datesResponse[index].cloud_cover;
      if (n > -1) percentage = percentage.substring(0, n + 3);

      const dateToIncidence =
        this.datesResponse[index].date.slice(8, 10) +
        '.' +
        this.datesResponse[index].date.slice(5, 7) +
        '.' +
        this.datesResponse[index].date.slice(2, 4);
      this.datesResponse[index].date = this.datesResponse[index].date.substring(0, 10);
      this.datesResponse[index].dateDisplayed = dateToIncidence + ' (' + percentage + '%)';
    }
    this.datesForStatistics.emit(this.datesResponse);
  }

  public changeLayerToAnotherDate() {
    if (this.selectedDate !== undefined) {
      this.showSelectedView(this.selectedDate);
      const dateToIncidence =
        this.selectedDate.slice(8, 10) + '.' + this.selectedDate.slice(5, 7) + '.' + this.selectedDate.slice(2, 4);
      this.mapSidebarComService.viewSelected = this.selectedDate;
      this.mapSidebarComService.dateSelected = dateToIncidence;
      this.mapSidebarComService.sendClickToDisplayorHideIncidence();
      this.mapSidebarComService.sendClickToUpdateIncidence();
    }
  }

  public changeViewToAnotherDate() {
    if (this.selectedDate !== undefined) {
      this.mapSidebarComService.viewId = this.selectedDate;
      let lotData = this.lotsQuery.getEntity(this.mapSidebarComService.lotId);
      let fieldData = this.fieldsQuery.getEntity(lotData.fieldId);
      for (const property in this.layerIds) {
        if (fieldData.lots.find((x) => x == property)) {
          for (const p in this.layerIds[property]) {
            if (p == 'NDVI' || p == 'SAVI' || p == 'RGB' || p == 'NDWI') {
              this.showLotView(true, property, p);
            }
          }
        }
      }
    }
  }

  public changeCompleteToAnotherDate() {
    if (this.selectedDate !== undefined) {
      this.removeAllImages();
      this.showImageComplete(true, this.selectedDate);
    }
  }
  generarCategorias(): string[] {
    const categorias = [];
    for (let i = 0; i <= this.pointList.length; i++) {
      categorias.push((i+20).toString()+"Km");
    }
    return categorias;
  }
  // ACA ES
  public showSelectedView(viewId) {
    this.presentedLayer = true;
    this.presentedLayerView = false;
    this.presentedLayerComplete = false;

    this.layerIds = {};
    var tmpId = this.lotsQuery.getActiveId();
    var tmpLot = this.sharedLotsService.selectSharedLot || this.lotsQuery.getEntity(tmpId);
    var a = JSON.stringify(tmpLot.coordinates);
    var polygon = L.polygon(JSON.parse(a), {
      weight: 7,
      fillOpacity: 0,
      color: 'white',
      dashArray: '0',
    }).addTo(this.map);
    this.layersOnMap.addLayer(polygon);

    var geoserverURL = environment.images_sat_url + 'geoserver/cite/wms';
    //var geoserverURL = 'http://infoagro.tk/geoserver/cite/wms';

    if (this.layerNameSelected === 'NDVI') {
      this.layerPresentedOnMap = L.tileLayer
        .wms(geoserverURL, {
          layers: 'cite:NDVI_' + tmpLot.id + '_' + viewId,
          format: 'image/png',
          transparent: true,
          maxZoom: 19,
          tileSize: 512,
        })
        .addTo(this.map);
    } else if (this.layerNameSelected === 'MOISTURE') {
      this.layerPresentedOnMap = L.tileLayer
        .wms(geoserverURL, {
          layers: 'cite:NDWI_' + tmpLot.id + '_' + viewId,
          format: 'image/png',
          transparent: true,
          maxZoom: 19,
        })
        .addTo(this.map);
    } else if (this.layerNameSelected === 'FALSE_COLOR') {
      this.layerPresentedOnMap = L.tileLayer
        .wms(geoserverURL, {
          layers: 'cite:SAVI_' + tmpLot.id + '_' + viewId,
          format: 'image/png',
          transparent: true,
          maxZoom: 19,
        })
        .addTo(this.map);
    } else if (this.layerNameSelected === 'AGRICULTURE') {
      this.layerPresentedOnMap = L.tileLayer
        .wms(geoserverURL, {
          layers: 'cite:RGB_' + tmpLot.id + '_' + viewId,
          format: 'image/png',
          transparent: true,
          maxZoom: 19,
        })
        .addTo(this.map);
    }
    this.layersOnMap.addLayer(this.layerPresentedOnMap);
  }

  // ACA ES
  public showLotView(change: boolean = false, lotId: any = undefined, layerNameSelected: any = undefined) {
    this.clearAllDrawedLayers();

    if (lotId === undefined) {
      lotId = this.mapSidebarComService.lotId;
    }
    if (layerNameSelected === undefined) {
      layerNameSelected = this.mapSidebarComService.layerNameSelected;
    }

    let viewId = this.mapSidebarComService.viewId;
    if (this.layerIds[lotId] != undefined && change === false) {
      this.removeLotView(lotId);
      return;
    } else if (this.layerIds[lotId] != undefined && change === true) {
      this.removeLotView(lotId);
    }

    this.presentedLayerView = true;
    this.presentedLayer = false;
    this.presentedLayerComplete = false;
    let tmpLot = this.sharedLotsService.selectSharedLot || this.lotsQuery.getEntity(lotId);
    this.getDates(tmpLot);

    let a = JSON.stringify(tmpLot.coordinates);
    let polygon = L.polygon(JSON.parse(a), {
      weight: 7,
      fillOpacity: 0,
      color: 'white',
      dashArray: '0',
    }).addTo(this.map);
    this.layersOnMap.addLayer(polygon);
    let indicesIds = {
      POL: polygon,
    };
    let geoserverURL = environment.images_sat_url + 'geoserver/cite/wms';

    if (layerNameSelected === 'POL') {
      return;
    } else if (
      layerNameSelected === 'NDVI' ||
      layerNameSelected === 'SAVI' ||
      layerNameSelected === 'NDWI' ||
      layerNameSelected === 'RGB'
    ) {
      let indice = L.tileLayer
        .wms(geoserverURL, {
          layers: 'cite:' + layerNameSelected + '_' + tmpLot.id + '_' + viewId,
          format: 'image/png',
          transparent: true,
          maxZoom: 19,
          tileSize: 512,
        })
        .addTo(this.map);

      this.layersOnMap.addLayer(indice);
      indicesIds[layerNameSelected] = indice;
      this.layerIds[lotId] = indicesIds;
    }
  }

  public showImageComplete(change: boolean = false, imageId: any = undefined, imageLayerNameSelected: any = undefined) {
    let lotId = this.mapSidebarComService.imageId;

    if (imageLayerNameSelected == undefined) imageLayerNameSelected = this.mapSidebarComService.imageLayerNameSelected;

    this.presentedLayerView = false;
    this.presentedLayer = false;
    this.presentedLayerComplete = true;

    if (imageLayerNameSelected === 'POL') {
      this.removeAllImages();
      return;
    }

    var tmpLot = this.sharedLotsService.selectSharedLot || this.lotsQuery.getEntity(lotId);
    var geoserverURL = environment.images_sat_url + 'geoserver/cite/wms';

    if (!change) {
      this.getDatesComplete(tmpLot.coordinates);
      return;
    }

    if (
      imageLayerNameSelected === 'NDVI' ||
      imageLayerNameSelected === 'SAVI' ||
      imageLayerNameSelected === 'NDWI' ||
      imageLayerNameSelected === 'RGB'
    ) {
      let indice = L.tileLayer
        .wms(geoserverURL, {
          layers: 'cite:' + imageLayerNameSelected + '_' + imageId,
          format: 'image/png',
          transparent: true,
          maxZoom: 19,
          tileSize: 512,
        })
        .addTo(this.map);

      this.layersOnMap.addLayer(indice);
      this.imageIds[imageId] = indice;
    }
  }

  public removeAllImages() {
    for (const property in this.imageIds) {
      this.map.removeLayer(this.imageIds[property]);
      delete this.imageIds[property];
    }
  }

  public async getDates(lot: any) {
    const dates = this.satImgDate.getEntity(lot.id);
    if (dates !== undefined) {
      const satellitalImagesDates = JSON.parse(JSON.stringify(dates.satImgDate));
      this.updateDates(satellitalImagesDates);
    } else {
      if (this.sharedLotsService.selectSharedLot != null) {
        await this.sharedLotsService
          .getImagesInformation(lot.id)
          .then((res) => {
            this.updateDates(res);
          })
          .catch((error) => {
            this.datesForStatistics.emit([]);
            console.log(error);
          });
      } else {
        await this.satImgService
          .getDatesForField(lot.id)
          .then((res) => {
            const satellitalImagesDates = JSON.parse(JSON.stringify(res));
            this.updateDates(satellitalImagesDates);
          })
          .catch((x) => {
            this.dialog.open(NoImageDialog, {
              data: {
                message: 'No se han encontrado imagenes para esa zona, contactarse con contactos@imasd.com.pe',
              },
            });
            this.spinner.show('Uploading');
            this.datesForStatistics.emit([]);
            this.satellitalImageService.setSatellitalImageDisplay(false);
            let data = {
              geometry: lot.coordinates,
            };
            this.satImgService.sendRequestImage(data);
          });
      }
    }
  }
  public async getDatesComplete(coordinates: any) {
    let newCoordiantes = [];

    for (let i = 0; i < coordinates.length; i++) {
      newCoordiantes.push([coordinates[i][1], coordinates[i][0]]);
    }

    this.satImgService.getDatesForImage(newCoordiantes).then((response) => {
      this.updateDatesComplete(response);
    });
  }

  public removeLotView(lotId: any = undefined) {
    if (lotId === undefined) {
      lotId = this.mapSidebarComService.lotId;
    }

    for (const property in this.layerIds[lotId]) {
      this.map.removeLayer(this.layerIds[lotId][property]);
      delete this.layerIds[lotId][property];
    }
    delete this.layerIds[lotId];
  }

  public removeLayerId(id) {
    this.map.removeLayer(id);
  }

  public removePresentedLayer() {
    if (this.layerPresentedOnMap !== undefined) {
      this.map.removeLayer(this.layerPresentedOnMap);
    }
    try {
      this.layersOnMap.eachLayer((element) => {
        if (element !== undefined) {
          this.map.removeLayer(element);
        }
      });
    } catch (error) {}
  }

  public showPoints() {
    this.polygonItems.getLayers().forEach((l) => this.map.removeLayer(l));
    this.pointsItems.getLayers().forEach((l) => l.addTo(this.map));
  }
  public showPolygons() {
    this.pointsItems.getLayers().forEach((l) => this.map.removeLayer(l));
    this.polygonItems.getLayers().forEach((l) => {
      let lot = this.lotsQuery.getEntity(l['options']['className']);
      l.bindTooltip(lot.name, { permanent: false, direction: 'center' }).openTooltip();
      l.addTo(this.map);
    });
  }

  public fitBoundsToLayer(lot: any = null) {
    this.thereIsASelectedField = true;
    var activeLot = lot || this.lotsQuery.getActive();
    let tmp = activeLot.id;
    this.pointsItems.getLayers().forEach((l) => this.map.removeLayer(l));
    this.polygonItems.getLayers().forEach((l) => this.map.removeLayer(l));
    if (this.selectedModal === '2' || this.selectedModal === '4') {
      //this.showPolygons();
      this.polygonItems.eachLayer((layer) => {
        if (layer['options']['className'] === tmp) {
          this.map.addLayer(layer);
        }
      });
    } else {
      //this.showPoints();
      this.pointsItems.eachLayer((layer) => {
        if (layer['options']['alt'] === tmp) {
          this.map.addLayer(layer);
        }
      });
    }
    this.map.fitBounds(activeLot['coordinates']);
  }

  public fitBoundsToLayerField() {
    var lot = lot || this.lotsQuery.getEntity(this.mapSidebarComService.fieldCenter);
    this.map.fitBounds(lot['coordinates'], { maxZoom: 14 });
  }

  public editedLayer = undefined;
  public enableEditOfLotById(idLot) {
    this.editedLayer = undefined;
    this.polygonItems.eachLayer((layer) => {
      if (layer['options']['className'] === idLot) {
        this.mainPageComponent.showFieldList = false;
        this.editedLayer = layer;
        this.tmpRestoreLayer = JSON.parse(JSON.stringify(this.editedLayer._latlngs));
        this.editedLayer.editing.enable();
        this.map.addLayer(this.editedLayer);
      }
    });
  }

  public stopEditAndSaveOfLotById(idLot, newName, pheno) {
    this.spinner.show('Uploading');
    try {
      this.pointsItems.eachLayer((layer) => {
        if (layer['options']['alt'] === idLot) {
          this.pointsItems.removeLayer(layer);
          this.map.removeLayer(layer);
        }
      });
      this.polygonItems.eachLayer(async (layer) => {
        if (layer['options']['className'] === idLot) {
          var tmp: any = layer;
          this.map.removeLayer(layer);
          this.polygonItems.removeLayer(layer);
          tmp.editing.disable();
          var tmpFeature = L.featureGroup();
          layer.addTo(tmpFeature);
          var actualCoords = JSON.stringify(tmp._latlngs);
          var prevCoords = JSON.stringify(this.tmpRestoreLayer);
          var polygonCoords = [null];
          if (actualCoords !== prevCoords) {
            polygonCoords = tmpFeature.toGeoJSON()['features'][0]['geometry']['coordinates'];
          }
          this.fieldsUiService.closeSidebar();
          await this.lotsService.updateLot(idLot, newName, polygonCoords, pheno).then(() => {
            this.tmpRestoreLayer = undefined;
            this.editedLayer = undefined;
          });
          this.spinner.hide('Uploading');
        }
      });
    } catch (error) {
      this.spinner.hide('Uploading');
    }
    this.mainPageComponent.showFieldList = true;
  }
  public showMenu()
  {
    var menu = document.getElementById("menu");
    menu.style.display = "";
  }
  public hideMenu()
  {
    var menu = document.getElementById("menu");
    menu.style.display = "none";
  }
  public tempLayer()
  {
    var temp_layer = document.getElementById("temp-layer") as HTMLInputElement;
    if (temp_layer.checked === true) {
      this.temp = true
      this.temperatureLayer = L.tileLayer(
        'https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
        {
          maxZoom: 18,
          attribution: 'Map data © OpenWeatherMap',
        },
      );

      this.map.addLayer(this.temperatureLayer);
      this.temperatureLayer.setOpacity(1.0);
    } else {
      this.temp = false
      if (this.temperatureLayer) {
        this.map.removeLayer(this.temperatureLayer);
        this.temperatureLayer = null; // Elimina la referencia a la capa
      }
    }
  }

  public cloudsLayer()
  {
    var cloud_layer = document.getElementById("cloud-layer") as HTMLInputElement;
    if (cloud_layer.checked === true) {
      this.cloud = true
      this.cloudLayer = L.tileLayer(
        'https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
        {
          maxZoom: 18,
          attribution: 'Map data © OpenWeatherMap',
        },
      );

      this.map.addLayer(this.cloudLayer);
      this.cloudLayer.setOpacity(1.0);
    } else {
      this.cloud = false
      if (this.cloudLayer) {
        this.map.removeLayer(this.cloudLayer);
        this.cloudLayer = null; // Elimina la referencia a la capa
      }
    }
  }

  public windsLayer()
  {
    var wind_layer = document.getElementById("wind-layer") as HTMLInputElement;
    if (wind_layer.checked === true) {
      this.wind = true
      this.windLayer = L.tileLayer(
        'https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
        {
          maxZoom: 18,
          attribution: 'Map data © OpenWeatherMap',
        },
      );

      this.map.addLayer(this.windLayer);
      this.windLayer.setOpacity(1.0);
    } else {
      this.wind = false
      if (this.windLayer) {
        this.map.removeLayer(this.windLayer);
        this.windLayer = null; // Elimina la referencia a la capa
      }
    }
  }

  public precipitationsLayer()
  {
    var prec_layer = document.getElementById("prec-layer") as HTMLInputElement;
    if (prec_layer.checked === true) {
      this.prec = true
      this.precipitationLayer = L.tileLayer(
        'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
        {
          maxZoom: 18,
          attribution: 'Map data © OpenWeatherMap',
        },
      );

      this.map.addLayer(this.precipitationLayer);
      this.precipitationLayer.setOpacity(1.0);
    } else {
      this.prec = false
      if (this.precipitationLayer) {
        this.map.removeLayer(this.precipitationLayer);
        this.precipitationLayer = null; // Elimina la referencia a la capa
      }
    }
  }

  public pressuresLayer()
  {
    var pres_layer = document.getElementById("pres-layer") as HTMLInputElement;
    if (pres_layer.checked === true) {
      this.pres = true
      this.pressureLayer = L.tileLayer(
        'https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
        {
          maxZoom: 18,
          attribution: 'Map data © OpenWeatherMap',
        },
      );

      this.map.addLayer(this.pressureLayer);
      this.pressureLayer.setOpacity(1.0);
    } else {
      this.pres = false
      if (this.pressureLayer) {
        this.map.removeLayer(this.pressureLayer);
        this.pressureLayer = null; // Elimina la referencia a la capa
      }
    }
  }
  public  controlLayers()
  {
    


    
    /*var cloudsLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    cloudsLayer.setOpacity(1.0);

    var windLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    windLayer.setOpacity(1.0);

    var precipitationLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    precipitationLayer.setOpacity(1.0);

    var pressureLayer = L.tileLayer(
      'https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=dbcf1cb1264c601d0f8e0102c443e237',
      {
        maxZoom: 18,
        attribution: 'Map data © OpenWeatherMap',
      },
    ).addTo(this.map);

    pressureLayer.setOpacity(1.0);*/
  }
  public cancelEditionPolygonAndReverse() {
    if (this.editedLayer !== undefined) {
      //this.editedLayer.editing.disable();
      this.map.removeLayer(this.editedLayer);
      this.polygonItems.removeLayer(this.editedLayer);
      this.editedLayer = L.polygon(this.tmpRestoreLayer, {
        weight: 12,
        fillOpacity: 0,
        color: this.fieldsQuery.getEntity(this.fieldsQuery.getActiveId()).color,
        dashArray: '0',
        className: this.lotsQuery.getActiveId(),
      }).addTo(this.polygonItems);
      if (this.selectedModal !== '2') {
        this.map.removeLayer(this.editedLayer);
      } else {
        this.map.addLayer(this.editedLayer);
      }
    }
    this.mainPageComponent.showFieldList = true;
  }

  public deletePolygon(lotId) {
    try {
      this.polygonItems.eachLayer((layer) => {
        if (layer['options']['className'] === lotId) {
          var tmp: any = layer;
          tmp.editing.disable();
          this.map.removeLayer(layer);
          this.polygonItems.removeLayer(layer);
        }
      });
      this.pointsItems.eachLayer((layer) => {
        if (layer['options']['alt'] === lotId) {
          var tmp: any = layer;
          this.map.removeLayer(layer);
          this.pointsItems.removeLayer(layer);
        }
      });
    } catch (error) {}
  }
  public clearAllDrawedLayers() {
    this.drawnItems.clearLayers();
  }

  public inputFilter(e) {
    this.searchInputText = e.target.value;
  }

  public async search() {
    if (this.searchInputText === '' || this.searchInputText === undefined) {
      return;
    }
    if (this.isLatLong(this.searchInputText)) {
      var coord = this.searchInputText.replace(' ', '').split(',');
      var a = new L.LatLng(parseFloat(coord[0]), parseFloat(coord[1]));
      this.map.setView(a, 15);
    } else {
      this.spinner.show('Uploading');
      await this.lotCropServiceService.getLocationOfText(this.searchInputText).then((x) => {
        try {
          if (x.features.length > 0) {
            var a = new L.LatLng(x.features[0].geometry.coordinates[1], x.features[0].geometry.coordinates[0]);
            this.map.setView(a, 15);
          }
        } catch (error) {}
      });
      this.spinner.hide('Uploading');
    }
  }

  public isLatLong(text: string) {
    var coord = text.replace(' ', '').split(',');
    if (coord.length === 2) {
      try {
        var a = new L.LatLng(parseFloat(coord[0]), parseFloat(coord[1]));
        return true;
      } catch (error) {}
    }
    return false;
  }
  //FILTER MID CHART
  public filtro(serie:string)
  {
    // console.log(this.allSeries)
    if(serie == "Todo")
    {
      this.seriesMidChart = this.allSeries;
    }
    if(serie == "tmax")
    {
      this.seriesMidChart = [
        {
          name: "Precipitación",
          type: "column",
          data: []
        },
        {
          name: "Temp. Alta",
          type: "area",
          data: this.allSeries[1].data
        },
        {
          name: "Temp. Baja",
          type: "area",
          data: []
        }
      ];
    }
    if(serie == "tmin")
    {
      this.seriesMidChart = [
        {
          name: "Precipitación",
          type: "column",
          data: []
        },
        {
          name: "Temp. Alta",
          type: "area",
          data: []
        },
        {
          name: "Temp. Baja",
          type: "area",
          data: this.allSeries[2].data
        }
      ];
    }
    if(serie == "prec")
    {
      this.seriesMidChart = [
        {
          name: "Precipitación",
          type: "column",
          data: this.allSeries[0].data
        },
        {
          name: "Temp. Alta",
          type: "area",
          data: []
        },
        {
          name: "Temp. Baja",
          type: "area",
          data: []
        }
      ];
    }
  }
  public filtro2(serie:string)
  {
    this.tramos = this.allTramos
    if(serie == "Todo")
    {
      this.getAllDatabyTramos(this.tam)
    }
    if(serie == "tmax")
    {
      this.allData = []
      this.allLabels = []
      this.allDataTemp = []
      this.tramos = this.allTramos;
      this.itemschartloading = true
      const observables = this.tramos.map(tramo =>
        forkJoin([
          this.weatherService.postForecast16(tramo['centroid']['latitude'], tramo['centroid']['altitude'],  this.userInfo.id),
          this.weatherService.getForecast16byFront(tramo['centroid']['latitude'], tramo['centroid']['altitude'],  this.userInfo.id)
        ])
      );
        
      forkJoin(observables).subscribe((responses: any[]) => {
        // console.log(responses)
        this.itemschartloading = false
        responses.forEach((response, index) => {
          const rpt = response[1]; // Obtener el resultado de la segunda llamada (getForecast16byFront)
          const dataLabel = [];
          const listElement = [
            { name: "Precipitación", type: "column", data: [] },
            { name: "Temp. Alta", type: "area", data: [] },
            { name: "Temp. Baja", type: "area", data: [] }
          ];
    
          for (let j = 0; j < this.tam; j++) {
            const day = new Date(rpt.list[j]['dt'] * 1000);
            const fecha = this.formatearFecha(day);
            dataLabel.push(fecha);
            listElement[1].data.push(Math.trunc(rpt.list[j]['temp']['max'] - 273));
          }
    
          this.allData[index] = listElement;
          this.allLabels.push(dataLabel);
          // console.log(this.allData)
        });
        // Renderizar gráficos después de que todas las llamadas asíncronas se completen
        // ...
      });
    }
    if(serie == "tmin")
    {
      this.allData = []
      this.allLabels = []
      this.allDataTemp = []
      this.tramos = this.allTramos;
      this.itemschartloading = true
      const observables = this.tramos.map(tramo =>
        forkJoin([
          this.weatherService.postForecast16(tramo['centroid']['latitude'], tramo['centroid']['altitude'], this.userInfo.id),
          this.weatherService.getForecast16byFront(tramo['centroid']['latitude'], tramo['centroid']['altitude'],  this.userInfo.id)
        ])
      );
        
      forkJoin(observables).subscribe((responses: any[]) => {
        // console.log(responses)
        this.itemschartloading = false
        responses.forEach((response, index) => {
          const rpt = response[1]; // Obtener el resultado de la segunda llamada (getForecast16byFront)
          const dataLabel = [];
          const listElement = [
            { name: "Precipitación", type: "column", data: [] },
            { name: "Temp. Alta", type: "area", data: [] },
            { name: "Temp. Baja", type: "area", data: [] }
          ];
    
          for (let j = 0; j < this.tam; j++) {
            const day = new Date(rpt.list[j]['dt'] * 1000);
            const fecha = this.formatearFecha(day);
            dataLabel.push(fecha);
            listElement[2].data.push(Math.trunc(rpt.list[j]['temp']['min'] - 273));
          }
    
          this.allData[index] = listElement;
          this.allLabels.push(dataLabel);
          // console.log(this.allData)
        });
        // Renderizar gráficos después de que todas las llamadas asíncronas se completen
        // ...
      });
    }
    if(serie == "prec")
    {
      this.allData = []
      this.allLabels = []
      this.allDataTemp = []
      this.tramos = this.allTramos;
      this.itemschartloading = true
      const observables = this.tramos.map(tramo =>
        forkJoin([
          this.weatherService.postForecast16(tramo['centroid']['latitude'], tramo['centroid']['altitude'],  this.userInfo.id),
          this.weatherService.getForecast16byFront(tramo['centroid']['latitude'], tramo['centroid']['altitude'],  this.userInfo.id)
        ])
      );
        
      forkJoin(observables).subscribe((responses: any[]) => {
        // console.log(responses)
        this.itemschartloading = false
        responses.forEach((response, index) => {
          const rpt = response[1]; // Obtener el resultado de la segunda llamada (getForecast16byFront)
          const dataLabel = [];
          const listElement = [
            { name: "Precipitación", type: "column", data: [] },
            { name: "Temp. Alta", type: "area", data: [] },
            { name: "Temp. Baja", type: "area", data: [] }
          ];
    
          for (let j = 0; j < this.tam; j++) {
            const day = new Date(rpt.list[j]['dt'] * 1000);
            const fecha = this.formatearFecha(day);
            dataLabel.push(fecha);
            listElement[0].data.push(rpt.list[j].rain || 0);
          }
    
          this.allData[index] = listElement;
          this.allLabels.push(dataLabel);
          // console.log(this.allData)
        });
        // Renderizar gráficos después de que todas las llamadas asíncronas se completen
        // ...
      });
    }
  
  }

  toggleSeriesVisibility(seriesIndex: number) {
    this.seriesVisibility[seriesIndex] = !this.seriesVisibility[seriesIndex];
  }
  //END FILTER MID CHART
  public compareSatellitalImages() {
    //const left = `&l=cite:${this.indexForm.value.index}_${this.indexForm.value.left}`;
    //const right = `&r=cite:${this.indexForm.value.index}_${this.indexForm.value.right}`;
    //// console.log(JSON.stringify(this.lotData.coordinates)); environment.images_comparison + left + right
    let lot_id = this.lotsQuery.getActiveId();
    let lot = this.lotsQuery.getEntity(lot_id);
    if (!lot) return;
    const center = 'c=' + lot.center[0][1] + ',' + lot.center[0][0];
    //window.open(url, '_blank');
    //// console.log(this.indexForm.value, url);
    this.dialog.open(ComparisonDialogComponent, {
      width: '50%',
      height: '50%',
      panelClass: 'modalClass',
      data: {
        center: center,
        datesInfo: this.datesResponse,
        coords: JSON.stringify(lot.coordinates),
        lot_id: lot_id,
      },
    });
  }

  addFileUploadControl(): void {
    const fileUploadControl = L.Control.extend({
      onAdd: () => {
        const button = L.DomUtil.create('button', 'leaflet-bar leaflet-control leaflet-control-custom');
        button.innerHTML = 'EXAMPLE';
        button.onclick = () => this.selectFile();
        return button;
      }
    });

    
      this.map.addControl(new fileUploadControl({ position: 'topright' }));
  }

  selectFile(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  
  @HostListener('change', ['$event'])
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const pdfData = reader.result as ArrayBuffer;
        this.graphRoutes()
      };
      reader.readAsArrayBuffer(file);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KeyCode.ENTER_KEY) {
      try {
        this.tmp.layer.editing.disable();
        this.onCreate(this.tmp);
      } catch (error) {}
    }
    if (event.keyCode == KeyCode.ESCAPE_KEY) {
      this.distance = 0;
      this.service = 1;
      this.clearAllDrawedLayers();
      this.mainPageComponent.showFieldList = true;
      if (this.layerInCreation === 1) {
        this.mapSidebarComService.sendClickToDeselect();
      }
      if (this.layerInCreation === 2) {
        this.mapSidebarComService.sendClickToDeselect();
        this.layerInCreation = 3;
      }
    }
  }
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html',
})
export class DialogConfirmationLot {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

@Component({
  selector: 'dialog-no-more-space',
  templateUrl: 'dialog-no-more-space.html',
})
export class NoMoreSpaceDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogMessage) {}
}

@Component({
  selector: 'dialog-no-image',
  templateUrl: 'dialog-no-image.html',
})
export class NoImageDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogMessage) {}
}

@Component({
  selector: 'dialog-peri',
  templateUrl: 'dialog-peri.html',
})
export class PerimeterDialog {
  constructor(public dialogRef: MatDialogRef<PerimeterDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  close(): void {
    this.dialogRef.close();
  }
}
