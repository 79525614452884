import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface PlagueData {
  description: string;
  image_url: string;
  prevent: string;
  supplement_name: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageProcessingService {
  private backendURL = `https://app.infoagro.pe/disease-recognition`;
  constructor(private http: HttpClient) {}

  sendPhotoToServer(formData: FormData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.backendURL}/submitPhoto`, formData, {
      headers,
    });
  }

  async convertImageToBlob(imageUri: string, filename: string = 'uploaded-image.jpg'): Promise<File> {
    const response = await fetch(imageUri);
    const blob = await response.blob();

    // Create a File object with the blob and filename
    const file = new File([blob], filename, { type: blob.type });
    return file;
  }

  getAllRegistredDiseases(user_id: string) {
    return this.http.get(`${this.backendURL}/diseaseplants/${user_id}`);
  }

  getRegistredDiseases(user_id: string, id: string) {
    return this.http.get(`${this.backendURL}/diseaseplants/${user_id}/${id}`);
  }

  putRegistredDiseases(user_id: string, id: string, data: any) {
    return this.http.patch(`${this.backendURL}/diseaseplants/${user_id}/${id}`, data);
  }
}
