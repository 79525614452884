import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface FieldsUiState {
	isOpen: boolean;
	selectedModal: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fields-ui' })
export class FieldsUiStore extends Store<FieldsUiState> {
	public constructor() {
		super({
			isOpen: false,
			selectedModal: 1,
		});
	}

	public openSidebar() {
		this.update({ isOpen: true });
	}

	public closeSidebar() {
		this.update({ isOpen: false });
	}

	public toggleSidebar() {
		this.update((x) => ({ isOpen: !x.isOpen }));
	}

	public setSelectedModal(modal: number) {
		this.update({
			selectedModal: modal,
		});
	}
}
