import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FieldMockService {
	private data = [
		{
			name: 'Test_01',
			lots: [
				{
					name: 'lot01',
					area: 190.9484,
					coordinates: [
						[-121.1958, 37.6683],
						[-121.1779, 37.6687],
						[-121.1773, 37.6792],
						[-121.1958, 37.6792],
						[-121.1958, 37.6683]
					],
					center: [[-121.1867, 37.67385]],
					id: '5a105299-0e33-4a13-aeea-7c8981dcc472'
				},
				{
					name: 'lot03',
					area: 190.4334,
					coordinates: [
						[-121.1957, 37.6683],
						[-121.1779, 37.6687],
						[-121.1773, 37.6792],
						[-121.1958, 37.6792],
						[-121.1957, 37.6683]
					],
					center: [[-121.18667500000001, 37.67385]],
					id: 'e8a8e39e-125c-4c42-bfd5-8e90f948c82d'
				},
				{
					name: 'lot02',
					area: 1.3354,
					coordinates: [
						[-76.89234, -12.171605],
						[-76.89212, -12.171757],
						[-76.891471, -12.172035],
						[-76.890736, -12.172077],
						[-76.889802, -12.172329],
						[-76.889727, -12.171621],
						[-76.89234, -12.171605]
					],
					center: [[-76.89103266666667, -12.171904]],
					id: '37f8cfa1-ae51-47b9-80f6-7d4ef7edd78e'
				},
				{
					name: 'lot02',
					area: 1.1874,
					coordinates: [
						[-79.966403, -5.215307],
						[-79.966435, -5.216429],
						[-79.965631, -5.216343],
						[-79.965497, -5.215195],
						[-79.966403, -5.215307]
					],
					center: [[-79.9659915, -5.2158185]],
					id: '8a93d087-9025-4113-880f-af6efa1591bc'
				}
			],
			id: 'f875c85f-fa56-48f8-a7cb-f752c5bd72f0'
		}
	];

	public constructor() {}

	public findAll() {
		return of(this.data)
			.pipe(delay(500))
			.toPromise();
	}
}
