import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

export interface AgrodatNotificationRecord {
    id?: number;
    date?: string;
    message?: {
        body?: string;
        title?: string;
    };
    readed: boolean;
}

export interface NotifyBody {
    statusCode: number;
    error: string;
    message: AgrodatNotificationRecord[];
}

@Injectable({ providedIn: "root" })
export class AgrodatHistoricalService {
    constructor(private http: HttpClient) {}

    public async findAll(): Promise<AgrodatNotificationRecord[]> {
        const data = await this.http.get<NotifyBody>(`${environment.apiUrl}/agrodat/notifications`).toPromise<NotifyBody>();
        return data.message;
    }
    
    public async update( id: any) {
        const data = await this.http.get<any>(`${environment.apiUrl}/agrodat/notifications/${id}/read`).toPromise<NotifyBody>();
        return data.message;
    }
    
    public async getNotReaded() {
        const data = await this.http.get<any>(`${environment.apiUrl}/agrodat/notifications/unread`).toPromise<any>();
        return data.message;
    }
}
