import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from '@store/user/user.service';
import { SidebarQuery } from '@src/app/store/sidebar/sidebar.query';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';

@Component({
	selector: 'app-home-layout',
	templateUrl: './home-layout.component.html',
	styleUrls: ['./home-layout.component.scss'],
})
export class HomeLayoutComponent implements OnInit {
	public isOpened = true;
	public isDock = false;
	public valueService = 0;
	constructor(
		private readonly sidebarQuery: SidebarQuery,
		private readonly sidebarService: SidebarService,
		private readonly userService: UserService,
	) {
		this.sidebarQuery.selectIsOpen.subscribe((x) => {
			this.isOpened = x;
		});
		this.sidebarQuery.selectIsDock.subscribe((x) => {
			this.isDock = x;
		});
		this.sidebarQuery.selectedService.subscribe((x)=>{
			this.valueService = x;
		})
	}

	public async ngOnInit() {
		// Los datos del usuario deben estar disponibles a nivel de aplicación
		await this.userService.loadUserData();
		this.sidebarService.onResize(window.innerWidth);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.sidebarService.onResize(window.innerWidth);
	}

	public toggleSidebar() {
		this.sidebarService.toggle();
	}

	public closeSidebar() {
		this.sidebarService.close();
	}
}
