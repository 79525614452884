import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexPlotOptions,
    ApexYAxis,
    ApexLegend,
    ApexStroke,
    ApexXAxis,
    ApexFill,
    ApexTooltip,
    ApexTitleSubtitle,
} from 'ng-apexcharts';

@Component({
    selector: 'app-labor-chart',
    templateUrl: './labor-chart.component.html',
    styleUrls: ['./labor-chart.component.scss'],
})
export class LaborChartComponent implements OnInit {
    @Input() series: ApexAxisChartSeries;
    @Input() dataUnit: string;
    //series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    constructor() {
        this.series = [];
        this.legend = {
            showForSingleSeries: true,
            labels: {
                colors: '#FFFFFF',
            },
        };
        this.title = {
            style: {
                color: '#FFFFFF',
            },
        };
        this.chart = {
            type: 'bar',
            width: '100%',
            height: '400px',
            redrawOnParentResize: true,
            toolbar: {
                show: false,
            },
        };
        this.plotOptions = {
            bar: {
                horizontal: false,
                columnWidth: '40%'
            },
        };
        this.dataLabels = {
            enabled: false,
        };
        this.stroke = {
            show: true,
            width: 2,
            colors: ['transparent'],
        };
        this.xaxis = {
            categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic'],
            labels: {
                style: {
                    cssClass: 'chartColor',
                },
            },
        };
        this.yaxis = {
            labels: {
                style: {
                    cssClass: 'chartColor',
                },
            },
            title: {
                style: {
                    cssClass: 'chartColor',
                },
            },
        };
        this.fill = {
            opacity: 1,
        };
        this.tooltip = {
            y: {
                formatter: (seriesName) => seriesName + ' ' + this.dataUnit,
            },
        };
    }

    ngOnInit() {}

    onResized(event: ResizedEvent) {
        this.chart = {
            type: 'bar',
            width: '100%',
            height: '400px',
            redrawOnParentResize: true,
            toolbar: {
                show: false,
            },
        };
    }
}
