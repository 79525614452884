import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ActivityState, ActivitiesStore, FullActivity } from './activities-storage.store'

@Injectable({ providedIn: 'root' })

export class ActivitiesStorageQuery extends QueryEntity<ActivityState> {
    public selectAllActivities: Observable<FullActivity[]> = this.selectAll();
    constructor(protected store: ActivitiesStore) {
        super(store);
    }

    public getAllActivitiesFromLot(lotId: string) {
        const allActivitiesFromLot = this.selectAll({
            filterBy: ({ camp_id }) => lotId == camp_id
        });
        return allActivitiesFromLot;
    }

}
