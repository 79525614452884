import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexOptions, ApexLegend, ApexStroke } from 'ng-apexcharts';

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
    legend: ApexLegend;
    colors: string[];
    stroke: ApexStroke;
};

@Component({
    selector: 'app-donut-resource',
    templateUrl: './donut-resource.component.html',
    styleUrls: ['./donut-resource.component.scss'],
})
export class DonutResourceComponent implements OnInit, OnChanges {
    public chartOptions: Partial<ChartOptions>;

    @Input()
    seriesValues = [0, 0, 0, 0, 0];

    constructor() {}
    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        if (this.seriesValues && this.chartOptions && this.chartOptions.series)
            this.updateData(changes.seriesValues.currentValue);
    }

    ngOnInit(): void {
        this.updateData(this.seriesValues);
    }

    public updateData(series) {
        this.chartOptions = {
            series: series,
            chart: {
                type: 'donut',
            },
            labels: ['Mano de Obra', 'Maquinaria', 'Agua', 'Insumos', 'Servicios'],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                            show: false,
                        },
                    },
                },
            ],
            legend: {
                show: false,
            },
            colors: ['#00b055', '#6e9bac', '#4dafe7', '#f6ff00', '#8b8b8b'],

            stroke: {
                show: false,
                width: 0,
            },
        };
    }

    onResized(){
        this.updateData(this.seriesValues);
    }
}
