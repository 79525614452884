import { Component, OnInit } from '@angular/core';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsService, SchemaRPT } from '@src/app/store/fields/fields.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { FieldsUiService } from '../../services/fields-ui.service';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';

@Component({
    selector: 'app-boundaries-state',
    templateUrl: './boundaries-state.component.html',
    styleUrls: ['./boundaries-state.component.scss']
})
export class BoundariesStateComponent implements OnInit {

    public dataSchema = undefined;
    public lotData = undefined;
    public fieldData = undefined;
    public perimeter = 0;

    constructor(
        private fieldsService: FieldsService,
        private lotQuery: LotsQuery,
        private fieldsQuery: FieldsQuery,
        private globalToastService: GlobalToastService,
        private mapSidebarComService: MapSidebarComService,
        private fieldUiService: FieldsUiService
    ) { }

    async ngOnInit() {
        this.perimeter = 0;
        var lotId = this.lotQuery.getActiveId();
        this.lotData = this.lotQuery.getEntity(lotId);
        var fieldId = this.fieldsQuery.getActiveId();
        this.fieldData = this.fieldsQuery.getEntity(fieldId);
        await this.fieldsService.getSchema(lotId).then((res: SchemaRPT) => {
            this.dataSchema = res.message;
            for (let index = 0; index < this.dataSchema.boundaries.length; index++) {
                const element = this.dataSchema.boundaries[index];
                element.display = false;
                this.perimeter = this.perimeter + element.distance
            }
            this.perimeter = Math.round(this.perimeter * 100) / 100
            this.dataSchema.lot_image.image_url = (this.dataSchema.lot_image.image_url).replace(".png","")
            this.dataSchema.lot_image.image_url = "https://paulet.space/media/" + this.dataSchema.lot_image.image_url
            this.dataSchema.boundaries.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        })
    }

    public async saveBoundary() {
        await this.fieldsService.updateSchema(this.dataSchema, this.lotData.id).then(rpt => {
        }).then(() => {
            this.globalToastService.createSuccessToast("Exito", "Se han guardado los datos")
        }).catch(() => {
            this.globalToastService.createSuccessToast("Error", "No se han guardado los datos, intente más tarde")
        })
    }

    public changeOwner(element, event) {
        element.owner = event.target.value
    }
    public changeCrop(element, event) {
        element.crop_name = event.target.value
    }
    public changePossesion(element, event) {
        element.possession_time = event.target.value
    }
    public changePesticideOption(element, event) {
        element.pesticides = event.value
    }

    public hideSelectedField() {
        this.fieldUiService.closeSidebar();
        this.mapSidebarComService.sendClickToDeselect();
    }
}
