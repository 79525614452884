import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LotEditStore, LotEditState } from './lot-edit.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LotEditQuery extends QueryEntity<LotEditState> {

  public selectSelectedResource: Observable<number> = this.select(
		(x) => x.selectedValue
	);

	public constructor(protected store: LotEditStore) {
		super(store);
	}

}
