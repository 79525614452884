import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

import { MachineStore, Machine } from './machine-storage.store';
import { MachineStorageQuery } from './machine-storage.query';

export interface Message {
    StatusCode: number,
    Error: string,
    message: Machine[]
}

@Injectable({ providedIn: 'root' })
export class MachineService {

    constructor(
        private machineStore: MachineStore,
        private machineStorageQuery: MachineStorageQuery,
        private http: HttpClient
    ) {
    }
    
    public updateActive(id){
        this.machineStore.setActive(id)
    }
    public async findAll(): Promise<Machine[]> {
        const hasCache = this.machineStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }

        try {
            const data = await this.http
                .get<Message>(`${environment.apiUrl}/machine/`)
                .toPromise<Message>();
            const dataArray = data.message
            const machines = dataArray.map((currentMachine: any) => {
                const labor = currentMachine;
                labor.id = String(labor.id);
                labor.quantity = String(labor.quantity);
                labor.cost = String(labor.cost);
                labor.coin = String(labor.coin)
                return labor;
            });
            this.machineStore.set(machines);
            return machines;
        }
        catch (error) {
        }
    }

    public async createManyMachines(arrayOfMachineObjects) {
        try {
            arrayOfMachineObjects.forEach(element => {
                element.dni = parseInt(element.dni);
                element.hours = parseInt(element.hours);
                element.coin = parseInt(element.coin);
                delete element.id;
                delete element.abbreviation;
                if (element.date === "")
                    element.date = null
                if (element.quantity === "")
                    element.quantity = null
            });
            const machine = await this.http
                .post<Message>(`${environment.apiUrl}/machine/`,
                    arrayOfMachineObjects
                )
                .toPromise<Message>();
            const dataArray = machine.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.machineStore.add(element)
            });
        } catch (error) {

        }
    }

    public async updateMany(arrayOfLaborObjects) {
        try {
            arrayOfLaborObjects.forEach((element) => {
                element.quantity = parseInt(element.quantity);
                element.coin = parseInt(element.coin);
                delete element.abbreviation;
                if (element.date === "")
                    element.date = null
                if (element.quantity === "")
                    element.quantity = null
            });
            const labor = await this.http
                .put<Message>(`${environment.apiUrl}/machine/`,
                    arrayOfLaborObjects
                )
                .toPromise<Message>();
            const dataArray = labor.message
            dataArray.forEach(element => {
                element.quantity = String(element.quantity);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.machineStore.update(element.id, element)
            });
        } catch (error) {

        }
    }

    public async deleteMany(arrayOfLaborObjects) {
        try {
            var idsOfMachines = []
            arrayOfLaborObjects.forEach(element => {
                idsOfMachines.push(parseInt(element))
            });
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    idsOfMachines
            };
            const labor = await this.http
                .delete(`${environment.apiUrl}/machine/`,
                    options
                )
                .toPromise();
            arrayOfLaborObjects.forEach(element => {
                this.machineStore.remove(element)
            });
        } catch (error) {
        }
    }
}   
