import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
export interface FielListState {
  selectedResource: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fiel-list' })
export class FielListStore extends Store<FielListState> {

  public constructor() {
		super({
			selectedResource: 1,
		});
	}
	public setSelectedResource(resource: number) {
		this.update({
			selectedResource: resource,
		});
	}

}

