import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthHttpService } from '@store/auth/auth-http.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	public constructor(private authService: AuthHttpService, private router: Router) { }

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(err => {
				// Si no está autorizado, regresa al inicio
				if (err.status === 401 && this.authService.isLoggedIn() && !this.router.routerState.snapshot.url.includes("/auth/login")) {
					this.authService.logout();
					location.reload();
				}

				const error = err.error.message || err.statusText;
				return throwError(error);
			})
		);
	}
}
