import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MetServiceService } from '@src/app/modules/agromet/services/met-service/met-service.service';
import { DialogActivityComponent } from '@src/app/modules/agromonitor/components/dialog-activity/dialog-activity.component';
import { ActivitiesService } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.service';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { Lot } from '@src/app/store/lots/lots.store';
import { PhenologicalDataQuery } from '@src/app/store/pheno-info/phenological-data.query';
import { PhenologicalDataService } from '@src/app/store/pheno-info/phenological-data.service';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { SubVarietyQuery } from '@src/app/store/subVariety/state/sub-variety.query';
import { UserQuery } from '@src/app/store/user/user.query';
import { VarietyQuery } from '@src/app/store/variety/state/variety.query';
import * as fileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SharedMapData } from '../../modules/fields/components/main-map/sharedMapData';
import { FieldsUiService } from '../../modules/fields/services/fields-ui.service';
import { MapSidebarComService } from '../../modules/fields/services/map-sidebar-com.service';
import { HistoricalCampaingComponent } from '../../modules/fields/sidebar-components/lot-detail/historical-campaing/historical-campaing.component';
import { LotDetailDeleteConfirmation } from '../../modules/fields/sidebar-components/lot-detail/lot-detail.component';
import { LotCropServiceService } from '../../services/lot-crop-service/lot-crop-service.service';
import { SharedLotsService } from '../../services/shared-lots/shared-lots.service';
import { LotEditQuery } from '../../states/lot-edit/lot-edit.query';
import { LotEditStore } from '../../states/lot-edit/lot-edit.store';
import { PhenologicalStateQuery } from '../../store/phenological-state-storage/phenological-state.query';
import { SatellitalImageService } from '../../store/satellital-images-state/satellital-images-state.service';

@Component({
  selector: 'app-shared-lot-detail',
  templateUrl: './shared-lot-detail.component.html',
  styleUrls: ['./shared-lot-detail.component.scss']
})
export class SharedLotDetailComponent implements OnInit {

  public lotData = undefined;
  public lotName = '';
  public fieldName = '';
  public fieldCropType = '';
  public fieldCropVariety = '';
  public lotArea = '';
  public lotCampaingName = '';
  public campaignId = undefined;
  public lotSubVarietyId = 0;
  public editEnable = false;
  public form: FormGroup;
  public formCampaing: FormGroup;
  public listOfCrops = [];
  public listOfCropsVarieties = [];
  public suscriptions: Subscription[] = [];
  public selectVisibilityFilter;
  public showNDVIGraph = false;
  public activitiesToday = [];
  public allActivities = [];
  public today = new Date();
  public monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  public campaingVarSelected = '0';
  public campaingSubVarSelected = '0';
  public campaingYearSelected = '-1';
  public campaingNumSelected = '-1';
  public layerSelected = '';
  public phenoName = '';
  public phenoDates = '';
  public tempInfo = undefined;
  public celciusSelected = true;
  public showIncidence = false;
  public showIncidenceButton = false;
  public dateSelected = '';
  public daySinceItWasPlanted = 0;
  public maxPhenoDuration = 0;
  public showCampaingOptions = false;
  public showCampaingEdition = false;
  public showCampaingCreation = false;
  public phenologicalStateOfCampaingID = 0;
  public enableReportButton = false;
  public hasSharedFields = false;
  public imagesPath = {
    Drizzle: 'assets/images/temp_rain.svg',
    Rain: 'assets/images/temp_rain.svg',
    Thunderstorm: 'assets/images/temp_storm.svg',
    Snow: 'assets/images/temp_snow.svg',
    Mist: 'assets/images/temp_fog.svg',
    Smoke: 'assets/images/temp_fog.svg',
    Haze: 'assets/images/temp_fog.svg',
    Dust: 'assets/images/temp_fog.svg',
    Fog: 'assets/images/temp_fog.svg',
    Sand: 'assets/images/temp_fog.svg',
    Ash: 'assets/images/temp_fog.svg',
    Squall: 'assets/images/temp_fog.svg',
    Tornado: 'assets/images/temp_fog.svg',
    Clear: 'assets/images/temp_clear.svg',
    Clouds: 'assets/images/temp_cloud_field.svg',
};

  constructor(
    private readonly formBuilder: FormBuilder,
    private mapSidebarComService: MapSidebarComService,
    public lotQuery: LotsQuery,
    public fieldsQuery: FieldsQuery,
    private fieldUiService: FieldsUiService,
    public lotCropService: LotCropServiceService,
    public dialog: MatDialog,
    public lotEditStore: LotEditStore,
    public lotEditQuery: LotEditQuery,
    private readonly subVarietyQuery: SubVarietyQuery,
    private readonly varietyQuery: VarietyQuery,
    private readonly globalToastService: GlobalToastService,
    private phenologicalStateQuery: PhenologicalStateQuery,
    private phenologicalService: PhenologicalDataService,
    private readonly sidebarService: SidebarService,
    private readonly router: Router,
    private metService: MetServiceService,
    private datePipe: DatePipe,
    private activitiesService: ActivitiesService,
    private fieldsService: FieldsService,
    private readonly spinner: NgxSpinnerService,
    private readonly userQuery: UserQuery,
    private satellitalImageService: SatellitalImageService,
    public shareData: SharedMapData,
    public readonly phenologicalQuery: PhenologicalDataQuery,
    private readonly sharedLots: SharedLotsService
  ) { }
 

  async ngOnInit() {
    if (this.sharedLots.sharedFields != undefined && this.sharedLots.sharedFields.length > 0)
      this.hasSharedFields = true;
    this.sharedLots.getUpdateSharedFields().subscribe(() => {
      if (this.sharedLots.sharedFields != undefined && this.sharedLots.sharedFields.length > 0)
        this.hasSharedFields = true;
    });
    this.sharedLots.getSelectedLotShared().subscribe(async () => {
      this.lotData = this.sharedLots.selectSharedLot;
      this.phenologicalStateOfCampaingID =
        this.lotData.campaigns[0].phonologicalState;
      this.calculateTemp();
      this.lotName = this.lotData.name;
      this.fieldName = this.lotData.fieldName;
      this.lotArea = this.lotData.area;
      this.lotCampaingName = this.lotData.campaigns[0].name
      let variety = this.subVarietyQuery.getEntity(this.lotData.campaigns[0].varietyId);
      if(variety){
        this.fieldCropVariety = variety.name;
        this.fieldCropType = variety.product.name;
        this.calculatePhenologicalState(variety.id);
      }

      this.getActivitiesShared(this.lotData.id)
      await this.sharedLots.getCollaborators(this.lotData.campaigns[0].id);
    })

    
    // this.suscriptions.push(
    //   this.lotEditQuery.selectSelectedResource.subscribe((x) => {
    //     this.selectVisibilityFilter = x;
    //     if (x == 1) {
    //       this.showIncidence = false;
    //       this.showNDVIGraph = false;
    //       this.layerSelected = '';
    //       this.showIncidenceButton = false;
    //     }
    //   }),
    //   this.satellitalImageQuery.selectSelectedResource.subscribe((x) => {
    //     if (this.lotData == undefined) {
    //       if (x.isSatellitalImageDisplayed) {
    //         this.showLy(x.satellitalImageType, 0);
    //         this.dateSelected =
    //           this.mapSidebarComService.dateSelected;
    //       }
    //     }
    //     if (!x.isSatellitalImageDisplayed) {
    //       this.layerSelected = '';
    //       this.showIncidence = false;
    //       this.showNDVIGraph = false;
    //       this.showIncidenceButton = false;
    //       this.enableReportButton = false;
    //       this.shareData.setNDVI(undefined);
    //     }
    //   }),
    // );
    // this.mapSidebarComService
    //   .getClickToDisplayorHideIncidence()
    //   .subscribe((x) => {
    //     this.dateSelected = this.mapSidebarComService.dateSelected;
    //     if (this.showIncidence) {
    //       this.showIncidence = false;
    //     }
    //   });

    this.mapSidebarComService
      .getClickToEnableReportButton()
      .subscribe(() => {
        this.enableReportButton =
          this.mapSidebarComService.enableReport;
      });

    // this.activitiesQuery.selectAll().subscribe((allActivities) => {
    //   this.allActivities = allActivities;
    //   if (this.lotData !== undefined) {
    //     this.filterTodayActivities(this.allActivities, this.lotData.id);
    //   }
    // });
    this.varietyQuery.selectAll().subscribe((elem) => {
      this.listOfCrops = elem;
    });

    this.subVarietyQuery.selectAll().subscribe((elem) => {
      this.listOfCropsVarieties = elem;
    });

    // this.lotQuery.selectAll().subscribe(async (allLots) => {
    //   await this.updateData();
    // });
    // this.lotQuery.selectActiveId().subscribe(async (x) => {
    //   await this.updateData();
    // });
  }
  public async getActivitiesShared(lotId){
    this.activitiesToday = []
    this.activitiesService.getShared(lotId).then( (activities) =>{
      this.activitiesToday = activities
    })
  }

  ngOnDestroy(): void {

  }

  public calculateTemp() {
    this.metService
      .getTemperatureForLot(
        this.lotData.center[0][1],
        this.lotData.center[0][0],
        this.lotData.id,
      )
      .then((res) => {
        this.tempInfo = res;
        this.tempInfo['main']['temp'] =
          Math.round(
            (((this.tempInfo['main']['temp'] - 273.15) * 9) / 5 +
              32) *
            10,
          ) / 10;
        this.tempInfo['main']['tempC'] =
          Math.round(
            (((this.tempInfo['main']['temp'] - 32) * 5) / 9) * 10,
          ) / 10;
      });
  }
  public async updateData() {
    var value = this.lotQuery.getEntity(this.lotQuery.getActiveId());
    this.lotCampaingName = '';
    this.fieldCropType = '';
    this.fieldCropVariety = '';
    this.campaignId = undefined;
    this.phenologicalStateOfCampaingID = 0;
    this.lotSubVarietyId = 0;
    this.clean();
    if (value == undefined) {
      return;
    }
    if (this.lotData == undefined) {
      this.lotData = value;
      this.calculateTemp();
    } else {
      if (this.lotData.id !== value.id) {
        this.lotData = value;
        this.calculateTemp();
      } else {
        this.lotData = value;
      }
    }
    this.filterTodayActivities(this.allActivities, this.lotData.id);
    this.lotName = this.lotData.name;
    this.lotArea = this.lotData.area;

    this.form = this.formBuilder.group({
      name: [
        (value as Lot).name,
        [Validators.required, Validators.maxLength(50)],
      ],
    });

    if (
      this.lotData !== undefined &&
      this.lotData.campaigns !== undefined &&
      this.lotData.campaigns.length > 0
    ) {
      for (
        let index = 0;
        index < this.lotData.campaigns.length;
        index++
      ) {
        const element = this.lotData.campaigns[index];
        if (!element.isFinished) {
          this.lotCampaingName = element.name;
          this.campaignId = element.id;
          this.lotSubVarietyId = element.varietyId;
          this.fieldCropType = this.lotData.varietyName;
          this.fieldCropVariety = this.lotData.subVarietyName;
          var subvar = this.subVarietyQuery.getEntity(
            this.lotSubVarietyId + '',
          );
          this.phenologicalStateOfCampaingID =
            element.phonologicalState;
          if (subvar !== undefined) {
            this.campaingSubVarSelected = subvar.id;
            this.campaingVarSelected = subvar.product.id;
          } else {
            this.campaingSubVarSelected = '0';
            this.campaingVarSelected = '0';
          }
          var campaingData = this.lotCampaingName.split('/');
          this.campaingYearSelected = campaingData[0].trim();
          this.campaingNumSelected = campaingData[1].trim();
          break;
        }
      }
    }
    if (this.phenologicalStateOfCampaingID !== 0) {
      await this.calculatePhenologicalState(this.lotSubVarietyId);
    } else {
      this.phenoName = '';
      this.phenoDates = '';
      this.mapSidebarComService.porcentagePhenological = 0;
      this.mapSidebarComService.sendClickToUpdatePhenologicalChart();
    }
  }
  private async calculatePhenologicalState(varietyId) {
    try {
      const variety = parseInt(
        this.subVarietyQuery.getEntity(varietyId).product.id,
      );
      const phenologicalOfVariety =
        this.phenologicalStateQuery.getEntity(variety);
      if (phenologicalOfVariety === undefined) {
        this.clean();
        return;
      } else {
        var phenoRegistred = this.phenologicalQuery.getEntity(
          this.phenologicalStateOfCampaingID,
        );
        if (phenoRegistred !== undefined) {
          this.calculateDataPhenological(
            phenoRegistred,
            phenologicalOfVariety,
          );
        } else {
          await this.phenologicalService
            .findOnePhenologicalState(
              this.phenologicalStateOfCampaingID,
            )
            .then((x) => {
              this.calculateDataPhenological(
                x,
                phenologicalOfVariety,
              );
            }).catch(()=>{
              console.log("No phenological result from server")
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  private calculateDataPhenological(x, phenologicalOfVariety) {
    var today = new Date();
    var date2 = undefined;
    if (phenologicalOfVariety.detail.length > 0) {
      if (phenologicalOfVariety.measure === 'DDS') {
        if (
          x.sowing_date === null ||
          x.classification !== phenologicalOfVariety.type
        ) {
          this.clean();
          return;
        }
        date2 = new Date(x.sowing_date);
      }
      if (phenologicalOfVariety.measure === 'DDP') {
        if (
          x.pruning_date === null ||
          x.classification !== phenologicalOfVariety.type
        ) {
          this.clean();
          return;
        }
        date2 = new Date(x.pruning_date);
      }
      if (phenologicalOfVariety.measure === 'IPP') {
        if (
          x.rainfall_date === null ||
          x.classification !== phenologicalOfVariety.type
        ) {
          this.clean();
          return;
        }
        date2 = new Date(x.rainfall_date);
      }
    }
    if (date2 === undefined) {
      this.clean();
      return;
    }
    var Difference_In_Time = today.getTime() - date2.getTime();
    var Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24),
    );
    this.daySinceItWasPlanted = Difference_In_Days;
    var base = 0;
    for (
      let index = 0;
      index < phenologicalOfVariety.detail.length;
      index++
    ) {
      const element = phenologicalOfVariety.detail[index];
      if (parseInt(element.maximun_days) > this.maxPhenoDuration) {
        this.maxPhenoDuration = parseInt(element.maximun_days);
      }
    }
    for (
      let index = 0;
      index < phenologicalOfVariety.detail.length;
      index++
    ) {
      const element = phenologicalOfVariety.detail[index];
      if (
        Difference_In_Days > base &&
        Difference_In_Days <= parseInt(element.maximun_days)
      ) {
        this.phenoName = element.name;
        this.phenoDates =
          element.minimun_days +
          ' - ' +
          element.maximun_days +
          ' días';
        this.mapSidebarComService.porcentagePhenological = Math.round(
          (100 * Difference_In_Days) / this.maxPhenoDuration,
        );
        this.mapSidebarComService.sendClickToUpdatePhenologicalChart();
        break;
      } else {
        base = parseInt(element.maximun_days);
      }
    }
  }
  public clean() {
    this.phenoName = '';
    this.phenoDates = '';
    this.mapSidebarComService.porcentagePhenological = 0;
    this.maxPhenoDuration = 0;
    this.daySinceItWasPlanted = 0;
    this.mapSidebarComService.sendClickToUpdatePhenologicalChart();
    this.showIncidence = false;
  }
  public showLy(layerType: string, callReq: number) {
    if (layerType == null) return;
    if (this.layerSelected === layerType) {
      this.layerSelected = '';
      this.satellitalImageService.setSatellitalImageDisplay(false);
      this.showIncidence = false;
      this.showNDVIGraph = false;
      this.mapSidebarComService.dateSelected = '';
    } else {
      this.layerSelected = layerType;

      this.showNDVIGraph = true;
      if (callReq == 1)
        this.satellitalImageService.updateStateSatellitalImage(
          true,
          layerType,
        );
    }
    if (this.layerSelected === 'NDVI') this.showIncidenceButton = true;
    else this.showIncidenceButton = false;
  }

  public displayIncidence() {
    this.showIncidence = !this.showIncidence;
  }
  public saveField(lotId, pheno) {
    if (this.form.invalid) {
      this.globalToastService.createWarnToast(
        'Advertencia',
        'Por favor ingrese un nombre',
      );
      return;
    }
    this.editEnable = false;
    this.mapSidebarComService.sentClickStopEdit(
      lotId,
      this.form.value.name,
      pheno,
    );
  }

  public closeBar() {
    this.fieldUiService.closeSidebar();
    this.editEnable = false;
    this.showCampaingOptions = false;
    this.mapSidebarComService.sentClickCancelEdit();
  }

  public hideSelectedField() {
    this.fieldUiService.closeSidebar();
    this.mapSidebarComService.sendClickToDeselect();
  }

  public filterTodayActivities(allActivities, id) {
    this.activitiesToday = [];
    for (let index = 0; index < allActivities.length; index++) {
      const element = allActivities[index];
      if (element.camp_id === id) {
        if (
          this.overlapWeek(
            this.today,
            new Date(element.activity.start_date),
            new Date(element.activity.end_date),
          )
        ) {
          this.activitiesToday.push(element);
        }
      }
    }
  }

  public overlapWeek(today, startdate, enddate) {
    return today >= startdate && today <= enddate;
  }

  public changeView() {
    this.sidebarService.updateSelectedService(7);
    this.router.navigate(['/agromonitor']);
  }

  async openDialog(activity): Promise<void> {
    let data = JSON.parse(JSON.stringify(activity));
    const dialogRef = this.dialog.open(DialogActivityComponent, {
      width: '600px',
      height: '540px',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      data: {
        name: data.activity.name,
        phase: data.activity.phase,
        detail: data.activity.detail,
        start: data.activity.start_date,
        end: data.activity.end_date,
        workingDay: data.activity.workday,
        duration: data.activity.duration,
        calendar: data.activity.schedule_setting,
        description: data.activity.description,
      },
    });

    dialogRef.afterClosed().subscribe(async (dataRecieve) => {
      if (dataRecieve.response == 'save') {
        data.activity.phase = dataRecieve.phase;
        data.activity.name = dataRecieve.name;
        data.activity.start_date = dataRecieve.start;
        data.activity.end_date = dataRecieve.end;
        data.activity.workday = dataRecieve.workingDay;
        data.activity.duration = dataRecieve.duration;
        data.activity.description = dataRecieve.description;
        data.activity.detail = [];
        dataRecieve.detail.forEach((detail) => {
          data.activity.detail.push({
            resource: detail.resource,
            percentage: detail.percentage,
            cost: '1',
            hour: 0,
            type: detail.type,
            id: detail.id != null ? detail.id : undefined,
          });
        });
        data.activity.start_date = this.datePipe.transform(
          data.activity.start_date,
          'yyyy-MM-dd',
        );
        data.activity.end_date = this.datePipe.transform(
          data.activity.end_date,
          'yyyy-MM-dd',
        );
        data.activity.schedule_setting = dataRecieve.calendar;
        if (data.id == undefined) {
          this.activitiesService.createOne([data]);
        } else {
          await this.activitiesService.updateActivitiesShared(this.lotData.id, [data]);
        }
        this.sidebarService.updateSelectedService(7);
      }
      await this.getActivitiesShared(this.lotData.id)
    });

    
    
  }

  public deleteActivity(activity) {
		const dialogRef = this.dialog.open(LotDetailDeleteConfirmation, {});
		dialogRef.afterClosed().subscribe(async (response) => {
			if (response)
				await this.activitiesService.deleteActivitiesShared(this.lotData.id, [activity.id]);
        await this.getActivitiesShared(this.lotData.id)
		});
	}

  public async saveReport() {
    if (!this.enableReportButton) {
      return;
    }
    this.spinner.show('downloadReport');
    try {
      //const delay = ms => new Promise(res => setTimeout(res, ms));
      //this.lotData
      const userInfo = this.userQuery.getInformation();
      const data = {
        name: userInfo.firstName + ' ' + userInfo.lastName,
        dni: userInfo.dni + '',
        address: userInfo.address,
        parcel_name: this.lotName,
        crop_name: this.fieldCropType,
        field_name: this.fieldName,
        variety_name: this.fieldCropVariety,
        pheno_porcentage:
          this.mapSidebarComService.porcentagePhenological,
        pheno_phase: this.phenoName,
        pheno_days: this.phenoDates,
        area: this.lotArea,
        lat: this.lotData.center[0][1] + '',
        lon: this.lotData.center[0][0] + '',
        day: this.daySinceItWasPlanted + '',
      };
      const lotNameForReport = this.lotName;
      await this.fieldsService
        .getPDF(this.lotData.id, data)
        .then((response: any) => {
          let blob: any = new Blob([response], {
            type: 'application/pdf',
          });
          const url = window.URL.createObjectURL(blob);
          fileSaver.saveAs(blob, this.lotName + '.pdf');
        })
        .catch((x) => {
          this.globalToastService.createWarnToast(
            '',
            'Los reportes estarán disponibles pronto',
          );
        });
    } catch (error) { }
    this.spinner.hide('downloadReport');
  }

  public showCampaing() {
    this.showCampaingOptions = !this.showCampaingOptions;
  }

  public showHistorical() {
    const dialogRef = this.dialog.open(HistoricalCampaingComponent, {
      minWidth: "550px",
      minHeight: "500px",
      maxHeight: "90vh",
      maxWidth: "90vw",
      data: this.lotData.id,
      panelClass: 'hist-camp-dialog'
    });
  }

}
