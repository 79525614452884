import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

import { ProviderStore, Provider } from './provider-storage.store';
import { ProviderStorageQuery } from './provider-storage.query';

export interface Message {
    statusCode: number,
    error: string,
    message: Provider
}
@Injectable({ providedIn: 'root' })
export class ProviderService {

    constructor(
        private providerStore: ProviderStore,
        private providerStorageQuery: ProviderStorageQuery,
        private http: HttpClient
    ) {
    }
    public async get(resource, type): Promise<Provider> {
        const hasCache = this.providerStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }
        try {
            const data = await this.http
                .get<any>(`${environment.apiAgromonitor}/provider/?resource=${resource}&type=${type}`)
                .toPromise<Message>();
            
            return data.message;
        }
        catch (error) {
        }
    }

    public async create(data: Provider) {
        try {
            const provider = await this.http
                .post<any>(`${environment.apiAgromonitor}/provider/`,
                    data
                )
                .toPromise<Message>();
            this.providerStore.add(provider.message)
            return provider.statusCode
        } catch (error) {

            return 500
        }
    }
}   
