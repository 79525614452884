import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MyFieldsModals } from '@modules/my-fields/enums/my-fields.enum';

export interface MyFieldsUiState {
	selectedResource: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-fields-ui' })
export class MyFieldsUiStore extends Store<MyFieldsUiState> {
	public constructor() {
		super({
			selectedResource: 1,
		});
	}

	public setSelectedResource(resource: number) {
		this.update({
			selectedResource: resource,
		});
	}
}
