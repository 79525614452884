import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SidebarState, SidebarStore } from './sidebar.store';
import { Services } from '@shared/enums/services';

@Injectable({ providedIn: 'root' })
export class SidebarQuery extends Query<SidebarState> {
	public selectIsOpen: Observable<boolean> = this.select(
		state => state.isOpen
	);

	public selectIsDock: Observable<boolean> = this.select(
		state => state.isDock
	);

	public selectedService: Observable<Services> = this.select(
		state => state.selectedService
	);

	public constructor(protected store: SidebarStore) {
		super(store);
	}
}
