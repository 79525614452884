import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { LotDroneTicket, LotDroneTicketStore } from './drone-ticket.store';
import { LotDroneTicketQuery } from './drone-ticket.query';

export interface LotDroneTicketResponse {
	statusCode: number,
	error: string,
	message: LotDroneTicket[]
}

@Injectable({ providedIn: 'root' })
export class LotDroneTicketService {
	public constructor(
		private readonly http: HttpClient,
		private readonly lotDroneTicketStore: LotDroneTicketStore,
		private readonly lotDroneTicketQuery: LotDroneTicketQuery
	) { }


	public async findAll() {
		try {
			const hasCache = this.lotDroneTicketQuery.getHasCache();
			if (hasCache) {
				return;
			}
			const rpt = await this.http.get<LotDroneTicketResponse>(`${environment.apiUrl}/ticket/`).toPromise<LotDroneTicketResponse>();
			this.lotDroneTicketStore.set(rpt.message);
			return rpt.message;
		} catch (error) {
			console.log(error)
		}
	}
	public async postDroneTicketsList(detail) {
		try {
			const rpt: any = await this.http.post(`${environment.apiUrl}/ticket/`, detail).toPromise();
			this.lotDroneTicketStore.add(rpt.message)
			return rpt.message;

		} catch (error) {
			console.log(error)
		}
	}

	public async deleteTicket(id_ticket, id_lot) {
		try {
			const info = {
				"id": id_ticket, "lot": id_lot
			}
			const options = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
				}),
				body:
					info
			};
			const rpt: any = await this.http.delete(`${environment.apiUrl}/ticket/`, options).toPromise();
			this.lotDroneTicketStore.remove(id_ticket)
			return rpt.message;

		} catch (error) {
			console.log(error)
		}
	}
}
