import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Money {
    id?: string;
    name?: string;
    abreviation?: string;
}
export interface MoneyState extends EntityState<Money, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'money-storage' })
export class MoneyStore extends EntityStore<MoneyState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "1",
                name: "Sol",
                abreviation: "S./"
            }
        )
    }
}

