import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Supply {
    id?: string;
    name?: string;
    kind?: string;
    abbreviation?: string;
    date?: string;
    cost: string;
    quantity?: string;
    unit?: string;
    coin?: string;
    observation?: string;
    costPerUnit?: string;
    used?: string;
    rest?: string;
    email_is_sended?: boolean
    email_to_send?: boolean
}

export interface EmailProvider {
    email: string;
    name: string;
    phone: string;
    provider: string;
    list_resources: EmailResource[];
}

export interface EmailResource{
    id: number,
    unit: string,
    amount: string,
    name: string
}
export interface SupplyState extends EntityState<Supply, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'supply-storage' })
export class SupplyStore extends EntityStore<SupplyState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "1",
                name: "semilla",
                kind: "semilla",          
                date: "2020-12-12",
                unit: "saco",
                quantity: "3",
                cost: "20",
                coin: "1",
                observation: "ninguna"
            }
        )
    }
}

