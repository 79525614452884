import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthHttpService } from '@store/auth/auth-http.service';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';

@Component({
	selector: 'app-select-plan',
	templateUrl: './select-plan.component.html',
	styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {
	public checkImage = 'assets/images/profile/check.svg';
	public crossImage = 'assets/images/profile/cross.svg';

	constructor(
		private readonly globalToast: GlobalToastService,
		private readonly router: Router,
		private readonly authService: AuthHttpService,
	) { }

	ngOnInit() {
		//this.store.updateUiState(2);
	}

	public async selectPlan(plan: number) {
		// this.store.update({
		// 	selectedPlan: plan
		// });

		// try {
		// 	const data = this.registerQuery.getValue();
		// 	await this.authService.register({
		// 		firstName: data.userInfo.firstName,
		// 		lastName: data.userInfo.lastName,
		// 		email: data.userInfo.email,
		// 		password: data.userInfo.password,
		// 		userType: data.profileType,
		// 		selectedPlan: data.selectedPlan,
		// 		dni: data.userInfo.dni,
		// 		phone: data.userInfo.phone
		// 	}).then(()=>{
		// 		this.globalToast.createSuccessToast("EXITO", "Se registro exitosamente");
		// 		this.router.navigate(['/auth/register/confirm-email']);
		// 	})
		// 	.catch((data) => {
		// 		this.globalToast.createErrorToast("ERROR", data);
		// 	});


		// } catch (error) {
		// 	this.globalToast.createErrorToast("ERROR", "Ocurrio un error al registrar");
		// }

	}
}
