import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';
import { LotsService } from '@src/app/store/lots/lots.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FieldsUiService } from '../../services/fields-ui.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { Lot } from '@src/app/store/lots/lots.store';
import { LotCropServiceService } from '@src/app/modules/my-fields/services/lot-crop-service/lot-crop-service.service';
import { MatDialog } from '@angular/material/dialog';
import { LotEditStore } from '@src/app/modules/my-fields/states/lot-edit/lot-edit.store';
import { LotEditQuery } from '@src/app/modules/my-fields/states/lot-edit/lot-edit.query';
import { Subscription } from 'rxjs';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { SubVarietyQuery } from '@src/app/store/subVariety/state/sub-variety.query';
import { VarietyQuery } from '@src/app/store/variety/state/variety.query';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { ActivitiesStorageQuery } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.query';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { MetServiceService } from '@src/app/modules/agromet/services/met-service/met-service.service';
import { DialogActivityComponent } from '@src/app/modules/agromonitor/components/dialog-activity/dialog-activity.component';
import { DatePipe } from '@angular/common';
import { ActivitiesService } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.service';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from 'file-saver';
import { UserQuery } from '@src/app/store/user/user.query';
import { SatellitalImageService } from '@src/app/modules/my-fields/store/satellital-images-state/satellital-images-state.service';
import { SatellitalImageQuery } from '@src/app/modules/my-fields/store/satellital-images-state/satellital-images-state.query';
import { SharedMapData } from '../../components/main-map/sharedMapData';
import { PhenologicalDataQuery } from '@src/app/store/pheno-info/phenological-data.query';
import { PhenologicalDataService } from '@src/app/store/pheno-info/phenological-data.service';
import { PhenologicalStateQuery } from '@src/app/modules/my-fields/store/phenological-state-storage/phenological-state.query';
import { HistoricalCampaingComponent } from './historical-campaing/historical-campaing.component';
import { SharedLotsService } from '@src/app/modules/my-fields/services/shared-lots/shared-lots.service';
import { CollaboratorInformationComponent } from '@src/app/modules/my-fields/components/collaborator-information/collaborator-information.component';
import { MainPageComponent } from '../../pages/main-page/main-page.component'
import { environment } from '@src/environments/environment';
import { ComparisonDialogComponent } from './comparison-dialog/comparison-dialog.component';
@Component({
	selector: 'app-lot-detail',
	templateUrl: './lot-detail.component.html',
	styleUrls: ['./lot-detail.component.scss'],
})
export class LotDetailComponent implements OnInit, OnDestroy {
	@Input()
	datesStats = []

	public indexForm = new FormGroup({
        index: new FormControl('', Validators.required),
        left: new FormControl('', Validators.required),
        right: new FormControl('', Validators.required),
    });

	public lotData = undefined;
	public lotName = '';
	public fieldName = '';
	public fieldCropType = '';
	public fieldCropVariety = '';
	public lotArea = '';
	public lotCampaingName = '';
	public campaignId = undefined;
	public lotSubVarietyId = 0;
	public editEnable = false;
	public form: FormGroup;
	public formCampaing: FormGroup;
	public listOfCrops = [];
	public listOfCropsVarieties = [];
	public suscriptions: Subscription[] = [];
	public selectVisibilityFilter;
	public showNDVIGraph = false;
	public activitiesToday = [];
	public allActivities = [];
	public today = new Date();
	public email = "";
	public monthNames = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	];
	public campaingVarSelected = '0';
	public campaingSubVarSelected = '0';
	public campaingYearSelected = '-1';
	public campaingNumSelected = '-1';
	public layerSelected = '';
	public phenoName = '';
	public phenoDates = '';
	public tempInfo = undefined;
	public celciusSelected = true;
	public showIncidence = false;
	public showIncidenceButton = false;
	public dateSelected = '';
	public daySinceItWasPlanted = 0;
	public maxPhenoDuration = 0;
	public showCampaingOptions = false;
	public showCampaingEdition = false;
	public showCampaingCreation = false;
	public phenologicalStateOfCampaingID = 0;
	public enableReportButton = false;
	public collaborators = [];
	public enableCollaboratorSearch = false;

	public imagesPath = {
        Drizzle: 'assets/images/temp_rain.svg',
        Rain: 'assets/images/temp_rain.svg',
        Thunderstorm: 'assets/images/temp_storm.svg',
        Snow: 'assets/images/temp_snow.svg',
        Mist: 'assets/images/temp_fog.svg',
        Smoke: 'assets/images/temp_fog.svg',
        Haze: 'assets/images/temp_fog.svg',
        Dust: 'assets/images/temp_fog.svg',
        Fog: 'assets/images/temp_fog.svg',
        Sand: 'assets/images/temp_fog.svg',
        Ash: 'assets/images/temp_fog.svg',
        Squall: 'assets/images/temp_fog.svg',
        Tornado: 'assets/images/temp_fog.svg',
        Clear: 'assets/images/temp_clear.svg',
        Clouds: 'assets/images/temp_cloud_field.svg',
    };


	constructor(
		private readonly formBuilder: FormBuilder,
		private mapSidebarComService: MapSidebarComService,
		private lotService: LotsService,
		public lotQuery: LotsQuery,
		public fieldsQuery: FieldsQuery,
		private fieldUiService: FieldsUiService,
		public lotCropService: LotCropServiceService,
		public dialog: MatDialog,
		public lotEditStore: LotEditStore,
		public lotEditQuery: LotEditQuery,
		private readonly subVarietyQuery: SubVarietyQuery,
		private readonly varietyQuery: VarietyQuery,
		private readonly globalToastService: GlobalToastService,
		private activitiesQuery: ActivitiesStorageQuery,
		private phenologicalStateQuery: PhenologicalStateQuery,
		private phenologicalService: PhenologicalDataService,
		private readonly sidebarService: SidebarService,
		private readonly router: Router,
		private metService: MetServiceService,
		private datePipe: DatePipe,
		private activitiesService: ActivitiesService,
		private fieldsService: FieldsService,
		private readonly spinner: NgxSpinnerService,
		private readonly userQuery: UserQuery,
		private satellitalImageService: SatellitalImageService,
		private satellitalImageQuery: SatellitalImageQuery,
		public shareData: SharedMapData,
		public readonly phenologicalQuery: PhenologicalDataQuery,
		private readonly sharedLots: SharedLotsService,
		public mainPageComponent: MainPageComponent
	) { }
	ngOnDestroy(): void { }

	async ngOnInit() {
		this.suscriptions.push(
			this.lotEditQuery.selectSelectedResource.subscribe((x) => {
				this.selectVisibilityFilter = x;
				if (x == 1) {
					this.showIncidence = false;
					this.showNDVIGraph = false;
					this.layerSelected = '';
					this.showIncidenceButton = false;
				}
			}),
			this.satellitalImageQuery.selectSelectedResource.subscribe((x) => {
				if (this.lotData == undefined) {
					if (x.isSatellitalImageDisplayed) {
						this.showLy(x.satellitalImageType, 0);
						this.dateSelected =
							this.mapSidebarComService.dateSelected;
					}
				}
				if (!x.isSatellitalImageDisplayed) {
					this.layerSelected = '';
					this.showIncidence = false;
					this.showNDVIGraph = false;
					this.showIncidenceButton = false;
					this.enableReportButton = false;
					this.shareData.setNDVI(undefined);
				}
			}),
		);
		this.mapSidebarComService
			.getClickToDisplayorHideIncidence()
			.subscribe((x) => {
				this.dateSelected = this.mapSidebarComService.dateSelected;
				if (this.showIncidence) {
					this.showIncidence = false;
				}
			});

		this.mapSidebarComService
			.getClickToEnableReportButton()
			.subscribe(() => {
				this.enableReportButton =
					this.mapSidebarComService.enableReport;
			});

		this.activitiesQuery.selectAll().subscribe((allActivities) => {
			this.allActivities = allActivities;
			if (this.lotData !== undefined) {
				this.filterTodayActivities(this.allActivities, this.lotData.id);
			}
		});
		this.varietyQuery.selectAll().subscribe((elem) => {
			this.listOfCrops = elem;
		});

		this.subVarietyQuery.selectAll().subscribe((elem) => {
			this.listOfCropsVarieties = elem;
		});

		this.lotQuery.selectAll().subscribe(async () => {
			await this.updateData();
		});
		this.lotQuery.selectActiveId().subscribe(async (x) => {
			await this.updateData();
		});
	}

	public filterCollaborators() {
		return this.collaborators.filter(c => c.userName.includes(this.email));
	}

	public collaboratorDetail(collaborator: any) {
		const dialogRef = this.dialog.open(CollaboratorInformationComponent, {
			panelClass: 'custom-dialog-container',
			data: collaborator
		});

		dialogRef.afterClosed().subscribe(data => {
			if (!data.response) {
				let index = this.collaborators.findIndex(c => c.id == collaborator.id);
				this.collaborators.splice(index, 1);
			}
		});


	}

	public calculateTemp() {
		this.metService
			.getTemperatureForLot(
				this.lotData.center[0][1],
				this.lotData.center[0][0],
				this.lotData.id
			)
			.then((res) => {
				this.tempInfo = res;
				this.tempInfo['main']['temp'] =
					Math.round(
						(((this.tempInfo['main']['temp'] - 273.15) * 9) / 5 +
							32) *
						10,
					) / 10;
				this.tempInfo['main']['tempC'] =
					Math.round(
						(((this.tempInfo['main']['temp'] - 32) * 5) / 9) * 10,
					) / 10;
			});
	}
	public async updateData() {
		
		this.fieldName = this.fieldsQuery.getEntity(
			this.fieldsQuery.getActiveId(),
		).name;
		var value = this.lotQuery.getEntity(this.lotQuery.getActiveId());
		this.lotCampaingName = '';
		this.fieldCropType = '';
		this.fieldCropVariety = '';
		this.campaignId = undefined;
		this.phenologicalStateOfCampaingID = 0;
		this.lotSubVarietyId = 0;
		this.clean();
		if (value == undefined) {
			return;
		}
		if (this.lotData == undefined) {
			this.lotData = value;
			this.calculateTemp();
			this.collaborators = await this.sharedLots.getCollaborators(this.lotData.campaigns[0].id);
		} else {
			if (this.lotData.id !== value.id) {
				this.lotData = value;
				this.calculateTemp();
			} else {
				this.lotData = value;
			}
		}
		this.filterTodayActivities(this.allActivities, this.lotData.id);
		this.lotName = this.lotData.name;
		this.lotArea = this.lotData.area;

		this.form = this.formBuilder.group({
			name: [
				(value as Lot).name,
				[Validators.required, Validators.maxLength(50)],
			],
		});

		if (
			this.lotData !== undefined &&
			this.lotData.campaigns !== undefined &&
			this.lotData.campaigns.length > 0
		) {
			for (
				let index = 0;
				index < this.lotData.campaigns.length;
				index++
			) {
				const element = this.lotData.campaigns[index];
				if (!element.isFinished) {
					this.lotCampaingName = element.name;
					this.campaignId = element.id;
					this.lotSubVarietyId = element.varietyId;
					this.fieldCropType = this.lotData.varietyName;
					this.fieldCropVariety = this.lotData.subVarietyName;
					var subvar = this.subVarietyQuery.getEntity(
						this.lotSubVarietyId + '',
					);
					this.phenologicalStateOfCampaingID =
						element.phonologicalState;
					if (subvar !== undefined) {
						this.campaingSubVarSelected = subvar.id;
						this.campaingVarSelected = subvar.product.id;
					} else {
						this.campaingSubVarSelected = '0';
						this.campaingVarSelected = '0';
					}
					var campaingData = this.lotCampaingName.split('/');
					this.campaingYearSelected = campaingData[0].trim();
					this.campaingNumSelected = campaingData[1].trim();
					break;
				}
			}
		}
		if (this.phenologicalStateOfCampaingID !== 0) {
			await this.calculatePhenologicalState(this.lotSubVarietyId);
		} else {
			this.phenoName = '';
			this.phenoDates = '';
			this.mapSidebarComService.porcentagePhenological = 0;
			this.mapSidebarComService.sendClickToUpdatePhenologicalChart();
		}
	}
	private async calculatePhenologicalState(varietyId) {
		try {
			const variety = parseInt(
				this.subVarietyQuery.getEntity(varietyId).product.id,
			);
			const phenologicalOfVariety =
				this.phenologicalStateQuery.getEntity(variety);
			
			if (phenologicalOfVariety === undefined) {
				this.clean();
				return;
			} else {
				var phenoRegistred = this.phenologicalQuery.getEntity(
					this.phenologicalStateOfCampaingID,
				);
				if (phenoRegistred !== undefined) {
					this.calculateDataPhenological(
						phenoRegistred,
						phenologicalOfVariety,
					);
				} else {
					await this.phenologicalService
						.findOnePhenologicalState(
							this.phenologicalStateOfCampaingID,
						)
						.then((x) => {
							this.calculateDataPhenological(
								x,
								phenologicalOfVariety,
							);
						});
				}
			}
		} catch (error) { }
	}
	private calculateDataPhenological(x, phenologicalOfVariety) {
		var today = new Date();
		var date2 = undefined;
		if (phenologicalOfVariety.detail.length > 0) {
			if (phenologicalOfVariety.measure === 'DDS') {
				if (
					x.sowing_date === null ||
					x.classification !== phenologicalOfVariety.type
				) {
					this.clean();
					return;
				}
				date2 = new Date(x.sowing_date);
			}
			if (phenologicalOfVariety.measure === 'DDP') {
				if (
					x.pruning_date === null ||
					x.classification !== phenologicalOfVariety.type
				) {
					this.clean();
					return;
				}
				date2 = new Date(x.pruning_date);
			}
			if (phenologicalOfVariety.measure === 'IPP') {
				if (
					x.rainfall_date === null ||
					x.classification !== phenologicalOfVariety.type
				) {
					this.clean();
					return;
				}
				date2 = new Date(x.rainfall_date);
			}
		}
		if (date2 === undefined) {
			this.clean();
			return;
		}
		var Difference_In_Time = today.getTime() - date2.getTime();
		var Difference_In_Days = Math.round(
			Difference_In_Time / (1000 * 3600 * 24),
		);
		this.daySinceItWasPlanted = Difference_In_Days;
		var base = 0;
		for (
			let index = 0;
			index < phenologicalOfVariety.detail.length;
			index++
		) {
			const element = phenologicalOfVariety.detail[index];
			if (parseInt(element.maximun_days) > this.maxPhenoDuration) {
				this.maxPhenoDuration = parseInt(element.maximun_days);
			}
		}
		for (
			let index = 0;
			index < phenologicalOfVariety.detail.length;
			index++
		) {
			const element = phenologicalOfVariety.detail[index];
			if (
				Difference_In_Days > base &&
				Difference_In_Days <= parseInt(element.maximun_days)
			) {
				this.phenoName = element.name;
				this.phenoDates =
					element.minimun_days +
					' - ' +
					element.maximun_days +
					' días';
				this.mapSidebarComService.porcentagePhenological = Math.round(
					(100 * Difference_In_Days) / this.maxPhenoDuration,
				);
				this.mapSidebarComService.sendClickToUpdatePhenologicalChart();
				break;
			} else {
				base = parseInt(element.maximun_days);
			}
		}
	}
	public clean() {
		this.phenoName = '';
		this.phenoDates = '';
		this.mapSidebarComService.porcentagePhenological = 0;
		this.maxPhenoDuration = 0;
		this.daySinceItWasPlanted = 0;
		this.mapSidebarComService.sendClickToUpdatePhenologicalChart();
		this.showIncidence = false;
	}
	public showLy(layerType: string, callReq: number) {
		if (layerType == null) return;
		if (this.layerSelected === layerType) {
			this.layerSelected = '';
			this.satellitalImageService.setSatellitalImageDisplay(false);
			this.showIncidence = false;
			this.showNDVIGraph = false;
			this.mapSidebarComService.dateSelected = '';
		} else {
			this.layerSelected = layerType;

			this.showNDVIGraph = true;
			if (callReq == 1)
				this.satellitalImageService.updateStateSatellitalImage(
					true,
					layerType,
				);
		}
		if (this.layerSelected === 'NDVI') this.showIncidenceButton = true;
		else this.showIncidenceButton = false;
	}

	public displayIncidence() {
		this.showIncidence = !this.showIncidence;
	}
	public saveField(lotId, pheno) {
		if (this.form.invalid) {
			this.globalToastService.createWarnToast(
				'Advertencia',
				'Por favor ingrese un nombre',
			);
			return;
		}
		this.editEnable = false;
		this.mapSidebarComService.sentClickStopEdit(
			lotId,
			this.form.value.name,
			pheno,
		);
	}

	public closeBar() {
		this.fieldUiService.closeSidebar();
		this.editEnable = false;
		this.showCampaingOptions = false;
		this.mapSidebarComService.sentClickCancelEdit();
	}

	public hideSelectedField() {
		this.fieldUiService.closeSidebar();
		this.mapSidebarComService.sendClickToDeselect();
	}

	public filterTodayActivities(allActivities, id) {
		this.activitiesToday = [];
		for (let index = 0; index < allActivities.length; index++) {
			const element = allActivities[index];
			if (element.camp_id === id) {
				if (
					this.overlapWeek(
						this.today,
						new Date(element.activity.start_date),
						new Date(element.activity.end_date),
					)
				) {
					this.activitiesToday.push(element);
				}
			}
		}
	}

	public overlapWeek(today, startdate, enddate) {
		return today >= startdate && today <= enddate;
	}

	public changeView() {
		this.sidebarService.updateSelectedService(7);
		this.router.navigate(['/agromonitor']);
	}

	public createActivity(camp_id) {
		let activity = {
			camp_id: camp_id,
			season: this.campaignId,
			activity: {
				name: '',
				phase: '',
				start_date: '',
				end_date: '',
				hour: 0,
				workday: 0,
				duration: 0,
				description: '',
				detail: [],
			},
		};
		this.openDialog(activity);
	}

	openDialog(activity): void {
		let data = JSON.parse(JSON.stringify(activity));
		const dialogRef = this.dialog.open(DialogActivityComponent, {
			width: '600px',
			height: '540px',
			disableClose: true,
			panelClass: 'custom-dialog-container',
			data: {
				name: data.activity.name,
				phase: data.activity.phase,
				detail: data.activity.detail,
				start: data.activity.start_date,
				end: data.activity.end_date,
				workingDay: data.activity.workday,
				duration: data.activity.duration,
				calendar: data.activity.schedule_setting,
				description: data.activity.description,
			},
		});

		dialogRef.afterClosed().subscribe((dataRecieve) => {
			if (dataRecieve.response == 'save') {
				data.activity.phase = dataRecieve.phase;
				data.activity.name = dataRecieve.name;
				data.activity.start_date = dataRecieve.start;
				data.activity.end_date = dataRecieve.end;
				data.activity.workday = dataRecieve.workingDay;
				data.activity.duration = dataRecieve.duration;
				data.activity.description = dataRecieve.description;
				data.activity.detail = [];
				dataRecieve.detail.forEach((detail) => {
					data.activity.detail.push({
						resource: detail.resource,
						percentage: detail.percentage,
						cost: '1',
						hour: 0,
						type: detail.type,
						id: detail.id != null ? detail.id : undefined,
					});
				});
				data.activity.start_date = this.datePipe.transform(
					data.activity.start_date,
					'yyyy-MM-dd',
				);
				data.activity.end_date = this.datePipe.transform(
					data.activity.end_date,
					'yyyy-MM-dd',
				);
				data.activity.schedule_setting = dataRecieve.calendar;
				if (data.id == undefined) {
					this.activitiesService.createOne([data]);
				} else {
					this.activitiesService.updateActivities([data]);
				}
				this.sidebarService.updateSelectedService(7);
			}
		});
	}

	public async saveReport() {
		if (!this.enableReportButton) {
			return;
		}
		this.spinner.show('downloadReport');
		try {
			//const delay = ms => new Promise(res => setTimeout(res, ms));
			//this.lotData
			const userInfo = this.userQuery.getInformation();
			const data = {
				name: userInfo.firstName + ' ' + userInfo.lastName,
				dni: userInfo.dni + '',
				address: userInfo.address,
				parcel_name: this.lotName,
				crop_name: this.fieldCropType,
				field_name: this.fieldName,
				variety_name: this.fieldCropVariety,
				pheno_porcentage:
					this.mapSidebarComService.porcentagePhenological,
				pheno_phase: this.phenoName,
				pheno_days: this.phenoDates,
				area: this.lotArea,
				lat: this.lotData.center[0][1] + '',
				lon: this.lotData.center[0][0] + '',
				day: this.daySinceItWasPlanted + '',
			};
			const lotNameForReport = this.lotName;
			await this.fieldsService
				.getPDF(this.lotData.id, data)
				.then((response: any) => {
					let blob: any = new Blob([response], {
						type: 'application/pdf',
					});
					const url = window.URL.createObjectURL(blob);
					fileSaver.saveAs(blob, this.lotName + '.pdf');
				})
				.catch((x) => {
					this.globalToastService.createWarnToast(
						'',
						'Los reportes estarán disponibles pronto',
					);
				});
		} catch (error) { }
		this.spinner.hide('downloadReport');
	}

	public showCampaing() {
		this.showCampaingOptions = !this.showCampaingOptions;
	}

	public deleteActivity(activity) {
		const dialogRef = this.dialog.open(LotDetailDeleteConfirmation, {});
		dialogRef.afterClosed().subscribe((response) => {
			if (response)
				this.activitiesService.deleteActivities([activity.id]);
		});
	}

	public async finishCampaing() {
		await this.lotService.updateCampaing(
			this.lotData.id,
			this.campaignId,
			this.lotCampaingName,
			this.lotSubVarietyId,
			true,
			this.phenologicalStateOfCampaingID,
		);
		this.showCampaingOptions = false;
		this.showCampaingCreation = false;
		this.showCampaingEdition = false;
		//this.closeBar()
	}

	public async displayCreateNewCampaing() {
		this.showCampaingCreation = true;
		this.showCampaingEdition = false;
		this.showCampaingOptions = false;
	}

	public showHistorical() {
		this.mainPageComponent.showFieldList = false;
		const dialogRef = this.dialog.open(HistoricalCampaingComponent, {
			minWidth: "550px",
			minHeight: "500px",
			maxHeight: "85vh",
			maxWidth: "90vw",
			height: '85vh',
			width: '90vw',
			position: {
				top: 'calc(6vh + 57px)'
			},
			data: this.lotData.id,
			panelClass: 'hist-camp-dialog'
		});
		dialogRef.beforeClosed().subscribe(() => {
			this.mainPageComponent.showFieldList = true;
		})
	}

	public async createNewCampaing() {
		if (
			this.campaingYearSelected !== '-1' &&
			this.campaingNumSelected !== '-1'
		) {
			await this.lotService
				.createNewCampaing(
					this.lotData.id,
					this.campaingYearSelected +
					' / ' +
					this.campaingNumSelected,
					parseInt(this.campaingSubVarSelected),
					false,
				)
				.then(() => {
					this.showCampaingCreation = false;
					this.globalToastService.createSuccessToast(
						'Completado',
						'Se ha creado correctamente',
					);
				});
		} else {
			this.globalToastService.createWarnToast(
				'Advertencia',
				'Por favor seleccione un año y un número de campaña',
			);
		}
	}

	public enableEditCampaing() {
		this.showCampaingEdition = true;
		this.showCampaingOptions = false;
	}

	public showComparation(){
		if(this.indexForm.invalid) return;
		const left = `&l=cite:${this.indexForm.value.index}_${this.indexForm.value.left}`;
        const right = `&r=cite:${this.indexForm.value.index}_${this.indexForm.value.right}`;
		//console.log(JSON.stringify(this.lotData.coordinates));
        const url = environment.images_comparison + 'c=' +this.lotData.center[0][1] + ',' + this.lotData.center[0][0] + left + right;
        //window.open(url, '_blank');
		//console.log(this.indexForm.value, url);
		this.dialog.open(ComparisonDialogComponent,{
			width:"50%",
			height:"50%",
			panelClass:'modalClass',
			data:{url: url, datesInfo: this.datesStats}
		})
	}

	public async updateCampaing() {
		if (
			this.campaingYearSelected !== '-1' &&
			this.campaingNumSelected !== '-1'
		) {
			await this.lotService
				.updateCampaing(
					this.lotData.id,
					this.campaignId,
					this.campaingYearSelected +
					' / ' +
					this.campaingNumSelected,
					parseInt(this.campaingSubVarSelected),
					false,
					this.phenologicalStateOfCampaingID,
				)
				.then(() => {
					this.showCampaingEdition = false;
					this.showCampaingOptions = false;
					this.globalToastService.createSuccessToast(
						'Completado',
						'Se ha actualizado correctamente',
					);
				});
		} else {
			this.globalToastService.createWarnToast(
				'Advertencia',
				'Por favor seleccione un año y un número de campaña',
			);
		}
	}
}

@Component({
	selector: 'confirmation-delete',
	templateUrl: 'confirmation-delete.html',
})
export class LotDetailDeleteConfirmation { }
