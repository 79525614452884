import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { StadisticsQuery } from '@src/app/store/stadistics/stadistics.query';
import { MetereologyObject, StadisticsService } from '@src/app/store/stadistics/stadistics.service';
import { StadisticsSingleObject } from '@src/app/store/stadistics/stadistics.store';
import { environment } from '@src/environments/environment';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexLegend,
    ApexMarkers,
    ApexStroke,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';

@Component({
    selector: 'app-statistics-chart',
    templateUrl: './statistics-chart.component.html',
    styleUrls: ['./statistics-chart.component.scss'],
})
export class StatisticsChartComponent implements OnInit {
    @Output()
    propagar = new EventEmitter<any>();

    public NDVI_data = [];
    public NDWI_data = [];
    public SAVI_data = [];
    public showChart = false;

    series1: ApexAxisChartSeries;
    chart1: ApexChart;
    xaxis1: ApexXAxis;
    dataLabels1: ApexDataLabels;
    yaxis1: ApexYAxis;
    fill1: ApexFill;
    stroke1: ApexStroke;
    markers1: ApexMarkers;
    colors1: string[];

    series2: ApexAxisChartSeries;
    chart2: ApexChart;
    xaxis2: ApexXAxis;
    dataLabels2: ApexDataLabels;
    yaxis2: ApexYAxis;
    fill2: ApexFill;
    stroke2: ApexStroke;
    markers2: ApexMarkers;
    colors2: string[];

    legend: ApexLegend;
    title: ApexTitleSubtitle;

    public metData = undefined;

    public displayCelsius = true;

    constructor(
        private stadisticsService: StadisticsService,
        private stadisticsQuery: StadisticsQuery,
        private lotQuery: LotsQuery,
    ) {
        this.legend = {
            labels: {
                colors: '#FFFFFF',
            },
        };
        this.title = {
            style: {
                color: '#FFFFFF',
            },
        };
        this.series1 = [
            {
                name: 'NDVI',
                data: this.NDVI_data,
            },
        ];
        this.chart1 = {
            id: 'chart2',
            type: 'line',
            height: 400,
            toolbar: {
                autoSelected: 'pan',
                show: false,
            },
        };
        this.colors1 = ['#FF6384', '#36A2EB', '#FFCE56'];
        this.stroke1 = {
            width: 2,
        };
        this.dataLabels1 = {
            enabled: false,
        };
        this.fill1 = {
            opacity: 1,
        };
        this.markers1 = {
            size: 0,
        };
        this.xaxis1 = {
            type: 'datetime',
            labels: {
                style: {
                    cssClass: 'chartColor',
                },
            },
        };
        this.yaxis1 = {
            tickAmount: undefined,
            min: -1,
            max: 1,
            labels: {
                style: {
                    cssClass: 'chartColor',
                },
            },
            title: {
                style: {
                    cssClass: 'chartColor',
                },
            },
        };

        this.series2 = [
            {
                name: 'NDVI',
                data: this.NDVI_data,
            },
        ];
        this.chart2 = {
            id: 'chart1',
            height: 130,
            type: 'area',
            brush: {
                target: 'chart2',
                enabled: true,
            },
            selection: {
                enabled: true,
                fill: {
                    color: 'white',
                    opacity: 0.6,
                },
            },
        };
        this.colors2 = ['#008FFB'];
        this.fill2 = {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.9,
                opacityTo: 0.2,
            },
        };
        this.xaxis2 = {
            type: 'datetime',
            tooltip: {
                enabled: false,
            },
            labels: {
                style: {
                    cssClass: 'chartColor',
                },
            },
        };
        this.yaxis2 = {
            tickAmount: 2,
            min: -1,
            max: 1,
            labels: {
                style: {
                    cssClass: 'chartColor',
                },
            },
            title: {
                style: {
                    cssClass: 'chartColor',
                },
            },
        };
    }

    public onResized(event) {
        this.series1 = [
            {
                name: 'NDVI',
                data: this.NDVI_data,
            },
            {
                name: 'NDWI',
                data: this.NDWI_data,
            },
            {
                name: 'SAVI',
                data: this.SAVI_data,
            },
        ];
        this.series2 = [
            {
                name: 'NDVI',
                data: this.NDVI_data,
            },
        ];
        this.chart2 = {
            id: 'chart1',
            height: 130,
            type: 'area',
            brush: {
                target: 'chart2',
                enabled: true,
            },
            selection: {
                enabled: true,
                xaxis: {
                    min: this.NDVI_data[3][0],
                    max: this.NDVI_data[this.NDVI_data.length - 3][0],
                },
                fill: {
                    color: 'white',
                    opacity: 0.6,
                },
            },
        };
    }

    async ngOnInit() {
        const lot_id = this.lotQuery.getActiveId();
        const lot = this.lotQuery.getEntity(lot_id);
        if (lot_id) {
            const stats = this.stadisticsQuery.getEntity(lot_id);
            if (stats !== undefined) {
                this.updateStats(stats.stats);
                this.onPropagar(this.series1);
                this.showChart = true;
            } else {
                await this.stadisticsService
                    .getEstadisticsInformation(lot_id)
                    .then((res) => {
                        if (res.status === 'SUCCESS') {
                            this.updateStats(res.detail);
                            this.showChart = true;
                            this.onPropagar(this.series1);
                        }
                    })
                    .catch(() => {
                        this.onPropagar(null);
                    });
            }
            console.log('Campaings', lot);
            let notFinishedCampaings = lot.campaigns.filter((elem) => !elem.isFinished);
            let activeCampaing;
            if (notFinishedCampaings.length > 0) activeCampaing = notFinishedCampaings[0];
            if (activeCampaing) {
                await this.stadisticsService
                    .getMetereologyPromCampaing(lot_id, activeCampaing.id)
                    .then((rpt) => {
                        console.log(rpt);
                        this.metData = {
                            clouds: 0,
                            humidity: 0,
                            temperature: 0,
                            wind: 0,
                            temperatureC: 0,
                            temperatureF: 0,
                        };
                        this.metData.clouds = rpt.clouds;
                        this.metData.humidity = rpt.humidity;
                        this.metData.temperature = rpt.temperature;
                        this.metData.temperatureC = rpt.temperatureC;
                        this.metData.wind = rpt.wind;
                        this.metData.temperatureF = rpt.temperatureC > 0 ? Math.round(((rpt.temperatureC * 9) / 5 + 32) * 10) / 10 : 0;
                    })
                    .catch((err) => {
                        console.log('Error al conectar al historial de met', err);
                    });
                //console.log(environment.apiUrl + '/agromet/stadistics/lot/' + lot_id +'/campaign/' + activeCampaing.id)
            }
        }
    }

    private updateStats(dataFromServer: StadisticsSingleObject[]) {
        var stats = JSON.parse(JSON.stringify(dataFromServer));
        stats.sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateA.getTime() - dateB.getTime();
        });

        for (let index = 0; index < stats.length; index++) {
            const element = stats[index];
            const dateUnixTime = new Date(element.date);
            for (let indexStadistic = 0; indexStadistic < element.statistics.length; indexStadistic++) {
                const value = Math.round(element.statistics[indexStadistic].average * 10) / 10;
                if (element.statistics[indexStadistic].name == 'NDVI')
                    this.NDVI_data.push([dateUnixTime.getTime(), value]);

                if (element.statistics[indexStadistic].name == 'NDWI')
                    this.NDWI_data.push([dateUnixTime.getTime(), value]);

                if (element.statistics[indexStadistic].name == 'SAVI')
                    this.SAVI_data.push([dateUnixTime.getTime(), value]);
            }
        }
        this.series1 = [
            {
                name: 'NDVI',
                data: this.NDVI_data,
            },
            {
                name: 'NDWI',
                data: this.NDWI_data,
            },
            {
                name: 'SAVI',
                data: this.SAVI_data,
            },
        ];

        this.series2 = [
            {
                name: 'NDVI',
                data: this.NDVI_data,
            },
        ];
        this.chart2 = {
            id: 'chart1',
            height: 130,
            type: 'area',
            brush: {
                target: 'chart2',
                enabled: true,
            },
            selection: {
                enabled: true,
                xaxis: {
                    min: this.NDVI_data[3][0],
                    max: this.NDVI_data[this.NDVI_data.length - 3][0],
                },
                fill: {
                    color: 'white',
                    opacity: 0.6,
                },
            },
        };
    }

    private onPropagar(data) {
        this.propagar.emit(data);
    }
}
