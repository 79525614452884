import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VarietyStore, Variety } from './variety.store';
import { tap } from 'rxjs/operators';
import { VarietyQuery } from './variety.query';
import { environment } from '@src/environments/environment';

@Injectable({ providedIn: 'root' })
export class VarietyService {
	constructor(
		private http: HttpClient,
		private readonly varietyQuery: VarietyQuery,
		private readonly varietyStore: VarietyStore,
	) {}

	public async findAll(): Promise<Variety[]> {
		const hasCache = this.varietyQuery.getHasCache();
		if (hasCache) {
			return;
		}
		try {
			/*
            var localVarieties = localStorage.getItem("varieties1.0")
            if (localVarieties == undefined) {
                */
			const data = await this.http
				.get<Variety[]>(`${environment.apiUrlAgroDat}/product/`)
				.toPromise<Variety[]>();
			const varieties = data.map((currentVariety: any) => {
				const variety = currentVariety;
				variety.id = String(variety.id);
				return variety;
			});
			this.varietyStore.set(varieties);
			return varieties;
		} catch (error) {
			console.log(error);
		}
	}
}
