import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Product {
    id?: string;
    camp_id?: string;
    camp_name?: string;
    camp_area?: string;
    lot_id?: string;
    lot_name?: string;
    lot_area?: string;
    variety_id?: string;
    variety_name?: string;
    subvariety_name?: string;
    season?: string;
    image?: string;
    image_file?: any;
    image_url?: any;
    zone?: string;
    quantity?: string;
    unit?:string;
    start_date?: string;
    end_date?: string;
    price?: string;
    total?: string;
    lost?: string;
}
export interface ProductState extends EntityState<Product, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'product-storage' })
export class ProductStore extends EntityStore<ProductState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "1",
                camp_id: "1",
                camp_name: "camp",
                camp_area: "100",
                lot_id: "1",
                lot_name: "lot_name",
                lot_area: "100",
                variety_id: '2',
                variety_name: "variety",
                subvariety_name: "subvariety",
                season: "2020-1",
                image: null,
                image_file: null,
                image_url: null,
                zone: "100",
                quantity: "100.00",
                start_date: "1",
                end_date: "",
                price: "",
                total: "",
                lost: "",
            }
        )
    }
}

