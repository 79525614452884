import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';


export interface AgrodatNotify {
    "id"? : number,
    "frecuency" : String,
    "price" : String,
    "variety" : number,
    "by_push_notification"?: Boolean,
    "by_sms"?: Boolean,
    "by_email"? : Boolean
    "edit_mode"? : Boolean
}

export interface AgrodatNotifyState extends EntityState<AgrodatNotify, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'agrodatNotify' })
export class AgrodatNotifyStore extends EntityStore<AgrodatNotifyState> {
    constructor() {
        super();
    }
}

