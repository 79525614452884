import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { FullActivity } from '../../storage/activities-storage/activities-storage.store';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ManageModalActivityService } from '../../services/manage-modal-activity.service';
import { Header } from '../../constants/header';
import { PreviewActivity } from '../../constants/previewActivity';

@Component({
    selector: 'app-month-view',
    templateUrl: './month-view.component.html',
    styleUrls: ['./month-view.component.scss']
})
export class MonthViewComponent implements OnInit, AfterViewChecked {

    public headers: Header[];
    @ViewChild('scrollOne') scrollOne: ElementRef;
    @Input() lotsFromServer: [];
    @Input() lotZoom: boolean;
    public percentage: number;
    public activities: PreviewActivity[];
    public allActivities: FullActivity[];
    public months: string[];
    public month: number;
    public year: number;
    public dateToPadding = 1;

    constructor(
        private activitiesService: ActivitiesService,
        private activitiesQuery: ActivitiesStorageQuery,
        public dialog: MatDialog,
        private sharedDataService: SharedDataServiceService,
        private sanitizer: DomSanitizer,
        private manageModalActivityService: ManageModalActivityService) {
        this.activitiesService.findAll();
        this.activities = [];
        this.initializeData()
        this.activitiesQuery.selectAll().subscribe(data => {
            this.allActivities = JSON.parse(JSON.stringify(data));
            this.updatePreviewActivity(this.allActivities);
        });

        this.sharedDataService.getClickToUpdateCalendar().subscribe(x => {
            this.initializeData()
            this.updatePreviewActivity(this.allActivities);
        })
    }

    ngOnInit(): void { }

    public initializeData() {
        this.year = this.sharedDataService.yearSelected;
        this.month = this.sharedDataService.monthSelected;
        this.getDaysOfMonth(this.year, this.month);
    }

    ngAfterViewChecked(): void {
        this.updateScrollToActualDate();
        this.dateToPadding = 0;
        const scrollOne = this.scrollOne.nativeElement as HTMLElement;
        scrollOne.scrollTop = this.sharedDataService.scrollVertical;
    }

    private getDaysOfMonth(year, month) {
        this.headers = [];
        var date = new Date(year, 0, 1);
        let week: number = 1;
        let detail = [];
        let totalDays = 0;
        while (date.getFullYear() == year) {
            detail.push(date.getDate());
            date.setDate(date.getDate() + 1);
            if (date.getDay() == 0) {
                this.headers.push(this.createWeek(week.toString(), detail))
                week += 1;
                detail = [];
            }
            totalDays += 1;
        }
        if (detail.length != 0)
            this.headers.push(this.createWeek(week.toString(), detail))

        this.percentage = 100 / 31;
    }

    private createWeek(numberWeek: string, detail) {
        let pre = "Semana ";
        if (detail.length == 1)
            pre = "";
        if (detail.length > 1 && detail.length <= 3)
            pre = "S"

        return {
            title: pre + numberWeek,
            detail: detail
        }
    }

    private getDuration(fullActivity: FullActivity) {
        let start = new Date(this.year, 0, 1);
        let temp = new Date(fullActivity.activity.start_date);
        let totalDays = 0;
        temp.setDate(temp.getDate() + 1);
        temp.setHours(0);
        temp.setMinutes(0);
        temp.setSeconds(0);
        if (temp > start) {
            start = temp;
        }
        let end = new Date(this.year + 1, 0, 1);
        temp = new Date(fullActivity.activity.end_date);
        temp.setDate(temp.getDate() + 1);
        temp.setHours(0);
        temp.setMinutes(0);
        temp.setSeconds(0);
        if (temp < end) {
            end = temp;
        }
        let diff = end.getTime() - start.getTime();
        diff = diff / (1000 * 60 * 60 * 24);
        if (diff < 0)
            diff = 0;
        let duration = start.getTime() - (new Date(this.year, 0, 1)).getTime();
        duration = duration / (1000 * 60 * 60 * 24);
        if (duration < 0)
            duration = 0;
        return {
            duration: Math.floor(diff),
            start: Math.floor(duration) + 1,
        }
    }
    public nextMonth(aum: number) {
        this.month += aum;
        if (this.month < 0) {
            this.year -= 1;
            this.month = 11;
        }
        if (this.month > 11) {
            this.year += 1;
            this.month = 0;
        }

    }

    public createActivity(lot, event) {
        let activity = {
            camp_id: lot.id,
            season: lot.campaign,
            activity: {
                name: "",
                phase: "",
                start_date: "",
                end_date: "",
                hour: 0,
                workday: 0,
                duration: 0,
                detail: [],
                description: ""
            }
        }
        this.openDialog(activity, event)
    }

    openDialog(data, e): void {
        e.stopPropagation();
        e.preventDefault();
        this.manageModalActivityService.openDialogActivity(this.dialog, data);
    }

    public updatePreviewActivity(activities) {
        this.activities = [];
        var lots: { [id: string]: number; } = {};
        activities.forEach(activity => {
            if (lots[activity.camp_id] != undefined)
                lots[activity.camp_id] += 1
            else
                lots[activity.camp_id] = 0
            
            //if (lots[activity.camp_id] < 4) {
            let durationActivity = this.getDuration(activity);
            if (durationActivity.duration > 0) {
                this.activities.push({
                    duration: durationActivity.duration,
                    fullActivity: activity,
                    start: durationActivity.start,
                    index: lots[activity.camp_id]
                })
            }
            //}
        }
        );
    }

    public getWidth(index: number, length: number) {
        let border = 1
        if (index != 0)
            border = 0
        if (index == this.headers.length - 1)
            border = 0
        return this.sanitizer.bypassSecurityTrustStyle("calc(" + length * 20 + "px - " + border + "px)");
    }

    public scrollUp = 0;
    public updateScroll() {
        const scrollOne = this.scrollOne.nativeElement as HTMLElement;

        let start = new Date(this.year, 0, 1);
        start.setDate(start.getDate() + Math.floor(scrollOne.scrollLeft / 20));

        if (scrollOne.scrollLeft >= 31 * 20) {
            this.sharedDataService.monthSelected = start.getMonth();
            this.sharedDataService.sentUpdateToCalendarScroll();
        }
        else {
            this.sharedDataService.monthSelected = 0;
            this.sharedDataService.sentUpdateToCalendarScroll();
        }

        if (this.scrollUp !== scrollOne.scrollTop) {
            this.sharedDataService.scrollVertical = scrollOne.scrollTop;
        }
    }

    public updateScrollToActualDate() {
        if (this.dateToPadding < 1) {
            return;
        }
        let duration = Date.now() - (new Date(this.year, 0, 1)).getTime();
        duration = Math.floor(duration / (1000 * 60 * 60 * 24));
        this.scrollOne.nativeElement.scrollLeft = duration * 20;
    }
}
