import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CalendarState, CalendarStore } from './calendar-storage.store'

@Injectable({ providedIn: 'root' })

export class CalendarStorageQuery extends QueryEntity<CalendarState> {
    constructor(protected store: CalendarStore) {
        super(store);
    }

}
