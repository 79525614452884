import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { MyFieldsUiState, MyFieldsUiStore } from './my-fields-ui.store';
import { MyFieldsModals } from '@modules/my-fields/enums/my-fields.enum';

@Injectable({ providedIn: 'root' })
export class MyFieldsUiQuery extends Query<MyFieldsUiState> {

	public selectSelectedResource: Observable<number> = this.select(
		(x) => x.selectedResource
	);

	public constructor(protected store: MyFieldsUiStore) {
		super(store);
	}
}
