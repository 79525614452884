import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Labor {
    id?: string;
    name?: string;
    dni?: string;
    abbreviation: string;
    labour?: string;
    start_date?: string;
    end_date?: string;
    unit?: string;
    hours?: string;
    cost?: string;
    costPerHour: string;
    observation?: string;
    coin?: string;
}
export interface LaborState extends EntityState<Labor, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'labor-storage' })
export class LaborStore extends EntityStore<LaborState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "1",
                name: "asd",
                dni: "12345678",
                labour: "asd",
                abbreviation: '',
                start_date: "2020-12-12",
                end_date: "2020-12-12",
                unit: "asd",
                hours: "100",
                cost: "100.00",
                observation: "ninguna",
                coin: "1",
                costPerHour: ""
            }
        )
    }
}

