import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogActivityComponent } from '../../components/dialog-activity/dialog-activity.component';
import { MatTableDataSource } from '@angular/material/table';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';
import {
	Activity,
	ActivityDetail,
	FullActivity,
} from '../../storage/activities-storage/activities-storage.store';
import { DatePipe } from '@angular/common';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { CalendarService } from '../../storage/calendar-storage/calendar-storage.service';
import { ExportExcelService } from '../../services/export-excel.service';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { Router } from '@angular/router';
import { phases } from '../../constants/phasesActivity';
import { ResourceService } from '../../shared-data/resources.service';
import { ModalActivityParameters } from '../../constants/modalActivityParameters';
import { LaborStorageQuery } from '../../storage/labor-storage/labor-storage.query';
import { MachineStorageQuery } from '../../storage/machine-storage/machine-storage.query';
import { SupplyStorageQuery } from '../../storage/supplies-storage/supplies-storage.query';
import { WaterStorageQuery } from '../../storage/water-storage/water-storage.query';
import { ServiceStorageQuery } from '../../storage/serv-other-storage/serv-other-storage.query';

export interface ModalData {
	phase: string;
	workingDay: number;
	start: Date | null;
	end: Date | null;
	duration: number;
	name: string;
	detail: ActivityDetail[];
}

export interface Campaign {
	id: number;
	name: string;
}

@Component({
	selector: 'app-activity-management',
	templateUrl: './activity-management.component.html',
	styleUrls: ['./activity-management.component.scss'],
})
export class ActivityManagementComponent implements OnInit {
	public displayedColumns: string[] = [
		'field',
		'lot',
		'campaign',
		'phase',
		'activity',
		'duration',
		'inicio',
		'fin',
		'totalHours',
		'laborHours',
		'machineHours',
		'totalCost',
	];
	public dataSource = new MatTableDataSource();
	public copiedElement: FullActivity = null;
	public fields = [];
	public activities = [];
	public activitiesModified;
	public activitiesDeleted;
	public campaigns: Campaign[] = [];
	public filterSelected = 0;
	public filterText = '';
	public phases: string[];
	public activityFilters = [
		{ value: 1, name: 'Campo' },
		{ value: 2, name: 'Parcela' },
		{ value: 3, name: 'Campaña/Temporada' },
		{ value: 4, name: 'Fase' },
		{ value: 5, name: 'Actividad' },
		{ value: 6, name: 'Duración' },
		{ value: 7, name: 'Inicio' },
		{ value: 8, name: 'Fin' },
	];

	constructor(
		public dialog: MatDialog,
		private fieldsQuery: FieldsQuery,
		private fieldsService: FieldsService,
		private activitiesService: ActivitiesService,
		private activitiesQuery: ActivitiesStorageQuery,
		private datePipe: DatePipe,
		private calendarService: CalendarService,
		private exportExcel: ExportExcelService,
		private readonly sidebarService: SidebarService,
		private lotsQuery: LotsQuery,
		private readonly router: Router,
		private resourcesGenericService: ResourceService,
		private laborQuery: LaborStorageQuery,
		private machineQuery: MachineStorageQuery,
		private supplyQuery: SupplyStorageQuery,
		private waterQuery: WaterStorageQuery,
		private servAndOthersQuery: ServiceStorageQuery,
	) {
		this.campaigns = [];
		this.phases = phases;
		this.fieldsService.findAll();
		const allLots = this.lotsQuery.getAll().length;

		if (allLots < 1) this.router.navigate(['/agromonitor']);

		this.resourcesGenericService.getAllResources();
		this.calendarService.findAll();
		this.activitiesModified = new Set();
		this.activitiesDeleted = new Set();
		this.fieldsQuery.selectFieldsWithLots().subscribe((data) => {
			this.fields = data;
			this.activities.forEach((fullActivity) => {
				for (let i = 0; i < this.fields.length; ++i) {
					for (let j = 0; j < this.fields[i].lots.length; ++j) {
						if (fullActivity.camp_id == this.fields[i].lots[j].id) {
							fullActivity.field = this.fields[i].id;
							break;
						}
					}
					if (
						fullActivity.field != '' &&
						fullActivity.field != null &&
						fullActivity.field != undefined
					)
						break;
				}
			});
		});
		this.activitiesService.findAll();
		this.activitiesQuery.selectAll().subscribe((data) => {
			this.activities = JSON.parse(JSON.stringify(data));
			this.activities.forEach((fullActivity) => {
				this.calculateCost(fullActivity);
				for (let i = 0; i < this.fields.length; ++i) {
					for (let j = 0; j < this.fields[i].lots.length; ++j) {
						if (fullActivity.camp_id == this.fields[i].lots[j].id) {
							fullActivity.field = this.fields[i].id;
							break;
						}
					}
					if (
						fullActivity.field != '' &&
						fullActivity.field != null &&
						fullActivity.field != undefined
					)
						break;
				}
			});
			if (this.activities.length < 20)
				for (let i = 0; this.activities.length < 20; ++i)
					this.activities.push(this.createActivity());

			this.dataSource = new MatTableDataSource(this.activities);
		});
	}

	calculateCost(fullActivity) {
		fullActivity.activity.totalCost = 0;
		fullActivity.activity.machineCost = 0;
		fullActivity.activity.laborCost = 0;
		var totalSum = 0;
		var totalLabor = 0;
		var totalMachine = 0;
		var alreadyListedLabor = [];
		var alreadyListedMachine = [];
		var alreadyListedResource = [];
		var alreadyListedWater = [];
		var alreadyListedServices = [];

		for (
			let index = 0;
			index < fullActivity.activity.detail.length;
			index++
		) {
			const element = fullActivity.activity.detail[index];

			if (element.type == 0) {
				if (alreadyListedLabor.includes(element.resource)) continue;
				alreadyListedLabor.push(element.resource);
				const resourceObj = this.laborQuery.getEntity(element.resource);
				if (resourceObj !== undefined) {
					const horasHombrePrice =
						parseFloat(resourceObj.cost) /
						parseFloat(resourceObj.hours);

					const hoursLabor =
						fullActivity.activity.workday *
						fullActivity.activity.duration *
						(element.percentage / 100);

					const totalValue = hoursLabor * horasHombrePrice;

					totalSum = totalSum + totalValue;
					totalLabor = totalLabor + hoursLabor;
				}
			}
			if (element.type == 1) {
				if (alreadyListedMachine.includes(element.resource)) continue;
				alreadyListedMachine.push(element.resource);
				const resourceObj = this.machineQuery.getEntity(
					element.resource,
				);
				if (resourceObj !== undefined) {
					const horasHombrePrice = parseFloat(resourceObj.cost);

					const hoursMachine =
						fullActivity.activity.workday *
						fullActivity.activity.duration *
						(element.percentage / 100);

					const totalValue = hoursMachine * horasHombrePrice;

					totalSum = totalSum + totalValue;
					totalMachine = totalMachine + hoursMachine;
				}
			}
			if (element.type == 2) {
				if (alreadyListedWater.includes(element.resource)) continue;
				alreadyListedWater.push(element.resource);
				const resourceObj = this.waterQuery.getEntity(element.resource);
				if (resourceObj !== undefined) {
					const costPerProduct = parseFloat(resourceObj.cost);
					const totalValue = element.percentage * costPerProduct;
					totalSum = totalSum + totalValue;
				}
			}
			if (element.type == 3) {
				if (alreadyListedResource.includes(element.resource)) continue;
				alreadyListedResource.push(element.resource);
				const resourceObj = this.supplyQuery.getEntity(
					element.resource,
				);
				if (resourceObj !== undefined) {
					const unityCost =
						parseFloat(resourceObj.cost) /
						parseFloat(resourceObj.quantity);

					const totalValue = element.percentage * unityCost;
					totalSum = totalSum + totalValue;
				}
			}
			if (element.type == 4) {
				if (alreadyListedServices.includes(element.resource)) continue;
				alreadyListedServices.push(element.resource);
				const resourceObj = this.servAndOthersQuery.getEntity(
					element.resource,
				);
				if (resourceObj !== undefined) {
					const totalValue =
						element.percentage * parseFloat(resourceObj.cost);
					totalSum = totalSum + totalValue;
				}
			}
		}
		if (totalSum > 0) totalSum = Math.round(totalSum * 100) / 100;
		if (totalLabor > 0) totalLabor = Math.round(totalLabor * 100) / 100;
		if (totalMachine > 0)
			totalMachine = Math.round(totalMachine * 100) / 100;

		fullActivity.activity.totalCost = totalSum.toLocaleString('en-US');
		fullActivity.activity.machineCost =
			totalMachine.toLocaleString('en-US');
		fullActivity.activity.laborCost = totalLabor.toLocaleString('en-US');
	}

	ngOnInit(): void {
		this.sidebarService.updateSelectedService(7);
	}

	createActivity(): FullActivity {
		return {
			field: '',
			camp_id: '',
			season: '',
			activity: {
				id: '',
				name: '',
				phase: '',
				start_date: '',
				end_date: '',
				hour: 0,
				workday: 0,
				duration: 0,
				detail: [],
			},
		};
	}

	public addRow(position) {
		this.activities.splice(position, 0, this.createActivity());
		this.dataSource = new MatTableDataSource(this.activities);
	}

	public copy(element: Activity) {
		this.copiedElement = JSON.parse(JSON.stringify(element));
		this.copiedElement.id = null;
		this.copiedElement.activity.id = '';
	}

	public paste(position) {
		this.activities[position] = this.copiedElement;
		this.dataSource = new MatTableDataSource(this.activities);
		this.copiedElement = null;
	}

	public delete(position) {
		if (
			this.activities[position].id != undefined &&
			this.activities[position].id != null
		) {
			this.activitiesDeleted.add(this.activities[position].id);
			if (this.activitiesModified.has(this.activities[position].id))
				this.activitiesModified.delete(this.activities[position].id);
		}
		this.activities.splice(position, 1);
		this.dataSource = new MatTableDataSource(this.activities);
	}

	public getLots(fieldId: string) {
		if (this.fields == undefined || this.fields == null) return [];

		for (let i = 0; i < this.fields.length; i++) {
			if (this.fields[i].id == fieldId) {
				return this.fields[i].lots;
			}
		}
		return [];
	}

	public getCampaigns(lotId: string, fieldId: string) {
		if (this.fields == undefined || this.fields == null) return [];
		for (let i = 0; i < this.fields.length; i++) {
			if (this.fields[i].id == fieldId) {
				for (let j = 0; j < this.fields[i].lots.length; j++) {
					if (this.fields[i].lots[j].id == lotId)
						return this.fields[i].lots[j].campaigns;
				}
			}
		}
		return [];
	}

	openDialog(data, index): void {
		if (data.id != undefined && data.id != null)
			this.activitiesModified.add(data.id);

		const dialogRef = this.dialog.open(DialogActivityComponent, {
			width: ModalActivityParameters.WIDTH,
			height: ModalActivityParameters.HEIGHT,
			disableClose: ModalActivityParameters.DISABLE_CLOSE,
			panelClass: 'custom-dialog-container',
			data: {
				name: data.activity.name,
				phase: data.activity.phase,
				detail: data.activity.detail,
				start: data.activity.start_date,
				end: data.activity.end_date,
				workingDay: data.activity.workday,
				duration: data.activity.duration,
				calendar: data.activity.schedule_setting,
				description: data.activity.description,
			},
		});

		dialogRef.afterClosed().subscribe((data) => {
			if (data.response == 'save') {
				this.activities[index].activity.phase = data.phase;
				this.activities[index].activity.name = data.name;
				this.activities[index].activity.start_date = data.start;
				this.activities[index].activity.end_date = data.end;
				this.activities[index].activity.workday = data.workingDay;
				this.activities[index].activity.duration = data.duration;
				this.activities[index].activity.description = data.description;
				this.activities[index].activity.delete = data.delete;
				this.activities[index].activity.detail = [];
				this.activities[index].activity.schedule_setting =
					data.calendar;
				data.detail.forEach((detail) => {
					this.activities[index].activity.detail.push({
						resource: detail.resource,
						percentage: detail.percentage,
						hour: 0,
						type: detail.type,
						id: detail.id != null ? detail.id : undefined,
					});
				});
				this.dataSource = new MatTableDataSource(this.activities);
			}
		});
	}

	saveData() {
		let newsActivitiesToSave = [];
		let activitiesToUpdate = [];
		let activitiesToDelete: Array<number> = Array.from(
			this.activitiesDeleted,
		);

		this.activities.forEach((fullActivity) => {
			let copy = JSON.parse(JSON.stringify(fullActivity));
			if (
				copy.activity.start_date != '' &&
				copy.activity.end_date != ''
			) {
				copy.activity.start_date = this.datePipe.transform(
					copy.activity.start_date,
					'yyyy-MM-dd',
				);
				copy.activity.end_date = this.datePipe.transform(
					copy.activity.end_date,
					'yyyy-MM-dd',
				);
				if (fullActivity.id == null) newsActivitiesToSave.push(copy);
				else if (this.activitiesModified.has(fullActivity.id))
					activitiesToUpdate.push(copy);
			}
		});

		if (newsActivitiesToSave.length >= 1)
			this.activitiesService.createOne(newsActivitiesToSave);
		if (activitiesToUpdate.length >= 1)
			this.activitiesService.updateActivities(activitiesToUpdate);
		if (activitiesToDelete.length >= 1)
			this.activitiesService.deleteActivities(activitiesToDelete);
		this.activitiesModified = new Set();
	}

	public filterData(filterValue) {
		this.setDataSourceFilter();
		this.filterText = filterValue;
		this.dataSource.filter = filterValue;
	}

	public changeFilterOption(event) {
		this.filterSelected = event;
		if (+this.filterSelected != 0) this.filterData(this.filterText);
	}

	public getNameField(fieldId) {
		for (let i = 0; i < this.fields.length; i++)
			if (this.fields[i].id == fieldId) return this.fields[i].name;
		return '';
	}

	public getNameLot(LotId, fieldId) {
		let lots = this.getLots(fieldId);
		for (let i = 0; i < lots.length; i++)
			if (lots[i].id == LotId) return lots[i].name;
		return '';
	}

	public getNameCampaign(fieldId, lotId, campaignId) {
		if (this.fields == undefined || this.fields == null) return '';
		for (let i = 0; i < this.fields.length; i++) {
			if (this.fields[i].id == fieldId) {
				for (let j = 0; j < this.fields[i].lots.length; j++) {
					if (this.fields[i].lots[j].id == lotId)
						for (
							let k = 0;
							k < this.fields[i].lots[j].campaigns.length;
							k++
						) {
							if (
								this.fields[i].lots[j].campaigns[k].id ==
								campaignId
							)
								return this.fields[i].lots[j].campaigns[k].name;
						}
				}
			}
		}
		return '';
	}

	public setDataSourceFilter() {
		switch (+this.filterSelected) {
			case 1:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return this.getNameField(data.field)
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 2:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return this.getNameLot(data.camp_id, data.field)
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 3:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return this.getNameCampaign(
						data.field,
						data.camp_id,
						data.season,
					)
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 4:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return data.activity.phase
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 5:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return data.activity.name
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 6:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return data.activity.duration >= +filter;
				};
				break;
			case 7:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return data.activity.start_date
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 8:
				this.dataSource.filterPredicate = (
					data: FullActivity,
					filter: string,
				) => {
					return data.activity.end_date
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
		}
	}
	public getTotalCost(activity) {
		let totalHours = activity.activity.duration * activity.activity.workday;
		let totalCost = 0;
		for (let i = 0; i < activity.activity.detail.length; ++i)
			totalCost += activity.activity.detail[i].cost * activity.activity.detail[i].percentage * totalHours / 100
		return totalCost;
	}

	public exportToExcel() {
		let dataToExcel = [];
		this.activities.forEach((fullActivity) => {
			let copy = JSON.parse(JSON.stringify(fullActivity));
			if (
				copy.activity.start_date != '' &&
				copy.activity.end_date != ''
			) {
				copy.activity.start_date = this.datePipe.transform(
					copy.activity.start_date,
					'yyyy-MM-dd',
				);
				copy.activity.end_date = this.datePipe.transform(
					copy.activity.end_date,
					'yyyy-MM-dd',
				);
				copy.camp_id = this.getNameLot(copy.camp_id, copy.field);
				copy.field = this.getNameField(copy.field);
				copy.season = this.getNameCampaign(
					copy.field,
					copy.camp_id,
					copy.season,
				);
				dataToExcel.push(copy);
				for (let s = 0; s < copy.activity.detail.length; s++) {
					this.resourcesGenericService.resources.forEach(
						(resources) => {
							if (
								resources.id == copy.activity.detail[s].resource
							) {
								copy.activity.detail[s].name =
									resources.type == 2
										? 'agua'
										: resources.name;
								copy.activity.detail[s].cost = +resources.cost;
							}
						},
					);
				}
			}
		});
		this.exportExcel.exportActivityExcel(dataToExcel);
		
	}
}
