import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ProductState, Product, ProductStore } from './product-storage.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ProductStorageQuery extends QueryEntity<ProductState> {
	public selectAllFields: Observable<Product[]> = this.selectAll();
	constructor(protected store: ProductStore) {
		super(store);
	}
}
