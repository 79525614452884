import { Injectable } from '@angular/core';

import { SatellitalImageStore } from './satellital-images-state.store';
//import { MyFieldsModals } from '@modules/my-fields/enums';

@Injectable({ providedIn: 'root' })
export class SatellitalImageService {
	public constructor(private readonly satellitalImageStore: SatellitalImageStore) {}
	
	public resetStateSatellitalImage(){
		this.satellitalImageStore.resetStateSatellitalImage();
	}

	public updateStateSatellitalImage(isSatellitalImageDisplayed, satellitalImageType){
		this.satellitalImageStore.updateStateSatellitalImage(isSatellitalImageDisplayed, satellitalImageType);
	}

	public setSatellitalImageType(satellital_image_type: string) {
		this.satellitalImageStore.setSatellitalImageType(satellital_image_type);
	}

	public setSatellitalImageDisplay(display: boolean) {
		this.satellitalImageStore.setSatellitalImageDisplay(display);
	}

	public setLotIdForSatellitalImage(idLot: string) {
		this.satellitalImageStore.setLotIdForSatellitalImage(idLot);
	}

}
