import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedLotsService } from '../../services/shared-lots/shared-lots.service';

@Component({
  selector: 'app-collaborator-information',
  templateUrl: './collaborator-information.component.html',
  styleUrls: ['./collaborator-information.component.scss']
})
export class CollaboratorInformationComponent implements OnInit {

  username: string;
  email: string;
  collaborator: any;
  constructor(public dialogRef: MatDialogRef<CollaboratorInformationComponent>,
    @Inject(MAT_DIALOG_DATA) data, private readonly sharedLots: SharedLotsService, public dialog: MatDialog,) {
    this.collaborator = data;
    this.email = data.userName;
  }

  async ngOnInit(): Promise<void> {

    let userData = await this.sharedLots.searchUsers(this.email);
    this.username = userData[0].firstName + " " + userData[0].lastName
  }

  close() {
    this.dialogRef.close({ response: true });
  }

  confirmRemove() {
    const dialogRef = this.dialog.open(CollaboratorWithDrawalConfirmation);
    dialogRef.afterClosed().subscribe(
      dataRecieve => {
        if (dataRecieve) {
          this.sharedLots.deleteCollaborator(this.collaborator.id);
          this.dialogRef.close({ response: false });
        }
      });
  }

  openPermissions() {
    const dialogRef = this.dialog.open(CollaboratorPermissions);
  }

}

@Component({
  selector: 'collaborator-withdrawal-confirmation',
  templateUrl: 'collaborator-withdrawal-confirmation.html',
})
export class CollaboratorWithDrawalConfirmation { }

@Component({
  selector: 'collaborator-permissions',
  templateUrl: 'permissions.html',
})
export class CollaboratorPermissions { }
