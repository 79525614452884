import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Calendar } from '../calendar-storage/calendar-storage.store';

export interface ActivityDetail {
    id?: string;
    resource?: number;
    percentage?: number;
    type?: number;
    cost?: number;
    name?: string;
}

export interface Activity {
    id?: string;
    name?: string;
    phase?: string;
    start_date?: string;
    end_date?: string;
    hour?: number;
    workday?: number;
    duration?: number;
    description?: string;
    detail?: ActivityDetail[];
    schedule_setting?: Calendar;
}

export interface FullActivity {
    id?: number | null;
    field?: string;
    camp_id: string;
    season: string;
    activity: Activity;
}
export interface ActivityState extends EntityState<FullActivity, number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-storage' })
export class ActivitiesStore extends EntityStore<ActivityState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                camp_id: "asd",
                season: "2020-1",
                activity: {
                    id: "",
                    name: "",
                    phase: "",
                    start_date: "",
                    end_date: "",
                    description: "",
                    hour: 0,
                    workday: 0,
                    duration: 0,
                    detail: []
                }
            }
        )
    }
}

