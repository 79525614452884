import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { AgrodatNotifyQuery } from './agrodat-notify.query';
import { AgrodatNotifyStore, AgrodatNotify } from './agrodat-notify.store';

export interface NotifyBody {
    statusCode: number,
    error: string,
    message: AgrodatNotify[]
}

@Injectable({ providedIn: 'root' })
export class AgrodatNotifyService {

    constructor(
        private http: HttpClient,
        private readonly AgrodatNotifyQuery: AgrodatNotifyQuery,
        private readonly AgrodatNotifyStore: AgrodatNotifyStore
    ) {
    }

    public async findAll(): Promise<AgrodatNotify[]> {
        const hasCache = this.AgrodatNotifyQuery.getHasCache();
        if (hasCache) {
            return ;
        } else {
            try {
                const data = await this.http.get<NotifyBody>(`${environment.apiUrl}/alert/`).toPromise<NotifyBody>();
                this.AgrodatNotifyStore.set(data.message)
                return data.message;
            } catch (error) {
                console.log(error)
            }
        }
    }

    public async postAgrodatNotification(agrodatNotify: AgrodatNotify) {
        try {
            const rpt: any = await this.http.post<AgrodatNotify>(`${environment.apiUrl}/alert/`, agrodatNotify).toPromise<AgrodatNotify>();
            rpt.message.edit_mode = false;
            this.AgrodatNotifyStore.add(rpt.message)
            return rpt.message;
        } catch (error) {
            console.log(error)
        }
    }

    public async putAgrodatNotification(agrodatNotify: AgrodatNotify) {
        try {
            const rpt: any = await this.http.put(`${environment.apiUrl}/alert/`, agrodatNotify).toPromise();
            rpt.message.edit_mode = false;
            this.AgrodatNotifyStore.update(rpt.message.id, rpt.message)
            return rpt.message;
        } catch (error) {
            console.log(error)
        }
    }

    public async deleteNotify(id) {
        try {
            const info = {
                "id": id,
            }
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    info
            };
            const rpt: any = await this.http.delete(`${environment.apiUrl}/alert/`, options).toPromise();
            this.AgrodatNotifyStore.remove(id)
            return rpt.message;

        } catch (error) {
            console.log(error)
        }
    }
}
