import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { environment } from '@src/environments/environment';

export interface Generical {
    id?: string,
    name?: string,
    product?: {
        id?: string,
        name?: string
    }
}

export interface Generical2 {
    features?:[
        {
            geometry?: {
                coordinates?: [
                    number, number
                ]
            }
        }
    ],
}

@Injectable({
    providedIn: 'root'
})
export class LotCropServiceService {

    constructor(
        private readonly http: HttpClient,
        private readonly globalToastService: GlobalToastService
    ) { }

    public async getCropFromServerRegional() {
        try {
            const data = await this.http.get<Generical[]>(`${environment.apiUrlAgroDat}/product/`).toPromise<Generical[]>();
            return data;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public async getCropVarietyFromServerRegional() {
        try {
            const data = await this.http.get<Generical[]>(`${environment.apiUrlAgroDat}/variety/`).toPromise<Generical[]>();
            return data;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public async getLocationOfText(text){
        try {
            const data = await this.http.get<Generical2>(`https://photon.komoot.io/api/?q=` + text).toPromise<Generical2>();
            return data;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }


}
