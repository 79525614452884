import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AuthState {
	name: string;
	token: string;
	expiresIn: string;
}

export function createInitialState(): AuthState {
	return {
		token: null,
		expiresIn: null,
		name: null
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
	public constructor() {
		super(createInitialState());
	}
}
