import { Injectable } from '@angular/core';
import { LotEdit } from './lot-edit.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
/*
    When 1 is selected as valu
*/
export interface LotEditState {
    selectedValue: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lot-edit' })
export class LotEditStore extends EntityStore<LotEditState> {

    constructor() {
        super({
            selectedValue: 0,
        });
    }

    public setSelectedValue(value: number) {
		this.update({
			selectedValue: value,
		});
	}
}

