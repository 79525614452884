import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { LaborStorageQuery } from '../../storage/labor-storage/labor-storage.query';
import { LaborService } from '../../storage/labor-storage/labor-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { Labor } from '../../storage/labor-storage/labor-storage.store';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { Subscription } from 'rxjs';
import { MoneyQuery } from '../../storage/money-storage/money-storage.query';
import { DialogConfirmationResource } from '../confirmation-dialog/confirmation-dialog';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import { MoneyService } from '../../storage/money-storage/money-storage.service';
import { ProviderService } from '../../storage/provider-storage/provider-storage.service';
import { DialogProviderSmallComponent } from '../dialog-provider-small/dialog-provider-small.component';
import { UserQuery } from '@src/app/store/user/user.query';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { DialogShowLaborComponent } from '../dialogs/dialog-show-labor/dialog-show-labor.component';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { DialogExcelFormat } from '../dialogs/excel-format/excel-format.component';
import { async } from '@angular/core/testing';

@Component({
	selector: 'app-labor-table',
	templateUrl: './labor-table.component.html',
	styleUrls: ['./labor-table.component.scss'],
})
export class LaborTableComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = [
		'position',
		'name',
		'dni',
		'abbreviation',
		'labour',
		'start_date',
		'end_date',
		'unit',
		'hours',
		'cost',
		'coin',
		'costPerHour',
		'totalhour',
		'totalcost',
		'observation',
	];
	public displayedColumnsOriginal: string[] = [
		'position',
		'name',
		'dni',
		'abbreviation',
		'labour',
		'start_date',
		'end_date',
		'unit',
		'hours',
		'cost',
		'coin',
		'costPerHour',
		'totalhour',
		'totalcost',
		'observation',
	];
	public dataSource;
	public elementData = [];
	public moneyValues = [];
	public copiedElement = undefined;
	private regexDNI = /^[0-9]{8}$/g;
	private numberOfElementsModified = 0;
	private idsOfElemetsThatWereModified = [];
	private subscriptions = new Subscription();
	private isSaving = false;
	private idsOfElementsToBeDeleted = [];
	public jornalUnity = [{ val: 'Hombre – día' }, { val: 'Hombre – Hora' }];
	public title = "XlsRead";
	public file: File;
	public arrayBuffer: any;
	public filelist: any;
	constructor(
		private readonly globalToastService: GlobalToastService,
		private laborQuery: LaborStorageQuery,
		private readonly laborService: LaborService,
		private providerService: ProviderService,
		private readonly moneyQuery: MoneyQuery,
		public dialog: MatDialog,
		public sharedData: SharedDataServiceService,
		private readonly moneyService: MoneyService,
		public activityQuery: ActivitiesStorageQuery,
		private readonly activityService: ActivitiesService,
		public userQuery: UserQuery
	) { }

	ngOnInit(): void {
		this.initializeData();
		console.log(this.dataSource);
	}

	public async initializeData() {
		await this.activityService.findAll();
		await this.moneyService.findAll();
		this.moneyQuery.selectAll().subscribe((allMoneys) => {
			this.moneyValues = allMoneys;
		});

		this.fillTableData();
		this.subscriptions.add(
			this.sharedData.getClickSaveLabor().subscribe((x) => {
				this.save();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilter().subscribe((x) => {
				this.filterData();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToExport().subscribe((x) => {
				this.exportDataToExcel();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilterColumns().subscribe((x) => {
				if (this.sharedData.selectedColumns.length == 0)
					this.displayedColumns = this.displayedColumnsOriginal;
				else {
					this.sharedData.selectedColumns.unshift('position');
					this.displayedColumns = this.sharedData.selectedColumns;
				}
			}),
		);
	}
	public applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue;
	}

	public filterData() {
		var rowSelected = this.sharedData.columnSelectedToFilter;
		var textInput = this.sharedData.searchTextToFilter;
		if (rowSelected === undefined) {
			return;
		}
		this.setDataSourceFilter(rowSelected, textInput);
		this.applyFilter(textInput);
	}

	public setDataSourceFilter(rowSelected, textInput) {
		switch (rowSelected) {
			case '1':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.name.trim().toLowerCase().indexOf(filter) !== -1
					);
				};
				break;
			case '2':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return data.dni.trim().toLowerCase().indexOf(filter) !== -1;
				};
				break;
			case '3':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.labour.trim().toLowerCase().indexOf(filter) !== -1
					);
				};
				break;
			case '4':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.start_date.trim().toLowerCase().indexOf(filter) !==
						-1
					);
				};
				break;
			case '5':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.end_date.trim().toLowerCase().indexOf(filter) !==
						-1
					);
				};
				break;
			case '6':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.unit.trim().toLowerCase().indexOf(filter) !== -1
					);
				};
				break;
			case '7':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.hours.trim().toLowerCase().indexOf(filter) !== -1
					);
				};
				break;
			case '8':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.cost.trim().toLowerCase().indexOf(filter) !== -1
					);
				};
				break;
			case '9':
				this.dataSource.filterPredicate = (
					data: Labor,
					filter: string,
				) => {
					return (
						data.coin.trim().toLowerCase().indexOf(filter) !== -1
					);
				};
				break;
			default:
				return;
		}
	}
	public async fillTableData() {
		//this.subscriptions.add(
		//	this.activityQuery.selectAllActivities.subscribe((allActivies) => {
		//		this.calculateCostPerHour(allActivies);
		//	}),
		//);

		await this.laborService.findAll();

		this.subscriptions.add(
			this.laborQuery.selectAll().subscribe((all) => {
				this.elementData = [];
				const allActivities = this.activityQuery.getAll();

				all.forEach((element) => {
					var elemnToBeInserted = {
						id: element.id,
						name: element.name,
						abbreviation: '',
						dni: element.dni,
						labour: element.labour,
						start_date: element.start_date,
						end_date: element.end_date,
						unit: element.unit,
						hours: element.hours,
						cost: element.cost,
						coin: element.coin,
						costPerHour: '',
						observation: element.observation,
						totalHour: 0,
						totalCost: 0,
					};
					elemnToBeInserted.abbreviation = this.getAbrreviation(
						element.name,
					);
					elemnToBeInserted.costPerHour = (
						parseInt(element.cost) / parseInt(element.hours)
					).toString();

					if (
						elemnToBeInserted.dni == undefined ||
						isNaN(parseInt(elemnToBeInserted.dni))
					) {
						elemnToBeInserted.dni = '';
					}
					this.elementData.push(elemnToBeInserted);
				});
				if (this.elementData.length < 20) {
					var elementsNeededToCompleteTable =
						20 - this.elementData.length;
					for (
						let index = 0;
						index < elementsNeededToCompleteTable;
						index++
					) {
						this.elementData.push(this.getNewLabourObject());
					}
				}
				this.calculateTotalCostAndTotalHourPerLaborObject(
					allActivities,
				);
				this.dataSource = new MatTableDataSource(this.elementData);
			}),
		);
	}

	public calculateTotalCostAndTotalHourPerLaborObject(allActivities) {
		if (allActivities.length > 0 && this.elementData.length > 0) {
			this.elementData.forEach((element) => {
				if (element.id !== '') {
					element.totalHour = 0;
					element.totalCost = 0;
					for (let index = 0; index < allActivities.length; index++) {
						const activityObject = allActivities[index];
						if (activityObject.activity.detail.length > 0) {
							for (
								let j = 0;
								j < activityObject.activity.detail.length;
								j++
							) {
								const resource =
									activityObject.activity.detail[j];
								if (
									resource.resource + '' == element.id &&
									resource.type == 0
								) {
									const horasHombre =
										activityObject.activity.workday *
										activityObject.activity.duration *
										(resource.percentage / 100);
									element.totalHour =
										horasHombre + element.totalHour;

									const horasHombrePrice =
										parseInt(element.cost) /
										parseInt(element.hours);
									const totalValue =
										activityObject.activity.workday *
										activityObject.activity.duration *
										(resource.percentage / 100) *
										horasHombrePrice;
									element.totalCost =
										totalValue + element.totalCost;
									break;
								}
							}
						}
					}
					if (element.totalHour > 0)
						element.totalHour = (
							Math.round(element.totalHour * 100) / 100
						).toLocaleString('en-US');

					if (element.totalCost > 0)
						element.totalCost = (
							Math.round(element.totalCost * 100) / 100
						).toLocaleString('en-US');
				}
			});
		}
	}

	public getAbrreviation(word) {
		try {
			var matches = word.match(/\b(\w)/g);
			return matches.join('');
		} catch (error) {
			return '';
		}
	}

	public getNewLabourObject() {
		return {
			id: '',
			name: '',
			abbreviation: '',
			dni: '',
			labour: '',
			start_date: '',
			end_date: '',
			unit: 'Hombre – día',
			hours: '',
			cost: '',
			coin: '1',
			costPerHour: '',
			observation: '',
			totalHour: 0,
			totalCost: 0,
		};
	}

	public addRowUp(position) {
		var elemnToBeInserted = this.getNewLabourObject();
		this.elementData.splice(position, 0, elemnToBeInserted);
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public addRowDown(position) {
		var elemnToBeInserted = this.getNewLabourObject();
		this.elementData.splice(position, 0, elemnToBeInserted);
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public copy(element) {
		var elemnToBeInserted = {
			id: 'new',
			name: element.name,
			abbreviation: element.abbreviation,
			dni: element.dni,
			labour: element.labour,
			start_date: element.start_date,
			end_date: element.end_date,
			unit: element.unit,
			hours: element.hours,
			cost: element.cost,
			coin: element.coin,
			costPerHour: element.costPerHour,
			observation: element.observation,
		};
		this.copiedElement = elemnToBeInserted;
	}

	public paste(position) {
		this.elementData[position] = this.copiedElement;
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public delete(position) {
		this.idsOfElementsToBeDeleted.push(this.elementData[position].id);
		if (
			this.idsOfElemetsThatWereModified.includes(
				this.elementData[position].id,
			)
		) {
			var index = this.idsOfElemetsThatWereModified.indexOf(
				this.elementData[position].id,
			);
			this.idsOfElemetsThatWereModified.splice(index, 1);
		}
		this.elementData.splice(position, 1);
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public isNotListedToBeUpdated(idOfLabor) {
		return (
			idOfLabor !== '' &&
			idOfLabor !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(idOfLabor)
		);
	}

	public changeNameAndLastName(event, element) {
		element.name = event.target.value;
		element.abbreviation = this.getAbrreviation(element.name);
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public changeDNI(event, element) {
		var value: string = event.target.value;
		value = value.trim();
		if (this.regexDNI.test(value) || value == '') {
			element.dni = event.target.value;
			if (element.id === '') {
				element.id = 'new';
				return;
			}
			if (this.isNotListedToBeUpdated(element.id)) {
				this.idsOfElemetsThatWereModified.push(element.id);
			}
		} else {
			this.globalToastService.createWarnToast(
				'Advertencia',
				'El DNI debe ser de 8 números',
			);
			element.dni = '';
		}
	}

	public changeLabor(event, element) {
		element.labour = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public changeStartDate(event, element) {
		element.start_date = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeEndDate(event, element) {
		element.end_date = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeUnity(event, element) {
		element.unit = event.target.value;
		if (element.unit === 'Hombre – Hora') {
			element.hours = 1;
		}
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeHours(event, element) {
		if (isNaN(parseFloat(event.target.value))) {
			element.hours = '';
			element.costPerHour = '';
		} else {
			element.hours = event.target.value;
			this.calculateCostPerHour(element);
			if (element.id === '') {
				element.id = 'new';
				return;
			}
			if (this.isNotListedToBeUpdated(element.id)) {
				this.idsOfElemetsThatWereModified.push(element.id);
			}
		}
	}

	public calculateCostPerHour(element) {
		if (element.cost !== '' && element.hours !== '') {
			try {
				element.costPerHour = (
					Math.round(
						(parseFloat(element.cost) / parseFloat(element.hours)) *
						100,
					) / 100
				).toString();
			} catch (error) {
				element.costPerHour = '';
			}
		}
	}
	public changeCost(event, element) {
		element.cost = event.target.value;
		this.calculateCostPerHour(element);
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeCoin(event, element) {
		element.coin = event.target.value + '';
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeObs(event, element) {
		element.observation = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public importExcel(arraylist) {



		arraylist.forEach((data: any) => {
			var elemnToBeInserted = {
				id: 'new',
				name: data.nombre_y_apellido,
				abbreviation: this.getAbrreviation(data.nombre_y_apellido),
				dni: data.dni,
				labour: data.funcion,
				start_date: data.inicio,
				end_date: data.fin,
				unit: "Hombre – Dia",
				hours: data.horas,
				cost: data.costo_x_unidad,
				coin: "Soles",
				costPerHour: (
					Math.round(
						(parseFloat(data.costo_x_unidad) / parseFloat(data.horas)) *
						100,
					) / 100
				).toString(),
				observation: data.observation,
			};
			console.log(this.elementData)
			this.elementData[3] = elemnToBeInserted;
			this.dataSource = new MatTableDataSource(this.elementData);
		});
	}
	//IS EMPTY
	public isEmpty()
	{
		var c = 0;
		for(var i = 0; i<this.elementData.length;i++)
		{
			var element = this.elementData[i];
			if(element.name!='')
			{
				c++;
			}
		}
		return c;
	}
	//END IS EMPTY

	//DATE FORMAT
	public formatdate(fecha:Date)
	{
		  let d = new Date(fecha);
		  let month = (d.getMonth() + 1).toString();
		  let day = d.getDate().toString();
		  let year = d.getFullYear();
		  if (month.length < 2) {
		    month = '0' + month;
		  }
		  if (day.length < 2) {
		    day = '0' + day;
		  }
		  return [year, month, day].join('-');
					
	}

	//END DATE FORMAT
	public openDialogExcel(): void {
		const dialogRef = this.dialog.open(DialogExcelFormat, {
			width: '350px',
			height: '540px',
			panelClass: 'modalClass',
			data: {}
			
		});
		var llenos = this.isEmpty();
		console.log("Aqui muere");
		dialogRef.afterClosed().subscribe( async result => {
			console.log('The dialog was closed');
			console.log("result");
			console.log(result);
			var start_row=result['startRow'];
			console.log("SR "+start_row);
			var end_row=result['endRow'];
			console.log("ER "+end_row);
			console.log("A "+result['name']);
			console.log("B "+result['dni']);
			console.log("C "+result['labour']);
			console.log("D "+result['startDate']);
			console.log("E "+result['endDate']);
			console.log("F "+result['hours']);
			console.log("G "+result['cost']);
			for(var i  = start_row-2; i < end_row-2 ; i++)
			{
					var index = i+2;
					var indexstr = index.toString();
					var excel_index_name=result['name'].toString();
					console.log("Variable: "+ excel_index_name);
					var excel_index_dni=result['dni'].toString();
					console.log("Variable: "+ excel_index_name);
					var excel_index_labour=result['role'].toString();
					console.log("Variable: "+ excel_index_name);
					var excel_index_start=result['startDate'].toString();
					console.log("Variable: "+ excel_index_name);
					var excel_index_end=result['endDate'].toString();
					console.log("Variable: "+ excel_index_name);
					var excel_index_hours=result['hours'].toString();
					console.log("Variable: "+ excel_index_name);
					var excel_index_cost=result['cost'].toString();
					console.log("Variable: "+ excel_index_name);

					var start = result['sheet'][excel_index_start+indexstr]['w'];
					var new_start = this.formatdate(start);
					var end = result['sheet'][excel_index_end+indexstr]['w'];
					var new_end = this.formatdate(end);
					var elemnToBeInserted = {
					id: 'new',
					name: result['sheet'][excel_index_name+indexstr]['v'],
					abbreviation: '',
					dni: result['sheet'][excel_index_dni+indexstr]['v'],
					labour: result['sheet'][excel_index_labour+indexstr]['v'],
					start_date:new_start,
					end_date:new_end,
					unit: 'Hombre – día',
					hours: result['sheet'][excel_index_hours+indexstr]['v'],
					cost: result['sheet'][excel_index_cost+indexstr]['v'],
					coin: '1',
					costPerHour: '',
					observation: '',
					totalHour: 0,
					totalCost: 0,
				};

				this.elementData[llenos+i] = elemnToBeInserted;
				this.dataSource = new MatTableDataSource(this.elementData);
			}
			var nowtotal = this.elementData.length;
			
			console.log("NOWTOTAL: ",nowtotal);
			console.log("LLENOS: ",llenos);


			
		});
	}

	public save() {
		if (this.isSaving) {
			return;
		} else {
			this.isSaving = true;
		}
		this.numberOfElementsModified = 0;
		var newElemets: Labor[] = [];
		var elementsToBeUpdated: Labor[] = [];
		for (let index = 0; index < this.elementData.length; index++) {
			const element = this.elementData[index];
			if (element.id !== '') {
				this.numberOfElementsModified++;
			}
			if (element.id === 'new') {
				if (this.validation(element)) {
					newElemets.push(element);
				} else {
					if (
						!(
							element.name == '' &&
							element.dni == '' &&
							element.labour == '' &&
							element.start_date == '' &&
							element.end_date == '' &&
							element.hours == '' &&
							element.cost == '' &&
							element.observation == ''
						)
					) {
						this.isSaving = false;
						this.globalToastService.createErrorToast(
							'Error',
							'Por favor llenar todos los campos obligatorios',
						);
						return;
					}
				}
			}
			if (this.idsOfElemetsThatWereModified.includes(element.id)) {
				if (this.validation(element)) {
					elementsToBeUpdated.push(element);
				} else {
					this.isSaving = false;
					this.globalToastService.createErrorToast(
						'Error',
						'Por favor llenar todos los campos obligatorios',
					);
					return;
				}
			}
		}
		if (
			newElemets.length === 0 &&
			elementsToBeUpdated.length === 0 &&
			this.idsOfElementsToBeDeleted.length === 0
		) {
			this.sharedData.sentViewConsultance();
			this.isSaving = false;
			return;
		}
		const dialogRef = this.dialog.open(DialogConfirmationResource, {
			data: {
				mensaje:
					'¿Desea guardar ' +
					this.numberOfElementsModified +
					' recursos de "Mano de Obra"?',
			},
		});
		var g = dialogRef.afterClosed().subscribe((result) => {
			if (result === undefined) {
				this.idsOfElemetsThatWereModified = [];
				this.idsOfElementsToBeDeleted = [];
				this.fillTableData();
				this.sharedData.sentViewConsultance();
			}

			if (result) {
				if (newElemets.length > 0)
					this.laborService.createMany(newElemets);
				if (elementsToBeUpdated.length > 0)
					this.laborService.updateMany(elementsToBeUpdated);
				if (this.idsOfElementsToBeDeleted.length > 0)
					this.laborService.deleteMany(this.idsOfElementsToBeDeleted);
				this.idsOfElementsToBeDeleted = [];
				this.idsOfElemetsThatWereModified = [];
				this.sharedData.sentViewConsultance();
			}

			if (result === false) {
			}
			this.numberOfElementsModified = 0;
			this.isSaving = false;
			g.unsubscribe();
		});
	}

	public validation(element: Labor): boolean {
		if (
			element.name === '' ||
			element.labour === '' ||
			element.unit === '' ||
			element.hours === '' ||
			element.coin === ''
		) {
			return false;
		}
		return true;
	}

	public exportDataToExcel() {
		if (this.elementData !== undefined) {
			const worksheet = XLSX.utils.json_to_sheet(this.elementData);
			const workbook: XLSX.WorkBook = {
				Sheets: { data: worksheet },
				SheetNames: ['data'],
			};
			const excelBuffer: any = XLSX.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			this.saveAsExcelFile(excelBuffer, 'excelFileName');
		}
	}
	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: ExcelConst.EXCEL_TYPE });
		FileSaver.saveAs(
			data,
			fileName +
			'_export_' +
			new Date().getTime() +
			ExcelConst.EXCEL_EXTENSION,
		);
	}
	public ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	public async getProvider(e, element) {
		const type = '0';
		if (element.id !== '') {
			const provider = await this.providerService.get(element.id, type);
			provider['type'] = type;
			provider['resource'] = element.id;
			this.openDialog(e, provider);
		}
	}

	public isValidPlan() {
		const currentUser = this.userQuery.getInformation();
		return currentUser.permission.resourceManagement;
	}

	public showConfirmPlanUpdate() {
		if (this.isValidPlan())
			return;

		const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
			width: '400px'
		});
		saveRef.afterClosed().subscribe()
	}

	public async getResourceInformation(element) {
		this.laborService.updateActive(element.id);
		const dialogRef = this.dialog.open(DialogShowLaborComponent, {
			width: '600px',
			height: '400px',
			panelClass: 'modalClass',
			disableClose: true,
		});
	}

	public openDialog(e, data): void {
		e.stopPropagation();
		e.preventDefault();

		const dialogRef = this.dialog.open(DialogProviderSmallComponent, {
			width: '415px',
			height: '200px',
			disableClose: true,
			panelClass: 'modalClass',
			data: data,
		});
	}
}
