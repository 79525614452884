import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FielListStore, FielListState } from './fiel-list.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FielListQuery extends Query<FielListState> {

  public selectSelectedResource: Observable<number> = this.select(
		(x) => x.selectedResource
	);
  constructor(protected store: FielListStore) {
    super(store);
  }

}
