import { Component, AfterViewInit, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	AbstractControl,
	Validators
} from '@angular/forms';
import * as L from 'leaflet';

import { FieldsQuery } from '@store/fields/fields.query';
import { Field } from '@store/fields/fields.store';
import { MarkerService } from '@shared/services';
import { Lot } from '@store/lots/lots.store';
import { LotsService } from '@store/lots/lots.service';
import { MyFieldsUiService } from '@modules/my-fields/store/my-fields-ui/my-fields-ui.service';

@Component({
	selector: 'app-register-lot',
	templateUrl: './register-lot.component.html',
	styleUrls: ['./register-lot.component.scss']
})
export class RegisterLotComponent {
	public map: L.DrawMap;
	public featureGroup: L.FeatureGroup;

	public form: FormGroup;

	public currentLot: Lot;
	public fields: Field[];

	public options: L.MapOptions = {
		layers: [
			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 19,
				attribution: 'Open Street Map'
			}),
			L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
				maxZoom: 20,
				subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
			})
		],
		zoom: 10,
		center: L.latLng({ lat: 46.879966, lng: -121.726909 })
	};

	public drawOptions: L.Control.DrawConstructorOptions = {};

	public constructor(
		private readonly formBuilder: FormBuilder,
		private readonly fieldsQuery: FieldsQuery,
		private readonly lotsService: LotsService,
		private readonly markerService: MarkerService,
		private readonly myFieldsUiService: MyFieldsUiService
	) {
		this.featureGroup = L.featureGroup();

		this.drawOptions = {
			position: 'bottomleft',
			draw: {
				polygon: {},
				marker: false,
				circle: false,
				circlemarker: false,
				rectangle: false,
				polyline: false
			},
			edit: {
				featureGroup: this.featureGroup
			}
		};

		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			fieldId: ['', [Validators.required]]
		});

		this.fields = [];
		this.fieldsQuery.selectAll().subscribe(data => {
			this.fields = data;
		});
	}

	// Only called once
	public onMapReady(map: L.DrawMap) {
		this.map = map;
		this.map.addLayer(this.featureGroup);
	}

	public onDrawStart(event) {
		this.featureGroup.clearLayers();
	}

	public onDrawCreated(event) {}

	public async createLot() {
		if (this.form.invalid || !this.isPolygonDrawed) {
			return;
		}

		const polygon: L.Polygon = this.featureGroup.getLayers()[0] as L.Polygon;

		await this.lotsService.createLot(this.ffieldId.value, {
			name: this.fname.value,
			coordinates: polygon.toGeoJSON().geometry
				.coordinates[0] as number[][],
			area: this.markerService.getPolygonArea(polygon)
		});

	}

	public get fname(): AbstractControl {
		return this.form.get('name');
	}

	public get ffieldId(): AbstractControl {
		return this.form.get('fieldId');
	}

	public get isPolygonDrawed(): boolean {
		return this.featureGroup.getLayers().length === 1;
	}
}
