import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

//Components
import { AgromonitorRoutingModule } from './agromonitor-routing.module';
import { CalendarDialog, MainPageComponent } from './pages/main-page/main-page.component';
import { ResourceManagementComponent } from './pages/resource-management/resource-management.component';
import { ActivityManagementComponent } from './pages/activity-management/activity-management.component';

//Material
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { DialogActivityComponent } from './components/dialog-activity/dialog-activity.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';

import { IN_WORDS } from '../../shared/services/format-datepicker/dayMonthYear';

import { LaborTableComponent } from './components/labor-table/labor-table.component';
import { MachineryTableComponent } from './components/machinery-table/machinery-table.component';
import { SuppliesTableComponent } from './components/supplies-table/supplies-table.component';
import { WaterTableComponent } from './components/water-table/water-table.component';
import { ServOthersTableComponent } from './components/serv-others-table/serv-others-table.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { StorageManagementComponent } from './pages/storage-management/storage-management.component';
import { StockTableComponent } from './components/stock-table/stock-table.component';
import { ProductTableComponent } from './components/product-table/product-table.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedDataServiceService } from './shared-data/shared-data-service.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { YearComponentComponent } from './components/year-component/year-component.component';
import { MonthViewComponent } from './components/month-view/month-view.component';
import { DialogMessageComponent } from './components/dialog-message/dialog-message.component';
import { WeekCalendarComponent } from './components/week-calendar/week-calendar.component';
import { SaveConfirmationDialogComponent } from './components/save-confirmation-dialog/save-confirmation-dialog.component';
import { DialogConfirmationResource } from './components/confirmation-dialog/confirmation-dialog';
import { DayCalendarComponent } from './components/day-calendar/day-calendar.component';
import { CalendarAvailabilityComponent } from './components/calendar-availability/calendar-availability.component';
import { DialogProviderComponent } from './components/dialog-provider/dialog-provider.component';
import { DialogProviderSmallComponent } from './components/dialog-provider-small/dialog-provider-small.component';
import { ResourceService } from './shared-data/resources.service';
import { ManageModalActivityService } from './services/manage-modal-activity.service';
import { DialogShowResourceComponent } from './components/dialogs/dialog-show-resource/dialog-show-resource.component';
import { DialogShowLaborComponent } from './components/dialogs/dialog-show-labor/dialog-show-labor.component';
import { DialogShowMachineryComponent } from './components/dialogs/dialog-show-machinery/dialog-show-machinery.component';
import { DialogShowWaterComponent } from './components/dialogs/dialog-show-water/dialog-show-water.component';
import { DialogShowServicesOthersComponent } from './components/dialogs/dialog-show-services-others/dialog-show-services-others.component';
import { DialogImageComponent } from './components/dialog-image/dialog-image.component';
import { CalendarActivityComponent } from './components/dialogs/calendar-activity/calendar-activity.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ActivityDetailInfoComponent } from './components/dialogs/activity-detail-info/activity-detail-info.component';
import { DialogExcelFormat } from './components/dialogs/excel-format/excel-format.component';
import { DiseaseInformationDialog } from './components/dialog-activity/disease.component';

@NgModule({
  declarations: [
    MainPageComponent,
    ResourceManagementComponent,
    ActivityManagementComponent,
    DialogActivityComponent,
    LaborTableComponent,
    MachineryTableComponent,
    SuppliesTableComponent,
    WaterTableComponent,
    ServOthersTableComponent,
    YearComponentComponent,
    StorageManagementComponent,
    StockTableComponent,
    ProductTableComponent,
    WeekCalendarComponent,
    MonthViewComponent,
    DialogMessageComponent,
    SaveConfirmationDialogComponent,
    DialogConfirmationResource,
    CalendarDialog,
    CalendarAvailabilityComponent,
    DayCalendarComponent,
    DialogProviderComponent,
    DialogProviderSmallComponent,
    DialogShowResourceComponent,
    DialogShowLaborComponent,
    DialogShowMachineryComponent,
    DialogShowWaterComponent,
    DialogExcelFormat,
    DialogShowServicesOthersComponent,
    DialogImageComponent,
    CalendarActivityComponent,
    ActivityDetailInfoComponent,
    DiseaseInformationDialog,
  ],
  imports: [
    CommonModule,
    AgromonitorRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    MatDatepickerModule,
    MatMomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxSpinnerModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule,
    MultiSelectModule,
    ButtonModule,
    TooltipModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: IN_WORDS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-es' },
    SharedDataServiceService,
    DatePipe,
    ResourceService,
    ManageModalActivityService,
  ],
  exports: [DialogActivityComponent],
})
export class AgromonitorModule {}
