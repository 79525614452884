import { Injectable } from '@angular/core';
import { SidebarStore } from './sidebar.store';
import { Services } from '@shared/enums/services';
import { SidebarQuery } from './sidebar.query';

@Injectable({
	providedIn: 'root'
})
export class SidebarService {
	public constructor(
		private readonly store: SidebarStore,
	) { }

	public updateSelectedService(service: Services) {
		const currentService = this.store.getValue().selectedService;
		if (currentService === service) {
			return;
		}
		if (!this.store.getValue().isDock) {
			this.store.close()
		}

		this.store.updateSelectedService(service);
	}

	public open() {
		this.store.open();
	}

	public toggle() {
		this.store.toggle();
	}

	public close() {
		this.store.close();
	}

	public onResize(innerWidth: number) {
		let isSmallDevice: boolean = true;
		if (innerWidth > 700)
			isSmallDevice = false;
		this.store.updateDock(!isSmallDevice);
	}
}
