import { QueryEntity } from '@datorama/akita';
import { StadisticsState, StadisticsStore } from './stadistics.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StadisticsQuery extends QueryEntity<StadisticsState> {
	public constructor(protected store: StadisticsStore) {
		super(store);
	}
}
