import { Injectable } from '@angular/core';
import { FielListStore } from './fiel-list.store';

@Injectable({ providedIn: 'root' })
export class FielListService {

  constructor(private readonly fielListStore: FielListStore) {}

  public setSelectedResource(resource: number) {
		this.fielListStore.setSelectedResource(resource);
	}
}
