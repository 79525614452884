import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { VarietyStore, VarietyState, Variety } from './variety.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VarietyQuery extends QueryEntity<VarietyState> {
    
    public selectAllFields: Observable<Variety[]> = this.selectAll();

    constructor(protected store: VarietyStore) {
        super(store);
    }

}
