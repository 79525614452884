import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';

export interface AgrometNotify {
    "notification_id": number
    "id": string,
    "lot": string,
    "lat": string,
    "lon": string,
    "lot_name?":string,
    "min_temperature": string,
    "max_temperature": string,
    "min_wind": string,
    "max_wind": string,
    "min_precipitation": string,
    "max_precipitation": string,
    "by_email": boolean,
    "by_push_notification": boolean,
    "by_sms": boolean
}

export interface AgrometNotifyState extends EntityState<AgrometNotify, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'agrometNotify' })
export class AgrometNotifyStore extends EntityStore<AgrometNotifyState> {
    constructor() {
        super();
    }
}

