import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-save-confirmation-dialog',
  templateUrl: './save-confirmation-dialog.component.html',
  styleUrls: ['./save-confirmation-dialog.component.scss']
})
export class SaveConfirmationDialogComponent implements OnInit {

  public message: string;
  constructor(public dialogRef: MatDialogRef<SaveConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.message = data.message;
  }

  ngOnInit(): void {
  }

  close(type: string) {
    this.dialogRef.close({
      response: type
    });
  }

}
