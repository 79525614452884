import { Component, OnInit } from '@angular/core';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';

@Component({
    selector: 'app-incidence-chart',
    templateUrl: './incidence-chart.component.html',
    styleUrls: ['./incidence-chart.component.scss']
})
export class IncidenceChartComponent implements OnInit {
    private dateSelected = undefined
    private data = undefined
    public porcentageData = undefined
    public area = 1

    public orderLevel = true;
    public orderArea = true;
    public orderPorcentage = true;

    constructor(
        private mapService: MapSidebarComService,
        private lotQuery: LotsQuery
    ) {
    }

    async ngOnInit() {
        if (this.mapService.viewSelected !== undefined) {
            this.area = this.lotQuery.getEntity(this.lotQuery.getActiveId()).area
            this.dateSelected = this.mapService.viewSelected
            this.data = this.mapService.lineChartDataNDVI_NDWI_SAVI
            for (let index = 0; index < this.data.length; index++) {
                const element = this.data[index];
                if (element.date == this.dateSelected) {
                    for (let index = 0; index < element.statistics.length; index++) {
                        const element2 = element.statistics[index];
                        if (element2.name == "NDVI") {
                            this.porcentageData = this.createStastObject(element2)
                        }
                    }
                }
            }
        }
    }

    public createStastObject(element) {
        try {
            return [
                {
                    level: "1",
                    color: "#1A9641",
                    porcentage: this.roundN(element.range10),
                    area: this.roundN((element.range10 * this.area) / 100)
                },
                {
                    level: "0.9",
                    color: "#4E9D48",
                    porcentage: this.roundN(element.range9),
                    area: this.roundN((element.range9 * this.area) / 100)
                },
                {
                    level: "0.8",
                    color: "#7EC93C",
                    porcentage: this.roundN(element.range8),
                    area: this.roundN((element.range8 * this.area) / 100)
                },
                {
                    level: "0.7",
                    color: "#C4E687",
                    porcentage: this.roundN(element.range7),
                    area: this.roundN((element.range7 * this.area) / 100)
                },
                {
                    level: "0.6",
                    color: "#D2E9A4",
                    porcentage: this.roundN(element.range6),
                    area: this.roundN((element.range6 * this.area) / 100)
                },
                {
                    level: "0.5",
                    color: "#F4F6A7",
                    porcentage: this.roundN(element.range5),
                    area: this.roundN((element.range5 * this.area) / 100)
                },
                {
                    level: "0.4",
                    color: "#FEC981",
                    porcentage: this.roundN(element.range4),
                    area: this.roundN((element.range4 * this.area) / 100)
                },
                {
                    level: "0.3",
                    color: "#FFCE5B",
                    porcentage: this.roundN(element.range3),
                    area: this.roundN((element.range3 * this.area) / 100)
                },
                {
                    level: "0.2",
                    color: "#DE7526",
                    porcentage: this.roundN(element.range2),
                    area: this.roundN((element.range2 * this.area) / 100)
                },
                {
                    level: '0.1',
                    color: "#C63D3D",
                    porcentage: this.roundN(element.range1),
                    area: this.roundN((element.range1 * this.area) / 100)
                },
                {
                    level: '0',
                    color: "#C63D3D",
                    porcentage: 0,
                    area: 0
                }
            ]
        } catch (error) {
            return undefined
        }

    }

    public orderByLevel() {
        if (this.porcentageData === undefined)
            return
        this.orderArea = false
        this.orderPorcentage = false
        if (this.orderLevel) {
            this.porcentageData.sort((a, b) => (a.level < b.level) ? 1 : -1)
        } else {
            this.porcentageData.sort((a, b) => (a.level > b.level) ? 1 : -1)
        }
        this.orderLevel = !this.orderLevel
    }
    public orderByArea() {
        if (this.porcentageData === undefined)
            return
        this.orderLevel = false
        this.orderPorcentage = false
        if (this.orderArea) {
            this.porcentageData.sort((a, b) => (a.area < b.area) ? 1 : -1)
        } else {
            this.porcentageData.sort((a, b) => (a.area > b.area) ? 1 : -1)
        }
        this.orderArea = !this.orderArea
    }

    public orderByPorcentage() {
        if (this.porcentageData === undefined)
            return
        this.orderLevel = false
        this.orderArea = false
        if (this.orderPorcentage) {
            this.porcentageData.sort((a, b) => (a.porcentage < b.porcentage) ? 1 : -1)
        } else {
            this.porcentageData.sort((a, b) => (a.porcentage > b.porcentage) ? 1 : -1)
        }
        this.orderPorcentage = !this.orderPorcentage
       
    }
    public roundN(val: number) {
        return Math.round(val * 100) / 100
    }
}
