import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivitiesStorageQuery } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.query';
import {
    ActivityDetail,
    FullActivity,
} from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.store';
import { LaborService } from '@src/app/modules/agromonitor/storage/labor-storage/labor-storage.service';
import { MachineService } from '@src/app/modules/agromonitor/storage/machine-storage/machine-storage.service';
import { ProductStorageQuery } from '@src/app/modules/agromonitor/storage/product-storage/product-storage.query';
import { ProductService } from '@src/app/modules/agromonitor/storage/product-storage/product-storage.service';
import { ServiceAndOthersService } from '@src/app/modules/agromonitor/storage/serv-other-storage/serv-other-storage.service';
import { SupplyService } from '@src/app/modules/agromonitor/storage/supplies-storage/supplies-storage.service';
import { WaterService } from '@src/app/modules/agromonitor/storage/water-storage/water-storage.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { Field } from '@src/app/store/fields/fields.store';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { Lot } from '@src/app/store/lots/lots.store';
import { SubVarietyQuery } from '@src/app/store/subVariety/state/sub-variety.query';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { animate, style, transition, trigger } from '@angular/animations';
import { WaterStorageQuery } from '@src/app/modules/agromonitor/storage/water-storage/water-storage.query';
import { LaborStorageQuery } from '@src/app/modules/agromonitor/storage/labor-storage/labor-storage.query';
import { MachineStorageQuery } from '@src/app/modules/agromonitor/storage/machine-storage/machine-storage.query';
import { CampaingFormat, GeneratePDFReport } from './exportToPDFInterface';
import { LotsService } from '@src/app/store/lots/lots.service';
import * as fileSaver from 'file-saver';
import { UserQuery } from '@src/app/store/user/user.query';
import { SupplyStorageQuery } from '@src/app/modules/agromonitor/storage/supplies-storage/supplies-storage.query';
import { ServiceStorageQuery } from '@src/app/modules/agromonitor/storage/serv-other-storage/serv-other-storage.query';

@Component({
    selector: 'app-historical-campaing',
    templateUrl: './historical-campaing.component.html',
    styleUrls: ['./historical-campaing.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('1s ease-in', style({ opacity: 0 }))]),
        ]),
    ],
})
export class HistoricalCampaingComponent implements OnInit {
    public lotCampains = [];
    public lotInformation: Lot = undefined;
    public fieldInformation: Field = undefined;
    public itemDisplayed = -1;
    private months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public laborChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public machineChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public laborCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public machineCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public supplyCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public servicesCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public waterChartData_lt = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public waterChartData_m3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public waterChartData_gl = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public labor_series: ApexAxisChartSeries = [];
    public machine_series: ApexAxisChartSeries = [];
    public labor_cost_series: ApexAxisChartSeries = [];
    public machine_cost_series: ApexAxisChartSeries = [];
    public water_series_lt: ApexAxisChartSeries = [];
    public water_series_m3: ApexAxisChartSeries = [];
    public water_series_gl: ApexAxisChartSeries = [];
    public supply_series: ApexAxisChartSeries = [];
    public service_series: ApexAxisChartSeries = [];
    private laborIds = [];
    private machineIds = [];
    private waterIds = [];
    private supplyIds = [];
    private servicesIds = [];
    public selectedUnitForWater = 'Lt.';
    public selectedUnitForLabor = 'HH';
    public selectedUnitForMachine = 'HM';
    public unitOptions = [{ name: 'Lt.' }, { name: 'm3' }, { name: 'Galones' }];
    public unitLabor = [{ name: 'HH' }, { name: 'Costo' }];
    public unitMachine = [{ name: 'HM' }, { name: 'Costo' }];
    private machineCost = 0;
    private machineCostLasCampaing = 0;
    private laborCostLastCampaign = 0;
    private laborCost = 0;
    private waterCost = 0;
    private suplyCost = 0;
    private serviceAndOtherCost = 0;
    private waterAmountLt = 0;
    private waterAmountm3 = 0;
    private waterAmountGal = 0;
    public water_series_amount_displayed = [];
    public labor_series_amount_displayed = [];
    public machine_series_amount_displayed = [];
    public selectedChart = 0;
    public export_pdf_data = new GeneratePDFReport();
    public costDataChart = [0, 0, 0, 0, 0];

    constructor(
        private dialogRef: MatDialogRef<HistoricalCampaingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
        private lotQuery: LotsQuery,
        private lotsService: LotsService,
        private fieldQuery: FieldsQuery,
        private subVarietyQuery: SubVarietyQuery,
        private productService: ProductService,
        private productStorage: ProductStorageQuery,
        private laborService: LaborService,
        private machineService: MachineService,
        private supplyService: SupplyService,
        private serviceAndOther: ServiceAndOthersService,
        private waterService: WaterService,
        private waterQuery: WaterStorageQuery,
        private ActivitiesStorage: ActivitiesStorageQuery,
        private laborQuery: LaborStorageQuery,
        private machineQuery: MachineStorageQuery,
        private userQuery: UserQuery,
        private supplyQuery: SupplyStorageQuery,
        private serviceAndOtherQuery: ServiceStorageQuery,
    ) {}

    async ngOnInit() {
        await this.productService.findAll();
        await this.laborService.findAll();
        await this.machineService.findAll();
        await this.supplyService.findAll();
        await this.serviceAndOther.findAll();
        await this.waterService.findAll();
        this.fillInformation();
    }

    private fillInformation() {
        this.lotInformation = this.lotQuery.getEntity(this.data);
        this.fieldInformation = this.fieldQuery.getEntity(this.lotInformation.fieldId);
        this.lotCampains = JSON.parse(JSON.stringify(this.lotInformation.campaigns));
        this.export_pdf_data.field_name = this.fieldInformation.name;
        this.export_pdf_data.lot_id = this.lotInformation.id;
        this.export_pdf_data.lot_name = this.lotInformation.name;
        const userInfo = this.userQuery.getValue();
        this.export_pdf_data.user_name = userInfo.firstName + ' ' + userInfo.lastName;
        this.lotCampains.forEach((campaing) => {
            let campaing_info_for_pdf = new CampaingFormat();
            campaing_info_for_pdf.campaing_id = campaing.id;
            campaing_info_for_pdf.campaing_name = campaing.name;

            const subVarietyInfo = this.subVarietyQuery.getEntity(campaing.varietyId + '');
            campaing['displayDetail'] = false;
            try {
                campaing['subVarietyName'] = subVarietyInfo.name;
                campaing['variety'] = subVarietyInfo.product.id;
                campaing['varietyName'] = subVarietyInfo.product.name;

                campaing_info_for_pdf.variety_name = campaing.varietyName;
                campaing_info_for_pdf.subvariety_name = campaing.subVarietyName;
            } catch (error) {
                campaing['subVarietyName'] = '';
                campaing['variety'] = '';
                campaing['varietyName'] = '';
            }

            campaing['product'] = undefined;
            const allProducts = this.productStorage.getAll();
            const product = allProducts.filter((product) => product.season == campaing.id);

            if (product.length > 0) {
                campaing['product'] = product[0];
                this.calculateUtilization(campaing.id, campaing.name);

                
                this.calculateCostPercentageUsedByLastCampaing();
                campaing_info_for_pdf.start_date = campaing.product.start_date;
                campaing_info_for_pdf.end_date = campaing.product.end_date;
                campaing_info_for_pdf.volumn = campaing.product.quantity;
                campaing_info_for_pdf.unit = campaing.product.unit;
                campaing_info_for_pdf.price_unit = (+campaing.product.price / +campaing.product.quantity).toFixed(2);
                campaing_info_for_pdf.total_amount = (+campaing.product.price * +campaing.product.quantity).toFixed(2);
                campaing_info_for_pdf.lost_volumn = campaing.product.lost;
                campaing_info_for_pdf.lost_amount = (
                    +campaing_info_for_pdf.price_unit * +campaing.product.lost
                ).toFixed(2);
                campaing_info_for_pdf.labor_utilization_unit = 'HH';
                campaing_info_for_pdf.machine_utilization_unit = 'HM';
                campaing_info_for_pdf.water_utilization_unit = 'Lt.';
                campaing_info_for_pdf.labor_utilization = this.laborChartData;
                campaing_info_for_pdf.machine_utilization = this.machineChartData;
                campaing_info_for_pdf.water_utilization = this.waterChartData_lt;
            }
            this.export_pdf_data.campaigns.push(campaing_info_for_pdf);
        });
        this.water_series_amount_displayed = this.water_series_lt;
        this.labor_series_amount_displayed = this.labor_series;
        this.machine_series_amount_displayed = this.machine_series;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    private calculateCostPercentageUsedByLastCampaing(){
        this.costDataChart = [this.laborCostLastCampaign, this.machineCostLasCampaing, this.waterCost, this.suplyCost, this.serviceAndOtherCost];;
        console.log(this.costDataChart)
    }

    private calculateUtilization(campaing_id: string, campaing_name: string) {
        const allActivities = this.ActivitiesStorage.getAll().filter((actv) => actv.season === campaing_id);

        this.laborChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.machineChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.laborCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.supplyCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.servicesCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.machineCostChartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.waterChartData_lt = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.waterChartData_gl = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.waterChartData_m3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (let index = 0; index < allActivities.length; index++) {
            this.waterAmountLt = 0;
            this.waterAmountm3 = 0;
            this.waterAmountGal = 0;
            this.laborCost = 0;
            this.machineCost = 0;
            this.suplyCost = 0;
            this.serviceAndOtherCost = 0;
            this.laborIds = [];
            this.machineIds = [];
            this.waterIds = [];
            this.supplyIds = [];
            this.servicesIds = [];
            const activityObject = allActivities[index];
            let duration = this.getDuration(activityObject);
            for (let j = 0; j < activityObject.activity.detail.length; j++) {
                const resource = activityObject.activity.detail[j];
                if (resource.type === 0) this.calculateLabor(activityObject, resource, duration); //break;
                if (resource.type === 1) this.calculateMachine(activityObject, resource, duration); //break;
                if (resource.type === 2) this.calculateWater(resource); //break;
                if (resource.type === 3) this.calculateSupplyCost(activityObject, resource, duration);
                if (resource.type === 4) this.calculateServiceCost(activityObject, resource, duration);
            }
            this.calculateWaterUsePerMonth(activityObject.activity.duration, duration);
            this.calculateLaborCost(duration);
            this.calculateMachineCost(duration);
            this.calculateSupplyCostDuration(activityObject.activity.duration, duration);
            this.calculateServiceCostDuration(activityObject.activity.duration, duration);
        }

        this.labor_cost_series.push({
            name: campaing_name,
            data: this.laborCostChartData,
        });
        this.labor_series.push({
            name: campaing_name,
            data: this.laborChartData,
        });
        this.machine_cost_series.push({
            name: campaing_name,
            data: this.machineCostChartData,
        });
        this.machine_series.push({
            name: campaing_name,
            data: this.machineChartData,
        });
        this.water_series_lt.push({
            name: campaing_name,
            data: this.waterChartData_lt,
        });
        this.water_series_m3.push({
            name: campaing_name,
            data: this.waterChartData_m3,
        });
        this.water_series_gl.push({
            name: campaing_name,
            data: this.waterChartData_gl,
        });
        this.supply_series.push({
            name: campaing_name,
            data: this.supplyCostChartData,
        });
        this.service_series.push({
            name: campaing_name,
            data: this.servicesCostChartData,
        });
        this.laborCostLastCampaign = this.laborCostChartData.reduce((sum, current) => sum + current, 0);
        this.machineCostLasCampaing = this.machineCostChartData.reduce((sum, current) => sum + current, 0);
    }

    private calculateLaborCost(duration: number[]) {
        const laborForThisActivity = duration.map((elem) => Math.round(elem * this.laborCost * 100) / 100);
        this.laborCostChartData = this.sumArrays(this.laborCostChartData, laborForThisActivity);
    }

    private calculateMachineCost(duration: number[]) {
        const machineForThisActivity = duration.map((elem) => Math.round(elem * this.machineCost * 100) / 100);
        this.machineCostChartData = this.sumArrays(this.machineCostChartData, machineForThisActivity);
    }

    public calculateSupplyCostDuration(days: number, duration: number[]) {
        const costPerDay = this.suplyCost / days;
        const supplyForThisActivity = duration.map((elem) => Math.round(elem * costPerDay * 100) / 100);
        this.supplyCostChartData = this.sumArrays(this.supplyCostChartData, supplyForThisActivity);
    }

    public calculateServiceCostDuration(days: number, duration: number[]) {
        const costPerDay = this.serviceAndOtherCost / days;
        const serviceForThisActivity = duration.map((elem) => Math.round(elem * costPerDay * 100) / 100);
        this.servicesCostChartData = this.sumArrays(this.servicesCostChartData, serviceForThisActivity);
    }

    private calculateWaterUsePerMonth(days: number, duration: number[]) {
        const lt_usedPerDay = (100 / days) * this.waterAmountLt;
        const m3_usedPerDay = (100 / days) * this.waterAmountm3;
        const gl_usedPerDay = (100 / days) * this.waterAmountGal;
        const waterForThisActivity_lt = duration.map((elem) => Math.round(elem * lt_usedPerDay) / 100);
        this.waterChartData_lt = this.sumArrays(this.waterChartData_lt, waterForThisActivity_lt);
        const waterForThisActivity_m3 = duration.map((elem) => Math.round(elem * m3_usedPerDay) / 100);
        this.waterChartData_m3 = this.sumArrays(this.waterChartData_m3, waterForThisActivity_m3);
        const waterForThisActivity_gl = duration.map((elem) => Math.round(elem * gl_usedPerDay) / 100);
        this.waterChartData_gl = this.sumArrays(this.waterChartData_gl, waterForThisActivity_gl);
    }

    private sumArrays(arr1: number[], arr2: number[]) {
        if (arr1.length !== arr2.length) return;
        let sum = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let index = 0; index < arr1.length; index++) {
            sum[index] = arr1[index] + arr2[index];
        }
        return sum;
    }

    private getDuration(full_activity: FullActivity) {
        let startDate = new Date(full_activity.activity.start_date + 'T12:00');
        const endDate = new Date(full_activity.activity.end_date + 'T12:00');
        let days_oc = Array.from(this.months);
        if (startDate.getMonth() === endDate.getMonth()) {
            days_oc[startDate.getMonth()] = full_activity.activity.duration;
        } else {
            const available_days_in_activity = full_activity.activity.schedule_setting.days
                .split(',')
                .map((elem) => parseInt(elem));
            while (startDate < endDate) {
                const dayOfWeek = startDate.getDay();
                if (dayOfWeek in available_days_in_activity) {
                    days_oc[startDate.getMonth()] = days_oc[startDate.getMonth()] + 1;
                }
                startDate.setDate(startDate.getDate() + 1);
            }
        }
        return days_oc;
    }

    private calculateLabor(activity: FullActivity, resource: ActivityDetail, duration: number[]) {
        if (this.laborIds.includes(resource.resource)) return;
        this.laborIds.push(resource.resource);
        const horasHombrePerDay = activity.activity.workday * (resource.percentage / 100);
        const duration_labor = duration.map((elem) => elem * horasHombrePerDay);

        const labor_object = this.laborQuery.getEntity(resource.resource + '');
        const costPerHour = +labor_object.cost / +labor_object.hours;
        const priceLabor = costPerHour * horasHombrePerDay;
        this.laborCost = this.laborCost + priceLabor;

        for (let index = 0; index < duration_labor.length; index++) {
            const element = duration_labor[index];
            this.laborChartData[index] = this.laborChartData[index] + element;
        }
    }
    private calculateMachine(activity: FullActivity, resource: ActivityDetail, duration: number[]) {
        if (this.machineIds.includes(resource.resource)) return;
        this.machineIds.push(resource.resource);
        const horasMachinePerDay = activity.activity.workday * (resource.percentage / 100);
        const duration_machine = duration.map((elem) => elem * horasMachinePerDay);

        const machine_object = this.machineQuery.getEntity(resource.resource + '');
        const costPerHour = +machine_object.cost;
        const priceMachine = costPerHour * horasMachinePerDay;
        this.machineCost = this.machineCost + priceMachine;

        for (let index = 0; index < duration_machine.length; index++) {
            const element = duration_machine[index];
            this.machineChartData[index] = this.machineChartData[index] + element;
        }
    }

    private calculateWater(resource: ActivityDetail) {
        if (this.waterIds.includes(resource.id)) return;
        this.waterIds.push(resource.id);
        const waterObject = this.waterQuery.getEntity(resource.resource + '');
        this.waterCost = this.waterCost + Math.round(+waterObject.cost * resource.percentage * 100) / 100;
        if (waterObject.unit === 'Lt.') {
            this.waterAmountLt = this.waterAmountLt + resource.percentage;
            this.waterAmountm3 = this.waterAmountm3 + resource.percentage / 1000;
            this.waterAmountGal = this.waterAmountGal + resource.percentage / 3.785;
        } else if (waterObject.unit === 'm3') {
            this.waterAmountLt = this.waterAmountLt + resource.percentage * 1000;
            this.waterAmountm3 = this.waterAmountm3 + resource.percentage;
            this.waterAmountGal = this.waterAmountGal + resource.percentage * 264;
        } else if (waterObject.unit === 'Galones') {
            this.waterAmountLt = this.waterAmountLt + resource.percentage * 3.785;
            this.waterAmountm3 = this.waterAmountm3 + resource.percentage * 0.0038;
            this.waterAmountGal = this.waterAmountGal + resource.percentage;
        }
    }

    private calculateSupplyCost(activity: FullActivity, resource: ActivityDetail, duration: number[]) {
        if (this.supplyIds.includes(resource.id)) return;
        this.supplyIds.push(resource.id);
        const supplyObject = this.supplyQuery.getEntity(resource.resource + '');
        const unityCost = parseFloat(supplyObject.cost) / parseFloat(supplyObject.quantity);
        this.suplyCost = this.suplyCost + Math.round(unityCost * resource.percentage * 100) / 100;
    }

    private calculateServiceCost(activity: FullActivity, resource: ActivityDetail, duration: number[]) {
        if (this.servicesIds.includes(resource.id)) return;
        this.servicesIds.push(resource.id);
        const serviceObject = this.serviceAndOtherQuery.getEntity(resource.resource + '');
        const unityCost = parseFloat(serviceObject.cost) / parseFloat(serviceObject.quantity);
        this.serviceAndOtherCost = this.serviceAndOtherCost + Math.round(unityCost * resource.percentage * 100) / 100;
    }

    public changeWaterUnits(event) {
        const newValue = event.value.name;
        if (newValue === this.selectedUnitForWater) return;
        this.selectedUnitForWater = newValue;
        if (newValue === 'Lt.') this.water_series_amount_displayed = this.water_series_lt;
        if (newValue === 'm3') this.water_series_amount_displayed = this.water_series_m3;
        if (newValue === 'Galones') this.water_series_amount_displayed = this.water_series_gl;
    }

    public changeLaborUnits(event) {
        const newValue = event.value.name;
        if (newValue === this.selectedUnitForLabor) return;
        this.selectedUnitForLabor = newValue;
        if (newValue === 'HH') this.labor_series_amount_displayed = this.labor_series;
        if (newValue === 'Costo') this.labor_series_amount_displayed = this.labor_cost_series;
    }

    public changeMachineUnits(event) {
        const newValue = event.value.name;
        if (newValue === this.selectedUnitForMachine) return;
        this.selectedUnitForMachine = newValue;
        if (newValue === 'HM') this.machine_series_amount_displayed = this.machine_series;
        if (newValue === 'Costo') this.machine_series_amount_displayed = this.machine_cost_series;
    }

    public procesaPropagarStats(event) {
        this.export_pdf_data.statistics = event;
    }

    public addDataForCampaing(event) {
        for (let index = 0; index < this.export_pdf_data.campaigns.length; index++) {
            const element = this.export_pdf_data.campaigns[index];
            if (element.campaing_id === event.campaing_id) {
                element.labor = event.labor;
                element.machine = event.machine;
                element.service_and_others = event.service_and_others;
                element.resources = event.resources;
            }
        }
    }

    public async exportTo_pdf() {
        await this.lotsService
            .generateReport(this.export_pdf_data)
            .then((response: any) => {
                let blob: any = new Blob([response], {
                    type: 'application/pdf',
                });
                const url = window.URL.createObjectURL(blob);
                fileSaver.saveAs(blob, this.lotInformation.id + '.pdf');
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
