import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { SatellitalImageNotifyQuery } from './satellital-image-notify.query';
import { SatellitalImageNotifyStore, SatellitalImageNotify } from './satellital-image-notify.store';

export interface NotifyBody {
    statusCode: number,
    error: string,
    message: SatellitalImageNotify[]
}

@Injectable({ providedIn: 'root' })
export class SatellitalImageNotifyService {

    constructor(
        private http: HttpClient,
        private readonly SatellitalImageNotifyQuery: SatellitalImageNotifyQuery,
        private readonly SatellitalImageNotifyStore: SatellitalImageNotifyStore
    ) {
    }

    public async findAll(): Promise<SatellitalImageNotify[]> {
        const hasCache = this.SatellitalImageNotifyQuery.getHasCache();
        if (hasCache) {
            return ;
        } else {
            try {
                const data = await this.http.get<NotifyBody>(`${environment.apiUrl}/alert/satellite-image`).toPromise<NotifyBody>();
                data.message.forEach(element => {
                    element.notification_id = parseInt(element.id)
                    element.id = element.lot_id
                });
                this.SatellitalImageNotifyStore.set(data.message)
                return data.message;
            } catch (error) {
                console.log(error)
            }
        }
    }

    public async postSatellitalImageNotification(detail) {
        try {
            const rpt: any = await this.http.post(`${environment.apiUrl}/alert/satellite-image/`, detail).toPromise();
            rpt.message.notification_id = parseInt(rpt.message.id)
            rpt.message.id = rpt.message.lot_id
            this.SatellitalImageNotifyStore.add(rpt.message)
            return rpt.message;
        } catch (error) {
            console.log(error)
        }
    }

    public async putSatellitalImageNotification(detail) {
        try {
            const rpt: any = await this.http.put(`${environment.apiUrl}/alert/satellite-image/`, detail).toPromise();
            rpt.message.notification_id = parseInt(rpt.message.id)
            rpt.message.id = rpt.message.lot_id
            this.SatellitalImageNotifyStore.update(rpt.message.lot_id, rpt.message)
            return rpt.message;
        } catch (error) {
            console.log(error)
        }
    }

    public async deleteSatellitalImageNotification(id, id_lot) {
        try {
            const info = {
                "id": id, "lot_id": id_lot
            }
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    info
            };
            const rpt: any = await this.http.delete(`${environment.apiUrl}/alert/satellite-image`, options).toPromise();
            this.SatellitalImageNotifyStore.remove(id_lot)
            return rpt.message;

        } catch (error) {
            console.log(error)
        }
    }
}
