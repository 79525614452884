import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DataDialog {
    text: string
}

@Component({
    selector: 'noti-confirm-dialog',
    templateUrl: './noti-confirm-dialog.html',
})
export class DialogConfirmationNotification {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DataDialog) { }
}