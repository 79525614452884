import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';
import * as moment from 'moment';
import { CalendarStore, Calendar } from './calendar-storage.store';
import { CalendarStorageQuery } from './calendar-storage.query';

export class CalendarMessage{
    message: [Calendar]
}

@Injectable({ providedIn: 'root' })
export class CalendarService {

    constructor(
        private calendarStore: CalendarStore,
        private calendarStorageQuery: CalendarStorageQuery,
        private http: HttpClient
    ) {
    }

    public async findAll(): Promise<Calendar> {
        const hasCache = this.calendarStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }
        try {
            const data = await this.http
                .get<CalendarMessage>(`${environment.apiUrl}/schedule_setting/`)
                .toPromise<CalendarMessage>();
            var a: Calendar = undefined

            if (data.message.length > 0) {
                this.calendarStore.set([data.message[0]])
                return data.message[0];
            }
            else {
                a = {
                    id: -1,
                    start_week: 1,
                    start_schedule: "09:00:00",
                    end_schedule: "18:00:00",
                    start_break: "13:00:00",
                    end_break: "14:00:00",
                    days: "0,1,2,3,4,5,6"
                } as Calendar
                this.calendarStore.set([a])

            }
            return a;

        }
        catch (error) {
            a = {
                id: -1,
                start_week: 1,
                start_schedule: "9:00:00",
                end_schedule: "18:00:00",
                start_break: "13:00:00",
                end_break: "14:00:00",
                days: "0,1,2,3,4,5,6"
            } as Calendar
            this.calendarStore.set([a])
        }
    }

    public async createGlobalCalendar(calendar) {
        try {
            const data = await this.http.post<CalendarMessage>(`${environment.apiUrl}/schedule_setting/`, calendar).toPromise<CalendarMessage>();
            this.calendarStore.set([data.message[0]])
        } catch (error) {

        }
    }

    public async updateGlobalCalendar(calendar) {
        try {
            const data = await this.http.put<CalendarMessage>(`${environment.apiUrl}/schedule_setting/`, calendar).toPromise<CalendarMessage>();
            this.calendarStore.set([data.message[0]])
        } catch (error) {

        }
    }

    public async createOne(activities: Calendar) {
        try {
            const data = await this.http
                .post<Calendar>(`${environment.apiUrl}/activity/`, activities).toPromise<Calendar>();

        } catch (error) {
        }
    }

    public async updateActivities(activities: Calendar) {
        try {
            const data = await this.http.put<Calendar>(`${environment.apiUrl}/activity/`, activities
            ).toPromise<Calendar>();
        } catch {
        }
    }

    public async deleteActivities(activities: Calendar) {
        try {
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body: activities
            };
            const data = await this.http.delete(`${environment.apiUrl}/activity/`, options
            ).toPromise();
            //this.activityStore.remove()
        } catch {
        }
    }
}   
