import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PhenologicalDataState, PhenologicalDataStore } from './phenological-data.store';

@Injectable({ providedIn: 'root' })

export class PhenologicalDataQuery extends QueryEntity<PhenologicalDataState> {
	public constructor(protected store: PhenologicalDataStore) {
		super(store);
	}
}