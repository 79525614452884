import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-resources-page',
	templateUrl: './resources-page.component.html',
	styleUrls: ['./resources-page.component.scss'],
})
export class ResourcesPageComponent implements OnInit {
	// TODO: Implementar un servicio para este sidebar

	public isOpened: boolean = false;

	constructor() {}

	ngOnInit(): void {}

	public closeSidebar() {
		this.isOpened = false;
	}
}
