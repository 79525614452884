import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexFill,
    ApexYAxis,
    ApexTooltip,
    ApexMarkers,
    ApexXAxis,
    ApexPlotOptions,
    ApexStroke
  } from "ng-apexcharts";

  export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis | ApexYAxis[];
    labels: string[];
    stroke: any; // ApexStroke;
    markers: ApexMarkers;
    plotOptions: ApexPlotOptions;
    fill: ApexFill;
    tooltip: ApexTooltip;
  };
@Component({
    selector: 'app-itemchart',
    templateUrl: './item-chart.component.html',
    styleUrls: ['item-chart.component.scss'],
  })

export class AppItemChartComponent implements OnChanges {
  @Input()heightChart!:string;
  @Input()
  dataSeries: { name: string; data: number[] }[] = [];
  @Input()
  dataLabelsForSeries: string[] = [];
  @Input() chartId: string;
  @Input() chartData: any;
  public colors = ['#FFFFFF', '#FA0D0D', '#0D63FA']; // Cambia aquí los colores
  series = this.dataSeries
  chart = {
    height: '250',
    width:'350',
    type: "line",
    stacked: false,
    colors: ['#F00404', '#3498DB', '#2ECC71']
  }
  xaxis = {
    labels: {
      style: {
        colors: "#ffffff" // Cambia aquí al color que desees para las etiquetas del eje x
      }
    },
    axisBorder: {
      color: "#ffffff" // Cambia aquí al color que desees para el borde del eje x
    },
    axisTicks: {
      color: "#ffffff" // Cambia aquí al color que desees para las marcas del eje x
    }
  };
  dataLabels = {
    enabled: false,
  };
  stroke: ApexStroke = {
    width: [0, 2, 5],
    curve: "smooth"
  };
  plotOptions = {
    bar: {
      columnWidth: "40%"
    }
  }
  fill = {
      opacity: [0.85, 0.25, 0.25],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    
  }
  labels = this.dataLabelsForSeries
  
  yaxis = {

    min: 0,
    labels: {
      style: {
        colors: "#ffffff" // Cambia aquí al color que desees para las etiquetas del eje x
      }
    },
    axisBorder: {
      color: "#ffffff" // Cambia aquí al color que desees para el borde del eje x
    },
    axisTicks: {
      color: "#ffffff" // Cambia aquí al color que desees para las marcas del eje x
    }
  }

  legend = {
    show: false // Establece la visibilidad de la leyenda como falso para ocultarla
  }
  constructor() {

  }
  
// En el ciclo de vida del componente, inicializa el gráfico usando ApexCharts
ngOnChanges(changes: SimpleChanges): void {
  
  if (changes['dataLabelsForSeries'] && changes['dataLabelsForSeries'].currentValue) {
    this.labels = changes['dataLabelsForSeries'].currentValue;
  }

  if (changes['dataSeries'] && changes['dataSeries'].currentValue) {
    this.series = changes['dataSeries'].currentValue;
  }
}

ngOnInit(): void {


}
  }



