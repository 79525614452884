import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';
import { SharedMapData } from '../../components/main-map/sharedMapData';
import { Subscription } from 'rxjs';
import { StadisticsService } from '@src/app/store/stadistics/stadistics.service';
import { StadisticsQuery } from '@src/app/store/stadistics/stadistics.query';
import { SharedLotsService } from '@src/app/modules/my-fields/services/shared-lots/shared-lots.service';
import { ThemeDarkLightService } from '@src/app/shared/services/theme/theme-service.service';

@Component({
    selector: 'app-line-graph',
    templateUrl: './line-graph.component.html',
    styleUrls: ['./line-graph.component.scss'],
})
export class LineGraphComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    public themeSelected: string = this.darkMode.getThemeName();
    public dataXAxisNDVI = [];
    public dataOfChartXAxis = [];
    public dataXAxisNDWI = [];
    public dataXAxisSAVI = [];
    public scrollToRigth = true;
    public noData = true;
    public loadingData = true;

    lineChartData: ChartDataSets[] = [
        { data: this.dataXAxisNDVI, label: 'NDVI', fill: 'white' },
        { data: this.dataXAxisNDWI, label: 'NDWI', fill: 'white' },
        { data: this.dataXAxisSAVI, label: 'SAVI', fill: 'white' },
    ];
    lineChartData2: ChartDataSets[] = [{}];
    lineChartLabels2: Label[] = [''];
    lineChartOptions2: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        drawBorder: true,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        fontColor: this.themeSelected == 'light-theme' ? 'black' : 'white',
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    position: 'left',
                    gridLines: {
                        display: true,
                        drawBorder: true,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        min: -1,
                        max: 1,
                        stepSize: 0.2,
                        fontColor: this.themeSelected == 'light-theme' ? 'black' : 'white',
                    },
                },
            ],
        },
    };
    lineChartLabels: Label[] = [];

    lineChartOptions: ChartOptions = {
        responsive: true,
        tooltips: {
            mode: 'index',
            intersect: false,
        },
        hover: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: true,
                        drawBorder: true,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        fontColor: this.themeSelected == 'light-theme' ? 'black' : 'white',
                    },
                },
            ],
            yAxes: [
                {
                    display: false,
                    position: 'right',
                    gridLines: {
                        display: true,
                        drawBorder: true,
                        drawOnChartArea: false,
                    },
                    ticks: {
                        min: -1,
                        max: 1,
                        stepSize: 0.2,
                        fontColor: this.themeSelected == 'light-theme' ? 'black' : 'white',
                    },
                },
            ],
        },
        legend: {
            align: 'start',
            labels: {
                padding: 10,
            },
        },
        animation: {
            onComplete: this.goToRight.bind(this),
        },
    };
    public goToRight() {
        try {
            if (this.scrollToRigth) {
                const canvas = document.getElementById('navi') as HTMLCanvasElement;
                canvas.scrollLeft = 10000;
                this.scrollToRigth = false;
            }
        } catch (error) {
            console.log(error);
        }
    }
    lineChartColors: Color[] = [
        {
            borderColor: '#FF6384',
            backgroundColor: '#FF6384',
            pointBackgroundColor: '#FF6384',
        },
        {
            borderColor: '#36A2EB',
            backgroundColor: '#36A2EB',
        },
        {
            borderColor: '#FFCE56',
            backgroundColor: '#FFCE56',
        },
    ];

    lineChartLegend = false;
    lineChartPlugins = [
        {
            beforeInit: function (chart, options) {
                chart.legend.afterFit = function () {
                    this.height = this.height + 15;
                };
            },
        },
    ];
    lineChartType = 'line';
    public showChar: boolean = true;
    constructor(
        public mapSidebarComService: MapSidebarComService,
        public shareData: SharedMapData,
        private stadisticsService: StadisticsService,
        private stadisticsQuery: StadisticsQuery,
        private sharedLotsService: SharedLotsService,
        private darkMode: ThemeDarkLightService,
    ) {
        this.subscriptions.push(
            this.mapSidebarComService.getClickForUpdatingLineChart().subscribe(() => {
                this.updateChart();
            }),
        );
        this.subscriptions.push(
            this.darkMode.getClickToUpdateTheme().subscribe((state: string) => {
                this.reviewColor(state);
            }),
        );
    }

    public reviewColor(state: string) {
        this.showChar = false;
        if (state == 'light-theme') {
            this.lineChartOptions.scales.yAxes[0].ticks.fontColor = 'black';
            this.lineChartOptions.scales.xAxes[0].ticks.fontColor = 'black';
            this.lineChartOptions2.scales.yAxes[0].ticks.fontColor = 'black';
            this.lineChartOptions2.scales.xAxes[0].ticks.fontColor = 'black';
        } else {
            this.lineChartOptions.scales.yAxes[0].ticks.fontColor = 'white';
            this.lineChartOptions.scales.xAxes[0].ticks.fontColor = 'white';
            this.lineChartOptions2.scales.yAxes[0].ticks.fontColor = 'white';
            this.lineChartOptions2.scales.xAxes[0].ticks.fontColor = 'white';
        }
        setTimeout(() => {
            this.showChar = true;
        }, 500);
    }

    private cleanGraph() {
        this.noData = true;
        this.lineChartData = [{ data: [] }];
        this.dataXAxisNDVI = [];
        this.dataOfChartXAxis = [];
        this.dataXAxisNDWI = [];
        this.dataXAxisSAVI = [];
    }

    ngOnInit(): void {
        this.updateChart();
    }

    public async updateChart() {
        try {
            this.cleanGraph();
            this.scrollToRigth = true;
            var response = this.shareData.getNDVI();
            if (response === undefined || response === '') {
                return;
            } else {
                const stats = this.stadisticsQuery.getEntity(response);
                if (stats !== undefined) {
                    this.updateStats(stats.stats);
                    this.lineChartLabels = this.dataOfChartXAxis;
                    this.lineChartData = [
                        {
                            data: this.dataXAxisNDVI,
                            label: 'NDVI',
                            fill: 'white',
                            pointBackgroundColor: 'black',
                        },
                        {
                            data: this.dataXAxisNDWI,
                            label: 'NDWI',
                            fill: 'white',
                        },
                        {
                            data: this.dataXAxisSAVI,
                            label: 'SAVI',
                            fill: 'white',
                        },
                    ];
                    this.mapSidebarComService.enableReport = true;
                    this.mapSidebarComService.sendClickToEnableReportButton();
                    this.loadingData = false;
                    this.noData = false;
                } else {
                    await this.stadisticsService
                        .getEstadisticsInformation(response)
                        .then((res) => {
                            console.log(res);
                            /*
                            if (res.status === 'SUCCESS') {
                                this.updateStats(res.detail);
                                this.mapSidebarComService.enableReport = true;
                                this.mapSidebarComService.sendClickToEnableReportButton();
                                this.noData = false;
                            }
                            if (res.status === 'PENDING') {
                                console.log('pending')
                                this.noData = true;
                            }
                            if (res.status === 'FAILURE') {
                                this.noData = true;
                            }
                            */

                            this.updateStats(res.detail);
                            this.mapSidebarComService.enableReport = true;
                            this.mapSidebarComService.sendClickToEnableReportButton();
                            this.noData = false;
                            this.loadingData = false;
                        })
                        .catch(() => {
                            this.mapSidebarComService.enableReport = false;
                            this.mapSidebarComService.sendClickToEnableReportButton();
                            this.loadingData = false;
                            this.noData = true;
                        });
                    this.lineChartLabels = this.dataOfChartXAxis;
                    this.lineChartData = [
                        {
                            data: this.dataXAxisNDVI,
                            label: 'NDVI',
                            fill: 'white',
                            pointBackgroundColor: 'black',
                        },
                        {
                            data: this.dataXAxisNDWI,
                            label: 'NDWI',
                            fill: 'white',
                        },
                        {
                            data: this.dataXAxisSAVI,
                            label: 'SAVI',
                            fill: 'white',
                        },
                    ];
                }
            }
            console.log(this.noData, this.loadingData)
        } catch (error) {
            console.log(error);
        }
    }

    public updateStats(res) {
        try {
            var tmp = JSON.parse(JSON.stringify(res));
            tmp.sort((a, b) => {
                var dateA = new Date(a.date);
                var dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            });
            if (tmp.length > 0) {
                this.mapSidebarComService.dateSelected = tmp[0].date;
                this.mapSidebarComService.lineChartDataNDVI_NDWI_SAVI = tmp;
            }
            for (let index = 0; index < tmp.length; index++) {
                const element = tmp[index];
                for (let indexStadistic = 0; indexStadistic < element.statistics.length; indexStadistic++) {
                    if (element.statistics[indexStadistic].name == 'NDVI')
                        this.dataXAxisNDVI.push(Math.round(element.statistics![indexStadistic].average * 1000) / 1000);
                    if (element.statistics[indexStadistic].name == 'NDWI')
                        this.dataXAxisNDWI.push(Math.round(element.statistics![indexStadistic].average * 1000) / 1000);
                    if (element.statistics[indexStadistic].name == 'SAVI')
                        this.dataXAxisSAVI.push(Math.round(element.statistics![indexStadistic].average * 1000) / 1000);
                }
                var txt = (element.date + '').split('-');
                var xName = txt[2] + '.' + txt[1] + '.' + txt[0].replace('20', '');
                this.dataOfChartXAxis.push(xName);
            }
        } catch (error) {
            console.log(error);
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((element) => {
            element.unsubscribe();
        });
        this.cleanGraph();
    }
}
