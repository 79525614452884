import { Calendar } from './../../../storage/calendar-storage/calendar-storage.store';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { CalendarService } from '../../../storage/calendar-storage/calendar-storage.service';
import { CalendarStorageQuery } from '../../../storage/calendar-storage/calendar-storage.query';
import { ActivitiesStorageQuery } from '../../../storage/activities-storage/activities-storage.query';
import { FullActivity } from '../../../storage/activities-storage/activities-storage.store';

@Component({
	selector: 'app-calendar-activity',
	templateUrl: './calendar-activity.component.html',
	styleUrls: ['./calendar-activity.component.scss'],
})
export class CalendarActivityComponent implements OnInit {
	public actualDate = new Date();
	public monthString = '';
	public monthNumber = 0;
	public year = 0;
	public calendarDates = [];
	public actualCalendar = undefined;
	public daysSelected: string[];
	public notAvailable: Date[] = [];
	public allActivities: FullActivity = undefined;
	public generalDays = '';

	constructor(
		public dialogRef: MatDialogRef<CalendarActivityComponent>,
		public calendarService: CalendarService,
		public calendarStorageQuery: CalendarStorageQuery,
		public activitiesQuery: ActivitiesStorageQuery,
	) {}

	async ngOnInit() {
		await this.calendarService.findAll();

		const activitySelected = this.activitiesQuery.getEntity(
			this.activitiesQuery.getActiveId(),
		);

		this.monthNumber = this.actualDate.getMonth();
		this.monthString = this.monthSpanish[this.monthNumber];
		this.year = this.actualDate.getFullYear();

		this.calendarStorageQuery.selectAll().subscribe((allCalendars) => {
			this.validateDate(allCalendars);
		});

		this.allActivities = activitySelected;
		this.calculateActivities();
	}

	close() {
		this.dialogRef.close();
	}

	calculateActivities() {
		if(this.allActivities !== undefined){
			this.calculateAvailableDates();
			const firstDay = new Date(this.year, this.monthNumber).getDay();
			const daysInMonth =
				32 - new Date(this.year, this.monthNumber, 32).getDate();
			this.calculateDates(firstDay, daysInMonth);
		}
		
	}

	calculateAvailableDates() {
		this.notAvailable = [];
		const element = this.allActivities;
		let startDateActivity = new Date(element.activity.start_date);
		let endDateActivity = new Date(element.activity.end_date);

		this.monthNumber = endDateActivity.getMonth();
		this.monthString = this.monthSpanish[this.monthNumber];
		this.year = endDateActivity.getFullYear();

		if (element.activity.schedule_setting !== null) {
			const datesAvailable =
				element.activity.schedule_setting.days.split(',');
			for (
				var i = startDateActivity;
				i < endDateActivity;
				i.setDate(i.getDate() + 1)
			) {
				var dateNumber = i.getDay() + 1;
				if (dateNumber > 6) dateNumber = 0;
				if (datesAvailable.includes(dateNumber + '')) {
					this.notAvailable.push(new Date(i));
				}
			}
		} else {
			for (
				var j = startDateActivity;
				j < endDateActivity;
				j.setDate(j.getDate() + 1)
			) {
				var dateNumber = j.getDay() + 1;
				if (dateNumber > 6) dateNumber = 0;
				if (this.daysSelected.includes(dateNumber + '')) {
					this.notAvailable.push(new Date(j));
				}
			}
		}
	}
	public validateDate(allCalendars: Calendar[]) {
		if (allCalendars !== undefined && allCalendars.length > 0) {
			const calendarObject = { ...allCalendars[0] };
			if (this.generalDays !== calendarObject.days) {
				this.calculateActivities();
			}
			this.generalDays = calendarObject.days;
			this.daysSelected = calendarObject.days.split(',');
		}
	}

	public calculateDates(firstDay, daysInMonth) {
		let date = 1;
		let calendar = [];
		for (let i = 0; i < 6; i++) {
			let row = [];
			for (let j = 0; j < 7; j++) {
				if (i === 0 && j < firstDay)
					row.push({ value: '-', color: false });
				else if (date > daysInMonth) break;
				else {
					var tmp = { value: date + '', color: false };
					if (this.notAvailable.length > 0) {
						var dateCalendar;
						var month = '01';
						if (this.monthNumber < 10)
							month = '0' + (this.monthNumber + 1);
						else month = this.monthNumber + 1 + '';
						if (date < 10)
							dateCalendar = new Date(
								this.year + '-' + month + '-0' + date,
							);
						else
							dateCalendar = new Date(
								this.year + '-' + month + '-' + date,
							);
						for (
							let index = 0;
							index < this.notAvailable.length;
							index++
						) {
							const element = this.notAvailable[index];
							if (element.getTime() == dateCalendar.getTime())
								tmp = { value: date + '', color: true };
						}
					}

					row.push(tmp);
					date++;
				}
			}
			if (row.length > 0) {
				while (row.length < 7) row.push({ value: '-', color: false });
				calendar.push(row);
			}
		}
		this.calendarDates = calendar;
	}

	public nextMonth() {
		++this.monthNumber;
		if (this.monthNumber > 11) {
			this.monthNumber = 0;
			++this.year;
		}
		const firstDay = new Date(this.year, this.monthNumber).getDay();
		const daysInMonth =
			32 - new Date(this.year, this.monthNumber, 32).getDate();
		this.calculateDates(firstDay, daysInMonth);
		this.monthString = this.monthSpanish[this.monthNumber];
	}

	public prevMonth() {
		--this.monthNumber;
		if (this.monthNumber < 0) {
			this.monthNumber = 11;
			--this.year;
		}
		const firstDay = new Date(this.year, this.monthNumber).getDay();
		const daysInMonth =
			32 - new Date(this.year, this.monthNumber, 32).getDate();
		this.calculateDates(firstDay, daysInMonth);
		this.monthString = this.monthSpanish[this.monthNumber];
	}

	public goToDateInActivities(value) {}

	public monthSpanish = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	];

	public changeToGeneralCalendar() {}
}
