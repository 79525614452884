import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';


export interface SubVariety {
    id?: string,
    name?: string,
    product?: {
        id?: string,
        name?: string
    },
    type: string
}

export interface SubVarietyState extends EntityState<SubVariety, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subVariety' })

export class SubVarietyStore extends EntityStore<SubVarietyState> {

    public constructor() {
        super();
    }

}

