import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { WaterState, Water, WaterStore } from './water-storage.store'

@Injectable({ providedIn: 'root' })

export class WaterStorageQuery extends QueryEntity<WaterState> {
    public selectAllFields: Observable<Water[]> = this.selectAll();
    constructor(protected store: WaterStore) {
        super(store);
    }

}
