import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Organization } from '@src/app/shared/models/organization.model';

export interface UserState {
	namePlanType?: string;
	id: string;
	firstName: string;
	lastName: string;
	birthdate: Date;
	gender: number;
	address: string;
	dni: string;
	ruc: string;
	email: string;
	phone: string;
	imageUrl: string;
	organization: Organization;
	permission?: {
		availableArea?: number;
		usedArea?: number;
		availableMemory?: number;
		usedMemory?: number;
		extendedWeather?: boolean;
		resourceManagement?: boolean;
		storeManagement?: boolean;
		advancedActivityManagement?: boolean;
		pushNotification?: boolean;
		exportData?: boolean;
		uploadInformation?: boolean;
	}
}

// TODO: Falta el url de la foto del usuario
// O
// TODO: La foto puede ser de google, facebook, etc

export function createInitialState(): UserState {
	return {
		id: '',
		firstName: '',
		lastName: '',
		email: '',
		address: '',
		birthdate: null,
		dni: '',
		gender: null,
		phone: '',
		ruc: '',
		organization: null,
		imageUrl: '',
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
	public constructor() {
		super(createInitialState());
	}

	public updateOrganization(organization: Organization) {
		this.update({
			organization: organization,
		});
	}

	public updatePermission(usedArea: number, usedMemory: number) {
		this.update({
			permission: {
				usedArea: usedArea + this.getValue().permission.usedArea,
				availableArea: this.getValue().permission.availableArea,
				usedMemory: usedMemory + this.getValue().permission.usedMemory,
				availableMemory: this.getValue().permission.availableMemory,
				advancedActivityManagement: this.getValue().permission.advancedActivityManagement,
				exportData: this.getValue().permission.exportData,
				extendedWeather: this.getValue().permission.extendedWeather,
				resourceManagement: this.getValue().permission.resourceManagement,
				pushNotification: this.getValue().permission.pushNotification,
				storeManagement: this.getValue().permission.storeManagement,
				uploadInformation: this.getValue().permission.uploadInformation
			}
		})
	}
}
