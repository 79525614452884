import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SupplyService } from '../../storage/supplies-storage/supplies-storage.service';
import { SupplyStorageQuery } from '../../storage/supplies-storage/supplies-storage.query';
import { Supply } from '../../storage/supplies-storage/supplies-storage.store';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { Subscription } from 'rxjs';
import { MoneyQuery } from '../../storage/money-storage/money-storage.query';
import { DialogConfirmationResource } from '../confirmation-dialog/confirmation-dialog';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { DialogProviderComponent } from '../dialog-provider/dialog-provider.component';
import { ProviderService } from '../../storage/provider-storage/provider-storage.service';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { UserQuery } from '@src/app/store/user/user.query';
import { DialogShowResourceComponent } from '../dialogs/dialog-show-resource/dialog-show-resource.component';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';

@Component({
	selector: 'app-supplies-table',
	templateUrl: './supplies-table.component.html',
	styleUrls: ['./supplies-table.component.scss'],
})
export class SuppliesTableComponent implements OnInit, OnDestroy {
	public displayedColumns: string[] = [
		'position',
		'name',
		'kind',
		'abbreviation',
		'date',
		'unit',
		'quantity',
		'cost',
		'coin',
		'costPerUnit',
		'usedAmount',
		'totalCost',
		'observation',
	];
	public displayedColumnsOriginal: string[] = [
		'position',
		'name',
		'kind',
		'abbreviation',
		'date',
		'unit',
		'quantity',
		'cost',
		'coin',
		'costPerUnit',
		'usedAmount',
		'totalCost',
		'observation',
	];
	public dataSource = new MatTableDataSource([]);
	public elementData = [];
	public moneyValues = [];
	public copiedElement = undefined;
	private numberOfElementsModified = 0;
	private idsOfElemetsThatWereModified = [];
	private subscriptions = new Subscription();
	private isSaving = false;
	private idsOfElementsToBeDeleted = [];

	constructor(
		private readonly globalToastService: GlobalToastService,
		public supplyService: SupplyService,
		public supplyStorageQuery: SupplyStorageQuery,
		private providerService: ProviderService,
		public dialog: MatDialog,
		private moneyQuery: MoneyQuery,
		public sharedData: SharedDataServiceService,
		public activityQuery: ActivitiesStorageQuery,
		public userQuery: UserQuery,
	) {}

	ngOnInit(): void {
		this.initializeData();
	}

	public async initializeData() {
		this.moneyQuery.selectAll().subscribe((allMoneys) => {
			this.moneyValues = allMoneys;
		});
		await this.supplyService.findAll();
		this.fillTableData();
		this.subscriptions.add(
			this.sharedData.getClickSaveSupplies().subscribe((x) => {
				this.save();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilter().subscribe((x) => {
				//this.filterData();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToExport().subscribe((x) => {
				this.exportDataToExcel();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickToFilterColumns().subscribe((x) => {
				if (this.sharedData.selectedColumns.length == 0)
					this.displayedColumns = this.displayedColumnsOriginal;
				else {
					this.sharedData.selectedColumns.unshift('position');
					this.displayedColumns = this.sharedData.selectedColumns;
				}
			}),
		);
	}

	applyFilter(filterValue: string) {
		//filterValue = filterValue.trim(); // Remove whitespace
		//filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}

	public filterData() {
		var rowSelected = this.sharedData.columnSelectedToFilter;
		var textInput = this.sharedData.searchTextToFilter;
		if (rowSelected === undefined) {
			return;
		}
		this.setDataSourceFilter(rowSelected);

		this.applyFilter(textInput);
	}

	public setDataSourceFilter(rowSelected) {
		switch (rowSelected) {
			case '1':
				this.dataSource.filterPredicate = (
					data: Supply,
					filter: string,
				) => {
					return data.name
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '2':
				this.dataSource.filterPredicate = (
					data: Supply,
					filter: string,
				) => {
					return data.kind
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '3':
				this.dataSource.filterPredicate = (
					data: Supply,
					filter: string,
				) => {
					return data.date
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '4':
				this.dataSource.filterPredicate = (
					data: Supply,
					filter: string,
				) => {
					return data.unit
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '5':
				this.dataSource.filterPredicate = (
					data: Supply,
					filter: string,
				) => {
					return data.quantity
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '6':
				this.dataSource.filterPredicate = (
					data: Supply,
					filter: string,
				) => {
					return data.cost
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case '7':
				this.dataSource.filterPredicate = (
					data: Supply,
					filter: string,
				) => {
					return data.coin
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			default:
				return;
		}
	}

	public fillTableData() {
		this.supplyStorageQuery.selectAll().subscribe((all) => {
			this.elementData = [];
			all.forEach((element) => {
				var elemnToBeInserted = {
					id: element.id,
					name: element.name,
					kind: element.kind,
					abbreviation: '',
					date: element.date,
					cost: element.cost,
					quantity: element.quantity,
					unit: element.unit,
					coin: element.coin,
					costPerUnit: '',
					observation: element.observation,
					usedAmount: 0,
					totalCost: 0,
				};
				elemnToBeInserted.abbreviation = this.getAbrreviation(
					element.name,
				);
				elemnToBeInserted.costPerUnit = (
					Math.round(
						(parseFloat(element.cost) /
							parseFloat(element.quantity)) *
							100,
					) / 100
				).toString();
				this.elementData.push(elemnToBeInserted);
			});
			if (this.elementData.length < 20) {
				var elementsNeededToCompleteTable =
					20 - this.elementData.length;
				for (
					let index = 0;
					index < elementsNeededToCompleteTable;
					index++
				) {
					this.elementData.push(this.getNewSupplyObject());
				}
			}

			const allActivities = this.activityQuery.getAll();
			this.calculateTotalCostSupply(allActivities);
			this.dataSource = new MatTableDataSource(this.elementData);
		});
	}

	public calculateTotalCostSupply(allActivities) {
		if (allActivities.length > 0) {
			this.elementData.forEach((element) => {
				if (element.id !== '') {
					element.usedAmount = 0;
					element.totalCost = 0;
					for (let index = 0; index < allActivities.length; index++) {
						const activityObject = allActivities[index];
						if (activityObject.activity.detail.length > 0) {
							for (
								let j = 0;
								j < activityObject.activity.detail.length;
								j++
							) {
								const resource =
									activityObject.activity.detail[j];
								if (
									resource.resource + '' == element.id &&
									resource.type == 3
								) {
									element.usedAmount =
										resource.percentage +
										element.usedAmount;

									const unityCost =
										parseFloat(element.cost) /
										parseFloat(element.quantity);

									const totalValue =
										resource.percentage * unityCost;
									element.totalCost =
										totalValue + element.totalCost;
									break;
								}
							}
						}
					}
					if (element.usedAmount > 0)
						element.usedAmount = (
							Math.round(element.usedAmount * 100) / 100
						).toLocaleString('en-US');

					if (element.totalCost > 0)
						element.totalCost = (
							Math.round(element.totalCost * 100) / 100
						).toLocaleString('en-US');
				}
			});
		}
	}
	public getAbrreviation(word: string) {
		if (word.length >= 2) {
			return word.substr(0, 2).toUpperCase();
		} else {
			return word;
		}
	}

	public getNewSupplyObject() {
		return {
			id: '',
			name: '',
			kind: '',
			abbreviation: '',
			date: '',
			cost: '',
			quantity: '',
			unit: '',
			coin: '1',
			costPerUnit: '',
			observation: '',
			usedAmount: 0,
			totalCost: 0,
		};
	}

	public copy(element) {
		var elemnToBeInserted = {
			id: 'new',
			name: element.name,
			kind: element.kind,
			abbreviation: element.abbreviation,
			date: element.date,
			cost: element.cost,
			unit: element.unit,
			quantity: element.quantity,
			coin: element.coin,
			costPerUnit: element.costPerUnit,
			observation: element.observation,
		};
		this.copiedElement = elemnToBeInserted;
	}

	public paste(position) {
		this.elementData[position] = this.copiedElement;
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public delete(position) {
		this.idsOfElementsToBeDeleted.push(this.elementData[position].id);
		if (
			this.idsOfElemetsThatWereModified.includes(
				this.elementData[position].id,
			)
		) {
			var index = this.idsOfElemetsThatWereModified.indexOf(
				this.elementData[position].id,
			);
			this.idsOfElemetsThatWereModified.splice(index, 1);
		}
		this.elementData.splice(position, 1);
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public addRowUp(position) {
		this.elementData.splice(position, 0, this.getNewSupplyObject());
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public addRowDown(position) {
		this.elementData.splice(position, 0, this.getNewSupplyObject());
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public changeClass(event, element) {
		element.name = event.target.value;
		element.abbreviation = this.getAbrreviation(element.name);
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeType(event, element) {
		element.kind = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeDate(event, element) {
		element.date = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeUnity(event, element) {
		element.unit = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeAmount(event, element) {
		if (isNaN(parseFloat(event.target.value))) {
			element.quantity = '';
			event.target.value = '';
			element.costPerUnit = '';
		} else {
			element.quantity = event.target.value;
			this.calculateCostPerUnity(element, 1);
			if (element.id === '') {
				element.id = 'new';
				return;
			}
			if (this.isNotListedToBeUpdated(element.id)) {
				this.idsOfElemetsThatWereModified.push(element.id);
			}
		}
	}
	public changeCost(event, element) {
		if (isNaN(parseFloat(event.target.value))) {
			element.cost = '';
			event.target.value = '';
			element.costPerUnit = '';
		} else {
			element.cost = event.target.value;
			this.calculateCostPerUnity(element, 2);
			if (element.id === '') {
				element.id = 'new';
				return;
			}
			if (this.isNotListedToBeUpdated(element.id)) {
				this.idsOfElemetsThatWereModified.push(element.id);
			}
		}
	}
	public changeCoin(event, element) {
		element.coin = event.target.value + '';
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changeObservations(event, element) {
		element.observation = event.target.value;
		if (element.id === '') {
			element.id = 'new';
			return;
		}
		if (this.isNotListedToBeUpdated(element.id)) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public calculateCostPerUnity(element, n) {
		if (element.cost !== '' && element.quantity !== '') {
			try {
				element.costPerUnit = (
					Math.round(
						(parseFloat(element.cost) /
							parseFloat(element.quantity)) *
							100,
					) / 100
				).toString();
			} catch (error) {
				element.costPerUnit = '';
				if (n == 1) {
					element.quantity = '';
				} else {
					element.cost = '';
				}
			}
		}
	}

	public isNotListedToBeUpdated(idOfLabor) {
		return (
			idOfLabor !== '' &&
			idOfLabor !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(idOfLabor)
		);
	}

	public save() {
		if (this.isSaving) {
			return;
		} else {
			this.isSaving = true;
		}
		this.numberOfElementsModified = 0;
		var newElemets: Supply[] = [];
		var elementsToBeUpdated: Supply[] = [];
		for (let index = 0; index < this.elementData.length; index++) {
			const element = this.elementData[index];
			if (element.id !== '') {
				this.numberOfElementsModified++;
			}
			if (element.id === 'new') {
				if (this.validation(element)) {
					newElemets.push(element);
				} else {
					if (
						!(
							element.name == '' &&
							element.kind == '' &&
							element.date == '' &&
							element.unit == '' &&
							element.quantity == '' &&
							element.cost == '' &&
							element.observation == ''
						)
					) {
						this.isSaving = false;
						this.globalToastService.createErrorToast(
							'Error',
							'Por favor llenar todos los campos obligatorios',
						);
						return;
					}
				}
			}
			if (this.idsOfElemetsThatWereModified.includes(element.id)) {
				if (this.validation(element)) {
					elementsToBeUpdated.push(element);
				} else {
					this.isSaving = false;
					this.globalToastService.createErrorToast(
						'Error',
						'Por favor llenar todos los campos obligatorios',
					);
					return;
				}
			}
		}
		if (
			newElemets.length === 0 &&
			elementsToBeUpdated.length === 0 &&
			this.idsOfElementsToBeDeleted.length === 0
		) {
			this.sharedData.sentViewConsultance();
			this.isSaving = false;
			return;
		}
		const dialogRef = this.dialog.open(DialogConfirmationResource, {
			data: {
				mensaje:
					'¿Desea guardar ' +
					this.numberOfElementsModified +
					' recursos de "Insumos"?',
			},
		});

		var g = dialogRef.afterClosed().subscribe((result) => {
			if (result === undefined) {
				this.idsOfElemetsThatWereModified = [];
				this.idsOfElementsToBeDeleted = [];
				this.fillTableData();
				this.sharedData.sentViewConsultance();
			}

			if (result) {
				if (newElemets.length > 0)
					this.supplyService.createManyMachines(newElemets);
				if (elementsToBeUpdated.length > 0)
					this.supplyService.updateMany(elementsToBeUpdated);
				if (this.idsOfElementsToBeDeleted.length > 0)
					this.supplyService.deleteMany(
						this.idsOfElementsToBeDeleted,
					);
				this.idsOfElemetsThatWereModified = [];
				this.sharedData.sentViewConsultance();
			}

			if (result === false) {
			}
			this.numberOfElementsModified = 0;
			this.isSaving = false;
			g.unsubscribe();
		});
	}

	public validation(element: Supply): boolean {
		if (
			element.name === '' ||
			element.kind === '' ||
			element.unit === '' ||
			element.quantity === '' ||
			element.cost === '' ||
			element.coin === ''
		) {
			return false;
		}
		return true;
	}

	public exportDataToExcel() {
		if (this.elementData !== undefined) {
			const worksheet = XLSX.utils.json_to_sheet(this.elementData);
			const workbook: XLSX.WorkBook = {
				Sheets: { data: worksheet },
				SheetNames: ['data'],
			};
			const excelBuffer: any = XLSX.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			this.saveAsExcelFile(excelBuffer, 'excelFileName');
		}
	}
	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: ExcelConst.EXCEL_TYPE });
		FileSaver.saveAs(
			data,
			fileName +
				'_export_' +
				new Date().getTime() +
				ExcelConst.EXCEL_EXTENSION,
		);
	}

	public ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	public async getProvider(e, element) {
		const type = '3';
		if (element.id !== '') {
			const provider = await this.providerService.get(element.id, type);
			provider['type'] = type;
			provider['resource'] = element.id;
			this.openDialog(e, provider);
		}
	}
	public openDialog(e, data): void {
		e.stopPropagation();
		e.preventDefault();

		const dialogRef = this.dialog.open(DialogProviderComponent, {
			width: '400px',
			height: '450px',
			disableClose: true,
			panelClass: 'custom-dialog-container',
			data: data,
		});
	}

	public async getResourceInformation(element) {
		this.supplyService.updateActive(element.id);
		const dialogRef = this.dialog.open(DialogShowResourceComponent, {
			data: 'INSUMO',
			width: '600px',
			height: '400px',
			panelClass: 'modalClass',
		});
	}
	public isValidPlan() {
        const currentUser = this.userQuery.getInformation();
        return currentUser.permission.resourceManagement;
    }

    public showConfirmPlanUpdate() {
        if (this.isValidPlan())
            return;

        const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
            width: '400px'
        });
        saveRef.afterClosed().subscribe()
    }
}
