import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { AgrodatNotify, AgrodatNotifyState, AgrodatNotifyStore } from './agrodat-notify.store';

@Injectable({ providedIn: 'root' })
export class AgrodatNotifyQuery extends QueryEntity<AgrodatNotifyState> {
    
    public selectAllNotifications: Observable<AgrodatNotify[]> = this.selectAll();

    constructor(protected store: AgrodatNotifyStore) {
        super(store);
    }

}
