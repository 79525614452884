import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ActivitiesStorageQuery } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.query';
import {
    ActivityDetail,
    FullActivity,
} from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.store';
import { LaborStorageQuery } from '@src/app/modules/agromonitor/storage/labor-storage/labor-storage.query';
import { MachineStorageQuery } from '@src/app/modules/agromonitor/storage/machine-storage/machine-storage.query';
import { ServiceStorageQuery } from '@src/app/modules/agromonitor/storage/serv-other-storage/serv-other-storage.query';
import { SupplyStorageQuery } from '@src/app/modules/agromonitor/storage/supplies-storage/supplies-storage.query';
import { WaterStorageQuery } from '@src/app/modules/agromonitor/storage/water-storage/water-storage.query';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-campaing-detail',
    templateUrl: './campaing-detail.component.html',
    styleUrls: ['./campaing-detail.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-out', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('0.5s ease-in', style({ opacity: 0 }))]),
        ]),
    ],
})
export class CampaingDetailComponent implements OnInit {
    @Input() campaing_id: any;
    @Input() volumn: any;
    @Output()
    propagarData = new EventEmitter<any>();
    public totalHoursLabor = 0;
    public totalCostLabor = 0;
    public costUnitLabor = 0;
    public hhUnitLabor = 0;
    public totalHoursMachine = 0;
    public totalCostMachine = 0;
    public costUnitMachine = 0;
    public hhUnitMachine = 0;
    public suppliesObjects = [];
    public servicesObjects = [];
    public waterObjects = [];
    private laborIds = [];
    private machineIds = [];
    private supplyIds = [];
    private waterIds = [];
    private serviceIds = [];
    public selectedView = 0;

    constructor(
        private ActivitiesStorage: ActivitiesStorageQuery,
        private laborQuery: LaborStorageQuery,
        private machineQuery: MachineStorageQuery,
        private waterQuery: WaterStorageQuery,
        private serviceAndOtherQuery: ServiceStorageQuery,
        private supplyQuery: SupplyStorageQuery,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.getInformation();
    }

    private getInformation() {
        const allActivities = this.ActivitiesStorage.getAll().filter((actv) => actv.season === this.campaing_id);
        for (let index = 0; index < allActivities.length; index++) {
            const activityObject = allActivities[index];
            this.laborIds = [];
            this.machineIds = [];
            this.supplyIds = [];
            this.waterIds = [];
            this.serviceIds = [];
            for (let j = 0; j < activityObject.activity.detail.length; j++) {
                const resource = activityObject.activity.detail[j];
                if (resource.type === 0) this.calculateLabor(activityObject, resource); //break;
                if (resource.type === 1) this.calculateMachine(activityObject, resource); //break;
                if (resource.type === 2) this.addWater(resource.resource + ''); //break;
                if (resource.type === 3) this.addSupply(resource); //break;
                if (resource.type === 4) this.addServiceAndOther(resource); //break;
            }
        }
        this.volumn > 0 ? (this.costUnitLabor = Math.round((this.totalHoursLabor / this.volumn) * 100) / 100) : 0;
        this.volumn > 0 ? (this.hhUnitLabor = Math.round((this.totalCostLabor / this.volumn) * 100) / 100) : 0;
        this.volumn > 0 ? (this.costUnitMachine = Math.round((this.totalHoursMachine / this.volumn) * 100) / 100) : 0;
        this.volumn > 0 ? (this.hhUnitMachine = Math.round((this.totalCostMachine / this.volumn) * 100) / 100) : 0;
        this.updateData();
    }

    ngOnInit(): void {}

    private updateData() {
        let data = {
            campaing_id: this.campaing_id,
            labor: {
                total_hh: '',
                total_cost: '',
                hh_unit: '',
                cost_unit: '',
            },
            machine: {
                total_hm: '',
                total_cost: '',
                hm_unit: '',
                cost_unit: '',
            },
            resources: [],
            service_and_others: [],
        };
        data.labor.total_hh = this.totalHoursLabor + '';
        data.labor.total_cost = this.totalCostLabor + '';
        data.labor.hh_unit = this.costUnitLabor + '';
        data.labor.cost_unit = this.hhUnitLabor + '';
        data.machine.total_hm = this.totalHoursMachine + '';
        data.machine.total_cost = this.totalCostMachine + '';
        data.machine.hm_unit = this.costUnitMachine + '';
        data.machine.cost_unit = this.hhUnitMachine + '';
        data.resources = this.suppliesObjects.map(
            (elem) => (elem = { name: elem.name, unit: elem.unit, quantity: elem.quantity, cost: elem.cost }),
        );
        data.service_and_others = this.servicesObjects.map(
            (elem) => (elem = { name: elem.name, unit: elem.unit, quantity: elem.quantity, cost: elem.cost }),
        );
        this.onPropagar(data);
    }
    private calculateLabor(activityObject: FullActivity, resource: ActivityDetail) {
        if (this.laborIds.includes(resource.resource)) return;
        this.laborIds.push(resource.resource);
        const horasHombre =
            activityObject.activity.workday * activityObject.activity.duration * (resource.percentage / 100);
        this.totalHoursLabor = horasHombre + this.totalHoursLabor;
        const laborObject = this.laborQuery.getEntity('' + resource.resource);
        if (laborObject) {
            const horasHombrePrice = parseInt(laborObject.cost) / parseInt(laborObject.hours);
            const totalValue = horasHombre * horasHombrePrice;
            this.totalCostLabor = totalValue + this.totalCostLabor;
        }
    }

    private calculateMachine(activityObject: FullActivity, resource: ActivityDetail) {
        if (this.machineIds.includes(resource.resource)) return;
        this.machineIds.push(resource.resource);
        const horasMachine =
            activityObject.activity.workday * activityObject.activity.duration * (resource.percentage / 100);
        this.totalHoursMachine = this.totalHoursMachine + horasMachine;
        const machineObject = this.machineQuery.getEntity('' + resource.resource);
        if (machineObject) {
            const hoursMachinePrice = parseInt(machineObject.cost);
            const totalValue = horasMachine * hoursMachinePrice;
            this.totalCostMachine = totalValue + this.totalCostMachine;
        }
    }

    private addSupply(resource: ActivityDetail) {
        if (this.supplyIds.includes(resource)) return;
        this.supplyIds.push(resource);
        const supplyObj = this.supplyQuery.getEntity(resource.resource + '');
        const costPerUnir = Math.round((+supplyObj.cost * 100) / +supplyObj.quantity) / 100;
        if (supplyObj)
            this.suppliesObjects.push({
                name: supplyObj.name,
                unit: supplyObj.unit,
                cost: costPerUnir * resource.percentage,
                quantity: resource.percentage,
            });
    }
    private addServiceAndOther(resource: ActivityDetail) {
        if (this.serviceIds.includes(resource)) return;
        this.serviceIds.push(resource);
        const serviceObj = this.serviceAndOtherQuery.getEntity(resource.resource + '');
        const costPerUnir = Math.round(+serviceObj.cost);
        if (serviceObj)
            this.servicesObjects.push({
                name: serviceObj.name,
                unit: serviceObj.unit,
                cost: costPerUnir * resource.percentage,
                quantity: resource.percentage,
            });
    }
    private addWater(resource: string) {
        if (this.waterIds.includes(resource)) return;
        this.waterIds.push(resource);
        const waterObj = this.waterQuery.getEntity(resource);
        if (waterObj) this.waterObjects.push(waterObj);
    }

    private onPropagar(data) {
        this.propagarData.emit(data);
    }
}
