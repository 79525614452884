import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { LotDroneTicketState, LotDroneTicketStore } from './drone-ticket.store';

@Injectable({ providedIn: 'root' })
export class LotDroneTicketQuery extends QueryEntity<LotDroneTicketState> {
	public constructor(protected store: LotDroneTicketStore) {
		super(store);
	}
}
