import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { MachineState, Machine, MachineStore } from './machine-storage.store'

@Injectable({ providedIn: 'root' })

export class MachineStorageQuery extends QueryEntity<MachineState> {
    public selectAllFields: Observable<Machine[]> = this.selectAll();
    constructor(protected store: MachineStore) {
        super(store);
    }
}
