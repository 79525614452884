import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@src/app/modules/my-fields/modules/fields/components';
export interface Mensaje{
	mensaje: ''
}
@Component({
	selector: 'confirmation-dialog',
	templateUrl: './confirmation-dialog.html',
})
export class DialogConfirmationResource {
	constructor(@Inject(MAT_DIALOG_DATA) public data: Mensaje) { }
}