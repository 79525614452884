import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsUiService } from '../../services/fields-ui.service';
import { Subscription } from 'rxjs';
import { FielListQuery } from '@src/app/modules/my-fields/store/field-list/fiel-list.query';
import { LotsService } from '@src/app/store/lots/lots.service';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LotEditService } from '@src/app/modules/my-fields/states/lot-edit/lot-edit.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SatellitalImageService } from '@src/app/modules/my-fields/store/satellital-images-state/satellital-images-state.service';
import { FielListService } from '@src/app/modules/my-fields/store/field-list/fiel-list.service';
import { LotDroneTicketQuery } from '@src/app/store/drone-ticket/drone-ticket.query';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-field-list',
    templateUrl: './field-list.component.html',
    styleUrls: ['./field-list.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('0.5s ease-out', style({ height: 300, opacity: 1 })),
            ]),
            transition(':leave', [
                style({ height: 300, opacity: 1 }),
                animate('0.5s ease-in', style({ height: 0, opacity: 0 })),
            ]),
        ]),
    ],
})
export class FieldListComponent implements OnInit {
    public selectedModal: number = 2;
    public suscriptions: Subscription[] = [];
    public allFields = [];
    public showList = true;
    public filterInputText = '';
    public allFieldsOriginal = undefined;
    public lotsDrawed: any = {}
    public fieldsDrawed: any = {}
    constructor(
        private readonly fieldsQuery: FieldsQuery,
        private readonly fieldsUiService: FieldsUiService,
        private readonly fielListQuery: FielListQuery,
        private readonly fielListService: FielListService,
        private lotsService: LotsService,
        private mapSidebarComService: MapSidebarComService,
        private fieldsService: FieldsService,
        public dialog: MatDialog,
        public lotEditService: LotEditService,
        private readonly spinner: NgxSpinnerService,
        private satellitalImageService: SatellitalImageService,
        private readonly lotDroneTicketQuery: LotDroneTicketQuery,
    ) { }

    ngOnInit(): void {
        this.suscriptions.push(
            this.fielListQuery.selectSelectedResource.subscribe((x) => {
                this.selectedModal = x;

            }),
            this.fieldsQuery.selectFieldsWithLots().subscribe((x) => {
                this.allFields = x;
                let lotsTinitialDrawed: any = {}
                let fieldsTinitialDrawed: any = {}
                for (let index = 0; index < this.allFields.length; index++) {
                    const element = this.allFields[index];
                    var totalArea = 0;
                    fieldsTinitialDrawed[element.id] = false;
                    for (let j = 0; j < element.lots.length; j++) {
                        const element2 = element.lots[j];
                        totalArea = totalArea + element2.area;
                        lotsTinitialDrawed[element2.id] = false;
                    }
                    if (totalArea > 0) element.totalArea = Math.round(totalArea * 100) / 100;
                    else element.totalArea = 0;
                }
                this.allFields.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : b.name.toLowerCase() > a.name.toLowerCase()
                            ? -1
                            : 0,
                );
                this.allFieldsOriginal = JSON.parse(JSON.stringify(this.allFields));
                this.lotsDrawed = lotsTinitialDrawed;
                this.fieldsDrawed = fieldsTinitialDrawed;
            }),
        );
    }

    public lotDetail(lotId) {
        this.lotEditService.setSelectedResource(0);
        this.lotsService.setActiveLot(lotId);
        this.fielListService.setSelectedResource(2);
        this.mapSidebarComService.sentClickToCenter();
        this.fieldsUiService.setSelectedResource(3);
        this.fieldsUiService.openSidebar();
        this.satellitalImageService.setLotIdForSatellitalImage(lotId);
        this.clearDrawed()
    }
    public clearDrawed() {

        for (const property in this.fieldsDrawed) {
            this.fieldsDrawed[property] = false;
        }
        for (const property in this.lotsDrawed) {
            this.lotsDrawed[property] = false;
        }
    }

    public filterSearch(event) {
        this.filterInputText = event.target.value;
        this.allFields = JSON.parse(JSON.stringify(this.allFieldsOriginal));
        if (this.selectedModal == 1) {
            this.allFields = this.allFields.filter((elem) =>
                elem.name.toLowerCase().includes(this.filterInputText.toLowerCase()),
            );
        }
        if (this.selectedModal == 2) {
            for (let index = 0; index < this.allFields.length; index++) {
                const element = this.allFields[index];
                element.lots = element.lots.filter((lot) =>
                    lot.name.toLowerCase().includes(this.filterInputText.toLowerCase()),
                );
            }
        }
        if (this.selectedModal == 3) {
            for (let index = 0; index < this.allFields.length; index++) {
                const element = this.allFields[index];
                element.lots = element.lots.filter((lot) =>
                    lot.varietyName.toLowerCase().includes(this.filterInputText.toLowerCase()),
                );
            }
        }
    }

    trackByFn(index, item) {
        return index; // or item.id
    }

    public isThereAnyLotTicketInField(fieldId) {
        const field = this.fieldsQuery.getEntity(fieldId);
        const ticketsOfLot = this.lotDroneTicketQuery.getAll().filter((ticket) => field.lots.includes(ticket.lot));
        return ticketsOfLot.length > 0;
    }

    public async deleteField(idField: string) {
        if (this.isThereAnyLotTicketInField(idField)) {
            const dialogRef = this.dialog.open(CannotDeleteDialog);
        } else {
            const dialogRef = this.dialog.open(FieldDeleteDialog);
            const a = dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.spinner.show('Syncrof');
                    try {
                        this.fieldsService.removeField(idField);
                    } catch (error) { }
                    this.spinner.hide('Syncrof');
                }
                a.unsubscribe();
            });
            this.fieldsUiService.closeSidebar();
        }
    }

    public editField(fieldId: string) {
        this.fieldsService.setActiveField(fieldId);
        this.fieldsUiService.setSelectedResource(4);
        this.fieldsUiService.openSidebar();
    }

    public isThereAnyLotTicket(idLot) {
        const ticketsOfLot = this.lotDroneTicketQuery.getAll().filter((ticket) => ticket.lot == idLot);
        return ticketsOfLot.length > 0;
    }

    public createField(fieldId) {
        this.fieldsService.setActiveField(fieldId);
        this.mapSidebarComService.sentClickCreateLot();
    }
    public open(field:any){
        if (field.lots.length > 0){
            this.mapSidebarComService.sentClickToCenterField(field.lots[0].id);
        }
    }

    public async drawField(field) {

        if (field.lots.length > 30) {
            if (this.fieldsDrawed[field.id]) {
                this.mapSidebarComService.sentClickDrawIndexComplete(undefined, "POL")
                this.fieldsDrawed[field.id] = false;

            }
            else {
                let lot = field.lots[0].id
                await this.dialog.open(FieldIndicesDialog).afterClosed().subscribe(async (res) => {
                    if (res != undefined) {
                        let indice = res.data
                        this.mapSidebarComService.sentClickDrawIndexComplete(lot, indice)
                        this.fieldsDrawed[field.id] = true
                    }
                });
            }
            return;
        }
        else {

            if (this.fieldsDrawed[field.id]) {
                for (let index = 0; index < field.lots.length; index++) {
                    if (this.lotsDrawed[field.lots[index].id]) {
                        this.mapSidebarComService.sentClickDrawIndex(field.lots[index].id, "POL", this.mapSidebarComService.viewId)
                        this.lotsDrawed[field.lots[index].id] = false
                    }
                }
                this.fieldsDrawed[field.id] = false;

            }
            else {
                await this.dialog.open(FieldIndicesDialog).afterClosed().subscribe(async (res) => {
                    if (res != undefined) {
                        let indice = res.data
                        for (let index = 0; index < field.lots.length; index++) {
                            if (this.lotsDrawed[field.lots[index].id]) {
                                this.mapSidebarComService.sentClickDrawIndex(field.lots[index].id, "POL", this.mapSidebarComService.viewId)
                                this.lotsDrawed[field.lots[index].id] = false
                            }
                            this.mapSidebarComService.sentClickDrawIndex(field.lots[index].id, indice, this.mapSidebarComService.viewId)
                            this.lotsDrawed[field.lots[index].id] = true
                        }
                        this.fieldsDrawed[field.id] = true;
                    }
                });
            }
        }
    }


    public hideList() {
        this.showList = !this.showList;
    }

    public deleteLot(lotId: string) {
        if (this.isThereAnyLotTicket(lotId)) {
            this.dialog.open(CannotDeleteDialog);
        } else {
            const dialogRef = this.dialog.open(LotDeleteDialog);
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) await this.comfirmDeleteLot(lotId);
            });
        }
    }

    public async selectIndices(lot: any, field: any) {

        if (this.lotsDrawed[lot.id]) {
            await this.mapSidebarComService.sentClickDrawIndex(lot.id, "POL", this.mapSidebarComService.viewId)
            this.lotsDrawed[lot.id] = false
        }
        else {
            await this.dialog.open(FieldIndicesDialog).afterClosed().subscribe((res) => {
                if (res != undefined) {
                    this.mapSidebarComService.sentClickDrawIndex(lot.id, res.data, this.mapSidebarComService.viewId)
                    this.lotsDrawed[lot.id] = true;
                }
            });

        }
        for (let index = 0; index < field.lots.length; index++) {
            if (this.lotsDrawed[field.lots[index].id] == true) {
                return;
            }
        }
        if (field.lots.length < 30)
            this.fieldsDrawed[field.id] = false;
    }

    public async comfirmDeleteLot(lotId: string) {
        this.spinner.show('Syncrof');
        this.lotsService.setActiveLot(lotId);
        let rpt = await this.lotsService.deleteLot(lotId);
        if (!rpt) return;

        this.fieldsUiService.closeSidebar();
        this.mapSidebarComService.sentClickDeleteLot(lotId);
        this.spinner.hide('Syncrof');
    }

    public editLot(lotId) {
        if (this.isThereAnyLotTicket(lotId)) {
            const dialogRef = this.dialog.open(CannotDeleteDialog);
        } else {
            //Change value of state that will display edit form
            this.lotEditService.setSelectedResource(1);
            //Set the lot that will be shown in the sidebar
            this.lotsService.setActiveLot(lotId);
            //Go to the center of the lot
            this.mapSidebarComService.sentClickToCenter();
            //Set the sidebar that will be displayed
            this.fieldsUiService.setSelectedResource(3);
            //Open the sidebar
            this.fieldsUiService.openSidebar();
            //Enable polygon edit
            this.mapSidebarComService.sentClickEnableEdit(lotId);
            this.satellitalImageService.setSatellitalImageDisplay(false);
        }
    }

    public hideListReactive() {
        if (this.showList) {
            this.heightOfList = '24px';
        } else {
            this.heightOfList = '40%';
        }
        this.showList = !this.showList;
    }
    public heightOfList = '100%';
    @ViewChild('filterField') redel: ElementRef;
}

@Component({
    selector: 'field-delete-confirmation',
    templateUrl: 'field-delete-confirmation.html',
})
export class FieldDeleteDialog { }

@Component({
    selector: 'field-delete-confirmation',
    templateUrl: 'field-delete-confirmation.html',
})
export class LotDeleteDialog { }

@Component({
    selector: 'cannot-delete',
    templateUrl: 'cannot-delete.html',
})
export class CannotDeleteDialog { }

@Component({
    selector: 'field-indices',
    templateUrl: 'field-indices.html',
})
export class FieldIndicesDialog {

    constructor(public dialogRef: MatDialogRef<FieldIndicesDialog>) { }

    public selectOption(option: String) {
        this.dialogRef.close({ data: option });
    }
}
