import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { ProviderService } from '../../storage/provider-storage/provider-storage.service';
import { Provider } from '../../storage/provider-storage/provider-storage.store';

@Component({
    selector: 'app-dialog-provider-small',
    templateUrl: './dialog-provider-small.component.html',
    styleUrls: ['./dialog-provider-small.component.scss']
})
export class DialogProviderSmallComponent implements OnInit {

    public messages: Array<string>;
    public editEnable = true;
    public provider: Provider = {
        resource: "",
        type: "",
        ruc: null,
        business_name: null,
        email: "",
        phone: "",
        web_page: null,
        person_contact: null,
    }
    constructor(
        public dialogRef: MatDialogRef<DialogProviderSmallComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private toastService: GlobalToastService,
        private providerService: ProviderService
    ) {
        this.setData(data)
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close({})
    }
    public setData(data) {
        this.provider.resource = data["resource"];
        this.provider.email = data["email"]
        this.provider.phone = data["phone"]
        this.provider.type = data["type"]
        if(this.provider.email != "" || this.provider.phone != "" )
            this.editEnable = false;
    }
    async createProvider() {
        const response = await this.providerService.create(this.provider)
        if (response === 201) {
            this.toastService.createSuccessToast(
                'Exito',
                'El proveedor se añadio correctamente',
            );
            this.close()
        }
        else {
            this.toastService.createErrorToast(
                'Hubo un error',
                'Los datos ingresados son incorrectos',
            );
        }

    }
}
