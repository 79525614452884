import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

import { LaborStore, Labor } from './labor-storage.store';
import { LaborStorageQuery } from './labor-storage.query';

export interface Message {
    StatusCode: number,
    Error: string,
    message: Labor[]
}
@Injectable({ providedIn: 'root' })
export class LaborService {

    constructor(
        private laborStore: LaborStore,
        private laborStorageQuery: LaborStorageQuery,
        private http: HttpClient
    ) {
    }
    
    public updateActive(id){
        this.laborStore.setActive(id)
    }
    public async findAll(): Promise<Labor[]> {
        const hasCache = this.laborStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }

        try {
            const data = await this.http
                .get<Message>(`${environment.apiUrl}/workforce/`)
                .toPromise<Message>();
            const dataArray = data.message
            const labors = dataArray.map((currentLabor: any) => {
                const labor = currentLabor;
                labor.id = String(labor.id);
                labor.dni = String(labor.dni);
                labor.hours = String(labor.hours);
                labor.cost = String(labor.cost);
                labor.coin = String(labor.coin);
                labor.quantity = String(labor.quantity);
                return labor;
            });
            this.laborStore.set(labors);
            return labors;
        }
        catch (error) {
        }
    }

    public async createMany(arrayOfLaborObjects) {
        try {
            arrayOfLaborObjects.forEach(element => {
                element.dni = parseInt(element.dni);
                element.hours = parseInt(element.hours);
                element.coin = parseInt(element.coin);
                delete element.id;
                delete element.labor;
                delete element.costPerHour;
                delete element.abbreviation;
                if (element.start_date === "")
                    element.start_date = null
                if (element.end_date === "")
                    element.end_date = null
                if (element.dni === "")
                    element.dni = null
            });
            const labor = await this.http
                .post<Message>(`${environment.apiUrl}/workforce/`,
                    arrayOfLaborObjects
                )
                .toPromise<Message>();
            const dataArray = labor.message
            dataArray.forEach(element => {
                element.dni = String(element.dni);
                element.hours = String(element.hours);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.laborStore.add(element)
            });
        } catch (error) {

        }
    }

    public async updateMany(arrayOfLaborObjects) {
        try {
            arrayOfLaborObjects.forEach(element => {
                element.dni = parseInt(element.dni);
                element.hours = parseInt(element.hours);
                element.coin = parseInt(element.coin);
                delete element.labor;
                delete element.costPerHour;
                delete element.abbreviation;
                if (element.start_date === "")
                    element.start_date = null
                if (element.end_date === "")
                    element.end_date = null
                if (element.dni === "")
                    element.dni = null
            });
            const labor = await this.http
                .put<Message>(`${environment.apiUrl}/workforce/`,
                    arrayOfLaborObjects
                )
                .toPromise<Message>();
            const dataArray = labor.message
            dataArray.forEach(element => {
                element.dni = String(element.dni);
                element.hours = String(element.hours);
                element.coin = String(element.coin);
                element.id = String(element.id)
                this.laborStore.update(element.id, element)
            });
        } catch (error) {

        }
    }

    public async deleteMany(arrayOfLaborObjects) {
        try {
            var idsOfLabors = []
            arrayOfLaborObjects.forEach(element => {
                idsOfLabors.push(parseInt(element))
            });
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    idsOfLabors
            };
            const labor = await this.http
                .delete(`${environment.apiUrl}/workforce/`,
                    options
                )
                .toPromise();

            arrayOfLaborObjects.forEach(element => {
                this.laborStore.remove(element)
            });
        } catch (error) {
        }
    }
}   
