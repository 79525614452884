import { Injectable } from '@angular/core';
import { QueryEntity, combineQueries } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { FieldsState, FieldsStore, Field } from './fields.store';
import { LotsQuery } from '@store/lots/lots.query';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FieldsQuery extends QueryEntity<FieldsState> {
	public selectAllFields: Observable<Field[]> = this.selectAll();

	public constructor(
		protected store: FieldsStore,
		private readonly lotsQuery: LotsQuery,
	) {
		super(store);
	}

	public selectFieldsWithLots() {
		return combineQueries([
			this.selectAll(),
			this.lotsQuery.selectAll({ asObject: true }),
		]).pipe(
			map(([fields, lots]) => {
				return fields.map((x) => ({
					...x,
					lots: x.lots.map((y: string) => lots[y]),
				}));
			}),
		);
	}

	public getFieldsWithLots() {
		let fields = this.getAll();
		let lots = this.lotsQuery.getAll();
	}

	public selectField(fieldId) {
		return this.selectEntity(fieldId);
	}
}
