import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@src/environments/environment';

import { ActivitiesStore, Activity, FullActivity } from './activities-storage.store';
import { ActivitiesStorageQuery } from './activities-storage.query';

export interface response {
    statusCode: number,
    error: string,
    message: any | Array<any>
}

@Injectable({ providedIn: 'root' })
export class ActivitiesService {

    constructor(
        private activityStore: ActivitiesStore,
        private activityStorageQuery: ActivitiesStorageQuery,
        private http: HttpClient
    ) {
    }

    public async findAll(): Promise<FullActivity[]> {
        const hasCache = this.activityStorageQuery.getHasCache();
        if (hasCache) {
            return;
        }
        try {
            const data = await this.http
                .get<response>(`${environment.apiUrl}/activity/`)
                .toPromise<response>();
            const activities = data.message.map((currentActivity: FullActivity) => {
                return currentActivity;
            });
            this.activityStore.set(activities);
            return activities;
        }
        catch (error) {
        }
    }

    public async getShared(camp_id : String): Promise<FullActivity[]> {
        try {
            const data = await this.http
                .get<response>(`${environment.apiUrl}/activity/shared/camp_id=`+camp_id)
                .toPromise<response>();
            const activities = data.message
            return activities;
        }
        catch (error) {
        }
    }

    public async createOne(activities: FullActivity[]) {
        try {
            const data = await this.http
                .post<response>(`${environment.apiUrl}/activity/`, activities).toPromise<response>();

            for (let i = 0; i < activities.length; ++i) {
                activities[i].id = data.message[data.message.length - 1 - i].id
                activities[i].activity.id = data.message[data.message.length - 1 - i].activity.id
                this.activityStore.add({
                    ...activities[i]
                });
            }


        } catch (error) {
        }
    }

    public async updateActivities(activities: FullActivity[]) {
        try {
            const data = await this.http.put<response>(`${environment.apiUrl}/activity/`, activities
            ).toPromise<response>();
            for (let i = 0; i < activities.length; ++i) {
                this.activityStore.update(({ id }) => id == activities[i].id, {
                    ...activities[i]
                });
            }
        } catch {
        }
    }

    public async updateActivitiesShared(camp_id : String, activities: FullActivity[]) {
        try {
            const data = await this.http.put<response>(`${environment.apiUrl}/activity/shared/camp_id=`+camp_id, activities
            ).toPromise<response>();
            for (let i = 0; i < activities.length; ++i) {
                this.activityStore.update(({ id }) => id == activities[i].id, {
                    ...activities[i]
                });
            }
        } catch {
        }
    }

    public async deleteActivities(activities: number[]) {
        try {
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body: activities
            };
            const data = await this.http.delete<response>(`${environment.apiUrl}/activity/`, options
            ).toPromise<response>();
            this.activityStore.remove(activities)
        } catch {
        }
    }

    public async deleteActivitiesShared(camp_id : String, activities: number[]) {
        try {
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body: activities
            };
            const data = await this.http.delete<response>(`${environment.apiUrl}/activity/shared/camp_id=`+camp_id, options
            ).toPromise<response>();
            this.activityStore.remove(activities)
        } catch {
        }
    }

    public deleteActivitesFromLot(lotId: string) {
        this.activityStorageQuery.getAllActivitiesFromLot(lotId).subscribe(activites => {
            let activitiesId: number[] = [];
            activites.forEach(activity => {
                activitiesId.push(activity.id);
            })
            this.activityStore.remove(activitiesId);
        });

    }
}
