import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DataDialog {
    text: string
}

@Component({
    selector: 'noti-information-dialog',
    templateUrl: './noti-information-dialog.html',
})
export class DialogInformationNotification {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DataDialog) { }
}