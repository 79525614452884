import { Component, OnInit } from '@angular/core';
import { UserQuery } from '@store/user/user.query';
import { User } from '@shared/entities/user/user.entity';
import { Router } from '@angular/router';
import { AuthHttpService } from '@src/app/store/auth/auth-http.service';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { CookieService } from 'ngx-cookie-service';
import { MyFieldsNotificationsComponent } from './notification-components/my-fields-notifications/my-fields-notifications.component';
import { AgrometNotificationsComponent } from './notification-components/agromet-notifications/agromet-notifications.component';
import { AgrodatNotificationsComponent } from './notification-components/agrodat-notifications/agrodat-notifications.component';
import { ThemeDarkLightService } from '@src/app/shared/services/theme/theme-service.service';
import { AgrodatHistoricalService } from '@src/app/store/historic-agrodat-notifications/historical-agrodat-notify.service';
import { AgrometHistoricalService } from '@src/app/store/historic-agromet-notifications/historical-agromet-notify.service';
import { FieldsHistoricalService } from '@src/app/store/historic-fields-notifications/historical-fields-notify.service';
import { MapSidebarComService } from '@src/app/modules/my-fields/modules/fields/services/map-sidebar-com.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    public user: User;
    private onlyShowOnce = 1;
    private theme = this.themeService.getThemeName();
    public agrodatNotifications = 0;
    public agrometNotifications = 0;
    public fieldNotifications = 0;
    constructor(
        private readonly userQuery: UserQuery,
        private readonly authService: AuthHttpService,
        private readonly router: Router,
        private readonly sidebarService: SidebarService,
        public dialog: MatDialog,
        private cookieService: CookieService,
        private themeService: ThemeDarkLightService,
        private agrodatHistoricalService: AgrodatHistoricalService,
        private agrometHistoricalService: AgrometHistoricalService,
        private fieldsHistoricalService: FieldsHistoricalService,
        private mapSidebarComService: MapSidebarComService,
    ) {
        this.user = {
            firstName: 'Loading',
            lastName: '...',
            permission: {
                availableArea: 0,
                usedArea: 0,
                usedMemory: 0,
                availableMemory: 0,
            },
        };
    }
    async ngOnInit() {
        await this.agrodatHistoricalService.getNotReaded().then((all) => {
            this.agrodatNotifications = all.not_readed;
        }).catch( error=>{
            console.log("Error in unread message from agrodat", error)
        });
        await this.agrometHistoricalService.getNotReaded().then((all) => {
            this.agrometNotifications = all.not_readed;
        }).catch(error=>{
            console.log("Error in unread message from agromet", error)
        });
        await this.fieldsHistoricalService.getNotReaded().then((all) => {
            this.fieldNotifications = all.not_readed;
        }).catch( error=>{
            console.log("Error in unread message from satellital images", error)
        });
        this.userQuery.select().subscribe(async (data) => {
            if (!data) {
                this.user = { firstName: 'Loading...' };
            }
            this.user = data;

            if (this.onlyShowOnce == 1) {
                this.onlyShowOnce = 0;
                const dataV = await this.authService.isValidate();
                if (!dataV.message.response) {
                    const dialogRef = this.dialog.open(ChangeEmailDialog);
                    dialogRef.afterClosed().subscribe(async (result) => {});
                }
            }
        });
        this.mapSidebarComService.getClickToCountNotifications().subscribe((typeToCount: string) => {
            if (typeToCount === 'fields' && this.fieldNotifications)
                this.fieldNotifications = this.fieldNotifications - 1;
            if (typeToCount === 'agromet' && this.agrometNotifications)
                this.agrometNotifications = this.agrometNotifications - 1;
            if (typeToCount === 'agrodat' && this.agrodatNotifications)
                this.agrodatNotifications = this.agrodatNotifications - 1;
        });
    }

    public openSidebar() {
        this.sidebarService.open();
    }

    public navigateToProfile() {
        this.router.navigate(['/profile']);
    }
    public navigateToSpaceManagement() {
        this.router.navigate(['/space-management']);
    }

    public logout(): void {
        this.cookieService.deleteAll();
        this.authService.logout();
        this.router.navigate(['/auth/login']);
    }

    public openNotificationModal(value: number) {
        switch (value) {
            case 1:
                const dialogRef = this.dialog.open(MyFieldsNotificationsComponent, {
                    panelClass: 'modalClass',
                    width: '500px',
                    height: '410px',
                });
                dialogRef.afterClosed().subscribe((result) => {});
                break;
            case 2:
                const dialogRef2 = this.dialog.open(AgrodatNotificationsComponent, {
                    panelClass: 'modalClass',
                    width: '500px',
                    height: '410px',
                });
                dialogRef2.afterClosed().subscribe((result) => {});
                break;
            case 3:
                const dialogRef3 = this.dialog.open(AgrometNotificationsComponent, {
                    panelClass: 'modalClass',
                    disableClose: false,
                    width: '500px',
                    height: '410px',
                });
                dialogRef3.afterClosed().subscribe((result) => {});
                break;
            default:
                break;
        }
    }

    public changeTheme() {
        this.theme == 'light-theme' ? (this.theme = 'dark-theme') : (this.theme = 'light-theme');

        this.themeService.sendClickToUpdateTheme(this.theme);
    }
}

@Component({
    selector: 'change-email-modal',
    templateUrl: 'change-email-modal.html',
})
export class ChangeEmailDialog {
    private form = {
        email: '',
        confirmMail: '',
        password: '',
    };
    constructor(
        public dialogRef: MatDialogRef<ChangeEmailDialog>,
        private readonly toastService: GlobalToastService,
        private readonly authService: AuthHttpService,
    ) {}

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public changeMail(e) {
        this.form.email = e;
    }
    public confirmMail(e) {
        this.form.confirmMail = e;
    }
    public changePassword(e) {
        this.form.password = e;
    }

    public async save() {
        if (this.form.email != this.form.confirmMail) {
            this.toastService.createWarnToast('', 'Ambos correos deben ser iguales');
            return;
        }
        try {
            await this.authService.updateEmail(this.form);
            this.dialogRef.close(this.form);
            this.toastService.createWarnToast('', 'Se cambio el correo');
        } catch (error) {
            this.toastService.createErrorToast('Error', 'Contraseña errónea');
        }
    }
    public cancel() {
        this.dialogRef.close();
    }
}
