import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { environment } from '@src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { StadisticsResponse } from '@src/app/store/stadistics/stadistics.service';
import { StadisticsSingleObject } from '@src/app/store/stadistics/stadistics.store';
import { SubVarietyQuery } from '@src/app/store/subVariety/state/sub-variety.query';

export class Response<Type> {
    statusCode: number;
    message: Type;
    error: string;
}

export class GeneralUserInfo {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
}

export class Request {
    id: string;
    status: string;
    toUserId: string;
    fromUserId: string;
    nameFromUser: string;
}

@Injectable({
    providedIn: 'root',
})
export class SharedLotsService {
    sharedFields: any[] = undefined;
    selectSharedLot: any = null;
    private updateSelectedLot = new Subject<any>();
    private updateShareFields = new Subject<any>();
    constructor(
        private readonly http: HttpClient,
        private readonly globalToastService: GlobalToastService,
        private readonly subVarietyQuery: SubVarietyQuery,
    ) { }

    public async searchUsers(email: string) {
        try {
            const data = await this.http
                .get<Response<GeneralUserInfo[]>>(`${environment.apiUrl}/users/search/${email}`)
                .toPromise<Response<GeneralUserInfo[]>>();
            return data.message;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public async sendRequests(toUserId: string, name1: string, name2: string) {
        try {
            const data = await this.http
                .post<Response<Boolean>>(`${environment.apiUrl}/lots/request/user/${toUserId}`, {
                    nameFromUser: name1,
                    nameToUser: name2,
                })
                .toPromise<Response<Boolean>>();
            return data.message;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public async getRequests() {
        try {
            const data = await this.http
                .get<Response<Request[]>>(`${environment.apiUrl}/lots/request/received`)
                .toPromise<Response<Request[]>>();
            return data.message;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public async responseRequest(datar: Request, response: boolean, campaignId: string[]) {
        try {
            const data = await this.http
                .put<Response<Boolean>>(`${environment.apiUrl}/lots/request/response`, {
                    requestId: datar.id,
                    response: response,
                    campaignsId: campaignId,
                })
                .toPromise<Response<Boolean>>();
            return data.message;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public async getLotsShared() {
        try {
            const data = await this.http
                .get<Response<any[]>>(`${environment.apiUrl}/lots/shared`)
                .toPromise<Response<any[]>>();
            this.sharedFields = data.message;
            this.swapCoordinates();
            this.updateShareFields.next(this.sharedFields);
            data.message.forEach((field) => {
                field['totalArea'] = 0
                field.lots.forEach((lot) => {
                    field['totalArea'] = lot['area'] + field['totalArea']
                    lot['varietyName'] = '';
                    lot['subVarietyName'] = '';
                    var lotVarietyId = undefined;
                    for (let index = 0; index < lot.campaigns.length; index++) {
                        if (!lot.campaigns[index].isFinished) {
                            lotVarietyId = lot.campaigns[index].varietyId;
                        }
                    }
                    if (lotVarietyId !== undefined) {
                        var tmpVarietyInformation = this.subVarietyQuery.getEntity(lotVarietyId);
                        if (tmpVarietyInformation !== undefined) {
                            lot['varietyName'] = tmpVarietyInformation.product.name;
                            lot['subVarietyName'] = tmpVarietyInformation.name;
                        }
                    }
                });
            });
            console.log(data.message);
            return data.message;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public swapCoordinates() {
        this.sharedFields.forEach((field) => {
            field.lots.forEach((lot) => {
                lot.fieldName = field.name;
                lot.coordinates.forEach((coord) => {
                    let tmp = coord[0];
                    coord[0] = coord[1];
                    coord[1] = tmp;
                });
            });
        });
    }

    public async getEstadisticsInformation(lot_id) {
        try {
            var today = new Date();
            var todayA =
                today.getFullYear() -
                1 +
                '-' +
                String(today.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(today.getDate()).padStart(2, '0');
            const rpt = await this.http
                .get<Response<StadisticsSingleObject[]>>(
                    `${environment.apiUrl}/lots/shared/` + lot_id + '/stadistics/date/' + todayA,
                )
                .toPromise<Response<StadisticsSingleObject[]>>();
            return rpt.message;
        } catch (error) {
            console.log(error);
        }
    }

    public async getImagesInformation(lot_id) {
        try {
            var today = new Date();
            var todayA =
                today.getFullYear() -
                1 +
                '-' +
                String(today.getMonth() + 1).padStart(2, '0') +
                '-' +
                String(today.getDate()).padStart(2, '0');
            const rpt = await this.http
                .get<Response<any>>(`${environment.apiUrl}/lots/shared/` + lot_id + '/images/date/' + todayA)
                .toPromise<Response<any>>();
            return rpt.message;
        } catch (error) {
            console.log(error);
        }
    }

    public async getCollaborators(campaignId: string) {
        try {
            const rpt = await this.http
                .get<Response<any>>(`${environment.apiUrl}/lots/shared/campaign/${campaignId}/collaborators`)
                .toPromise<Response<any>>();
            return rpt.message;
        } catch (error) {
            console.log(error);
        }
    }

    public async deleteCollaborator(collaboratorId: string) {
        try {
            const data = await this.http
                .delete<Response<any>>(`${environment.apiUrl}/lots/shared/campaigns/collaborators/${collaboratorId}`)
                .toPromise<Response<any>>();
            return data.message;
        } catch (error) {
            this.globalToastService.createErrorToast('Error', 'No se pudo conectar al servidor');
        }
    }

    public sendUpdateSelectedLotShared(lot: any) {
        this.selectSharedLot = lot;
        this.updateSelectedLot.next();
    }

    public getSelectedLotShared(): Observable<any> {
        return this.updateSelectedLot.asObservable();
    }

    public getUpdateSharedFields(): Observable<any> {
        return this.updateShareFields.asObservable();
    }
}
