import { Injectable } from '@angular/core';
import { FieldsUiStore } from '../store/fields-ui.store';

@Injectable({ providedIn: 'root' })
export class FieldsUiService {
	public constructor(private readonly fieldsUiStore: FieldsUiStore) {}

	public setSelectedResource(resource: number) {
		this.fieldsUiStore.setSelectedModal(resource);
	}

	public openSidebar() {
		this.fieldsUiStore.openSidebar();
	}

	public closeSidebar() {
		this.fieldsUiStore.closeSidebar();
	}

	public toggleSidebar() {
		this.fieldsUiStore.toggleSidebar();
	}
}
