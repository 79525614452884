import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PhenologicalStateState, PhenologicalStateStore } from './phenological-state.store';

@Injectable({ providedIn: 'root' })

export class PhenologicalStateQuery extends QueryEntity<PhenologicalStateState> {
	public constructor(protected store: PhenologicalStateStore) {
		super(store);
	}
}
