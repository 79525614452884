import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { AgrometNotify, AgrometNotifyState, AgrometNotifyStore } from './agromet-notify.store';

@Injectable({ providedIn: 'root' })
export class AgrometNotifyQuery extends QueryEntity<AgrometNotifyState> {
    
    public selectAllNotifications: Observable<AgrometNotify[]> = this.selectAll();

    constructor(protected store: AgrometNotifyStore) {
        super(store);
    }

}
