import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { CalendarStorageQuery } from '../../storage/calendar-storage/calendar-storage.query';
import { Calendar } from '../../storage/calendar-storage/calendar-storage.store';
export interface HourTime {
    value: string;
    id: number
}
@Component({
    selector: 'app-calendar-availability',
    templateUrl: './calendar-availability.component.html',
    styleUrls: ['./calendar-availability.component.scss']
})
export class CalendarAvailabilityComponent implements OnInit {
    public hours: HourTime[] = []
    public isChange: boolean;
    public isGeneric: boolean;
    public weekImages = [
        {
            id: "0",
            isSelected: false,
            path: "assets/images/agromonitor/dates/dom.svg",
            pathSelected: "assets/images/agromonitor/dates/dom_selected.svg"
        },
        {
            id: "1",
            isSelected: false,
            path: "assets/images/agromonitor/dates/lun.svg",
            pathSelected: "assets/images/agromonitor/dates/lun_selected.svg"
        },
        {
            id: "2",
            isSelected: false,
            path: "assets/images/agromonitor/dates/mar.svg",
            pathSelected: "assets/images/agromonitor/dates/mar_selected.svg"
        },
        {
            id: "3",
            isSelected: false,
            path: "assets/images/agromonitor/dates/mie.svg",
            pathSelected: "assets/images/agromonitor/dates/mie_selected.svg"
        },
        {
            id: "4",
            isSelected: false,
            path: "assets/images/agromonitor/dates/jue.svg",
            pathSelected: "assets/images/agromonitor/dates/jue_selected.svg"
        },
        {
            id: "5",
            isSelected: false,
            path: "assets/images/agromonitor/dates/vie.svg",
            pathSelected: "assets/images/agromonitor/dates/vie_selected.svg"
        },
        {
            id: "6",
            isSelected: false,
            path: "assets/images/agromonitor/dates/sab.svg",
            pathSelected: "assets/images/agromonitor/dates/sab_selected.svg"
        },
    ];
    public days = [
        {
            value: "Lunes",
            id: 1
        },
        {
            value: "Martes",
            id: 2
        },
        {
            value: "Miercoles",
            id: 3
        },
        {
            value: "Jueves",
            id: 4
        },
        {
            value: "Viernes",
            id: 5
        },
        {
            value: "Sábado",
            id: 6
        },
        {
            value: "Domingo",
            id: 0
        }
    ]
    
    public calendarObject: Calendar = undefined;
    constructor(
        private calendarStorageQuery: CalendarStorageQuery, public dialogRef: MatDialogRef<CalendarAvailabilityComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private readonly globalToastService: GlobalToastService
    ) {
        this.isChange = false;
        this.initializate();
        if (data == null) {
            this.isGeneric = true;
            this.calendarStorageQuery.selectAll().subscribe(x => {
                //this.initializate();
                this.validateDate(x);
            })
        }
        else {
            this.isGeneric = false;
            this.calendarObject = { ...data };
            var daysSelected = this.calendarObject.days.split(",");
            this.weekImages.forEach(element => {
                if (daysSelected.includes(element.id)) {
                    element.isSelected = true;
                }
            });
        }
    }

    ngOnInit(): void {
    }
    orderingHours(){
        const difference = parseInt(this.calendarObject.start_schedule.substr(0,2)) - parseInt(this.calendarObject.end_schedule.substr(0,2))
        return difference > 0
    }

    atLeastOneDayIsSelected(){
        const difference = parseInt(this.calendarObject.start_break.substr(0,2)) - parseInt(this.calendarObject.end_break.substr(0,2))
        return difference > 0
    }

    atLeastOneDaySelected(){
        for (let index = 0; index < this.weekImages.length; index++) {
            const element = this.weekImages[index];
            if (element.isSelected)
                return false
        }
        return true
    }

    closeAndSave(): void {
        if(this.orderingHours()){
            this.globalToastService.createWarnToast("","La hora de comienzo debe ser mayor a la hora de fin")
           return; 
        }
        if(this.atLeastOneDayIsSelected()){
            this.globalToastService.createWarnToast("","La hora de comienzo del receso debe ser menor a la hora de fin")
           return; 
        }
        if(this.atLeastOneDaySelected()){
            this.globalToastService.createWarnToast("","Se debe de seleccionar al menos un día")
           return; 
        }
        let days: Array<number> = [];
        this.weekImages.forEach(day => {
            if (day.isSelected)
                days.push(+day.id)
        })
        this.calendarObject.days = days.toString();
        if (!this.isChange && this.isGeneric)
            this.dialogRef.close(null);
        else
            this.dialogRef.close(this.calendarObject);
            this.globalToastService.createSuccessToast("Completado", "Se han actualizado los parámetros")

        
    }

    close(): void {
        this.dialogRef.close(null);
    }

    public initializate() {
        for (let index = 1; index <= 24; index++) {
            if (index < 10) {
                this.hours.push({
                    value: "0" + index + ":00:00",
                    id: index
                })
            } else {
                this.hours.push({
                    value: index + ":00:00",
                    id: index
                })
            }
        }
    }

    public selectChangeStartWeek(event: any) {
        this.isChange = true;
        this.calendarObject.start_week = event.target.value;
    }
    public selectChangeStartSchedule(event: any) {
        this.isChange = true;
        this.calendarObject.start_schedule = event.target.value;
    }
    public selectChangeEndSchedule(event: any) {
        this.isChange = true;
        this.calendarObject.end_schedule = event.target.value;
    }
    public selectChangeStartBreak(event: any) {
        this.isChange = true;
        this.calendarObject.start_break = event.target.value;
    }
    public selectChangeEndBreak(event: any) {
        this.isChange = true;
        this.calendarObject.end_break = event.target.value;
    }

    public validateDate(x) {
        if (x !== undefined && x.length > 0) {
            this.calendarObject = { ...x[0] };
            var daysSelected = this.calendarObject.days.split(",");
            this.weekImages.forEach(element => {
                if (daysSelected.includes(element.id)) {
                    element.isSelected = true;
                }
            });
        }
    }
}
