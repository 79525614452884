import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthHttpService } from '@store/auth/auth-http.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	public constructor(private authService: AuthHttpService) {}

	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let currentUser = this.authService.currentUser;

		if (
			!request.url.includes('router.hereapi.com') && !request.url.includes('167.114.174.169') && !request.url.includes('api.openweathermap.org') && !request.url.includes('https://api.open-elevation.com') &&
			currentUser && currentUser.token
			) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${currentUser.token}`
				}
			});
		}
		

		return next.handle(request);
	}
}
