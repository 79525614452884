import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { FieldsUiService } from '../../services/fields-ui.service';
import { FieldsUiQuery } from '../../store/fields-ui.query';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { MapSidebarComService } from '../../services/map-sidebar-com.service';
import { KeyCode } from '@src/app/modules/my-fields/enums/keyboard-enum';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { FielListQuery } from '@src/app/modules/my-fields/store/field-list/fiel-list.query';


@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, OnDestroy {
	public isOpened: boolean = false;
	public selectedModal: number = 1;
	public suscriptions: Subscription[] = [];
	public showFieldList: boolean = true;
	public numberOfFields = -1;
	public mode = "push";
	public selectedList = 1;
	public datesStats = []

	constructor(
		private readonly fieldsUiService: FieldsUiService,
		private readonly fieldsUiQuery: FieldsUiQuery,
		private fieldsQuery: FieldsQuery,
		private mapSidebarComService: MapSidebarComService,
		private readonly fielListQuery: FielListQuery,
	) { }

	public ngOnInit() {
		if (window.innerWidth < 620) {
			this.mode = "over";
		}
		this.suscriptions.push(
			this.fieldsUiQuery.selectIsOpen.subscribe((x) => {
				this.isOpened = x;
			}),
			this.fieldsUiQuery.selectSelectedModal.subscribe((x) => {
				this.selectedModal = x;
			}),
		);

		this.fielListQuery.selectSelectedResource.subscribe((x) => {
			if(this.selectedList==4){
				this.isOpened = false
			}
			this.selectedList = x
			if (x == 4) {
				this.isOpened = true
				this.selectedModal = 7;
			}


		})

		this.fieldsQuery.selectFieldsWithLots().subscribe((res) => {

			if (res !== undefined) {
				this.numberOfFields = res.length;
			} else {
				this.fieldsUiService.closeSidebar();
			}
		})
	}
	public createField() {
		this.fieldsUiService.setSelectedResource(1);
		this.fieldsUiService.openSidebar();
	}

	public closeSidebar() {
		this.fieldsUiService.closeSidebar();
	}

	public ngOnDestroy() {
		this.suscriptions.forEach((x) => x.unsubscribe());
	}

	public hideListReactive() {
		this.fieldsUiService.closeSidebar();
	}

	public changeDatesStats(data){
		this.datesStats = data;
	}

	@HostListener('window:keyup', ['$event'])
	keyEvent(event: KeyboardEvent) {


		if (event.keyCode === KeyCode.ESCAPE_KEY) {
			this.fieldsUiService.closeSidebar();
			this.mapSidebarComService.sentClickCancelEdit();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		if (window.innerWidth < 620) {
			this.mode = "over";
		} else {
			this.mode = "push"
		}
	}
}
