import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard } from '@app/guards/auth.guard';

// Components
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { NotFound404Component } from './core/components/not-found404/not-found404.component';
import { SelectPlanComponent } from './core/components/select-plan/select-plan.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-fields',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/my-fields/my-fields.module').then((m) => m.MyFieldsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-fields-admin',
    component: HomeLayoutComponent,
    loadChildren: () =>
      import('./modules/fields-management/fields-management.module').then((m) => m.FieldsManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agrodat',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/agrodat/agrodat.module').then((m) => m.AgrodatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'space-management',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/space-management/space-management.module').then((m) => m.SpaceManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agroaprende',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/test/test.module').then((m) => m.TestModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agroanalytics',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/agroanalytics/agroanalytics.module').then((m) => m.AgroanalyticsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agromet',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/agromet/agromet.module').then((m) => m.AgrometModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agromonitor',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/agromonitor/agromonitor.module').then((m) => m.AgromonitorModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agrodrone',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/agrodrone/agrodrone.module').then((m) => m.AgrodroneModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'agromeeting',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/agromeeting/agromeeting.module').then((m) => m.AgromeetingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'plans',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        component: SelectPlanComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    loadChildren: () => import('./modules/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: '404',
    component: NotFound404Component,
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
