import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { FieldsUiState, FieldsUiStore } from './fields-ui.store';

@Injectable({ providedIn: 'root' })
export class FieldsUiQuery extends Query<FieldsUiState> {
	public selectSelectedModal: Observable<number> = this.select(
		(x) => x.selectedModal,
	);

	public selectIsOpen: Observable<boolean> = this.select((x) => x.isOpen);

	public constructor(protected store: FieldsUiStore) {
		super(store);
	}
}
