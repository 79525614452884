import { Component, OnInit } from '@angular/core';
import { MyFieldsUiQuery } from '../../../store';

@Component({
	selector: 'app-resource-tables',
	templateUrl: './resource-tables.component.html',
	styleUrls: ['./resource-tables.component.scss'],
})
export class ResourceTablesComponent {
	public selectedResource: number;

	public staffData: any[] = [
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
		{
			name: 'name',
			dni: 'dni',
			position: 'position',
			unit: 'unit',
			monthlyCost: 'monthlyCost',
			recruitmentDate: 'recruitmentDate',
		},
	];

	constructor(private readonly myFieldsUiQuery: MyFieldsUiQuery) {
		this.myFieldsUiQuery.selectSelectedResource.subscribe((x) => {
			this.selectedResource = x;
		});
	}

	// TODO: Modificar el item del HTML por el id del item
	// TODO: Mejorar el performance con la funcion trackby

	public editStaff(id: string) {
	}
	public deleteStaff(id: string) {
	}
}
