import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { MapSidebarComService } from '@src/app/modules/my-fields/modules/fields/services/map-sidebar-com.service';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsHistoricalService } from '@src/app/store/historic-fields-notifications/historical-fields-notify.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { SatellitalImageNotifyQuery } from '@src/app/store/satellital-image-notify/satellital-image-notify.query';
import { SatellitalImageNotifyService } from '@src/app/store/satellital-image-notify/satellital-image-notify.service';
import { UserQuery } from '@src/app/store/user/user.query';
import { UserService } from '@src/app/store/user/user.service';
import { DialogConfirmationNotification } from '../confirmation-dialog/noti-confirm-dialog';
import { DialogInformationNotification } from '../information-dialog/noti-information-dialog';

@Component({
    selector: 'app-my-fields-notifications',
    templateUrl: './my-fields-notifications.component.html',
    styleUrls: ['./my-fields-notifications.component.scss']
})
export class MyFieldsNotificationsComponent implements OnInit {

    public checkedPushNotification = false;
    public checkedEmailNotification = false;
    public checkedSMSNotification = false;
    public enableEdition = false;
    public allLotsFromServer = [];
    public lot_id_selected = undefined;
    public notificationDetail = undefined;
    public historicalData = [];

    min_ndvi = null
    max_ndvi = null
    min_ndwi = null
    max_ndwi = null
    min_savi = null
    max_savi = null

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmationNotification>,
        public lotsQuery: LotsQuery,
        public satellitalImageNotifyService: SatellitalImageNotifyService,
        public satellitalImageNotifyQuery: SatellitalImageNotifyQuery,
        public toastService: GlobalToastService,
        public dialog: MatDialog,
        private userService: UserService,
        private fieldsQuery: FieldsQuery,
        private readonly userQuery: UserQuery,
        private fieldsHistoricalService: FieldsHistoricalService,
        private mapSidebarComService: MapSidebarComService
    ) {
        this.lotsQuery.selectAll().subscribe(allLots => {
            this.allLotsFromServer = allLots;
        });
    
    }


    public close() {
        this.dialogRef.close()
    }

    public rangeValues = [
        { value: "ninguno" },
        { value: -1 },
        { value: -0.8 },
        { value: -0.6 },
        { value: -0.4 },
        { value: -0.2 },
        { value: "0" },
        { value: 0.2 },
        { value: 0.4 },
        { value: 0.6 },
        { value: 0.8 },
        { value: 1 },
    ]

    async ngOnInit() {

        await this.fieldsHistoricalService.findAll().then((all) => {
            this.historicalData = JSON.parse(JSON.stringify(all));
            this.historicalData.forEach((element) => {
                const dateFromElem = new Date(element.date);
                let monthNumber = dateFromElem.getMonth() + 1;
                if (monthNumber < 10) element.date = dateFromElem.getFullYear() + "-" + "0" + monthNumber + "-" + dateFromElem.getDate();
                else element.date = dateFromElem.getFullYear() + "-" + monthNumber + "-" + dateFromElem.getDate();
                let minutes = dateFromElem.getMinutes();
                if(minutes < 10) element.hour = dateFromElem.getHours() + ":0" + dateFromElem.getMinutes();
                else element.hour = dateFromElem.getHours() + ":" + dateFromElem.getMinutes();
                const lotData = this.lotsQuery.getEntity(element.lot_id);
                element.lot_name = lotData.name;
                const fieldData = this.fieldsQuery.getEntity(lotData.fieldId);
                element.field_name = fieldData.name;
            });
        });

        this.lot_id_selected = this.allLotsFromServer[0].id
        await this.satellitalImageNotifyService.findAll();
        await this.setInitialLot(this.lot_id_selected)
        await this.userService.loadUserData();
        
    }

    public async checkNotification(element: any){
        if(element.readed) return;
        await this.fieldsHistoricalService.update(element.id)
        element.readed = true;
        this.mapSidebarComService.sendClickToCountNotifications('fields');
    }


    public changeLotSelected(data) {
        this.resetValues();
        this.lot_id_selected = data.value.id;
        try {
            this.enableEdition = false;
            const satellitalimagenotify = this.satellitalImageNotifyQuery.getEntity(this.lot_id_selected);
            if (satellitalimagenotify != undefined) {
                this.notificationDetail = satellitalimagenotify;
                this.min_ndvi = { value: parseFloat(satellitalimagenotify.min_ndvi) };
                this.max_ndvi = { value: parseFloat(satellitalimagenotify.max_ndvi) };
                this.min_ndwi = { value: parseFloat(satellitalimagenotify.min_ndwi) };
                this.max_ndwi = { value: parseFloat(satellitalimagenotify.max_ndwi) };
                this.min_savi = { value: parseFloat(satellitalimagenotify.min_savi) };
                this.max_savi = { value: parseFloat(satellitalimagenotify.max_savi) };
                this.checkedPushNotification = satellitalimagenotify.by_push_notification
                this.checkedEmailNotification = satellitalimagenotify.by_email
                this.checkedSMSNotification = satellitalimagenotify.by_sms
            } else {
                this.notificationDetail = undefined;
                this.checkedPushNotification = false;
                this.checkedEmailNotification = false;
                this.checkedSMSNotification = false;
            }

        } catch (error) {

        }
    }

    public async setInitialLot(lot_id) {
        this.resetValues();
        this.lot_id_selected = lot_id;
        try {
            this.enableEdition = false;
            const satellitalimagenotify = this.satellitalImageNotifyQuery.getEntity(this.lot_id_selected);

            if (satellitalimagenotify != undefined) {
                this.notificationDetail = satellitalimagenotify;
                this.min_ndvi = { value: parseFloat(satellitalimagenotify.min_ndvi) };
                this.max_ndvi = { value: parseFloat(satellitalimagenotify.max_ndvi) };
                this.min_ndwi = { value: parseFloat(satellitalimagenotify.min_ndwi) };
                this.max_ndwi = { value: parseFloat(satellitalimagenotify.max_ndwi) };
                this.min_savi = { value: parseFloat(satellitalimagenotify.min_savi) };
                this.max_savi = { value: parseFloat(satellitalimagenotify.max_savi) };
                this.checkedPushNotification = satellitalimagenotify.by_push_notification
                this.checkedEmailNotification = satellitalimagenotify.by_email
                this.checkedSMSNotification = satellitalimagenotify.by_sms

            } else {
                this.notificationDetail = undefined;
                this.checkedPushNotification = false;
                this.checkedEmailNotification = false;
                this.checkedSMSNotification = false;
            }

        } catch (error) {

        }
    }

    public async save() {
        const selectedNotify = this.satellitalImageNotifyQuery.getEntity(this.lot_id_selected);
        const selectedLot = this.lotsQuery.getEntity(this.lot_id_selected)
        if (selectedLot == undefined)
            return
        
        const fieldInfo = this.fieldsQuery.getEntity(
            selectedLot.fieldId,
        );
        if (selectedNotify == undefined) {
            //case when theres no notification
            
            this.validate_values();
            var detail = {
                "lot_id": this.lot_id_selected,
                "min_ndvi": this.min_ndvi.value,
                "max_ndvi": this.max_ndvi.value,
                "min_ndwi": this.min_ndwi.value,
                "max_ndwi": this.max_ndwi.value,
                "min_savi": this.min_savi.value,
                "max_savi": this.max_savi.value,
                "by_email": false,
                "by_push_notification": false,
                "by_sms": false,
                "user_name": this.userQuery.getInformation().firstName,
                "user_dni": this.userQuery.getInformation().dni,
                "lot_name": selectedLot.name,
                "field_name": fieldInfo.name,
                "lat": selectedLot.center[0][1] + "",
                "lon": selectedLot.center[0][0] + "",
                "crop_name": selectedLot.varietyName,
                "variety_name": selectedLot.subVarietyName,

            }
            try {
                await this.satellitalImageNotifyService.postSatellitalImageNotification(detail).then((rpt) => {
                    const satellitalimagenotify = this.satellitalImageNotifyQuery.getEntity(this.lot_id_selected);
                    this.notificationDetail = satellitalimagenotify;
                    this.min_ndvi = { value: parseFloat(rpt.min_ndvi) };
                    this.max_ndvi = { value: parseFloat(rpt.max_ndvi) };
                    this.min_ndwi = { value: parseFloat(rpt.min_ndwi) };
                    this.max_ndwi = { value: parseFloat(rpt.max_ndwi) };
                    this.min_savi = { value: parseFloat(rpt.min_savi) };
                    this.max_savi = { value: parseFloat(rpt.max_savi) };
                })
                this.toastService.createSuccessToast("Completado", "Se han guardado los parámetros")
            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        } else {
            //case to update one already created
            this.validate_values();
            var detailUpdate = {
                "id": selectedNotify.notification_id,
                "lot_id": this.lot_id_selected,
                "min_ndvi": this.min_ndvi.value,
                "max_ndvi": this.max_ndvi.value,
                "min_ndwi": this.min_ndwi.value,
                "max_ndwi": this.max_ndwi.value,
                "min_savi": this.min_savi.value,
                "max_savi": this.max_savi.value,
                "by_email": selectedNotify.by_email,
                "by_push_notification": selectedNotify.by_push_notification,
                "by_sms": selectedNotify.by_sms,
                "user_name": this.userQuery.getInformation().firstName,
                "user_dni": this.userQuery.getInformation().dni,
                "lot_name": selectedLot.name,
                "field_name": fieldInfo.name,
                "lat": selectedLot.center[0][1] + "",
                "lon": selectedLot.center[0][0] + "",
                "crop_name": selectedLot.varietyName,
                "variety_name": selectedLot.subVarietyName,
            }
            try {
                await this.satellitalImageNotifyService.putSatellitalImageNotification(detailUpdate).then((rpt) => {
                    const satellitalimagenotify = this.satellitalImageNotifyQuery.getEntity(this.lot_id_selected);
                    this.notificationDetail = satellitalimagenotify;
                    this.min_ndvi = { value: parseFloat(rpt.min_ndvi) };
                    this.max_ndvi = { value: parseFloat(rpt.max_ndvi) };
                    this.min_ndwi = { value: parseFloat(rpt.min_ndwi) };
                    this.max_ndwi = { value: parseFloat(rpt.max_ndwi) };
                    this.min_savi = { value: parseFloat(rpt.min_savi) };
                    this.max_savi = { value: parseFloat(rpt.max_savi) };
                })
                this.toastService.createSuccessToast("Completado", "Se han actualizado los parámetros")
            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        }
        this.enableEdition = false;
        this.goToConfig();

    }
    public changeNDVI($event){
        if ($event.value.value == "ninguno"){
            this.max_ndvi= this.rangeValues[0]
            this.min_ndvi= this.rangeValues[0]
        }
    }
    public changeNDWI($event){
        if ($event.value.value == "ninguno"){
            this.max_ndwi= this.rangeValues[0]
            this.min_ndwi= this.rangeValues[0]
        }
    }
    public changeSAVI($event){
        if ($event.value.value == "ninguno"){
            this.max_savi= this.rangeValues[0]
            this.min_savi= this.rangeValues[0]
        }
    }

    public resetValues() {
        this.lot_id_selected = undefined;
        this.min_ndvi = { value: -1 };
        this.max_ndvi = { value: 1 };
        this.min_ndwi = { value: -1 };
        this.max_ndwi = { value: 1 };
        this.min_savi = { value: -1 };
        this.max_savi = { value: 1 };
    }
    public goToConfig(){
        const dialogRef = this.dialog.open(DialogInformationNotification, {
            panelClass: "modalClass",
            width: "470px",
            height: "120px",
            data: {
                text: "Los Parametros se han guardado ahora puede ir hacia Configuraciones"
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
        });
    }

    public deleteNotification() {
        const dialogRef = this.dialog.open(DialogConfirmationNotification, {
            panelClass: "modalClass",
            width: "470px",
            height: "120px",
            data: {
                text: "¿Está seguro de eliminar los parámetros?"
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                try {
                    await this.satellitalImageNotifyService.deleteSatellitalImageNotification(this.notificationDetail.notification_id, this.lot_id_selected).then(() => {
                        this.notificationDetail = undefined;
                        this.min_ndvi = { value: -1 };
                        this.max_ndvi = { value: 1 };
                        this.min_ndwi = { value: -1 };
                        this.max_ndwi = { value: 1 };
                        this.min_savi = { value: -1 };
                        this.max_savi = { value: 1 };
                        this.enableEdition = false;
                        this.checkedPushNotification = false;
                        this.checkedEmailNotification = false;
                        this.checkedSMSNotification = false;
                        this.toastService.createSuccessToast("Completado", "Se han borrado los parámetros")
                    }).catch(() => {
                        this.toastService.createErrorToast("Error", "No se han podido borrar los parámetros")
                    })

                } catch (error) {

                }

            }
        });
    }

    public validate_values(notify = null) {
        if (notify == null) {
            if (this.min_ndvi.value == null || this.min_ndvi.value.toString() == "ninguno")
                this.min_ndvi.value = null
            if (this.max_ndvi.value == null || this.max_ndvi.value.toString() == "ninguno")
                this.max_ndvi.value = null
            if (this.min_savi.value == null || this.min_savi.value.toString() == "ninguno")
                this.min_savi.value = null
            if (this.max_savi.value == null || this.max_savi.value.toString() == "ninguno")
                this.max_savi.value = null
            if (this.min_ndwi.value == null || this.min_ndwi.value.toString() == "ninguno")
                this.min_ndwi.value = null
            if (this.max_ndwi.value == null || this.max_ndwi.value.toString() == "ninguno")
                this.max_ndwi.value = null

        }
        else {
            if (notify.min_ndvi.value == null || notify.min_ndvi.value.toString() == "ninguno")
                notify.min_ndvi.value = null
            if (notify.max_ndvi.value == null || notify.max_ndvi.value.toString() == "ninguno")
                notify.max_ndvi.value = null
            if (notify.min_savi.value == null || notify.min_savi.value.toString() == "ninguno")
                notify.min_savi.value = null
            if (notify.max_savi.value == null || notify.max_savi.value.toString() == "ninguno")
                notify.max_savi.value = null
            if (notify.min_ndwi.value == null || notify.min_ndwi.value.toString() == "ninguno")
                notify.min_ndwi.value = null
            if (notify.max_ndwi.value == null || notify.max_ndwi.value.toString() == "ninguno")
                notify.max_ndwi.value = null

        }
    }

    public async saveConfig() {
        const selectedNotify = this.satellitalImageNotifyQuery.getEntity(this.lot_id_selected);
        const selectedLot = this.lotsQuery.getEntity(this.lot_id_selected);
        if (selectedLot == undefined)
            return
        if (selectedNotify != undefined) {
            var detailUpdate = {
                "id": selectedNotify.notification_id,
                "lot_id": this.lot_id_selected,
                "by_push_notification": this.checkedPushNotification,
                "by_email": this.checkedEmailNotification,
                "by_sms": this.checkedSMSNotification
            }
            try {
                await this.satellitalImageNotifyService.putSatellitalImageNotification(detailUpdate).then((rpt) => {
                    this.toastService.createSuccessToast("Completado", "Se han actualizado los parámetros")
                })

            } catch (error) {
                this.toastService.createErrorToast("Error", "No se han guardado los parámetros")
            }
        }
        this.enableEdition = false;

    }

    public lotSelectedLabel = undefined;

    public validatePlan(e) {
        const currentUser = this.userQuery.getInformation();

        if (e.checked && !currentUser.permission.pushNotification) {
            const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
                width: '400px'
            });
            saveRef.afterClosed().subscribe(
                data => {
                    this.checkedPushNotification = false;
                    this.checkedEmailNotification = false;
                    this.checkedSMSNotification = false;
                })
        }
    }
}