import { Injectable } from '@angular/core';
import { LotEditStore } from './lot-edit.store';

@Injectable({ providedIn: 'root' })
export class LotEditService {
  public constructor(private readonly myFieldsUiStore: LotEditStore) {}

	public setSelectedResource(value: number) {
		this.myFieldsUiStore.setSelectedValue(value);
	}
}
