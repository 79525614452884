import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface Service {
    id?: string;
    name?: string;
    abbreviation?: string;
    kind?: string;
    unit?: string;
    quantity?: string;
    cost: string;
    coin?: string;
    totalCost?: string;
    observation?: string;
}
export interface ServiceState extends EntityState<Service, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'service-storage' })
export class ServiceStore extends EntityStore<ServiceState> {

    constructor() {
        super();
        //this.initializeData();
    }

    public initializeData() {
        this.add(
            {
                id: "1",
                name: "Transporte de Insumos",
                kind: "Servicio",
                quantity: "2",
                unit: "viaje",
                cost: "80.00",
                coin: "1",
                observation: "Transporte de Insumos"
            }
        )
    }
}

