import { Injectable } from '@angular/core';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';

export interface SatellitalImageNotify {
    "notification_id": number
    "id": string,
    "lot_id": string,
    "min_ndvi": string,
    "max_ndvi": string,
    "min_ndwi": string,
    "max_ndwi": string,
    "min_savi": string,
    "max_savi": string,
    "by_email": boolean,
    "by_push_notification": boolean,
    "by_sms": boolean,
    "field_name": string,
    "crop_name": string,
    "lat": string,
    "lon": string,
    "lot_name": string,
    "user_dni": string,
    "user_name": string,
    "variety_name": string
}

export interface SatellitalImageNotifyState extends EntityState<SatellitalImageNotify, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'SatellitalImageNotify' })
export class SatellitalImageNotifyStore extends EntityStore<SatellitalImageNotifyState> {
    constructor() {
        super();
    }
}

