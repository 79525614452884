import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubVarietyStore, SubVariety } from './sub-variety.store';
import { SubVarietyQuery } from './sub-variety.query';
import { environment } from '@src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SubVarietyService {
	fieldsQuery: any;
	public constructor(
		private subVarietyQuery: SubVarietyQuery,
		private subVarietyStore: SubVarietyStore,
		private readonly http: HttpClient,
	) {}

	public async findAll() {
		const hasCache = this.subVarietyQuery.getHasCache();
		if (hasCache) {
			return;
		}
		try {
			/*/
            var localSubVarieties = localStorage.getItem("subVarieties1.0")
            if (localSubVarieties == undefined) {*/
			const data = await this.http
				.get<SubVariety[]>(`${environment.apiUrlAgroDat}/variety/`)
				.toPromise<SubVariety[]>();
			const subVarieties = data.map((currentSubVariety: any) => {
				const subVariety = currentSubVariety;
				subVariety.id = String(subVariety.id);
				return subVariety;
			});
			this.subVarietyStore.set(subVarieties);
			return subVarieties;
			/*
            
			localStorage.setItem(
				'subVarieties1.0',
				JSON.stringify(subVarieties),
			);
            } else {
                var allSubVarieties = JSON.parse(localSubVarieties);
                this.subVarietyStore.set(allSubVarieties)
                return allSubVarieties;
            }
            */
		} catch (error) {
			console.log(error);
			return undefined;
		}
	}
}
