import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { CoordinateConverterService } from '../coordinate-converter/coordinate-converter.service';

@Injectable({
	providedIn: 'root'
})
export class MarkerService {
	public constructor(
		private readonly coordinatesConverterService: CoordinateConverterService
	) {}

	public async makePolygon(
		event: L.LeafletEvent,
		drawItems: L.FeatureGroup
	): Promise<void> {
		const polygon = event.layer as L.Polygon;
		const latLngs = polygon.getLatLngs()[0] as L.LatLng[];

		drawItems.addLayer(polygon);

		const perimeter: number = this.getPerimeter(latLngs);
		const area: number = this.getArea(latLngs);

		// Opening popup
		const id: number = drawItems.getLayerId(polygon);
		drawItems
			.getLayer(id)
			.bindPopup(this.makePopUp(area, perimeter))
			.openPopup();
	}

	public getPolygonArea(polygon: L.Polygon) {
		return this.getArea(polygon.getLatLngs()[0] as L.LatLng[]);
	}

	public getArea(latLngs: L.LatLng[]) {
		return L.GeometryUtil.geodesicArea(latLngs);
	}

	public getPerimeter(coordinates: Array<L.LatLng>): number {
		let perimeter: number = 0;

		// Validando que sea un poligono
		const length = coordinates.length;
		if (length < 3) {
			return null;
		}

		const first = coordinates[0];
		const last = coordinates[length - 1];

		for (var i = 0; i < length - 1; ++i) {
			perimeter += coordinates[i].distanceTo(coordinates[i + 1]);
		}
		perimeter += first.distanceTo(last);

		return perimeter;
	}

	public makePopUp(area: number, perimeter: number): string {
		let sArea = (area / 10000).toFixed(2);

		return `
			<div>Area: ${sArea} Hectareas</div>
			<div>Perimetro: ${perimeter.toFixed(2)} m</div>
			`;
	}

	public drawAndCenterPolygon(
		map: L.DrawMap,
		featureGroup: L.FeatureGroup,
		coordinates: number[][],
		area: number
	) {
		featureGroup.clearLayers();

		const latLng = this.coordinatesConverterService.LngLatToLatLng(
			coordinates
		);

		const perimeter = this.getPerimeter(latLng);

		const polygon: L.Polygon = L.polygon(latLng, {
			color: 'rgba(255,255,255,1)',
			fillColor: 'white'
		})
			.bindPopup(this.makePopUp(area, perimeter))
			.openPopup();
		featureGroup.addLayer(polygon);

		const center = polygon.getBounds();
		map.fitBounds(center, { animate: true });
	}

	public drawImage(map: L.DrawMap, imageUrl: string, polygon: L.Polygon) {
		const bounds = polygon.getBounds();

		L.tileLayer(imageUrl, { maxZoom: 19 })
			.addTo(map)
			.bringToFront();

		map.fitBounds(bounds);
	}
}
