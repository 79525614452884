import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { UserState, UserStore } from './user.store';
import { Organization } from '@src/app/shared/models/organization.model';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
	public constructor(protected store: UserStore) {
		super(store);
	}

	// Observable: Cuando cambia en diferentes partes de la UI
	public selectUserId: Observable<string> = this.select((x) => x.id);
	public selectInformation: Observable<UserState> = this.select();
	public selectOrganization: Observable<Organization> = this.select(
		(x) => x.organization,
	);

	// Values: Cuando no cambia en la UI
	public getInformation() {
		return this.getValue();
	}

	public get getOrganization(): Organization {
		return this.getValue().organization;
	}
}
