import { Injectable } from '@angular/core';
import { MyFieldsUiStore } from '@modules/my-fields/store';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { MyFieldsModals } from '@modules/my-fields/enums';
import { RegisterFieldComponent } from '@modules/my-fields/components/register-field/register-field.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { RegisterLotComponent } from '@modules/my-fields/components/register-lot/register-lot.component';

// const DEFAULT_CONFIG = {
// 	hasBackdrop: true,
// 	backdropClass: 'dark-backdrop',
// 	panelClass: 'tm-file-preview-dialog-panel'
// };

@Injectable({
	providedIn: 'root',
})
export class MyFieldsModalsService {
	private overlayRef: OverlayRef;

	constructor(private readonly overlay: Overlay) {
		this.overlayRef = null;
	}

	public open(modal: MyFieldsModals) {
		if (this.overlayRef) {
			// Tiene un valor
			return;
		}

		// Container
		this.overlayRef = this.createOverlay();

		// Component
		const modalPortal = this.createComponentPortal(modal);

		// Rendering the component
		this.overlayRef.attach(modalPortal);

		// Close on backdrop click
		this.overlayRef.backdropClick().subscribe(() => this.close());
	}

	// TODO: Agregar aqui los nuevos modales
	private createComponentPortal(modal: MyFieldsModals) {
		switch (modal) {
			case MyFieldsModals.REGISTER_FIELD:
				return new ComponentPortal(RegisterFieldComponent);

			case MyFieldsModals.REGISTER_LOT:
				return new ComponentPortal(RegisterLotComponent);
		}
	}

	public close() {
		this.overlayRef.dispose();
		this.overlayRef = null;
	}

	private createOverlay() {
		const overlayConfig = this.getOverlayConfig();

		return this.overlay.create(overlayConfig);
	}

	private getOverlayConfig(): OverlayConfig {
		const positionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();

		const overlayConfig = new OverlayConfig({
			hasBackdrop: true,
			backdropClass: 'dark-backdrop',
			panelClass: 'tm-file-preview-dialog-panel',
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy,
		});

		return overlayConfig;
	}
}
