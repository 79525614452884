import { Injectable } from '@angular/core';

// PrimeNg
import { MessageService } from 'primeng/api';

@Injectable({
	providedIn: 'root',
})
export class GlobalToastService {
	constructor(private readonly messageService: MessageService) {}

	public createToast(
		severity: 'success' | 'info' | 'warn' | 'error',
		header: string,
		detail: string
	): void {
		this.messageService.add({
			key: 'general-toast',
			severity: severity,
			summary: header,
			detail,
			life: 5000
		});
	}

	public createSuccessToast(header: string, detail: string) {
		this.createToast('success', header, detail);
	}

	public createInfoToast(header: string, detail: string) {
		this.createToast('info', header, detail);
	}

	public createWarnToast(header: string, detail: string) {
		this.createToast('warn', header, detail);
	}

	public createErrorToast(header: string, detail: string) {
		this.createToast('error', header, detail);
	}

	public clearToast() {
		this.messageService.clear('general-toast');
	}
}

// "success", "info", "warn" and "error".
