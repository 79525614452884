import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MapService {

  private urlApi = 'https://api.open-elevation.com/api/v1/lookup';
  constructor(private http: HttpClient) {}

  public postApi(body:any): Observable<any> {
    return this.http
      .post<any>(this.urlApi, body)
  }
}