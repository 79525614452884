import { Component, OnInit } from '@angular/core';
import { MyFieldsUiService } from '@modules/my-fields/store/my-fields-ui/my-fields-ui.service';
import { MyFieldsModals } from '@modules/my-fields/enums/my-fields.enum';
import { MyFieldsModalsService } from '@modules/my-fields/services';

@Component({
	selector: 'app-field-search-bar',
	templateUrl: './field-search-bar.component.html',
	styleUrls: ['./field-search-bar.component.scss']
})
export class FieldSearchBarComponent implements OnInit {
	public fieldName: string;

	constructor(
		private readonly myFielsUiService: MyFieldsUiService,
		private readonly myFieldsModalsService: MyFieldsModalsService
	) {
		this.fieldName = '';
	}

	ngOnInit(): void {}

	public addField() {
		// this.myFielsUiService.openModal(MyFieldsModals.REGISTER_FIELD);
		this.myFieldsModalsService.open(MyFieldsModals.REGISTER_FIELD);
	}
}
