import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LotsStore, Lot, Campaing } from './lots.store';
import { LotsQuery } from './lots.query';
import { FieldsQuery } from '@store/fields/fields.query';
import { FieldsStore } from '@store/fields/fields.store';
import { environment } from '@src/environments/environment';
import { arrayAdd } from '@datorama/akita';
import { SubVarietyQuery } from '../subVariety/state/sub-variety.query';
import { UserService } from '../user/user.service';
import { SatellitalImageQuery } from '@src/app/modules/my-fields/store/satellital-images-state/satellital-images-state.query';
import { SatellitalImageService } from '@src/app/modules/my-fields/store/satellital-images-state/satellital-images-state.service';
import { SatImageStore } from '../satImgDates/satImgDates.store';
import { StadisticsStore } from '../stadistics/stadistics.store';
import { ActivitiesService } from '@src/app/modules/agromonitor/storage/activities-storage/activities-storage.service';
import { GeneratePDFReport } from '@src/app/modules/my-fields/modules/fields/sidebar-components/lot-detail/historical-campaing/exportToPDFInterface';

export interface CampaingResponse {
    statusCode: number;
    error: string;
    message: Campaing;
}

@Injectable({ providedIn: 'root' })
export class LotsService {
    public constructor(
        private readonly http: HttpClient,
        private readonly lotsStore: LotsStore,
        private readonly lotsQuery: LotsQuery,
        private readonly fieldsQuery: FieldsQuery,
        private readonly fieldsStore: FieldsStore,
        private readonly subVarietyQuery: SubVarietyQuery,
        private readonly userService: UserService,
        private satellitalImageQuery: SatellitalImageQuery,
        private satellitalImageService: SatellitalImageService,
        private satImageStore: SatImageStore,
        private stadisticsStore: StadisticsStore,
        private activitiesService: ActivitiesService,
    ) {}

    // TODO: CORREGIR Método: No es consecuente con el nombre
    public drawPolygon(polygonId: string) {
        this.lotsStore.setActive(polygonId);
    }

    public setActiveLot(lotId: string) {
        const field = this.fieldsQuery.getAll().find((x) => {
            const result = x.lots.find((y) => y === lotId);

            if (!result) return false;
            return true;
        });
        this.fieldsStore.setActive(field.id);

        this.lotsStore.setActive(lotId);
    }

    public async createLot(fieldId: string, lot: Lot) {
        try {
            var nameOfCampaing = '';
            var actualYear = new Date().getFullYear();
            nameOfCampaing = actualYear + '-' + (actualYear + 1 + '').substr(2) + ' / 01';

            const newLot = await this.http
                .post<Lot>(`${environment.apiUrl}/lots`, {
                    fieldId,
                    name: lot.name,
                    coordinates: lot.coordinates,
                    area: lot.area,
                    varietyId: parseInt(lot.varietyId),
                    campaign: {
                        name: nameOfCampaing,
                        varietyId: parseInt(lot.varietyId),
                    },
                })
                .toPromise<Lot>();

            newLot.id = String(newLot.id);

            this.fieldsStore.update(fieldId, (state) => {
                return {
                    lots: arrayAdd(state.lots, newLot.id),
                };
            });
            for (let index = 0; index < newLot.coordinates.length; index++) {
                let tmp = JSON.parse(JSON.stringify(newLot.coordinates[index][1]));
                newLot.coordinates[index][1] = newLot.coordinates[index][0];
                newLot.coordinates[index][0] = tmp;
            }
            newLot.varietyId = lot.varietyId;
            this.userService.updatePermissionsUser(newLot.area, 0);
            newLot.area = Math.floor(newLot.area * 100) / 100;
            var temporal = this.subVarietyQuery.getEntity(lot.varietyId);
            if (temporal !== undefined) {
                newLot.varietyName = temporal.product.name;
                newLot.subVarietyName = temporal.name;
            } else {
                newLot.varietyName = '';
                newLot.subVarietyName = '';
            }
            this.lotsStore.add(newLot);
        } catch (error) {
            console.log(error);
        }
    }
    public async deleteLot(idLot): Promise<boolean> {
        try {
            const satellitalImageState = this.satellitalImageQuery.getValue();
            if (satellitalImageState !== undefined) {
                if (satellitalImageState.idLotToBeDisplayed == idLot) {
                    this.satellitalImageService.resetStateSatellitalImage();
                }
            }
            const rpt = await this.http.delete<any>(`${environment.apiUrl}/lots/` + idLot).toPromise();
            this.deleteLotInStore(idLot);
            var newField: any = this.fieldsQuery.getActive();
            var rmp = newField.lots.filter((obj) => obj !== idLot);
            this.fieldsStore.updateActive({
                lots: rmp,
            });
            this.userService.updatePermissionsUser(rpt.message.area, rpt.message.space);

            return true;
        } catch (error) {
            console.log('Lot delete', error);
        }
    }

    public deleteLotInStore(idLot) {
        this.activitiesService.deleteActivitesFromLot(idLot);
        this.lotsStore.remove(idLot);
        this.satImageStore.remove(idLot);
        this.stadisticsStore.remove(idLot);
    }

    public async updatePhenological(idLot, pheno) {
        try {
            var field: any = this.fieldsQuery.getActive();
            var lot = this.lotsQuery.getEntity(idLot);
            const rpt2 = await this.http
                .put<Lot>(`${environment.apiUrl}/lots/` + idLot, {
                    fieldId: field.id,
                    name: lot.name,
                    varietyId: parseInt(lot.varietyId),
                    phonologicalState: pheno,
                })
                .toPromise();
            this.lotsStore.updateActive({ phonologicalState: pheno });
        } catch (error) {}
    }

    public async updateLot(idLot: string, name: string, coords, pheno) {
        try {
            var field: any = this.fieldsQuery.getActive();
            var lot: any = this.lotsQuery.getActive();

            const rpt = await this.http
                .put<any>(`${environment.apiUrl}/lots/` + idLot, {
                    fieldId: field.id,
                    name: name,
                    coordinates: coords[0],
                    phonologicalState: pheno,
                })
                .toPromise();
            this.userService.updatePermissionsUser(rpt.message.area, rpt.message.space);
            if (coords[0] != null) {
                var newCoordinates = coords[0];
                for (let index = 0; index < newCoordinates.length - 1; index++) {
                    let tmp = JSON.parse(JSON.stringify(newCoordinates[index][1]));
                    newCoordinates[index][1] = newCoordinates[index][0];
                    newCoordinates[index][0] = tmp;
                }
                this.satImageStore.remove(idLot);
                this.stadisticsStore.remove(idLot);
                this.lotsStore.updateActive({
                    name: name,
                    coordinates: newCoordinates,
                    area: Math.round((rpt.message.area + lot.area) * 100) / 100,
                });
            } else {
                this.lotsStore.updateActive({
                    name: name,
                    area: Math.round((rpt.message.area + lot.area) * 100) / 100,
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async updateCampaing(
        idLot: string,
        idCampaing: string,
        name: string,
        varietyId: number,
        isFinished: boolean,
        phonologicalState: number,
    ) {
        try {
            const rpt2: CampaingResponse = await this.http
                .put<CampaingResponse>(`${environment.apiUrl}/campaign/` + idCampaing, {
                    name: name,
                    varietyId: varietyId,
                    isFinished: isFinished,
                    phonologicalState: phonologicalState,
                })
                .toPromise<CampaingResponse>();
            var allLots = JSON.parse(JSON.stringify(this.lotsQuery.getAll()));
            var campaing;
            for (let index = 0; index < allLots.length; index++) {
                if (allLots[index].id == idLot) {
                    for (let j = 0; j < allLots[index].campaigns.length; j++) {
                        if (allLots[index].campaigns[j].id === idCampaing) {
                            allLots[index].campaigns[j] = {
                                id: rpt2.message.id,
                                isFinished: rpt2.message.isFinished,
                                name: rpt2.message.name,
                                varietyId: rpt2.message.varietyId,
                                phonologicalState: rpt2.message.phonologicalState,
                            };
                            campaing = allLots[index].campaigns;
                            break;
                        }
                    }
                }
            }
            var tmpVarietyInformation = this.subVarietyQuery.getEntity(rpt2.message.varietyId + '');
            if (!isFinished) {
                if (tmpVarietyInformation == undefined) {
                    this.lotsStore.updateActive({
                        campaigns: campaing,
                        varietyName: '',
                        subVarietyName: '',
                    });
                } else {
                    this.lotsStore.updateActive({
                        campaigns: campaing,
                        varietyName: tmpVarietyInformation.product.name,
                        subVarietyName: tmpVarietyInformation.name,
                    });
                }
            } else {
                this.lotsStore.updateActive({
                    campaigns: campaing,
                    varietyName: '',
                    subVarietyName: '',
                });
            }
            return rpt2;
        } catch (error) {
            console.log(error);
        }
    }

    public async createNewCampaing(idLot: string, name: string, varietyId: number, isFinished: boolean) {
        const rpt2: CampaingResponse = await this.http
            .post<CampaingResponse>(`${environment.apiUrl}/lots/${idLot}/campaign`, {
                name: name,
                varietyId: varietyId,
                isFinished: isFinished,
            })
            .toPromise<CampaingResponse>();
        var actualLot = JSON.parse(JSON.stringify(this.lotsQuery.getEntity(idLot)));
        actualLot.campaigns.push(rpt2.message);
        if (varietyId !== 0) {
            var variety = this.subVarietyQuery.getEntity(varietyId + '');
            this.lotsStore.updateActive({
                campaigns: actualLot.campaigns,
                varietyName: variety.product.name,
                subVarietyName: variety.name,
                phonologicalState: 0,
            });
        } else {
            this.lotsStore.updateActive({
                campaigns: actualLot.campaigns,
                varietyName: '',
                subVarietyName: '',
                phonologicalState: 0,
            });
        }
        return rpt2;
    }

    public async generateReport(data: GeneratePDFReport) {
		const response = await this.http.post(`${environment.apiUrl}/lots/campaign/report`, data, { responseType: 'blob' }).toPromise()
		return response;
	}
}
