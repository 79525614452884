import { Component, OnInit, OnDestroy, Inject, ɵConsole } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { ProductStorageQuery } from '../../storage/product-storage/product-storage.query';
import { ProductService } from '../../storage/product-storage/product-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { Product } from '../../storage/product-storage/product-storage.store';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { Subscription } from 'rxjs';
import { DialogConfirmationResource } from '../confirmation-dialog/confirmation-dialog';
import { ExcelConst } from '@src/app/modules/agroanalytics/constants/excel';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { UserQuery } from '@src/app/store/user/user.query';
import { baseUrl } from '@src/environments/environment';
import { DialogImageComponent } from '../dialog-image/dialog-image.component';
import { DialogConfirmationNotification } from '@src/app/layouts/navbar/notification-components/confirmation-dialog/noti-confirm-dialog';

class ImageSnippet {
	constructor(public src: string, public file: File) { }
}

export interface Campaign {
	id: number;
	name: string;
}

@Component({
	selector: 'app-product-table',
	templateUrl: './product-table.component.html',
	styleUrls: ['./product-table.component.scss'],
})
export class ProductTableComponent implements OnInit, OnDestroy {
	selectedFile: ImageSnippet;
	public displayedColumns: string[] = [
		'position',
		'field',
		'camp_area',
		'lot',
		'lot_area',
		'variety_name',
		'subvariety_name',
		'season',
		'chargeImage',
		'showImage',
		'deleteImage',
		'zone',
		'start_date',
		'end_date',
		'quantity',
		'unit',
		'price',
		'total',
		'lost',
	];
	public dataSource = new MatTableDataSource([]);
	public elementData: Product[] = [];
	public fields = [];
	public copiedElement = undefined;
	private numberOfElementsModified = 0;
	private idsOfElemetsThatWereModified = [];
	private subscriptions = new Subscription();
	private isSaving = false;
	private idsOfElementsToBeDeleted = [];
	public campaigns: Campaign[] = [];
	constructor(
		private readonly globalToastService: GlobalToastService,
		private productQuery: ProductStorageQuery,
		private productService: ProductService,
		public dialog: MatDialog,
		public sharedData: SharedDataServiceService,
		private fieldsQuery: FieldsQuery,
		private fieldsService: FieldsService,
		private lotsQuery: LotsQuery,
		private readonly userQuery: UserQuery,
	) {
		this.campaigns = [{ id: 1, name: "2020-1" }, { id: 2, name: "2020-2" }];
	}

	ngOnInit(): void {
		this.initializeData();
	}
	public async initializeData() {
		await this.fieldsService.findAll();
		await this.productService.findAll();
		this.fillTableData();
		this.subscriptions.add(
			this.sharedData.getClickToFilter().subscribe((x) => {
				this.filterData();
			}),
		);
		this.subscriptions.add(
			this.sharedData.getClickSaveProduct().subscribe((x) => {
				this.save();
			}),
		);
		this.fieldsQuery.selectFieldsWithLots().subscribe((data) => {
			this.fields = data;
		});
		this.subscriptions.add(
			this.sharedData.getClickToExport().subscribe((x) => {
				this.exportDataToExcel();
			}),
		);
	}
	public getCampaigns(lotId: string, fieldId: string) {
		if (this.fields == undefined || this.fields == null)
			return [];
		for (let i = 0; i < this.fields.length; i++) {
			if (this.fields[i].id == fieldId) {
				for (let j = 0; j < this.fields[i].lots.length; j++) {
					if (this.fields[i].lots[j].id == lotId)
						return this.fields[i].lots[j].campaigns;
				}
			}
		}
		return [];
	}

	public getNameCampaign(campaignId) {
		for (let i = 0; i < this.campaigns.length; i++) {
			if (this.campaigns[i].id == campaignId) {
				return this.campaigns[i].name;
			}
		}
		return "";
	}

	public getLots(fieldId: string) {
		if (this.fields == undefined || this.fields == null) return [];
		for (let i = 0; i < this.fields.length; i++) {
			if (this.fields[i].id == fieldId) {
				return this.fields[i].lots;
			}
		}
		return [];
	}

	public fillTableData() {
		var a = this.productQuery.selectAll().subscribe((all) => {
			this.elementData = [];
			all.forEach((element) => {
				var elemnToBeInserted: Product = {
					id: element.id,
					camp_id: element.camp_id,
					camp_name: '',
					camp_area: '',
					lot_id: element.lot_id,
					lot_name: '',
					lot_area: '',
					variety_id: element.variety_id,
					variety_name: '',
					subvariety_name: '',
					season: element.season,
					image: element.image,
					zone: element.zone,
					quantity: element.quantity,
					start_date: element.start_date,
					end_date: element.end_date,
					price: element.price,
					lost: element.lost,
					unit: element.unit,
					total: '',
					image_file: null,
				};
				if (element.image != '' && element.image != null){
					elemnToBeInserted.image_url = baseUrl.base + element.image
				}

				elemnToBeInserted.total = (
					Number(elemnToBeInserted.price) *
					Number(elemnToBeInserted.quantity)
				).toString();
				var camp_entity = this.fieldsQuery.getEntity(
					elemnToBeInserted.camp_id,
				);
				if (camp_entity !== undefined){

					elemnToBeInserted.camp_name = camp_entity.name;
					elemnToBeInserted.camp_area = this.calculateCampArea(
						camp_entity,
					);

				}
				

				if (
					elemnToBeInserted.lot_id != undefined &&
					elemnToBeInserted.lot_id != ''
				) {
					var lot_entity = this.lotsQuery.getEntity(
						elemnToBeInserted.lot_id,
					);
					if (lot_entity != undefined) {
						elemnToBeInserted.lot_name = lot_entity.name;
						elemnToBeInserted.lot_area = lot_entity.area.toString();
						elemnToBeInserted.variety_name = lot_entity.varietyName;
						elemnToBeInserted.subvariety_name =
							lot_entity.subVarietyName;
					}
				}

				this.elementData.push(elemnToBeInserted);
			});
			if (this.elementData.length < 20) {
				var elementsNeededToCompleteTable =
					20 - this.elementData.length;
				for (
					let index = 0;
					index < elementsNeededToCompleteTable;
					index++
				) {
					this.elementData.push(this.getNewLabourObject());
				}
			}
			this.dataSource = new MatTableDataSource(this.elementData);
		});
	}

	public calculateCampArea(camp_entity) {
		var totalArea = 0;
		camp_entity.lots.forEach((element) => {
			totalArea += this.lotsQuery.getEntity(element).area;
		});
		return totalArea.toFixed(2);
	}

	public getNewLabourObject() {
		return {
			id: '',
			camp_id: '',
			variety_id: '',
			season: '',
			image: '',
			zone: '',
			quantity: '',
			start_date: '',
			end_date: '',
			price: '',
			lost: '',
			unit:'',
		};
	}

	public addRowUp(position) {
		var elemnToBeInserted = this.getNewLabourObject();
		this.elementData.splice(position, 0, elemnToBeInserted);
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public addRowDown(position) {
		var elemnToBeInserted = this.getNewLabourObject();
		this.elementData.splice(position, 0, elemnToBeInserted);
		this.dataSource = new MatTableDataSource(this.elementData);
	}

	public copy(element) {
		var elemnToBeInserted = {
			id: 'new',
			camp_id: element.camp_id,
			camp_name: element.camp_name,
			lot_id: element.lot_id,
			lot_name: element.lot_name,
			camp_area: element.camp_area,
			lot_area: element.lot_area,
			variety_name: element.variety_name,
			subvariety_name: element.subvariety_name,
			variety_id: element.variety_id,
			season: element.season,
			image: element.image,
			zone: element.zone,
			quantity: element.quantity,
			start_date: element.start_date,
			end_date: element.end_date,
			price: element.price,
			lost: element.lost,
			unit:element.unit,
		};
		this.copiedElement = elemnToBeInserted;
	}

	public paste(position) {
		this.elementData[position] = this.copiedElement;
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public delete(position) {
		this.idsOfElementsToBeDeleted.push(this.elementData[position].id);
		if (
			this.idsOfElemetsThatWereModified.includes(
				this.elementData[position].id,
			)
		) {
			var index = this.idsOfElemetsThatWereModified.indexOf(
				this.elementData[position].id,
			);
			this.idsOfElemetsThatWereModified.splice(index, 1);
		}
		this.elementData.splice(position, 1);
		this.dataSource = new MatTableDataSource(this.elementData);
		this.copiedElement = undefined;
	}

	public changeCamp(event, element) {
		element.camp_id = event;
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
		var camp_entity = this.fieldsQuery.getEntity(
			element.camp_id,
		);

		element.camp_name = camp_entity.name;
		element.camp_area = this.calculateCampArea(
			camp_entity,
		);
	}

	public changeLot(event, element) {
		element.lot_id = event;
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
		var lot_entity = this.lotsQuery.getEntity(element.lot_id);
		element.lot_name = lot_entity.name;
		element.lot_area = lot_entity.area.toString();
		element.variety_name = lot_entity.varietyName;
		element.subvariety_name = lot_entity.subVarietyName;
	}

	public changeSeason(campaign_id, element) {
		element.season = campaign_id
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public onChange(file: File, element) {
		if (file) {
			if (file.size >= 1024 * 1024) {
				return;
			}
			element.image_file = file;

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (event) => {
				element.image_url = reader.result;
			};
		}
	}

	public changeZone(event, element) {
		element.zone = event.target.value;
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public changeQuantity(event, element) {
		if (isNaN(parseFloat(event.target.value))) {
            element.quantity = ''
        } else {
            element.quantity = event.target.value;
            if (
				element.id !== '' &&
				element.id !== 'new' &&
				!this.idsOfElemetsThatWereModified.includes(element.id)
			) {
				this.idsOfElemetsThatWereModified.push(element.id);
			}
        }
		element.total = (
			Number(element.price) * Number(element.quantity)
		).toString();

	}

	public changeUnit(event, element) {
		console.log(event.target.value)
		element.unit = event.target.value;
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}

	}

	public changeStartDate(event, element) {
		element.start_date = event.target.value;
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}

	public changeEndDate(event, element) {
		element.end_date = event.target.value;
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public changePrice(event, element) {

		if (isNaN(parseFloat(event.target.value))) {
            element.price = ''
        } else {
            element.price = event.target.value;
            if (
				element.id !== '' &&
				element.id !== 'new' &&
				!this.idsOfElemetsThatWereModified.includes(element.id)
			) {
				this.idsOfElemetsThatWereModified.push(element.id);
			}
        }

		element.total = (
			Number(element.price) * Number(element.quantity)
		).toString();
	}

	public changeLost(event, element) {
		element.lost = event.target.value;
		if (element.id === '') {
			element.id = 'new';
		}
		if (
			element.id !== '' &&
			element.id !== 'new' &&
			!this.idsOfElemetsThatWereModified.includes(element.id)
		) {
			this.idsOfElemetsThatWereModified.push(element.id);
		}
	}
	public updateImage(element) {
		if (element.id !== '' && element.id !== 'new') {
			let form_data = new FormData();
			form_data.append('image', element.image_file);
			form_data.append('quantity', element.quantity);
			this.productService.updateImage(form_data, element.id);
		}
	}

	public deleteImage(element) {
        const dialogRef = this.dialog.open(DialogConfirmationNotification, {
            panelClass: "modalClass",
            width: "470px",
            height: "120px",
            data: {
                text: "¿Desea eliminar la imagen?"
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
				if (element.id !== '' && element.id !== 'new') {
					this.productService.deleteImage(element);
					element.image_url = null;
					element.imahe = null;
				}
                    
            }
		})
    }

	public save() {
		if (this.isSaving) {
			return;
		} else {
			this.isSaving = true;
		}
		this.numberOfElementsModified = 0;
		var newElemets: Product[] = [];
		var elementsToBeUpdated: Product[] = [];
		for (let index = 0; index < this.elementData.length; index++) {
			const element = this.elementData[index];

			if (element.id !== '') {
				this.numberOfElementsModified++;
			}
			if (element.id === 'new') {
				if (this.validation(element)) {
					newElemets.push(element);
				} else {
					this.globalToastService.createErrorToast(
						'Error',
						'Por favor llenar todos los campos obligatorios',
					);
					this.isSaving = false;
					return;
				}
			}
			if (this.idsOfElemetsThatWereModified.includes(element.id)) {
				if (this.validation(element)) {
					elementsToBeUpdated.push(element);
				} else {
					this.globalToastService.createErrorToast(
						'Error',
						'Por favor llenar todos los campos obligatorios',
					);
					this.isSaving = false;
					return;
				}
			}
		}
		if (
			newElemets.length === 0 &&
			elementsToBeUpdated.length === 0 &&
			this.idsOfElementsToBeDeleted.length === 0
		) {
			this.sharedData.sentViewConsultance();
			this.isSaving = false;
			return;
		}
		const dialogRef = this.dialog.open(DialogConfirmationResource, {
			data: {
				mensaje:
					'¿Desea guardar ' +
					this.numberOfElementsModified +
					' recursos de "Productos"?',
			},
		});

		var g = dialogRef.afterClosed().subscribe((result) => {
			if (result === undefined) {
				this.idsOfElemetsThatWereModified = [];
				this.idsOfElementsToBeDeleted = [];
				this.fillTableData();
				this.sharedData.sentViewConsultance();
			}

			if (result) {
				if (newElemets.length > 0)
					this.productService.createMany(newElemets);
				if (elementsToBeUpdated.length > 0)
					this.productService.updateMany(elementsToBeUpdated);
				if (this.idsOfElementsToBeDeleted.length > 0)
					this.productService.deleteMany(
						this.idsOfElementsToBeDeleted,
					);
				this.idsOfElementsToBeDeleted = [];
				this.idsOfElemetsThatWereModified = [];
				this.sharedData.sentViewConsultance();
			}

			if (result === false) {
			}
			this.numberOfElementsModified = 0;
			this.isSaving = false;
			g.unsubscribe();
		});
	}

	public validation(element: Product): boolean {
        if (element.camp_id === "" || element.price === "" || element.quantity === "" || element.lot_id === "") {
            return false;
        }
        return true;
    }

	applyFilter(filterValue: string) {
		//filterValue = filterValue.trim(); // Remove whitespace
		//filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}

	public setDataSourceFilter(rowSelected) {
		switch (rowSelected) {
			case 1:
				this.dataSource.filterPredicate = (
					data: Product,
					filter: string,
				) => {
					return data.camp_name
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 2:
				this.dataSource.filterPredicate = (
					data: Product,
					filter: string,
				) => {
					return data.lot_name
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 3:
				this.dataSource.filterPredicate = (
					data: Product,
					filter: string,
				) => {
					return data.variety_name
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 4:
				this.dataSource.filterPredicate = (
					data: Product,
					filter: string,
				) => {
					return data.subvariety_name
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 5:
				this.dataSource.filterPredicate = (
					data: Product,
					filter: string,
				) => {
					return data.season
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 6:
				this.dataSource.filterPredicate = (
					data: Product,
					filter: string,
				) => {
					return data.quantity
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;
			case 7:
				this.dataSource.filterPredicate = (
					data: Product,
					filter: string,
				) => {
					return data.price
						.toLowerCase()
						.includes(filter.toLowerCase());
				};
				break;

			default:
				return;
		}
	}

	public filterData() {
		let rowSelected = this.sharedData.columnSelectedToFilter;
		let textInput = this.sharedData.searchTextToFilter;
		if (rowSelected === undefined) {
			return;
		}
		this.setDataSourceFilter(rowSelected);

		this.applyFilter(textInput);
	}
	public showImage(e, element){
        if (element.id !== "") {
            this.openDialog(e,element.image_url)
        }
		
	}
	public openDialog(e, data): void {
        e.stopPropagation();
        e.preventDefault();

        const dialogRef = this.dialog.open(DialogImageComponent, {
            width: '455px',
            height: '400px', disableClose: true,
            panelClass: 'modalClass',
            data: data
        });

    }


	public exportDataToExcel() {
		if (this.elementData !== undefined) {
			const worksheet = XLSX.utils.json_to_sheet(this.elementData);
			const workbook: XLSX.WorkBook = {
				Sheets: { data: worksheet },
				SheetNames: ['data'],
			};
			const excelBuffer: any = XLSX.write(workbook, {
				bookType: 'xlsx',
				type: 'array',
			});
			this.saveAsExcelFile(excelBuffer, 'excelFileName');
		}
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: ExcelConst.EXCEL_TYPE });
		FileSaver.saveAs(
			data,
			fileName +
			'_export_' +
			new Date().getTime() +
			ExcelConst.EXCEL_EXTENSION,
		);
	}

	public ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	public isValidPlan() {
		const currentUser = this.userQuery.getInformation();
		return currentUser.permission.resourceManagement;
	}

	public showConfirmPlanUpdate() {
		if (this.isValidPlan())
			return;

		const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
			width: '400px'
		});
		saveRef.afterClosed().subscribe()
	}
}
