import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class SharedDataServiceService {
	//Data
	public yearSelected;
	public yearDisplayed = new Date().getFullYear();
	public weekSelected;
	public daySelected;
	public dayName;
	public monthSelected;
	public columnSelectedToFilter = '';
	public searchTextToFilter = '';
	public scrollVertical = 0;
	public selectedColumns = []

	//Observables
	private subject0 = new Subject<any>();
	private subject1 = new Subject<any>();
	private subject2 = new Subject<any>();
	private subject3 = new Subject<any>();
	private subject4 = new Subject<any>();
	private subject5 = new Subject<any>();
	private subject6 = new Subject<any>();
	private subject7 = new Subject<any>();
	private subject8 = new Subject<any>();
	private subjectFilterColumns = new Subject<any>();
	private subjectScroll = new Subject<any>();
	private subjectFilter = new Subject<any>();
	private subjectExportExcel = new Subject<any>();
	private subjectScrollActualDate = new Subject<any>();

	constructor() {}

	public sentUpdateFilterColumns(data){
		this.selectedColumns = data;
		this.subjectFilterColumns.next()
	}

	public getClickToFilterColumns(): Observable<any> {
		return this.subjectFilterColumns.asObservable();
	}

	public sentUpdateScrollActualDate() {
		this.subjectScrollActualDate.next();
	}
	public getClickToScrollActualDate(): Observable<any> {
		return this.subjectScrollActualDate.asObservable();
	}

	public sentUpdateToExport() {
		this.subjectExportExcel.next();
	}
	public getClickToExport(): Observable<any> {
		return this.subjectExportExcel.asObservable();
	}

	public sentUpdateToFilter() {
		this.subjectFilter.next();
	}
	public getClickToFilter(): Observable<any> {
		return this.subjectFilter.asObservable();
	}

	public sentUpdateToCalendarScroll() {
		this.subjectScroll.next();
	}
	public getClickToUpdateCalendarScroll(): Observable<any> {
		return this.subjectScroll.asObservable();
	}

	public sentUpdateToCalendar() {
		this.subject6.next();
	}
	public getClickToUpdateCalendar(): Observable<any> {
		return this.subject6.asObservable();
	}

	public sentViewConsultance() {
		this.subject0.next();
	}
	public getClickViewConsultance(): Observable<any> {
		return this.subject0.asObservable();
	}

	public saveLabor() {
		this.subject1.next();
	}
	public getClickSaveLabor(): Observable<any> {
		return this.subject1.asObservable();
	}

	public saveProduct() {
		this.subject7.next();
	}
	public getClickSaveProduct(): Observable<any> {
		return this.subject7.asObservable();
	}

	public saveMachine() {
		this.subject2.next();
	}
	public getClickSaveMachine(): Observable<any> {
		return this.subject2.asObservable();
	}

	public saveSupplies() {
		this.subject3.next();
	}
	public getClickSaveSupplies(): Observable<any> {
		return this.subject3.asObservable();
	}

	public saveWater() {
		this.subject4.next();
	}
	public getClickSaveWater(): Observable<any> {
		return this.subject4.asObservable();
	}

	public saveServicesAndOther() {
		this.subject5.next();
	}
	public getClickSaveServicesAndOther(): Observable<any> {
		return this.subject5.asObservable();
	}

	public saveEmailProvider() {
		this.subject8.next();
	}
	
	public getClickSaveEmailProvider(): Observable<any> {
		return this.subject8.asObservable();
	}
}
