import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
//import { MyFieldsModals } from '@modules/my-fields/enums/my-fields.enum';

export interface SatellitalImageState {
	isSatellitalImageDisplayed: boolean;
	satellitalImageType: string;
	idLotToBeDisplayed: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sat-img-state' })
export class SatellitalImageStore extends Store<SatellitalImageState> {
	public constructor() {
		super({
			isSatellitalImageDisplayed: false,
			idLotToBeDisplayed: null,
			satellitalImageType: null
		});
	}
	public resetStateSatellitalImage(){
		this.update({
			isSatellitalImageDisplayed: false,
			idLotToBeDisplayed: null,
			satellitalImageType: null
		});
	}

	public updateStateSatellitalImage(isSatellitalImageDisplayed, satellitalImageType){
		this.update({
			isSatellitalImageDisplayed: isSatellitalImageDisplayed,
			satellitalImageType: satellitalImageType
		});
	}
	public setSatellitalImageType(satellital_image_type: string) {
		this.update({
			satellitalImageType: satellital_image_type
		});
	}

	public setSatellitalImageDisplay(display: boolean) {
		this.update({
			isSatellitalImageDisplayed: display
		});
	}

	public setLotIdForSatellitalImage(idLot: string) {
		this.update({
			idLotToBeDisplayed: idLot
		});
	}
}
