import { Injectable } from '@angular/core';

import { MyFieldsUiStore } from './my-fields-ui.store';
import { MyFieldsModals } from '@modules/my-fields/enums';

@Injectable({ providedIn: 'root' })
export class MyFieldsUiService {
	public constructor(private readonly myFieldsUiStore: MyFieldsUiStore) {}

	public setSelectedResource(resource: number) {
		this.myFieldsUiStore.setSelectedResource(resource);
	}
}
