import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Header } from '../../constants/header';
import { ManageModalActivityService } from '../../services/manage-modal-activity.service';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { ActivitiesStorageQuery } from '../../storage/activities-storage/activities-storage.query';
import { ActivitiesService } from '../../storage/activities-storage/activities-storage.service';
import { FullActivity } from '../../storage/activities-storage/activities-storage.store';
import { CalendarStorageQuery } from '../../storage/calendar-storage/calendar-storage.query';
import { CalendarService } from '../../storage/calendar-storage/calendar-storage.service';
import { Calendar } from '../../storage/calendar-storage/calendar-storage.store';


export interface previewActivity {
	fullActivity: FullActivity;
	duration_first_schedule: number;
	duration_second_schedule: number;
	start_first_schedule?: number;
	start_second_schedule?: number;
	index?: number;

}

@Component({
	selector: 'app-day-calendar',
	templateUrl: './day-calendar.component.html',
	styleUrls: ['./day-calendar.component.scss'],
})
export class DayCalendarComponent implements OnInit, AfterViewChecked {
	@ViewChild('scrollOne') scrollOne: ElementRef;
	@Input() lotZoom: boolean;
	@Input() lotsFromServer: [];
	public header: Header;
	public percentage: number;
	public activities: previewActivity[];
	public allActivities: FullActivity[];
	public months: string[];
	public month: number;
	public year: number;
	public week: number;
	public day: number;
	public calendarObject: Calendar = undefined;
	private start_schedule: number;
	private end_schedule: number;
	private start_break: number;
	private end_break: number;
	public dateToPadding = 0;
	public name_days = [
		'Domingo',
		'Lunes',
		'Martes',
		'Miercoles',
		'Jueves',
		'Viernes',
		'Sabado',
	];

	constructor(
		private activitiesService: ActivitiesService,
		private activitiesQuery: ActivitiesStorageQuery,
		public dialog: MatDialog,
		private sharedDataService: SharedDataServiceService,
		private calendarStorageQuery: CalendarStorageQuery,
		private calendarService: CalendarService,
		private manageModalActivityService: ManageModalActivityService
	) {
		this.activitiesService.findAll();
		this.calendarService.findAll();
		this.activities = [];
		this.initializeData();
		this.activitiesQuery.selectAll().subscribe((data) => {
			this.allActivities = JSON.parse(JSON.stringify(data));
			this.updatePreviewActivity(this.allActivities);
		});
		this.calendarStorageQuery.selectAll().subscribe(x => {
			this.getDataCalendar(x);
		})

		this.sharedDataService.getClickToUpdateCalendar().subscribe((x) => {
			this.initializeData();
			this.updatePreviewActivity(this.allActivities);
		});

	}

	ngOnInit(): void { }

	public initializeData() {
		this.year = this.sharedDataService.yearSelected;
		this.month = this.sharedDataService.monthSelected;
		this.week = this.sharedDataService.weekSelected;
		this.day = this.sharedDataService.daySelected;
		this.getDaysOfMonth();
	}

	public getDataCalendar(activity) {
		this.start_schedule = Number((activity[0].start_schedule).substring(0, 2));
		this.end_schedule = Number((activity[0].end_schedule).substring(0, 2));
		this.start_break = Number((activity[0].start_break).substring(0, 2));
		this.end_break = Number((activity[0].end_break).substring(0, 2));
	}


	public updateDataCalendar(schedule_setting) {

		let start_schedule = Number((schedule_setting.start_schedule).substring(0, 2));
		let end_schedule = Number((schedule_setting.end_schedule).substring(0, 2));
		let start_break = Number((schedule_setting.start_break).substring(0, 2));
		let end_break = Number((schedule_setting.end_break).substring(0, 2));

		return [start_schedule, start_break, end_break, end_schedule]
	}

	private getDaysOfMonth() {
		this.header = null;
		let detail = [];
		let totalDays = 0;
		let index = 1;
		while (index <= 24) {
			detail.push(index + ' hrs');
			totalDays += 1;
			index++;
		}
		if (detail.length != 0) {
			this.header = ({
				title: "object",
				detail: detail,
			});
		}
		this.percentage = 100 / totalDays;
	}

	private getDuration(fullActivity: FullActivity) {
		let day = new Date(this.year, this.month, this.day);
		let start = new Date(fullActivity.activity.start_date);

		let end = new Date(fullActivity.activity.end_date);

		let duration = 0;
		if (day >= start && day <= end) {
			duration = 1;
		}
		return {
			duration: duration,
			start: start.getDate(),
		};
	}

	public createActivity(lot, event) {
		let activity = {
			camp_id: lot.id,
			season: lot.campaign,
			activity: {
				name: "",
				phase: "",
				start_date: "",
				end_date: "",
				hour: 0,
				workday: 0,
				duration: 0,
				detail: [],
				description: ""
			}
		}
		this.openDialog(activity, event)
	}

	openDialog(data, e): void {
		e.stopPropagation();
		e.preventDefault();
		this.manageModalActivityService.openDialogActivity(this.dialog, data);
	}

	public updatePreviewActivity(activities) {
		this.activities = [];
		this.dateToPadding = 0;
		var lots: { [id: string]: number } = {};
		activities.forEach((activity) => {
			if (lots[activity.camp_id] != undefined) {
				lots[activity.camp_id] += 1;
			} else {
				lots[activity.camp_id] = 0;
			}


			let durationActivity = this.getDuration(activity);
			if (durationActivity.duration > 0) {
				let first = this.start_break - this.start_schedule
				let second = this.end_schedule - this.end_break
				let start_first_schedule = this.start_schedule
				let start_second_schedule = this.end_break
				if (activity.activity.schedule_setting != undefined || activity.activity.schedule_setting != null) {
					let date_list = this.updateDataCalendar(activity.activity.schedule_setting)
					first = date_list[1] - date_list[0]
					second = date_list[3] - date_list[2]
					start_first_schedule = date_list[0]
					start_second_schedule = date_list[2]
				}
				this.activities.push({
					duration_first_schedule: first,
					duration_second_schedule: second,
					fullActivity: activity,
					start_first_schedule: start_first_schedule,
					start_second_schedule: start_second_schedule,
					index: lots[activity.camp_id],
				});
				
			}
			
		});
		this.dateToPadding = (new Date()).getHours()
	}


	ngAfterViewChecked(): void {
		this.updateScrollToActualDate();
		this.dateToPadding = 0;
		const scrollOne = this.scrollOne.nativeElement as HTMLElement;
		scrollOne.scrollTop = this.sharedDataService.scrollVertical;
	}
	public scrollUp = 0;

	updateScroll() {

		const scrollOne = this.scrollOne.nativeElement as HTMLElement;
		// do logic and set

		if (this.scrollUp !== scrollOne.scrollTop) {
			this.sharedDataService.scrollVertical = scrollOne.scrollTop;
		}

	}

	public updateScrollToActualDate() {
		if (this.dateToPadding < 1) {
			return;
		}
		const scrollOne = this.scrollOne.nativeElement as HTMLElement;

		scrollOne.scrollLeft = (this.dateToPadding * 100) - 100
	}
}
