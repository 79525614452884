import { Component, OnInit } from '@angular/core';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { Router } from '@angular/router';
import { MessageUpdatePlanComponent } from '@src/app/core/components/message-update-plan/message-update-plan.component';
import { UserQuery } from '@src/app/store/user/user.query';

@Component({
    selector: 'app-storage-management',
    templateUrl: './storage-management.component.html',
    styleUrls: ['./storage-management.component.scss'],
})
export class StorageManagementComponent implements OnInit {
    public stockFilters = [
        { value: "ng", name: "Buscar por fila" },
        { value: "1", name: "Clase" },
        { value: "2", name: "Tipo" },
        { value: "3", name: "Unidad" },
        { value: "4", name: "Cantidad" },
        { value: "5", name: "Costo" },
    ]

    public productFilters = [
        { value: "ng", name: "Buscar por fila" },
        { value: "1", name: "Campo" },
        { value: "2", name: "Parcela" },
        { value: "3", name: "Cultivo" },
        { value: "4", name: "Variedad" },
        { value: "5", name: "Temporada" },
        { value: "6", name: "Volumen" },
        { value: "7", name: "Precio" },
    ]

    constructor(
        public sharedData: SharedDataServiceService,
        public dialog: MatDialog,
        private readonly sidebarService: SidebarService,
        private lotsQuery: LotsQuery,
        private fieldsService: FieldsService,
        private readonly router: Router,
        private readonly userQuery: UserQuery
    ) { }

    value = 'Buscar';

    optionSelected = 'stock';
    applySelection = 'stock';
    rowForFilter = this.stockFilters

    async ngOnInit() {
        this.sidebarService.updateSelectedService(7);
        await this.fieldsService.findAll();
        const allLots = this.lotsQuery.getAll().length

        if (allLots < 1) {
            this.router.navigate(['/agromonitor']);
        }
        this.sharedData.getClickViewConsultance().subscribe(x => {
            this.optionSelected = String(this.applySelection)
            this.filterSelected = false;
        })
    }

    public save() {
        if (this.optionSelected === 'product') {
            this.sharedData.saveProduct();
        }
    }

    public changeOption(newOption: string) {
        if (newOption === this.optionSelected) {
            return;
        }
        this.applySelection = newOption;
        this.optionSelected = newOption;
        if (this.optionSelected === 'product') {
            this.rowForFilter = this.productFilters;
            this.sharedData.saveProduct();
        }
        else {
            this.rowForFilter = this.stockFilters;
        }
    }

    public filterSelected = false;
    public changeFilterOption(event) {
        if (event === "ng") {
            this.filterSelected = false;
            this.sharedData.columnSelectedToFilter = undefined;
            return;
        } else {
            this.filterSelected = true;
            this.sharedData.columnSelectedToFilter = event;
        }
    }

    public changeText(event) {
        this.sharedData.searchTextToFilter = event;
        this.sharedData.sentUpdateToFilter();
    }

    public exportToExcel() {
        const currentUser = this.userQuery.getInformation();
        if (currentUser.permission.exportData)
            this.sharedData.sentUpdateToExport();
        else {
            const saveRef = this.dialog.open(MessageUpdatePlanComponent, {
                width: '400px'
            });
            saveRef.afterClosed().subscribe(
                data => {
                })
        }
    }
    public sendEmail(){
        this.sharedData.saveEmailProvider();
    }
}
