import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FieldsQuery } from '@src/app/store/fields/fields.query';
import { MatDialog, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { FieldsService } from '@src/app/store/fields/fields.service';
import { LotsQuery } from '@src/app/store/lots/lots.query';
import { LotsService } from '@src/app/store/lots/lots.service';
import { SharedDataServiceService } from '../../shared-data/shared-data-service.service';
import { CalendarService } from '../../storage/calendar-storage/calendar-storage.service';
import { LaborService } from '../../storage/labor-storage/labor-storage.service';
import { MachineService } from '../../storage/machine-storage/machine-storage.service';
import { SupplyService } from '../../storage/supplies-storage/supplies-storage.service';
import { WaterService } from '../../storage/water-storage/water-storage.service';
import { ServiceAndOthersService } from '../../storage/serv-other-storage/serv-other-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarAvailabilityComponent } from '../../components/calendar-availability/calendar-availability.component';
import { SidebarService } from '@src/app/store/sidebar/sidebar.service';
import { UserQuery } from '@src/app/store/user/user.query';


@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})

export class MainPageComponent implements OnInit, AfterViewChecked {
    public today = new Date();
    public name_days = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"]
    public yearSelected = this.today.getFullYear();
    public yearDisplayed;
    public monthSelected = this.today.getMonth();
    public weekSelected = 0;
    public daySelected = this.today.getDate();
    public dayName = this.today.getDay();
    public lotsFromServer = [];
    public lotsFromServerOriginal = [];
    public selectedFilter = undefined;
    public stringFilter = '';
    public selectedCalendarButton = 'year';
    public MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    public showMessage = true;


    constructor(
        private lotsQuery: LotsQuery,
        private fieldsQuery: FieldsQuery,
        private fieldsService: FieldsService,
        private sharedDataService: SharedDataServiceService,
        public dialog: MatDialog,
        private calendarService: CalendarService,
        private machineService: MachineService,
        private laborService: LaborService,
        public waterService: WaterService,
        public supplyService: SupplyService,
        public serviceAndOthersService: ServiceAndOthersService,
        private readonly spinner: NgxSpinnerService,
        private readonly sidebarService: SidebarService,
        private readonly userQuery: UserQuery
    ) {
        this.initializeData();
    }
    ngAfterViewChecked(): void {
        if (!this.showMessage) {
            const scrollOne = this.scrollFields.nativeElement as HTMLElement;
            scrollOne.scrollTop = this.sharedDataService.scrollVertical;
        }
    }

    ngOnInit(): void {
        this.sidebarService.updateSelectedService(7);
    }

    private async initializeData() {

        this.getActualYear();
        this.spinner.show('DescargandoDatosDelServidorMonitor');
        await this.fieldsService.findAll();
        const allLots = this.lotsQuery.getAll().length

        if (allLots > 0) {
            this.showMessage = false
            await this.calendarService.findAll();
            const currentUser = this.userQuery.getInformation();
            if (currentUser.permission.resourceManagement) {
                await this.laborService.findAll();
                await this.machineService.findAll();
                await this.supplyService.findAll();
                await this.waterService.findAll();
                await this.serviceAndOthersService.findAll();
            }
            this.lotsQuery.selectAll().subscribe(allLots => {
                allLots.forEach(element => {
                    let campaignName = ""
                    element.campaigns.forEach(campaign => {
                        if (!campaign.isFinished)
                            campaignName = campaign.id
                    });
                    var newElem = {
                        id: element.id,
                        name: element.name,
                        area: element.area,
                        varietyName: element.varietyName,
                        "fieldName": "",
                        "selected": false,
                        campaign: campaignName
                    }
                    this.lotsFromServer.push(newElem)
                    this.lotsFromServerOriginal.push(newElem)
                });
            });

            this.fillLotsWithFildsData();

            this.yearDisplayed = this.sharedDataService.yearDisplayed;
            this.sharedDataService.getClickToUpdateCalendarScroll().subscribe(x => {
                this.yearDisplayed = this.sharedDataService.yearDisplayed;
            })
            this.sharedDataService.yearSelected = this.yearSelected;
            this.sharedDataService.monthSelected = this.monthSelected;
            this.sharedDataService.weekSelected = this.weekSelected;
            this.sharedDataService.daySelected = this.daySelected;
            this.sharedDataService.dayName = this.dayName;
            this.weekSelected = this.sharedDataService.weekSelected;
            this.sharedDataService.getClickToUpdateCalendarScroll().subscribe(x => {
                this.weekSelected = this.sharedDataService.weekSelected;
                this.monthSelected = this.sharedDataService.monthSelected;
            })
            this.sharedDataService.sentUpdateToCalendar();
        }
        else {
            this.showMessage = true
        }

        this.spinner.hide('DescargandoDatosDelServidorMonitor');
    }

    public fillLotsWithFildsData() {
        var tmpArrayOfFields;
        this.fieldsQuery.selectAll().subscribe(allFields => {
            tmpArrayOfFields = allFields;
        })
        for (let i = 0; i < this.lotsFromServer.length; i++) {
            for (let j = 0; j < tmpArrayOfFields.length; j++) {
                if (tmpArrayOfFields[j].lots.includes(this.lotsFromServer[i].id)) {
                    this.lotsFromServer[i]["fieldName"] = tmpArrayOfFields[j].name
                    this.lotsFromServer[i]["color"] = tmpArrayOfFields[j].color
                }
            }
        }
    }

    public getActualYear() {
        var actualDate = new Date()
        this.yearSelected = actualDate.getFullYear()
    }

    public nextTime(aum: number) {
        if (this.selectedCalendarButton == "year") {
            if (aum == -1)
                this.prevYear();
            else
                this.nextYear();
        }
        else {
            if (this.selectedCalendarButton == "month") {
                this.nextMonth(aum);
            }
            else if (this.selectedCalendarButton == "week") {
                this.nextWeek(aum);
            }
            else {
                this.nextDay(aum);
            }
        }

    }

    public prevYear() {
        this.yearSelected = --this.yearSelected;
        this.yearDisplayed = --this.yearSelected;
        this.sharedDataService.yearSelected = this.yearSelected;
        this.sharedDataService.sentUpdateToCalendar();
    }

    public nextYear() {
        this.yearSelected = ++this.yearSelected;
        this.yearDisplayed = ++this.yearSelected;
        this.sharedDataService.yearSelected = this.yearSelected;
        this.sharedDataService.sentUpdateToCalendar();
    }

    public nextWeek(aum: number) {
        this.weekSelected += aum;
        if (this.weekSelected < 0) {
            this.monthSelected -= 1;
            this.weekSelected = 4;
        }
        if (this.weekSelected > 4) {
            this.monthSelected += 1;
            this.weekSelected = 0;
        }
        this.sharedDataService.monthSelected = this.monthSelected;
        this.sharedDataService.weekSelected = this.weekSelected;
        this.sharedDataService.sentUpdateToCalendar();

    }

    public nextDay(aum: number) {
        let first = new Date(this.yearSelected, this.monthSelected, 1)
        let last = new Date(this.yearSelected, this.monthSelected + 1, 0)
        let now = new Date(this.yearSelected, this.monthSelected, this.daySelected)
        if (now.getTime() == last.getTime() && aum == 1) {
            this.monthSelected += 1;
            this.daySelected = 1;
            aum = 0;
        }
        else if (now.getTime() == first.getTime() && aum == -1) {
            this.monthSelected -= 1;
            this.daySelected = (new Date(this.yearSelected, this.monthSelected + 1, 0)).getDate();
            aum = 0;
        }
        this.daySelected += aum;
        this.dayName = (new Date(this.yearSelected, this.monthSelected, this.daySelected)).getDay();
        let now2 = new Date(this.yearSelected, this.monthSelected, this.daySelected)
        this.sharedDataService.monthSelected = this.monthSelected;
        this.sharedDataService.daySelected = this.daySelected;
        this.sharedDataService.dayName = this.dayName
        this.sharedDataService.sentUpdateToCalendar();

    }

    public nextMonth(aum: number) {
        this.monthSelected += aum;
        if (this.monthSelected < 0) {
            this.yearSelected -= 1;
            this.monthSelected = 11;
        }
        if (this.monthSelected > 11) {
            this.yearSelected += 1;
            this.monthSelected = 0;
        }
        this.sharedDataService.yearSelected = this.yearSelected;
        this.sharedDataService.monthSelected = this.monthSelected;
        this.sharedDataService.sentUpdateToCalendar();
    }

    public changeFilter(e) {
        if (e === undefined) {
            this.lotsFromServer = this.lotsFromServerOriginal
        } else {
            this.selectedFilter = e;
            this.filterLots()
        }
    }

    public filterLots() {
        this.lotsFromServer = JSON.parse(JSON.stringify(this.lotsFromServerOriginal));
        if (this.stringFilter === '') {
            return;
        }
        var newLots = []
        if (this.selectedFilter === 'lots') {
            this.lotsFromServer.forEach(element => {
                if ((element.name).toUpperCase().includes((this.stringFilter).toUpperCase())) {
                    newLots.push(element)
                }
            });
        }
        if (this.selectedFilter === 'variety') {
            this.lotsFromServer.forEach(element => {
                if ((element.varietyName).toUpperCase().includes((this.stringFilter).toUpperCase())) {
                    newLots.push(element)
                }
            });
        }
        if (this.selectedFilter === 'fields') {
            this.lotsFromServer.forEach(element => {
                if ((element.fieldName).toUpperCase().includes((this.stringFilter).toUpperCase())) {
                    newLots.push(element)
                }
            });
        }
        this.lotsFromServer = newLots

        //
    }

    public inputFilter(event) {
        this.stringFilter = event.target.value;
        this.filterLots();
    }

    public changeCalendarMode(e) {
        this.selectedCalendarButton = e;
        this.sharedDataService.sentUpdateToCalendar();
        // if (this.selectedCalendarButton === 'year') {
        //     this.sharedDataService.sentUpdateToCalendar();
        // }
    }

    public lotZoom = false;
    public selectLot(lot) {
        if (!lot.selected) {
            lot.selected = true;
            this.lotZoom = true;
            this.lotsFromServer = [lot]
        } else {
            lot.selected = false;
            this.lotZoom = false;
            this.lotsFromServer = this.lotsFromServerOriginal;
        }

    }

    public isCalendarOpened = false;
    public openCalendar() {
        if (this.isCalendarOpened) {
            return;
        } else {
            this.isCalendarOpened = true;
        }
        const dialogRef = this.dialog.open(CalendarAvailabilityComponent, {
            height: '370px',
            width: '400px',
            disableClose: true,
            panelClass: 'custom-dialog-container',
            data: null
        });

        var g = dialogRef.afterClosed().subscribe(async result => {
            if (result === undefined) {
            }

            if (result) {
                if (result !== undefined) {
                    if (result.id === -1) {
                        delete result.id
                        await this.calendarService.createGlobalCalendar(result)
                    } else {
                        await this.calendarService.updateGlobalCalendar(result)
                    }
                }
            }

            if (result === false) {

            }
            this.isCalendarOpened = false;
            g.unsubscribe();
        });
    }
    @ViewChild('scrollFields') scrollFields: ElementRef;
    public updateScroll() {
        const scrollOne = this.scrollFields.nativeElement as HTMLElement;
        this.sharedDataService.scrollVertical = scrollOne.scrollTop;
    }
}

export interface Mensaje {
    mensaje: ''
}
@Component({
    selector: 'confirmation-dialog',
    templateUrl: './calendar.html',
})
export class CalendarDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: Mensaje) { }
}