import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@src/environments/environment';
import { AgrometNotifyQuery } from './agromet-notify.query';
import { AgrometNotifyStore, AgrometNotify } from './agromet-notify.store';

export interface NotifyBody {
    statusCode: number,
    error: string,
    message: AgrometNotify[]
}

@Injectable({ providedIn: 'root' })
export class AgrometNotifyService {

    constructor(
        private http: HttpClient,
        private readonly agrometNotifyQuery: AgrometNotifyQuery,
        private readonly agrometNotifyStore: AgrometNotifyStore
    ) {
    }

    public async findAll(): Promise<AgrometNotify[]> {
        const hasCache = this.agrometNotifyQuery.getHasCache();
        if (hasCache) {
            return ;
        } else {
            try {
                const data = await this.http.get<NotifyBody>(`${environment.apiUrl}/alert/agromet/`).toPromise<NotifyBody>();
                data.message.forEach(element => {
                    element.notification_id = parseInt(element.id)
                    element.id = element.lot
                });
                this.agrometNotifyStore.set(data.message)
                return data.message;
            } catch (error) {
                console.log(error)
            }
        }
    }

    public async postAgrometNotification(detail) {
        try {
            const rpt: any = await this.http.post(`${environment.apiUrl}/alert/agromet/`, detail).toPromise();
            rpt.message.notification_id = parseInt(rpt.message.id)
            rpt.message.id = rpt.message.lot
            this.agrometNotifyStore.add(rpt.message)
            return rpt.message;
        } catch (error) {
            console.log(error)
        }
    }

    public async putAgrometNotification(detail) {
        try {
            const rpt: any = await this.http.put(`${environment.apiUrl}/alert/agromet/`, detail).toPromise();
            rpt.message.notification_id = parseInt(rpt.message.id)
            rpt.message.id = rpt.message.lot
            this.agrometNotifyStore.update(rpt.message.lot, rpt.message)
            return rpt.message;
        } catch (error) {
            console.log(error)
        }
    }

    public async deleteTicket(id, id_lot) {
        try {
            const info = {
                "id": id, "lot": id_lot
            }
            const options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                body:
                    info
            };
            const rpt: any = await this.http.delete(`${environment.apiUrl}/alert/agromet/`, options).toPromise();
            this.agrometNotifyStore.remove(id_lot)
            return rpt.message;

        } catch (error) {
            console.log(error)
        }
    }
}
