import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { MatMenuModule } from '@angular/material/menu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CookieService } from 'ngx-cookie-service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { MatSelectModule } from '@angular/material/select';

// PrimeNg
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
// NgxSpinner
import { NgxSpinnerModule } from 'ngx-spinner';

// Sidebar
import { SidebarModule } from 'ng-sidebar';

// Environment
import { environment } from '@src/environments/environment';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { ChangeEmailDialog, NavbarComponent } from './layouts/navbar/navbar.component';
import { ChartsModule } from 'ng2-charts';
// Guards
import { AuthGuard } from '@app/guards/auth.guard';

// Interceptors
import { JwtInterceptor } from '@app/interceptors/jwt.interceptor';
import { ErrorInterceptor } from '@app/interceptors/error.interceptor';
import { SharedMapData } from './modules/my-fields/modules/fields/components/main-map/sharedMapData';

//Google and facebook
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { MyFieldsNotificationsComponent } from './layouts/navbar/notification-components/my-fields-notifications/my-fields-notifications.component';
import { AgrodatNotificationsComponent } from './layouts/navbar/notification-components/agrodat-notifications/agrodat-notifications.component';
import { AgrometNotificationsComponent } from './layouts/navbar/notification-components/agromet-notifications/agromet-notifications.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DialogInformationNotification } from './layouts/navbar/notification-components/information-dialog/noti-information-dialog';
import { DialogConfirmationNotification } from './layouts/navbar/notification-components/confirmation-dialog/noti-confirm-dialog';
import {MatBadgeModule} from '@angular/material/badge'; 
import {MatListModule} from '@angular/material/list'; 
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatExpansionModule } from '@angular/material/expansion';
import { MyFieldsModule } from './modules/my-fields/my-fields.module';
import { FieldsModule } from './modules/my-fields/modules/fields/fields.module';

let config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('598193257475-k7ja1lpmm464h7duefi8pidaqf5eifk9.apps.googleusercontent.com'),
    },
    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('4156676937731554'),
    },
]);

export function provideConfig() {
    return config;
}

@NgModule({
    imports: [
        MatFormFieldModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SidebarModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        MatMenuModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        // Leaflet
        LeafletModule.forRoot(),
        LeafletDrawModule.forRoot(),
        // Flex layout
        FlexLayoutModule,
        // PrimerNg
        ToastModule,
        // NgxSpinner
        NgxSpinnerModule,
        ChartsModule,
        SocialLoginModule,
        MatButtonModule,
        MatDialogModule,
        InputTextModule,
        InputSwitchModule,
        MatInputModule,
        MatIconModule,
        MatTabsModule,
        DropdownModule,
        ButtonModule,
        MatSelectModule,
        NgApexchartsModule,
        MatBadgeModule,
        MatListModule,
        MatExpansionModule,
        MyFieldsModule,
        FieldsModule

    ],
    declarations: [
        AppComponent,
        HomeLayoutComponent,
        SidebarComponent,
        NavbarComponent,
        ChangeEmailDialog,
        MyFieldsNotificationsComponent,
        AgrodatNotificationsComponent,
        AgrometNotificationsComponent,
        DialogConfirmationNotification,
        DialogInformationNotification,

    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig,
        },
        // PrimeNg
        MessageService,
        SharedMapData,
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    public constructor() {}
}
