import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NominatimService {
  private urlApi = 'https://nominatim.openstreetmap.org';
  constructor(private http: HttpClient) {}

  public getLatLong(place: any): Observable<any> {
    const notAdmit =['residential', 'hamlet', 'park']
    return this.http.get<any>(`${this.urlApi}/search?q=${place}%2C%20PERU&polygon_geojson=1&format=jsonv2`);
    // .pipe(
    //   map((response) => {
    //     const lat = response?.lat; 
    //     const long = response?.long; 

    //     return { lat, long };
    //   }),
    // );
  }
}
