import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalToastService } from '@src/app/shared/services/global-toast-service/global-toast.service';
import { PhenologicalDataStore } from '@src/app/store/pheno-info/phenological-data.store';
import { environment } from '@src/environments/environment';
import { PhenologicalStateQuery } from './phenological-state.query';
import { PhenologicalState, PhenologicalStateStore } from './phenological-state.store';

export interface PhenologicalData {
    id: number,
    lot_id: string,
    season: string,
    type: string,
    classification: string,
    sowing_date: string,
    pruning_date: string,
    rainfall_date: string
}

@Injectable({ providedIn: 'root' })
export class PhenologicalStateService {
    public constructor(
        private readonly phenologicalStateStore: PhenologicalStateStore,
        private phenologicalStateQuery: PhenologicalStateQuery,
        private http: HttpClient,
        private readonly globalToastService: GlobalToastService,
        private readonly phenologicalDataStore: PhenologicalDataStore
    ) { }

    public async findAll(): Promise<PhenologicalState[]> {
        const hasCache = this.phenologicalStateQuery.getHasCache();
        if (hasCache) {
            return;
        }
        try {
            const data = await this.http
                .get<PhenologicalState[]>(`${environment.apiUrlAgroDat}/crop_phase/`)
                .toPromise<PhenologicalState[]>();
            const products = data.map((currentActivity: PhenologicalState) => {
                return currentActivity;
            });
            for (let index = 0; index < products.length; index++) {
                products[index].id = products[index].product_id;
                products[index].detail.sort((a, b) => (a.minimun_days > b.minimun_days) ? 1 : ((b.minimun_days > a.minimun_days) ? -1 : 0))
                this.phenologicalStateStore.add(products[index])
            }
            return products;
        }
        catch (error) {
        }
    }
    public async findOnePhenologicalState(idPhenologicalState): Promise<PhenologicalData> {
        try {
            const data = await this.http.get<PhenologicalData>(`${environment.apiUrlAgroDat}/variety_detail/` + idPhenologicalState + "/")
                .toPromise<PhenologicalData>();
            this.phenologicalDataStore.add(data)
            return data;
        } catch (error) {
        }
    }

    public async updateOnePhenologicalState(idPhenologicalState, campaingId, season, classification, sowing_date, pruning_date, rainfall_date) {
        try {
            if (sowing_date === "") { sowing_date = null }
            if (pruning_date === "") { pruning_date = null }
            if (rainfall_date === "") { rainfall_date = null }
            const data = await this.http.put<PhenologicalData>(`${environment.apiUrlAgroDat}/variety_detail/` + idPhenologicalState + "/", {
                "lot_id": campaingId,
                "season": season,
                "classification": classification,
                "sowing_date": sowing_date,
                "pruning_date": pruning_date,
                "rainfall_date": rainfall_date
            }).toPromise<PhenologicalData>();
            this.phenologicalStateStore.update(data.id, data)
            this.globalToastService.createSuccessToast('', 'Se ha guardado la información');
            return data;
        } catch (error) {
        }
    }

    public async createOnePhenologicalState(campaingId, season, classification, sowing_date, pruning_date, rainfall_date) {
        try {
            if (sowing_date === "") { sowing_date = null }
            if (pruning_date === "") { pruning_date = null }
            if (rainfall_date === "") { rainfall_date = null }
            const data = await this.http.post<PhenologicalData>(`${environment.apiUrlAgroDat}/variety_detail/`, {
                "lot_id": campaingId,
                "season": season,
                "classification": classification,
                "sowing_date": sowing_date,
                "pruning_date": pruning_date,
                "rainfall_date": rainfall_date
            }).toPromise<PhenologicalData>();
            this.globalToastService.createSuccessToast('', 'Se ha guardado la información');
            return data;
        } catch (error) {
        }
    }
    public async deleteOne(id) {
        try {
            const data = await this.http.delete(`${environment.apiUrlAgroDat}/variety_detail/` + id + "/", {}).toPromise();
            //this.phenologicalDataStore.remove(id)
            return data;
        } catch (error) {
        }

    }
}
