import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ServiceState, Service, ServiceStore } from './serv-other-storage.store'

@Injectable({ providedIn: 'root' })

export class ServiceStorageQuery extends QueryEntity<ServiceState> {
    public selectAllFields: Observable<Service[]> = this.selectAll();
    constructor(protected store: ServiceStore) {
        super(store);
    }

}
