import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ResourceManagementComponent } from './pages/resource-management/resource-management.component';
import { ActivityManagementComponent } from './pages/activity-management/activity-management.component';
import { StorageManagementComponent } from './pages/storage-management/storage-management.component';
import { AuthGuard } from '@src/app/core/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		component: MainPageComponent,
		children: [
			{
				path: 'agromonitor',
				loadChildren: () =>
					import('./agromonitor.module').then(
						(m) => m.AgromonitorModule
					),
			}
		],
	},
	{
		path: 'resource-management',
		component: ResourceManagementComponent,
		children: [
			{
				path: 'agromonitor/resource-management',
				loadChildren: () =>
					import('./agromonitor.module').then(
						(m) => m.AgromonitorModule
					),
			}
		],
		canActivate: [AuthGuard]
	},
	{
		path: 'activity-management',
		component: ActivityManagementComponent,
		children: [
			{
				path: 'agromonitor/activity-management',
				loadChildren: () =>
					import('./agromonitor.module').then(
						(m) => m.AgromonitorModule
					),
			}
		],
		canActivate: [AuthGuard]
	},
	{
		path: 'storage-management',
		component: StorageManagementComponent,
		children: [
			{
				path: 'agromonitor/storage-management',
				loadChildren: () =>
					import('./agromonitor.module').then(
						(m) => m.AgromonitorModule
					),
			}
		],
		canActivate: [AuthGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AgromonitorRoutingModule { }
