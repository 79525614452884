import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { SatellitalImageState, SatellitalImageStore } from './satellital-images-state.store';
//import { MyFieldsModals } from '@modules/my-fields/enums/my-fields.enum';

@Injectable({ providedIn: 'root' })
export class SatellitalImageQuery extends Query<SatellitalImageState> {

	public selectSelectedResource: Observable<SatellitalImageState> = this.select(
		(x) => x
	);

	public constructor(protected store: SatellitalImageStore) {
		super(store);
	}
}
