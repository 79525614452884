import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Services } from '@shared/enums/services';

export interface SidebarState {
	selectedService: Services;
	isOpen: boolean;
	isDock: boolean;
}

export function createInitialState(): SidebarState {
	return {
		selectedService: 2,
		isOpen: false,
		isDock: false
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sidebar' })
export class SidebarStore extends Store<SidebarState> {
	public constructor() {
		super(createInitialState());
	}

	public updateSelectedService(service: Services) {
		this.update({
			selectedService: service
		});
	}

	public open() {
		this.update({
			isOpen: true
		});
	}

	public toggle() {
		this.update(x => ({
			isOpen: !x.isOpen
		}));
	}

	public close() {
		this.update({
			isOpen: false
		});
	}

	public updateDock(dock: boolean) {
		this.update({
			isDock: dock
		})
	}
}
